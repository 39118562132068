import React, { Component } from "react";
import AttBar from "./attBar";

class AttBarGroup extends Component {
  state = {
    fighter: this.props.fighter,
    type: this.props.type,
  };

  renderCard() {
    const { fighter, type } = this.state;

    return (
      <div className="attributes">
        {type !== "Short" ? (
          <>
            <div className="baseAtt">
              <div>
                HP: <strong>{fighter.HP}</strong>
              </div>
            </div>
            <AttBar type="Stamina" amount={fighter.Stamina} />
          </>
        ) : null}

        <div className="stats">
          <div className="column">
            <AttBar type="Intellect" amount={fighter.Intellect} />
            <AttBar type="Strength" amount={fighter.Strength} />
          </div>
          <div className="column">
            <AttBar type="Defense" amount={fighter.Defense} />
            <AttBar type="Attack" amount={fighter.Attack} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default AttBarGroup;
