import React, { Component } from "react";

class AttBar extends Component {
  state = {
    type: this.props.type,
    amount: this.props.amount,
  };

  //{/*Stamina max 15*/}
  //{/*Intellect max 17*/}
  //{/*Strength max 19*/}
  //{/*Defense max 17*/}
  //{/*Attack max 29*/}

  handleWidth() {
    const { type, amount } = this.state;
    let max;

    switch (type) {
      case "Intellect":
        max = 17;
        break;
      case "Strength":
        max = 19;
        break;
      case "Defense":
        max = 17;
        break;
      case "Attack":
        max = 29;
        break;
      case "Stamina":
      default:
        max = 15;
        break;
    }

    return (amount / max) * 100 + "%";
  }

  renderCard() {
    const { type, amount } = this.state;

    return (
      <div className="att">
        <label>
          {type.substring(0, 3)}({amount})
        </label>
        <div className="attWrapper">
          <div className="attBar" style={{ width: this.handleWidth() }}></div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default AttBar;
