import React, { Component } from "react";

// SORTING
function sortList(list, att, asc) {
  var ul = document.getElementById(list);

  if (!ul) {
    console.log("Can not find: ", list);
    return;
  }

  Array.from(ul.getElementsByTagName("LI"))
    .sort((a, b) => {
      return asc
        ? a.getAttribute(att) - b.getAttribute(att)
        : b.getAttribute(att) - a.getAttribute(att);
    })
    .forEach((li) => ul.appendChild(li));
}

class SortBar extends Component {
  render() {
    return (
      <div id="list-sorting">
        <button onClick={() => sortList("booCollection", "power", false)}>
          Power
        </button>
        <button onClick={() => sortList("booCollection", "rank", true)}>
          Rank
        </button>
        <button onClick={() => sortList("booCollection", "boo-id", true)}>
          ID
        </button>
      </div>
    );
  }
}

export default SortBar;
