import React, { Component } from "react";
import AssetCard from "./assetCard";

const wpapers = [
  "boo-horns.png",
  "cangaceiro.png",
  "owl.png",
  "purple-ribs.png",
  "spider.png",
  "spikes.png",
  "angel.png",
  "demon.png",
  "evil-santa.png",
  "faceless.png",
  "Jason-bloody.png",
  "Jason.png",
  "monster.png",
  "panther.png",
  "pregos.png",
  "skeleton.png",
];

class AssetCollection extends Component {
  state = {
    id: this.props.id,
    title: this.props.title,
  };

  render() {
    const { id, title } = this.state;
    return (
      <>
        <h2>{title}</h2>
        <div className="collection" id={id}>
          <div className="wrapper">
            {wpapers.map((i) => (
              <AssetCard key={i} id={i} />
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default AssetCollection;
