import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import ConnectButton from "../components/connector";

function Navbar() {
  return (
    <header>
      <nav className="main">
        <ul>
          <li className="siteLogo">
            <Link to="/">
              <img src="/images/logo/boo-things.svg" />
            </Link>
          </li>
          <li className="menu">
            <ul>
              <li>
                <Link to="/">
                  <img src="/images/logo/boo-things.svg" />
                </Link>
              </li>
              <li>
                <Link to="/">Boo</Link>
              </li>
              <li>
                <Link to="/rank">Rank</Link>
              </li>
              <li>
                <Link to="/boo-cup">Cup</Link>
              </li>
            </ul>
          </li>
          <li className="wallet">
            <ConnectButton />
          </li>
        </ul>
      </nav>
      <nav className="mobile">
        <button>menu</button>
      </nav>
    </header>
  );
}

export default Navbar;
