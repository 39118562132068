import React, { Component } from "react";

class NFTCardPFP extends Component {
  renderCard() {
    const { id, former } = this.props;

    return (
      <>
        <li key={id} className="boo-card pfp" boo-id={id}>
          <div className="boo-number">Boo PFP #{id}</div>

          <div className="image-wrapper">
            <a
              href={
                former === true
                  ? "https://opensea.io/assets/matic/0xbf4c805aee2d811d6b9a1b0efe7ca527f231ed41/" +
                    id
                  : "https://opensea.io/assets/matic/0xbf4c805aee2d811d6b9a1b0efe7ca527f231ed41/" +
                    id
              }
              alt={"Check it Boo Things PFP #" + id + " at OpenSea"}
              title={"Check it Boo Things PFP #" + id + " at OpenSea"}
              target="_blank"
            >
              <div className="tool opensea"></div>
            </a>
            <a
              href={
                former === true
                  ? "https://desenhoeilustracao.com.br/wp-content/boo-pfp/full/former-boo-pfp-" +
                    id +
                    ".png"
                  : "https://desenhoeilustracao.com.br/wp-content/boo-pfp/full/boo-pfp-" +
                    id +
                    ".png"
              }
              alt={"Download the full size image of Boo PFP #" + id}
              title={"Download the Full size image of Boo PFP #" + id}
              target="_blank"
              download={"Boo PFP #" + id}
            >
              <div className="tool download"></div>
            </a>
            <img
              src={
                former === true
                  ? "https://desenhoeilustracao.com.br/wp-content/boo-pfp/400/former-boo-pfp-" +
                    id +
                    ".png"
                  : "https://desenhoeilustracao.com.br/wp-content/boo-pfp/400/boo-pfp-" +
                    id +
                    ".png"
              }
            />
          </div>
        </li>
      </>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default NFTCardPFP;
