export const CONFIG = {
  CONTRACTS: {
    BOO_THINGS: "0xEd9B49afaC032401F15527C42C6c54931aa6571A",
    BOO_THINGS_TESTNET: "0x412aa40c39dDBce6c05c471967a459A485C29E4c",
    BOO_GLADIATORS: "",
    BOO_FORGE: "",
    BOO_PFP: "0xbF4C805aEe2d811d6b9A1b0EfE7CA527f231Ed41",
    BOO_PFP_FORMER: "0x141F881DA9D89402146e16d24e10832D225554F2",
    BOO_CUP: "0x8041C98503cc2f35126872E59673ac58457F8579",
    OPENSEA_POLYGON: "0x2953399124F0cBB46d2CbACD8A89cF0599974963",
  },
  NETWORK: {
    NAME: "Polygon Mainet",
    ID: 137,
  },
  TOKENS: {
    BOO_PFP_CURRENT_TOKEN_ID: 11,
  },
};
