import React, { Component } from "react";

const addresSubstring = 5;

class NavBar extends Component {
  render() {
    const { address, nftCount } = this.props;

    return (
      <div className="walletBar">
        {address === undefined ? (
          <button className="btnConnect" onClick={this.props.onConnect}>
            Connect Wallet
          </button>
        ) : (
          <>
            <div className="icon"></div>
            <div className="walletAddress">
              {address.substring(0, addresSubstring)}...
              {address.substring(
                address.length - addresSubstring,
                address.length
              )}
            </div>
            <div className="tokenCount">({nftCount} NFTs)</div>
          </>
        )}
      </div>
    );
  }
}

export default NavBar;
