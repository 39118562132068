import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

function BooCupAlbum() {
  const [booCupCollection, setBooCupCollection] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  // ///
  // ///
  // /// BOO PFP
  // ///
  // ///
  const fetchBooCupCollection = async () => {
    let maxID = 40;
    let ids = [];
    let addresses = [];

    for (let i = 1; i <= maxID; i++) {
      ids.push(i);
      addresses.push(blockchain.account);
    }

    try {
      const tokenCount = blockchain.booCupSmartContract.methods
        .balanceOfBatch(addresses, ids)
        .call()
        .then((receipt) => {
          setBooCupCollection(receipt);
          console.log(receipt);
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  // // Called for every wallet change
  useEffect(() => {
    if (!blockchain.account) {
      return; // Waiting for wallet to connect
    }

    fetchBooCupCollection();
  }, [blockchain.account]);

  return (
    <>
      {blockchain.account == null ? (
        <h2>⚠️ Please, connect your Wallet to see your Boo Cup Album</h2>
      ) : (
        <>
          <h2>Boo Cup Album</h2>
        </>
      )}

      <div className="boocup-collection">
        {booCupCollection.map((i, index) => (
          <div key={index} className="boocup-card">
            <div className="image-wrapper">
              {i > 0 ? <div className="count">{"x" + i}</div> : null}
              <img
                className={i > 0 ? "owned" : "missing"}
                src={
                  "https://www.desenhoeilustracao.com.br/wp-content/boo-cup/600/" +
                  (index + 1) +
                  ".png"
                }
              />
            </div>
            <footer className="toolbar">
              <a
                href={
                  "https://opensea.io/assets/matic/0x8041c98503cc2f35126872e59673ac58457f8579/" +
                  (index + 1)
                }
                title="Check this NFT on OpenSea"
                target="_blank"
              >
                Check on OpenSea
              </a>
              {i > 0 ? (
                <>
                  <a
                    href={
                      "https://www.desenhoeilustracao.com.br/wp-content/boo-cup/original/" +
                      (index + 1) +
                      ".png"
                    }
                    title="Download this Boo Cup Scene"
                    alt="Download this Boo Cup Scene"
                    target="_blank"
                    download
                  >
                    Download
                  </a>
                  {/* <a
                    className="disabled"
                    href="#"
                    title="Trade this card - SOON"
                    alt="Trade this card - SOON"
                  >
                    Trade
                  </a> */}
                </>
              ) : null}
            </footer>
          </div>
        ))}
      </div>
    </>
  );
}

export default BooCupAlbum;
