import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Collection from "./pages/Collection";
import BooCupAlbum from "./pages/BooCupAlbum";
import BooRank from "./pages/BooRank";
import Battle from "./pages/Battle";
import TeamBattle from "./pages/TeamBattle";
import NoPage from "./pages/NoPage";
import store from "./redux/store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Collection />} />
            <Route path="boo-cup" element={<BooCupAlbum />} />
            <Route path="rank" element={<BooRank />} />
            <Route path="battle" element={<Battle />} />
            <Route path="teambattle" element={<TeamBattle />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster />
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
