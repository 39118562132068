import React, { Component } from "react";
import AttBar from "./attBar";
import AttBarGroup from "./attBarGroup";

class TeamBattleCard extends Component {
  state = {
    fighter: this.props.fighter,
    hitPower: this.props.hitPower,
  };

  showHitPower(power) {
    return power[0] + "-" + power[power.length - 1];
  }

  renderCard() {
    const { fighter, hitPower } = this.state;
    // console.log(fighter.Id);
    return (
      <div className="team battleCard boo-card">
        <div className="cardWrapper">
          <div className="wrapperColumn image">
            <img
              src={
                "https://desenhoeilustracao.com.br/wp-content/boo-things/" +
                fighter.Id +
                ".png"
              }
            />
          </div>
          <div className="wrapperColumn attributes">
            <div className="boo-number">
              Boo #{fighter.Id} (HP: {fighter.HP})
            </div>
            <div className="boo-number"></div>

            <AttBarGroup fighter={fighter} type={"Short"} />

            <div className="base-attr">
              <div>
                Hit: <strong>{this.showHitPower(hitPower)}</strong>
              </div>
              <div>
                Miss: <strong>{(fighter.Miss * 100).toFixed(1)}%</strong>
              </div>
              <div>
                Crit: <strong>{(fighter.Critic * 100).toFixed(1)}%</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default TeamBattleCard;
