import React, { Component } from "react";
import { BooAttributes, BooPower } from "../Battle/BooPower";
import AttBarGroup from "../Battle/Components/attBarGroup";

class NFTCard extends Component {
  state = {
    type: this.props.type,
    id: this.props.id,
    rank: this.props.rank,
    power: this.props.power,
  };

  renderCardActions() {
    const { type, id, rank, power } = this.state;

    return (
      <div className="card-actions">
        <div className="card-action boo-power" title="Combat Power">
          <div className="icon"></div>
          <div className="value">{power}</div>
        </div>
        <div
          className="card-action boo-rank"
          title="Rank of your Boo - Based on it's traits"
        >
          <div className="icon"></div>
          <div className="value">{rank}</div>
        </div>
      </div>
    );
  }

  renderCardAttributes() {
    return (
      <>
        <div className="xpbar base">
          <div
            className="xpbar current"
            style={{ width: "50%" }}
            title="300 XP"
          ></div>
          <div
            className="xpbar pendent"
            style={{ width: "60%" }}
            title="300 XP to collect"
          ></div>
        </div>

        <div className="attributes">
          <div className="main_attributes">
            <div className="att" title="Health Points">
              <strong>❤️ HP:</strong> 120
            </div>
            <div className="att" title="Power">
              <strong>👊 POWER:</strong> 40
            </div>
          </div>
          <div className="minor_attributes">
            <div className="att" title="Stamina">
              <strong>🔋 Sta:</strong> 10
            </div>
            <div className="att" title="Intellect">
              <strong>🧠 Int:</strong> 10
            </div>
            <div className="att" title="Strenght">
              <strong>💪 Str:</strong> 10
            </div>
            <div className="att" title="Defense">
              <strong>🛡 Def:</strong> 10
            </div>
          </div>
        </div>

        <div className="gearItems">
          <div className="column left">
            <div className="gearItem HEAD"></div>
            <div className="gearItem NECK"></div>
            <div className="gearItem CHEST"></div>
            <div className="gearItem WAIST"></div>
            <div className="gearItem unavailable LEGS"></div>
            <div className="gearItem unavailable FEET"></div>
          </div>
          <div className="column right">
            <div className="gearItem HANDS"></div>
            <div className="gearItem TRINKET_LEFT"></div>
            <div className="gearItem TRINKET_RIGHT"></div>
            <div className="gearItem RING_LEFT"></div>
            <div className="gearItem RING_RIGHT"></div>
            <div className="gearItem unavailable PET"></div>
          </div>
        </div>
      </>
    );
  }

  renderCard() {
    const { type, id, rank, power } = this.state;

    return (
      <>
        <li
          key={id}
          className={type === "gladiators" ? "boo-card gladiator" : "boo-card"}
          rank={rank}
          power={power}
          boo-id={id}
        >
          <div className="boo-number">
            {type === "gladiators" ? "Gladiator #" : "Boo #"}
            {id}
          </div>

          <div className="image-wrapper">
            <a
              href={
                "https://opensea.io/assets/matic/0xed9b49afac032401f15527c42c6c54931aa6571a/" +
                id
              }
              alt={"Check it Boo Things #" + id + " at OpenSea"}
              title={"Check it Boo Things #" + id + " at OpenSea"}
              target="_blank"
            >
              <div className="tool opensea"></div>
            </a>
            <a
              href={
                "https://desenhoeilustracao.com.br/wp-content/boo-things-1200/" +
                id +
                ".png"
              }
              alt={"Download the 1200px image of Boo #" + id}
              title={"Download the 1200px image of Boo #" + id}
              target="_blank"
              download={"Boo Things #" + id + "-1200.png"}
            >
              <div className="tool download"></div>
            </a>
            <img
              src={
                "https://desenhoeilustracao.com.br/wp-content/boo-things/" +
                id +
                ".png"
              }
            />
          </div>

          {type === "gladiators"
            ? this.renderCardAttributes()
            : this.renderCardActions()}

          <footer className="toolbar">
            {type === "gladiators" ? (
              <>
                <button>Collect XP</button>
                <button>Dismiss</button>
              </>
            ) : (
              <>
                <div className="attToolbar">
                  <button
                    className="summon-gladiator"
                    disabled
                    title="Coming Soon!"
                  >
                    Summon Gladiator
                  </button>
                  <button
                    className="attBtn"
                    title="Battle Attributes"
                    alt="Battle Attributes"
                    onClick={this.props.onClickAttributes}
                  ></button>
                </div>
                {this.props.showAttributes ? (
                  <AttBarGroup fighter={BooAttributes(id)} />
                ) : null}
              </>
            )}
          </footer>
        </li>
      </>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default NFTCard;
