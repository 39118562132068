import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import { CONFIG } from "../config/config";
import NftCollection from "../components/nftCollection";
import SortBar from "../components/sortBar";
import NavBar from "../components/navBar";
import toast from "react-hot-toast";
import AssetCollection from "../components/assetCollection";
import {
  GetMaxDitos,
  GetDitoIDs,
  GetQueryAddressArray,
} from "../collections/ditos";

function Collection() {
  const [fetchingBalance, setSetchingBalance] = useState(false);

  const [walletAddress, setWalletAddress] = useState();

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const [booPFPFormerTokensCount, setBooPFPFormerTokensCount] = useState(0);
  const [booPFPFormerCollectionKey, setBooPFPFormerCollectionKey] = useState(
    "booPFPFormerCollection"
  );
  const [booPFPFormerCollection, setBooPFPFormerCollection] = useState([]);

  const [booPFPTokensCount, setBooPFPTokensCount] = useState(0);
  const [booPFPCollectionKey, setBooPFPCollectionKey] =
    useState("booPFPCollection");
  const [booPFPCollection, setBooPFPCollection] = useState([]);
  let boopfps = [];

  const [booTokensCount, setBooTokensCount] = useState(0);
  const [booCollection, setBooCollection] = useState(false);
  const [booCollectionKey, setBooCollectionKey] = useState("booCollection");
  const [booThingsReady, setBooThingsReady] = useState(false);
  let boothings = [];
  let currentBoo = 0;

  const loadingToast = toast;

  const [showAttributes, setShowAttributes] = useState(false);

  /////
  /////
  ///// BOO THINGS
  /////
  /////
  const fetchCollectionCount = async () => {
    if (fetchingBalance) {
      console.log("Already fetching collection");
      return;
    }

    setSetchingBalance(true);

    try {
      blockchain.smartContract.methods
        .balanceOf(blockchain.account)
        .call()
        .then((receipt) => {
          setBooTokensCount(receipt);
          setSetchingBalance(false);
        });
    } catch (error) {
      console.log(error);
      setSetchingBalance(false);
    }
  };

  //
  //
  //
  const lazyFetchBooCollection = async () => {
    if (currentBoo >= parseInt(booTokensCount)) {
      setBooCollection(boothings);
      setBooThingsReady(true);
      setBooCollectionKey("booCollectionReady");
      loadingToast.dismiss();
      toast.success("All Ghosts Collected", { duration: 4000 });
      return;
    }
    // setBooThingsReady(false);
    setBooCollection(boothings);
    setBooCollectionKey("booCollectionfetching" + currentBoo);

    loadingToast.loading(
      "Hunting " + booTokensCount + " Boos (" + currentBoo + ")",
      { id: loadingToast }
    );

    try {
      blockchain.smartContract.methods
        .tokenOfOwnerByIndex(blockchain.account, currentBoo)
        .call()
        .then((receipt) => {
          boothings.push(receipt);

          currentBoo++;
          lazyFetchBooCollection();
        });
    } catch (error) {
      console.log(error);
    }
  };

  ///
  ///
  /// BOO PFP FORMER
  ///
  ///
  const fetchBooPFPFormerCount = async () => {
    let ids = [1];
    let addresses = [blockchain.account];

    try {
      const tokenCount = blockchain.booPFPFormerSmartContract.methods
        .balanceOfBatch(addresses, ids)
        .call()
        .then((receipt) => {
          // console.log("fetchBooPFPFormerCount balanceOf Token", receipt);
          setBooPFPFormerCollectionKey("BooPFPFormerCollectionLoaded");
          if (receipt[0] > 0) {
            setBooPFPFormerCollection(receipt);
            setBooPFPFormerTokensCount(receipt.length);
          }
        });
    } catch (error) {
      console.log("fetchBooPFPFormerCount", error);
    }
  };

  ///
  ///
  /// BOO PFP
  ///
  ///
  const fetchBooPFPCount = async () => {
    let maxID = parseInt(CONFIG.TOKENS.BOO_PFP_CURRENT_TOKEN_ID);
    let ids = [];
    let addresses = [];

    for (let i = 1; i <= maxID; i++) {
      ids.push(i);
      addresses.push(blockchain.account);
    }

    try {
      const tokenCount = blockchain.booPFPSmartContract.methods
        .balanceOfBatch(addresses, ids)
        .call()
        .then((receipt) => {
          // console.log("fetchBooPFPCount balanceOf Token", receipt);
          setPFPIds(receipt);
        });
    } catch (error) {
      console.log("fetchBooPFPCount", error);
    }
  };

  //
  //
  //
  function setPFPIds(balance) {
    let i = 1;
    balance.forEach((e) => {
      if (parseInt(e) > 0) {
        boopfps.push(i);
        setBooPFPCollection(boopfps);
        setBooPFPCollectionKey("booPFPCollection" + i);
      }
      i++;
    });

    setBooPFPCollectionKey("booPFPCollectionDoneCounting");

    if (boopfps.length > 0) {
      toast.success("Boo PFPs Collected", { duration: 3000 });
      setBooPFPTokensCount(boopfps.length);
    }
  }

  //
  //
  // Attibutes
  //
  //
  function onClickAttributes() {
    setShowAttributes(!showAttributes);
    // console.log("showAttributes", showAttributes);
  }

  //
  //
  // DITO THE PUG
  //
  //
  // const fetchDitoCount = async () => {
  //   try {
  //     const tokenCount = blockchain.openSeaSmartContract.methods
  //       .balanceOfBatch(GetQueryAddressArray(blockchain.account), GetDitoIDs())
  //       .call()
  //       .then((receipt) => {
  //         console.log("fetchDitoCount balanceOf Token", receipt);
  //         // setPFPIds(receipt);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // Called for every wallet change
  useEffect(() => {
    if (!blockchain.account) {
      return; // Waiting for wallet to connect
    }

    fetchCollectionCount();
    fetchBooPFPCount();
    fetchBooPFPFormerCount();
    // fetchDitoCount();
    setWalletAddress(blockchain.account);
    // console.log("Conta conectou ou mudou", blockchain.account);
  }, [blockchain.account]);

  useEffect(() => {
    if (!blockchain.account || parseInt(booTokensCount) === 0) {
      return;
    }

    lazyFetchBooCollection();
    toast.loading("Hunting " + booTokensCount + " Boos", {
      id: loadingToast,
    });
  }, [booTokensCount]);

  const handleConnect = () => {
    dispatch(connect(CONFIG));
  };

  if (blockchain.account == null) {
    return (
      <>
        <h4>Please, Connect your Wallet</h4>
      </>
    );
  }

  // RETURN
  return (
    <>
      {/* <NavBar
        onConnect={handleConnect}
        address={walletAddress}
        nftCount={parseInt(booTokensCount) + parseInt(booPFPTokensCount)}
      /> */}

      {booTokensCount > 0 ? (
        <AssetCollection
          key={"wallpapers"}
          id="wallpapers"
          title="Wallpapers"
        />
      ) : null}

      {booPFPFormerTokensCount > 0 ? (
        <NftCollection
          key={booPFPFormerCollectionKey}
          type="booPFPFormer"
          collection={booPFPFormerCollection}
          id="booPFPFormerCollection"
          title={"Boo Former PFP (1)"}
        />
      ) : null}

      {booPFPCollection ? (
        <NftCollection
          key={booPFPCollectionKey}
          type="booPFP"
          collection={booPFPCollection}
          id="booPFPCollection"
          title={"Boo PFPs (" + booPFPTokensCount + ")"}
        />
      ) : null}

      <div>
        {booCollection ? (
          <>
            <NftCollection
              key={booCollectionKey}
              type="boo"
              collection={booCollection}
              id="booCollection"
              title={"Boo Things (" + booTokensCount + ")"}
              onClickAttributes={onClickAttributes}
              showAttributes={showAttributes}
            />
          </>
        ) : null}
        {booThingsReady ? <SortBar /> : null}
      </div>
    </>
  );
}

export default Collection;
