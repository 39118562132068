import React, { Component } from "react";
import AttBar from "./attBar";
import AttBarGroup from "./attBarGroup";

class BattleCard extends Component {
  state = {
    fighter: this.props.fighter,
    hitPower: this.props.hitPower,
    missChance: this.props.missChance,
    criticChance: this.props.criticChance,
  };

  renderCard() {
    const { fighter, hitPower, missChance, criticChance } = this.state;
    // console.log(fighter.Id);
    return (
      <div className="battleCard boo-card">
        <div className="boo-number">Boo #{fighter.Id}</div>
        <div className="card-image">
          <img
            src={
              "https://desenhoeilustracao.com.br/wp-content/boo-things/" +
              fighter.Id +
              ".png"
            }
          />
          <div className="health">
            HP: <strong>{fighter.HP}</strong>
          </div>
        </div>
        <AttBarGroup fighter={fighter} type={"Short"} />
        <div className="base-attr">
          <div>Max Hit: {hitPower.toFixed(2)}</div>
          <div>Miss: {(missChance * 100).toFixed(1)}%</div>
          <div>Critic: {(criticChance * 100).toFixed(1)}%</div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default BattleCard;
