import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BooAttributes } from "../Battle/BooPower";
import BattleArena from "../Battle/Components/battleArena";

import BattleCard from "../Battle/Components/battleCard";
import Wad from "web-audio-daw";

// Audio
// https://stackoverflow.com/questions/1933969/sound-effects-in-javascript-html5

// this.state = { opts: [] };
function Battle() {
  const baseMissChance = 0.2; //20%
  const baseCritChance = 0.01; //1%

  const [fHP, setFHP] = useState([0, 0]);
  const [opponents, setOpponents] = useState([]);
  const [combat, setCombat] = useState();
  const [cLog, setCLog] = useState();
  const [winner, setWinner] = useState([]);

  const sounds = new Wad({
    source: "./sounds/sounds.wav",
    sprite: {
      punch: [0, 0.5],
      critic: [0.5, 1],
      miss: [0.95, 1.3],
      punch2: [1.27, 1.64],
      punch3: [1.64, 1.99],
      punch4: [2.05, 2.5],
      punch5: [2.55, 3],
    },
    volume: 0.3,
  });

  const bgMusic = new Wad({
    source: "./sounds/music.wav",
    volume: 0.1,
  });

  useEffect(() => {
    //has both fighters
    if (opponents.length > 1) {
      setFHP([opponents[0].HP, opponents[1].HP]);
    }
  }, [opponents]);

  useEffect(() => {
    updateOpponents();
  }, []);

  //////
  //
  ////// Battle Calculations
  //
  //////
  function getFigthers(IDs) {
    let fters = [];
    for (let i = 0; i < IDs.length; i++) {
      fters.push(BooAttributes(IDs[i]));
    }
    return fters;
  }

  function getHitPower(fters) {
    return [
      fters[0].Attack - fters[1].Defense / 2,
      fters[1].Attack - fters[0].Defense / 2,
    ];
  }
  function getMissChance(fters) {
    return [
      baseMissChance - fters[0].Intellect / 100,
      baseMissChance - fters[1].Intellect / 100,
    ];
  }
  function getCriticChance(fters) {
    return [
      baseCritChance + fters[0].Intellect / 100,
      baseCritChance + fters[1].Intellect / 100,
    ];
  }

  function handleBattle(b1, b2) {
    if (b1 === b2) {
      toast.error("Can't fight himself");
      return []; // Can't fight itself
    }

    if (b1 < 1 || b1 > 10000 || b2 < 1 || b2 > 10000) {
      return []; // Out of range
    }

    // reset winner
    // setWinner(0);

    // figthers attributes
    let fters = getFigthers([b1, b2]);

    // console.log("fters", fters);

    // choose the first one to attack
    // TODO: handle same intellect
    let fMove = fters[0].Intellect < fters[1].Intellect ? 1 : 0;

    // console.log("fMove", fMove);

    //Calculate hit power
    let hPower = getHitPower(fters);

    // console.log("hPower", hPower);

    let mChance = getMissChance(fters);

    // console.log("mChance", mChance, mChance[0] * 100);

    let cChance = getCriticChance(fters);

    // console.log("cChance", cChance);

    // Round data
    const dThreshold = 3; // The amount of defence that will be used to random reduce the DMG
    let round = 0;
    let attacker = fMove;
    let hps = [fters[0].HP, fters[1].HP];
    // Combat Data
    let bLog = [];

    // console.log("hps", hps);

    // return;
    // calculate combat
    for (let i = 0; hps[0] > 0 && hps[1] > 0; i++) {
      round = i;

      let dmg = hPower[attacker]; // Define max damage
      let opponent = attacker == 0 ? 1 : 0;
      dmg = (
        dmg -
        (Math.random() * fters[opponent].Defense) / dThreshold
      ).toFixed(3); // Calculate real damage

      // console.log("Max DMG:", hPower[attacker], "Real DMG:", dmg);

      let initHPs = [hps[0], hps[1]];
      let missed = false;
      let critic = false;

      let diceMiss = Math.random(); // Check for miss
      if (diceMiss <= mChance[attacker]) {
        missed = true;
        dmg = 0; // missed
      }

      let diceCritic = Math.random(); // check for critic
      if (!missed && diceCritic <= cChance[attacker]) {
        critic = true;
        dmg = dmg * 2;
      }

      // Depploy the blow
      hps[opponent] = hps[opponent] - dmg;

      // Log battle
      bLog.push({
        round: round + 1,
        attacker: attacker, //fters[attacker].Id,
        opponent: opponent, //fters[opponent].Id,
        damage: dmg,
        iniHp: initHPs,
        curHp: [hps[0], hps[1]],
        miss: missed,
        critic: critic,
      });

      attacker = attacker == 0 ? 1 : 0;
    }

    let w = hps[0] < hps[1] ? 1 : 0;

    setWinner((winner) => [fters[w].Id, ...winner]);
    // console.log("WINNER", winner);

    // console.log("bLog", bLog);
    setCombat(bLog);

    return bLog;
  }

  //////
  //
  ////// SUPPORT FUNCTIONS
  //
  //////
  function getImputIDs() {
    let b1 = document.getElementById("b1").value;
    let b2 = document.getElementById("b2").value;

    // console.log("IDs", b1, b2);
    if (b1 < 1 || b2 < 1 || b1 > 10000 || b2 > 10000) {
      toast.error("ID out of range");
      return [0, 0];
    }

    return [b1, b2];
  }

  function performCombat() {
    let b1 = getImputIDs()[0];
    let b2 = getImputIDs()[1];

    handleBattle(b1, b2);
    bgMusic.play();
  }

  function updateOpponents() {
    let b1 = getImputIDs()[0];
    let b2 = getImputIDs()[1];

    setOpponents([BooAttributes(b1), BooAttributes(b2)]);
  }

  function getPercent(maxHP, curHP) {
    if (curHP <= 0) {
      return 0;
    }

    return (curHP / maxHP) * 100;
  }

  //////
  //
  ////// UI UPDATE
  //
  //////
  function resetUI() {
    let f1 = document.getElementById("fighter1");
    let f2 = document.getElementById("fighter2");
    f1.classList.remove("loser");
    f2.classList.remove("loser");

    setFHP([opponents[0].HP, opponents[1].HP]);
  }

  function setAttacker(attacker, miss) {
    let cA = document.getElementById(attacker ? "fighter2" : "fighter1");
    let cO = document.getElementById(attacker ? "fighter1" : "fighter2");
    cA.classList.add("turn");
    cO.classList.remove("turn");

    cA.classList.remove("gotHit");
    if (!miss) {
      cO.classList.add("gotHit");
    }
  }

  function showWinner() {
    console.log("******BATTLE ENDED*****");
    let lastRound = combat.length - 1;

    let f1 = document.getElementById("fighter1");
    let f2 = document.getElementById("fighter2");

    if (combat[lastRound].curHp[0] > combat[lastRound].curHp[1]) {
      f2.classList.add("loser");
    } else {
      f1.classList.add("loser");
    }
  }

  function showAttack(fghter, message, critical) {
    let cID = fghter === 0 ? "f1log" : "f2log";
    let container = document.getElementById(cID);
    container.innerHTML = critical
      ? "<div class='hitMessage criticalcHit'>Critic</div><div class='hitMessage'>" +
        message +
        "</div>"
      : "<div class='hitMessage'>" + message + "</div>";
  }

  //////
  //
  ////// Combat Animation
  //
  //////
  let c = 0;
  let tInterval;

  useEffect(() => {
    if (!combat) {
      return;
    }

    tInterval = setInterval(() => {
      if (c >= combat.length) {
        showWinner();

        clearInterval(tInterval);
        return;
      }

      setAttacker(combat[c].attacker, combat[c].miss);

      setFHP([combat[c].curHp[0], combat[c].curHp[1]]);

      let hitMessage = combat[c].miss ? "Miss" : "-" + combat[c].damage;
      showAttack(combat[c].opponent, hitMessage, combat[c].critic);

      if (combat[c].miss) {
        sounds.miss.play();
      } else {
        if (combat[c].critic) {
          sounds.critic.play();
        } else {
          switch (Math.floor(Math.random() * 5)) {
            case 1:
              sounds.punch2.play();
              break;
            case 2:
              sounds.punch3.play();
              break;
            case 3:
              sounds.punch4.play();
              break;
            case 4:
              sounds.punch5.play();
              break;

            default:
              sounds.punch.play();
              break;
          }
        }
      }

      console.log(combat[c]);
      c++;
    }, 1300);

    return () => clearInterval(tInterval);
  }, [combat]);

  function closeCombatWindow() {
    Wad.stopAll();
    clearInterval(tInterval);
    setCombat(null);
    resetUI();
  }

  return (
    <>
      <h1>Battlefield</h1>

      {opponents.length ? (
        <div className="figtersWrapper">
          <BattleCard
            key={opponents[0].Id + "f1" + getHitPower(opponents)[0]}
            fighter={opponents[0]}
            hitPower={getHitPower(opponents)[0]}
            missChance={getMissChance(opponents)[0]}
            criticChance={getCriticChance(opponents)[0]}
          />
          <BattleCard
            key={opponents[1].Id + "f2" + getHitPower(opponents)[1]}
            fighter={opponents[1]}
            hitPower={getHitPower(opponents)[1]}
            missChance={getMissChance(opponents)[1]}
            criticChance={getCriticChance(opponents)[1]}
          />
        </div>
      ) : null}

      <div className="battleToolBar">
        <div className="inputs">
          <input id="b1" defaultValue={1} onBlur={updateOpponents}></input>
          vs.
          <input id="b2" defaultValue={2} onBlur={updateOpponents}></input>
        </div>
        <button onClick={performCombat}>Fight</button>
      </div>

      {combat ? (
        <>
          <div className="combatWindow">
            <div className="combatContent">
              <div className="header">
                <h2>Arena</h2>
                <button id="closeButton" onClick={closeCombatWindow}>
                  X
                </button>
              </div>
              <div className="hpToolbar">
                <div
                  className={
                    "hpWrapper " +
                    (fHP[0] < opponents[0].HP / 3 ? "dangerGlow" : "")
                  }
                >
                  <div
                    id="hp1"
                    style={{
                      width: getPercent(opponents[0].HP, fHP[0]) + "%",
                    }}
                    className="hpBar"
                  ></div>
                  <div className="hpStatus">
                    {fHP[0] === opponents[0].HP
                      ? opponents[0].HP
                      : fHP[0] > 0
                      ? fHP[0].toFixed(3)
                      : "Dead"}
                  </div>
                </div>

                <div className="vs">VS</div>

                <div
                  className={
                    "hpWrapper " +
                    (fHP[1] < opponents[1].HP / 3 ? "dangerGlow" : "")
                  }
                >
                  <div
                    id="hp2"
                    style={{
                      width: getPercent(opponents[1].HP, fHP[1]) + "%",
                    }}
                    className="hpBar"
                  ></div>
                  <div className="hpStatus">
                    {fHP[1] === opponents[1].HP
                      ? opponents[1].HP
                      : fHP[1] > 0
                      ? fHP[1].toFixed(3)
                      : "Dead"}
                  </div>
                </div>
              </div>

              <div className="fighters">
                <div id="fighter1" className="fighter left">
                  <div className="strike"></div>
                  <div
                    id="f1Img"
                    className="fighterImg"
                    style={{
                      backgroundImage:
                        "url(https://desenhoeilustracao.com.br/wp-content/boo-things/" +
                        opponents[0].Id +
                        ".png)",
                    }}
                  ></div>
                  <div className="turnLabel">Attacking</div>
                  <div id="f1log"></div>
                </div>
                <div id="fighter2" className="fighter right">
                  <div className="strike"></div>
                  <div
                    id="f2Img"
                    className="fighterImg"
                    style={{
                      backgroundImage:
                        "url(https://desenhoeilustracao.com.br/wp-content/boo-things/" +
                        opponents[1].Id +
                        ".png)",
                    }}
                  ></div>
                  <div className="turnLabel">Attacking</div>
                  <div id="f2log"></div>
                </div>
              </div>

              <div className="combatLog">
                {combat
                  ? combat.map((i, index) => (
                      <div key={index} className="combat-row">
                        <li>{combat[index].round} </li>
                        <li>🗡️{combat[index].attacker} </li>
                        <li>🛡️{combat[index].opponent} </li>
                        <li>💥 DMG: {combat[index].damage} </li>
                        <li>
                          🩸 Init Hp: {combat[index].iniHp[0].toFixed(3)} |{" "}
                          {combat[index].iniHp[1].toFixed(3)}{" "}
                        </li>
                        <li>
                          🩸 Hp: {combat[index].curHp[0].toFixed(3)} |{" "}
                          {combat[index].curHp[1].toFixed(3)}{" "}
                        </li>
                        {combat[index].miss ? <li> | ❌ Miss</li> : null}
                        {combat[index].critic ? <li> | 🔥 Crit</li> : null}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </>
      ) : null}

      {winner.length ? (
        <div className="battleHistory">
          <h3>Battle History</h3>
          <div className="battles">
            {winner.map((i, index) => (
              <div key={index} className="historyRow">
                <label>Battle {winner.length - index}</label>
                <img
                  src={
                    "https://desenhoeilustracao.com.br/wp-content/boo-things/" +
                    i +
                    ".png"
                  }
                />
                <label>🏆 #{i}</label>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Battle;

// import React, { useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
// import { BooAttributes } from "../Battle/BooPower";
// import BattleArena from "../Battle/Components/battleArena";

// import BattleCard from "../Battle/Components/battleCard";
// import Wad from "web-audio-daw";

// // Audio
// // https://stackoverflow.com/questions/1933969/sound-effects-in-javascript-html5

// // this.state = { opts: [] };
// function Battle() {
//   const baseMissChance = 0.2; //20%
//   const baseCritChance = 0.01; //1%

//   const [fHP, setFHP] = useState([0, 0]);
//   const [opponents, setOpponents] = useState([]);
//   const [combat, setCombat] = useState();
//   const [cLog, setCLog] = useState();
//   const [winner, setWinner] = useState([]);

//   const sounds = new Wad({
//     source: "./sounds/sounds.wav",
//     sprite: {
//       punch: [0, 0.5],
//       critic: [0.5, 1],
//       miss: [0.95, 1.3],
//       punch2: [1.27, 1.64],
//       punch3: [1.64, 1.99],
//       punch4: [2.05, 2.5],
//       punch5: [2.55, 3],
//     },
//     volume: 0.3,
//   });

//   const bgMusic = new Wad({
//     source: "./sounds/music.wav",
//     volume: 0.1,
//   });

//   useEffect(() => {
//     //has both fighters
//     if (opponents.length > 1) {
//       setFHP([opponents[0].HP, opponents[1].HP]);
//     }
//   }, [opponents]);

//   useEffect(() => {
//     updateOpponents();
//   }, []);

//   //////
//   //
//   ////// Battle Calculations
//   //
//   //////
//   function getFigthers(IDs) {
//     let fters = [];
//     for (let i = 0; i < IDs.length; i++) {
//       fters.push(BooAttributes(IDs[i]));
//     }
//     return fters;
//   }

//   function getHitPower(fters) {
//     return [
//       fters[0].Attack - fters[1].Defense / 2,
//       fters[1].Attack - fters[0].Defense / 2,
//     ];
//   }
//   function getMissChance(fters) {
//     return [
//       baseMissChance - fters[0].Intellect / 100,
//       baseMissChance - fters[1].Intellect / 100,
//     ];
//   }
//   function getCriticChance(fters) {
//     return [
//       baseCritChance + fters[0].Intellect / 100,
//       baseCritChance + fters[1].Intellect / 100,
//     ];
//   }

//   function handleBattle(b1, b2) {
//     if (b1 === b2) {
//       toast.error("Can't fight himself");
//       return []; // Can't fight itself
//     }

//     if (b1 < 1 || b1 > 10000 || b2 < 1 || b2 > 10000) {
//       return []; // Out of range
//     }

//     // reset winner
//     // setWinner(0);

//     // figthers attributes
//     let fters = getFigthers([b1, b2]);

//     // console.log("fters", fters);

//     // choose the first one to attack
//     // TODO: handle same intellect
//     let fMove = fters[0].Intellect < fters[1].Intellect ? 1 : 0;

//     // console.log("fMove", fMove);

//     //Calculate hit power
//     let hPower = getHitPower(fters);

//     // console.log("hPower", hPower);

//     let mChance = getMissChance(fters);

//     // console.log("mChance", mChance, mChance[0] * 100);

//     let cChance = getCriticChance(fters);

//     // console.log("cChance", cChance);

//     // Round data
//     const dThreshold = 3; // The amount of defence that will be used to random reduce the DMG
//     let round = 0;
//     let attacker = fMove;
//     let hps = [fters[0].HP, fters[1].HP];
//     // Combat Data
//     let bLog = [];

//     // console.log("hps", hps);

//     // return;
//     // calculate combat
//     for (let i = 0; hps[0] > 0 && hps[1] > 0; i++) {
//       round = i;

//       let dmg = hPower[attacker]; // Define max damage
//       let opponent = attacker == 0 ? 1 : 0;
//       dmg = (dmg - Math.random(fters[opponent].Defense / dThreshold)).toFixed(
//         3
//       ); // Calculate real damage

//       // console.log("Max DMG:", hPower[attacker], "Real DMG:", dmg);

//       let initHPs = [hps[0], hps[1]];
//       let missed = false;
//       let critic = false;

//       let diceMiss = Math.random(); // Check for miss
//       if (diceMiss <= mChance[attacker]) {
//         missed = true;
//         dmg = 0; // missed
//       }

//       let diceCritic = Math.random(); // check for critic
//       if (!missed && diceCritic <= cChance[attacker]) {
//         critic = true;
//         dmg = dmg * 2;
//       }

//       // Depploy the blow
//       hps[opponent] = hps[opponent] - dmg;

//       // Log battle
//       bLog.push({
//         round: round + 1,
//         attacker: attacker, //fters[attacker].Id,
//         opponent: opponent, //fters[opponent].Id,
//         damage: dmg,
//         iniHp: initHPs,
//         curHp: [hps[0], hps[1]],
//         miss: missed,
//         critic: critic,
//       });

//       attacker = attacker == 0 ? 1 : 0;
//     }

//     let w = hps[0] < hps[1] ? 1 : 0;

//     setWinner((winner) => [fters[w].Id, ...winner]);
//     // console.log("WINNER", winner);

//     // console.log("bLog", bLog);
//     setCombat(bLog);

//     return bLog;
//   }

//   //////
//   //
//   ////// SUPPORT FUNCTIONS
//   //
//   //////
//   function getImputIDs() {
//     let b1 = document.getElementById("b1").value;
//     let b2 = document.getElementById("b2").value;

//     // console.log("IDs", b1, b2);
//     if (b1 < 1 || b2 < 1 || b1 > 10000 || b2 > 10000) {
//       toast.error("ID out of range");
//       return [0, 0];
//     }

//     return [b1, b2];
//   }

//   function performCombat() {
//     c = 0;
//     cd = 0;

//     let b1 = getImputIDs()[0];
//     let b2 = getImputIDs()[1];

//     countDown(b1, b2);
//     bgMusic.play();
//   }

//   function updateOpponents() {
//     let b1 = getImputIDs()[0];
//     let b2 = getImputIDs()[1];

//     setOpponents([BooAttributes(b1), BooAttributes(b2)]);
//   }

//   function getPercent(maxHP, curHP) {
//     if (curHP <= 0) {
//       return 0;
//     }

//     return (curHP / maxHP) * 100;
//   }

//   //////
//   //
//   ////// UI UPDATE
//   //
//   //////
//   function resetUI() {
//     let f1 = document.getElementById("fighter1");
//     let f2 = document.getElementById("fighter2");
//     f1.classList.remove("loser");
//     f2.classList.remove("loser");

//     setFHP([opponents[0].HP, opponents[1].HP]);
//   }

//   function setAttacker(attacker, miss) {
//     let cA = document.getElementById(attacker ? "fighter2" : "fighter1");
//     let cO = document.getElementById(attacker ? "fighter1" : "fighter2");
//     cA.classList.add("turn");
//     cO.classList.remove("turn");

//     cA.classList.remove("gotHit");
//     if (!miss) {
//       cO.classList.add("gotHit");
//     }
//   }

//   function showWinner() {
//     console.log("******BATTLE ENDED*****");
//     let lastRound = combat.length - 1;

//     let f1 = document.getElementById("fighter1");
//     let f2 = document.getElementById("fighter2");

//     if (combat[lastRound].curHp[0] > combat[lastRound].curHp[1]) {
//       f2.classList.add("loser");
//     } else {
//       f1.classList.add("loser");
//     }
//   }

//   function showAttack(fghter, message, critical) {
//     let cID = fghter === 0 ? "f1log" : "f2log";
//     let container = document.getElementById(cID);
//     container.innerHTML = critical
//       ? "<div class='hitMessage criticalcHit'>Critic</div><div class='hitMessage'>" +
//         message +
//         "</div>"
//       : "<div class='hitMessage'>" + message + "</div>";
//   }

//   //////
//   //
//   ////// Combat Animation
//   //
//   //////
//   let c = 0;
//   let cd = 0;
//   let tInterval;
//   let tCountdown;

//   function countDown(b1, b2) {
//     let container = document.getElementById("countdown");
//     container.innerHTML = "<div>" + 3 + "</div>";

//     tCountdown = setInterval(() => {
//       if (cd >= 2) {
//         console.log("finish");
//         clearInterval(tCountdown);
//         handleBattle(b1, b2);
//         return;
//       }

//       container.innerHTML = "<div>" + (2 - cd) + "</div>";
//       cd++;
//     }, 1000);
//   }

//   useEffect(() => {
//     if (!combat) {
//       return;
//     }

//     tInterval = setInterval(() => {
//       if (c >= combat.length) {
//         showWinner();

//         clearInterval(tInterval);
//         return;
//       }

//       setAttacker(combat[c].attacker, combat[c].miss);

//       setFHP([combat[c].curHp[0], combat[c].curHp[1]]);

//       let hitMessage = combat[c].miss ? "Miss" : "-" + combat[c].damage;
//       showAttack(combat[c].opponent, hitMessage, combat[c].critic);

//       if (combat[c].miss) {
//         sounds.miss.play();
//       } else {
//         if (combat[c].critic) {
//           sounds.critic.play();
//         } else {
//           switch (Math.floor(Math.random() * 5)) {
//             case 1:
//               sounds.punch2.play();
//               break;
//             case 2:
//               sounds.punch3.play();
//               break;
//             case 3:
//               sounds.punch4.play();
//               break;
//             case 4:
//               sounds.punch5.play();
//               break;

//             default:
//               sounds.punch.play();
//               break;
//           }
//         }
//       }

//       console.log(combat[c]);
//       c++;
//     }, 1300);

//     return () => clearInterval(tInterval);
//   }, [combat]);

//   function closeCombatWindow() {
//     Wad.stopAll();
//     clearInterval(tInterval);
//     clearInterval(tCountdown);
//     setCombat(null);
//     resetUI();
//   }

//   return (
//     <>
//       <h1>Battlefield</h1>

//       {opponents.length ? (
//         <div className="figtersWrapper">
//           <BattleCard
//             key={opponents[0].Id + "f1" + getHitPower(opponents)[0]}
//             fighter={opponents[0]}
//             hitPower={getHitPower(opponents)[0]}
//             missChance={getMissChance(opponents)[0]}
//             criticChance={getCriticChance(opponents)[0]}
//           />
//           <BattleCard
//             key={opponents[1].Id + "f2" + getHitPower(opponents)[1]}
//             fighter={opponents[1]}
//             hitPower={getHitPower(opponents)[1]}
//             missChance={getMissChance(opponents)[1]}
//             criticChance={getCriticChance(opponents)[1]}
//           />
//         </div>
//       ) : null}

//       <div className="battleToolBar">
//         <div className="inputs">
//           <input id="b1" defaultValue={1} onBlur={updateOpponents}></input>
//           vs.
//           <input id="b2" defaultValue={2} onBlur={updateOpponents}></input>
//         </div>
//         <button onClick={performCombat}>Fight</button>
//       </div>

//       {combat ? (
//         <>
//           <div className="combatWindow">
//             <div className="combatContent">
//               <div className="header">
//                 <h2>Arena</h2>
//                 <button id="closeButton" onClick={closeCombatWindow}>
//                   X
//                 </button>
//               </div>
//               <div className="hpToolbar">
//                 <div
//                   className={
//                     "hpWrapper " +
//                     (fHP[0] < opponents[0].HP / 3 ? "dangerGlow" : "")
//                   }
//                 >
//                   <div
//                     id="hp1"
//                     style={{
//                       width: getPercent(opponents[0].HP, fHP[0]) + "%",
//                     }}
//                     className="hpBar"
//                   ></div>
//                   <div className="hpStatus">
//                     {fHP[0] === opponents[0].HP
//                       ? opponents[0].HP
//                       : fHP[0] > 0
//                       ? fHP[0].toFixed(3)
//                       : "Dead"}
//                   </div>
//                 </div>

//                 <div className="vs">VS</div>

//                 <div
//                   className={
//                     "hpWrapper " +
//                     (fHP[1] < opponents[1].HP / 3 ? "dangerGlow" : "")
//                   }
//                 >
//                   <div
//                     id="hp2"
//                     style={{
//                       width: getPercent(opponents[1].HP, fHP[1]) + "%",
//                     }}
//                     className="hpBar"
//                   ></div>
//                   <div className="hpStatus">
//                     {fHP[1] === opponents[1].HP
//                       ? opponents[1].HP
//                       : fHP[1] > 0
//                       ? fHP[1].toFixed(3)
//                       : "Dead"}
//                   </div>
//                 </div>
//               </div>

//               <div className="fighters">
//                 <div id="fighter1" className="fighter left">
//                   <div className="strike"></div>
//                   <div
//                     id="f1Img"
//                     className="fighterImg"
//                     style={{
//                       backgroundImage:
//                         "url(https://desenhoeilustracao.com.br/wp-content/boo-things/" +
//                         opponents[0].Id +
//                         ".png)",
//                     }}
//                   ></div>
//                   <div className="turnLabel">Attacking</div>
//                   <div id="f1log"></div>
//                 </div>
//                 <div id="fighter2" className="fighter right">
//                   <div className="strike"></div>
//                   <div
//                     id="f2Img"
//                     className="fighterImg"
//                     style={{
//                       backgroundImage:
//                         "url(https://desenhoeilustracao.com.br/wp-content/boo-things/" +
//                         opponents[1].Id +
//                         ".png)",
//                     }}
//                   ></div>
//                   <div className="turnLabel">Attacking</div>
//                   <div id="f2log"></div>
//                 </div>
//               </div>

//               <div className="combatLog">
//                 {combat
//                   ? combat.map((i, index) => (
//                       <div key={index} className="combat-row">
//                         <li>{combat[index].round} </li>
//                         <li>🗡️{combat[index].attacker} </li>
//                         <li>🛡️{combat[index].opponent} </li>
//                         <li>💥 DMG: {combat[index].damage} </li>
//                         <li>
//                           🩸 Init Hp: {combat[index].iniHp[0].toFixed(3)} |{" "}
//                           {combat[index].iniHp[1].toFixed(3)}{" "}
//                         </li>
//                         <li>
//                           🩸 Hp: {combat[index].curHp[0].toFixed(3)} |{" "}
//                           {combat[index].curHp[1].toFixed(3)}{" "}
//                         </li>
//                         {combat[index].miss ? <li> | ❌ Miss</li> : null}
//                         {combat[index].critic ? <li> | 🔥 Crit</li> : null}
//                       </div>
//                     ))
//                   : null}
//               </div>
//             </div>
//           </div>
//         </>
//       ) : null}

//       {winner.length ? (
//         <div className="battleHistory">
//           <h3>Battle History</h3>
//           <div className="battles">
//             {winner.map((i, index) => (
//               <div key={index} className="historyRow">
//                 <label>Battle {winner.length - index}</label>
//                 <img
//                   src={
//                     "https://desenhoeilustracao.com.br/wp-content/boo-things/" + i + ".png"
//                   }
//                 />
//                 <label>🏆 #{i}</label>
//               </div>
//             ))}
//           </div>
//         </div>
//       ) : null}

//       <div id="countdown"></div>
//     </>
//   );
// }

// export default Battle;
