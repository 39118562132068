import React, { useEffect, useState } from "react";
import NftCollection from "../components/nftCollection";
import SortBar from "../components/sortBar";

function BooRank() {
  const [booCollection, setBooCollection] = useState([]);
  const [booCollectionKey, setBooCollectionKey] = useState("booCollection");

  function searchID() {
    let f = document.getElementById("boo-id-value");

    if (f.value < 10001 && f.value > 0 && !booCollection.includes(f.value)) {
      setBooCollection([...booCollection, f.value]);
      setBooCollectionKey("booCollection" + f.value);

      f.value = "";
    }

    console.log("count", booCollection);
  }

  return (
    <>
      <div>
        <div id="rank-serach-bar">
          <input id="boo-id-value" placeholder="Boo ID" type="number" min="1" />
          <button onClick={searchID}>Search</button>
        </div>
      </div>
      <div>
        {booCollection.length > 0 ? (
          <>
            <NftCollection
              key={booCollectionKey}
              type="booRank"
              collection={booCollection}
              id="booCollection"
              title={"Boo Things"}
            />
            {booCollection.length > 1 ? <SortBar /> : null}
          </>
        ) : null}
      </div>
    </>
  );
}

export default BooRank;
