import React, { Component } from "react";
import NFTCard from "./nftCard";
import NFTCardPFP from "./nftCardPFP";
import NFTCardRank from "./nftCardRank";
import GetRank, { GetPower } from "../collections/ranking";

class NftCollection extends Component {
  state = {
    type: this.props.type,
    collection: this.props.collection,
    id: this.props.id,
    title: this.props.title,
  };

  renderCard(i) {
    const { type } = this.state;

    // console.log(type + i);

    if (type === "booPFP") {
      return <NFTCardPFP key={type + i} id={i} />;
    } else if (type === "booPFPFormer") {
      return <NFTCardPFP key={type + i} id={i} former={true} />;
    } else if (type === "booRank") {
      return (
        <NFTCardRank
          key={type + i}
          rank={GetRank(i)}
          power={GetPower(i)}
          type={type}
          id={i}
        />
      );
    } else {
      return (
        <NFTCard
          key={type + i}
          rank={GetRank(i)}
          power={GetPower(i)}
          type={type}
          id={i}
          onClickAttributes={this.props.onClickAttributes}
          showAttributes={this.props.showAttributes}
        />
      );
    }
  }

  render() {
    const { type, id, collection, title } = this.state;

    // console.log("<NftCollection> render collection", collection);

    if (!collection || collection.length === 0) {
      // console.log("No collection given");
      return <></>;
    }

    return (
      <>
        <h2>{title}</h2>
        <div className="collection" id={id}>
          {collection.map((i) => (
            <>{this.renderCard(i)}</>
          ))}
        </div>
      </>
    );
  }
}

export default NftCollection;
