import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BooAttributes } from "../Battle/BooPower";
import BattleArena from "../Battle/Components/battleArena";

import BattleCard from "../Battle/Components/battleCard";
import Wad from "web-audio-daw";
import TeamBattleCard from "../Battle/Components/teamBattleCard";
import FighterImage from "../Battle/Components/fighterImage";

// Audio
// https://stackoverflow.com/questions/1933969/sound-effects-in-javascript-html5

// this.state = { opts: [] };
function TeamBattle() {
  const baseMissChance = 0.2; //20%
  const baseCritChance = 0.01; //1%
  const maxRounds = 35;
  const attBoost = 1;
  let lastFighters = ["b00", "b10"];

  // deprecated
  const [fHP, setFHP] = useState([0, 0]);
  const [opponents, setOpponents] = useState([]);
  const [combat, setCombat] = useState();
  const [winner, setWinner] = useState([]);

  // Team Battle
  const [fContainers, setFContainers] = useState();
  const [fullTeams, setFullTeams] = useState([]);
  const [teamCombat, setTeamCombat] = useState();
  const [teamHPs, setTeamHPs] = useState([
    [0, 0, 0],
    [0, 0, 0],
  ]);

  // Audio
  const sounds = new Wad({
    source: "./sounds/sounds.wav",
    sprite: {
      punch: [0, 0.5],
      critic: [0.5, 1],
      miss: [0.95, 1.3],
      punch2: [1.27, 1.64],
      punch3: [1.64, 1.99],
      punch4: [2.05, 2.5],
      punch5: [2.55, 3],
    },
    volume: 0.3,
  });

  const bgMusic = new Wad({
    source: "./sounds/music.wav",
    volume: 0.1,
  });

  useEffect(() => {
    //has both fighters
    if (opponents.length > 1) {
      setFHP([opponents[0].HP, opponents[1].HP]);
    }
  }, [opponents]);

  useEffect(() => {
    updateOpponents();
  }, []);

  //////
  //
  ////// Battle Calculations
  //
  //////
  function getFigthers(IDs) {
    let fters = [];
    for (let i = 0; i < IDs.length; i++) {
      let ftr = structuredClone(BooAttributes(IDs[i])); // Make a copy without reference

      ftr["Miss"] = getFighterMissChance(ftr);
      ftr["Critic"] = getFighterCriticChance(ftr);

      console.log(ftr);
      fters.push(ftr);
    }
    return fters;
  }

  // Get fighters based on given IDs
  function getTeams() {
    let fIDs = getImputIDs();
    return [
      getFigthers([fIDs[0][0], fIDs[0][1], fIDs[0][2]]),
      getFigthers([fIDs[1][0], fIDs[1][1], fIDs[1][2]]),
    ];
  }

  function getHitPowerAgainstTeam(fighter, team) {
    let power = [];
    for (let i = 0; i < team.length; i++) {
      power.push(calculateHitPower(fighter, team[i]));
    }

    power.sort(function (a, b) {
      return a - b;
    });

    return power;
  }

  function getFighterMissChance(fter) {
    let fterStats = fter.Intellect / 100 + fter.Strength / 2 / 100;
    let calc = baseMissChance - fterStats;
    return calc >= 0 ? calc : 0;
  }

  function getFighterCriticChance(fter) {
    return baseCritChance + fter.Intellect / 100 + fter.Strength / 2 / 100;
  }

  function calculateTotalIntellect(team) {
    let int = 0;
    for (let i = 0; i < team.length; i++) {
      int += team[i].Intellect;
    }
    return int;
  }

  function collectTeamHPs(team) {
    let hps = [];
    for (let i = 0; i < team.length; i++) {
      hps.push(team[i].HP);
    }
    return hps;
  }

  // define a function to check if a team has any fighters left
  function anyFightersLeft(team) {
    for (let i = 0; i < team.length; i++) {
      if (team[i].HP > 0) {
        return true;
      }
    }
    return false;
  }

  function getStandingFighter(team) {
    for (let i = 0; i < team.length; i++) {
      if (team[i].HP > 0) {
        return i;
      }
    }
    return -1;
  }

  //
  //
  //
  function calculateHitPower(attacker, defender) {
    return attacker.Attack - defender.Defense / 2;
  }

  function calculateHitAmount(attacker, defender) {
    let hitPower = calculateHitPower(attacker, defender);
    let variation = hitPower / 3;

    return hitPower - Math.random() * variation;
  }
  function calculateMissHit(attacker) {
    let dice = Math.random();
    if (dice <= attacker.Miss) {
      return true;
    }
    return false;
  }
  function calculateCriticHit(attacker) {
    let dice = Math.random();
    if (dice <= attacker.Critic) {
      return true;
    }
    return false;
  }

  //
  //
  //
  function handleBattle() {
    console.log("full teams", fullTeams);

    // Set teams
    let tms = getTeams();

    console.log("Teams", tms);

    // return;
    // choose the first one to attack
    // TODO: handle same intellect
    let ints = [
      calculateTotalIntellect(fullTeams[0]),
      calculateTotalIntellect(fullTeams[1]),
    ];
    let fMove =
      ints[0] == ints[1]
        ? Math.round(Math.random())
        : ints[0] < ints[1]
        ? 1
        : 0;

    console.log("Team Intellect", ints);
    console.log("First Move", fMove);

    // time que ataca primeiro
    // energia de todos os players

    let tHPs = [collectTeamHPs(fullTeams[0]), collectTeamHPs(fullTeams[1])];
    console.log("Team HPs", tHPs);

    let turn = fMove;
    let teamBattleLog = [];
    let rnd = 0;

    while (anyFightersLeft(tms[0]) && anyFightersLeft(tms[1])) {
      let defTurn = turn == 0 ? 1 : 0;

      let aTeam = tms[turn]; // attacker team
      let dTeam = tms[defTurn]; // defender team

      rnd++;
      if (rnd > maxRounds) {
        console.log("Max rounds reached");
        break;
      }
      let roundLog = [];

      for (let f = 0; f < aTeam.length; f++) {
        let defIndex = getStandingFighter(dTeam);
        let activeFighter0 = getStandingFighter(tms[0]);
        let activeFighter1 = getStandingFighter(tms[1]);
        if (aTeam[f].HP > 0 && defIndex !== -1) {
          let defender = dTeam[defIndex];

          let hMiss = true;
          let hCritic = false;
          let hDMG = 0;

          // NOT MISS
          if (!calculateMissHit(aTeam[f])) {
            hMiss = false;
            hDMG = calculateHitAmount(aTeam[f], defender);

            if (calculateCriticHit(aTeam[f])) {
              hCritic = true;
              hDMG *= 2;
            }
          }

          hDMG *= attBoost;
          dTeam[defIndex].HP = dTeam[defIndex].HP - hDMG;

          teamBattleLog.push({
            team: turn,
            round: rnd,
            active: [activeFighter0, activeFighter1],
            index: [
              [turn, f],
              [defTurn, defIndex],
            ],
            ids: [aTeam[f].Id, defender.Id],
            miss: hMiss,
            dmg: hDMG,
            critic: hCritic,
            parry: false,
            hp: [collectTeamHPs(tms[0]), collectTeamHPs(tms[1])],
          });
        }
      }

      turn = turn == 0 ? 1 : 0;
    }

    console.log("game finished", teamBattleLog);

    setTeamCombat(teamBattleLog);

    return;

    // console.log("hps", hps);

    // return;
    // calculate combat

    // let w = hps[0] < hps[1] ? 1 : 0;

    // setWinner((winner) => [fters[w].Id, ...winner]);
    // // console.log("WINNER", winner);

    // // console.log("bLog", bLog);
    // setCombat(bLog);

    // return bLog;
  }

  //////
  //
  ////// SUPPORT FUNCTIONS
  //
  //////
  function getIDFromImput(iID) {
    let id = document.getElementById(iID).value;
    if (id < 0 || id > 10000 || isNaN(id)) {
      toast.error("ID out of range or NAN");
      return 1;
    }
    return id;
  }
  function getImputIDs() {
    return [
      [getIDFromImput("i10"), getIDFromImput("i11"), getIDFromImput("i12")],
      [getIDFromImput("i20"), getIDFromImput("i21"), getIDFromImput("i22")],
    ];
  }

  function performCombat() {
    handleBattle();

    console.log("performCombat: full teams", fullTeams);
    // bgMusic.play();
  }

  function updateOpponents() {
    setFullTeams(getTeams());
  }

  function getPercent(maxHP, curHP) {
    if (curHP <= 0) {
      return 0;
    }

    return (curHP / maxHP) * 100;
  }

  //////
  //
  ////// UI UPDATE
  //
  //////
  function resetUI() {
    // let f1 = document.getElementById("fighter1");
    // let f2 = document.getElementById("fighter2");
    // f1.classList.remove("loser");
    // f2.classList.remove("loser");

    // setFHP([opponents[0].HP, opponents[1].HP]);

    setTeamHPs([0, 0, 0], [0, 0, 0]);
  }

  function showWinner() {
    console.log("******BATTLE ENDED*****");
    let lastRound = combat.length - 1;

    let f1 = document.getElementById("fighter1");
    let f2 = document.getElementById("fighter2");

    if (combat[lastRound].curHp[0] > combat[lastRound].curHp[1]) {
      f2.classList.add("loser");
    } else {
      f1.classList.add("loser");
    }
  }

  function addClass(id, clss) {
    // lastFightersIndex[attacker, deffender]
    let boo = document.getElementById(id);
    boo.classList.add(clss);
  }
  function removeClass(id, clss) {
    // lastFightersIndex[attacker, deffender]
    let boo = document.getElementById(id);
    boo.classList.remove(clss);
  }

  function showAttacker(rData) {
    //Attacking label
    addClass("team" + rData.team, "turn");
    removeClass(rData.team == 1 ? "team0" : "team1", "turn");

    //
    let aID = "b" + rData.index[0][0] + rData.index[0][1];
    let dID = "b" + rData.index[1][0] + rData.index[1][1];

    removeClass(lastFighters[0], "turn");
    addClass(aID, "turn");
    lastFighters[0] = aID;

    removeClass(lastFighters[1], "gotHit");
    if (!rData.miss) {
      addClass(dID, "gotHit");
    }
    lastFighters[1] = dID;
  }

  function showAttack(rData) {
    let hDMG = rData.miss ? "Miss" : "-" + rData.dmg.toFixed(3);
    let container = document.getElementById("hitMessage" + rData.index[1][0]);

    let innerMessage = "<div class='hitMessage'>" + hDMG + "</div>";
    if (rData.critic) {
      innerMessage =
        "<div class='hitMessage criticalcHit'>Critic</div>" + innerMessage;
    }

    container.innerHTML = innerMessage;
  }

  function setDead(index) {
    let hpID = "hp" + index[0] + index[1];
    let bID = "b" + index[0] + index[1];

    addClass(hpID, "dead");
    addClass(bID, "dead");
    if (index[1] < 2) {
      removeClass(hpID, "active");
      removeClass(bID, "active");
    }
  }

  function setActive(index) {
    let hpID = "hp" + index[0] + index[1];
    let bID = "b" + index[0] + index[1];

    addClass(hpID, "active");
    addClass(bID, "active");
  }

  function setActiveBoos(roundData) {
    setActive([0, roundData.active[0]]);
    setActive([1, roundData.active[1]]);
  }

  function setDeadBoos(roundData) {
    for (let i = 0; i < roundData.hp.length; i++) {
      for (let a = 0; a < roundData.hp[i].length; a++) {
        if (roundData.hp[i][a] > 0) {
          break;
        }
        setDead([i, a]);
      }
    }
  }

  //////
  //
  ////// Team Combat Animation
  //
  //////
  let teamRound = 0;
  let teamInterval;
  useEffect(() => {
    if (!teamCombat) {
      return;
    }
    console.log("INIT TEAM ANIMATION");

    setTeamHPs([
      [fullTeams[0][0].HP, fullTeams[0][1].HP, fullTeams[0][2].HP],
      [fullTeams[1][0].HP, fullTeams[1][1].HP, fullTeams[1][2].HP],
    ]);

    teamInterval = setInterval(() => {
      if (teamRound > teamCombat.length) {
        clearInterval(teamInterval);
        return;
      }

      if (teamRound < teamCombat.length) {
        let rData = teamCombat[teamRound];
        let pastData = teamCombat[teamRound === 0 ? 0 : teamRound - 1];
        console.log(rData);
        setTeamHPs(rData.hp);
        setActiveBoos(rData);
        setDeadBoos(pastData);
        showAttack(rData);
        showAttacker(rData);
      } else if (teamRound == teamCombat.length) {
        setDeadBoos(teamCombat[teamRound - 1]);
      }

      teamRound++;
    }, 1300);

    return () => clearInterval(teamInterval);
  }, [teamCombat]);

  function closeCombatWindow() {
    Wad.stopAll();

    clearInterval(teamInterval);
    setTeamCombat(null);

    resetUI();
  }

  return (
    <>
      <h1>Battlefield</h1>

      {fullTeams.length ? (
        <div className="team fighters">
          <div className="team figtersWrapper">
            <TeamBattleCard
              key={fullTeams[0][0].Id + "t1" + fullTeams}
              fighter={fullTeams[0][0]}
              hitPower={getHitPowerAgainstTeam(fullTeams[0][0], fullTeams[1])}
            />
            <TeamBattleCard
              key={fullTeams[0][1].Id + "t1" + fullTeams}
              fighter={fullTeams[0][1]}
              hitPower={getHitPowerAgainstTeam(fullTeams[0][1], fullTeams[1])}
            />
            <TeamBattleCard
              key={fullTeams[0][2].Id + "t1" + fullTeams}
              fighter={fullTeams[0][2]}
              hitPower={getHitPowerAgainstTeam(fullTeams[0][2], fullTeams[1])}
            />
          </div>

          <h3>vs</h3>

          <div className="team figtersWrapper">
            <TeamBattleCard
              key={fullTeams[1][0].Id + "t2" + fullTeams}
              fighter={fullTeams[1][0]}
              hitPower={getHitPowerAgainstTeam(fullTeams[1][0], fullTeams[0])}
            />
            <TeamBattleCard
              key={fullTeams[1][1].Id + "t2" + fullTeams}
              fighter={fullTeams[1][1]}
              hitPower={getHitPowerAgainstTeam(fullTeams[1][1], fullTeams[0])}
            />
            <TeamBattleCard
              key={fullTeams[1][2].Id + "t2" + fullTeams}
              fighter={fullTeams[1][2]}
              hitPower={getHitPowerAgainstTeam(fullTeams[1][2], fullTeams[0])}
            />
          </div>
        </div>
      ) : null}

      <div className="battleToolBar">
        <div className="inputs">
          <input id="i10" defaultValue={1} onBlur={updateOpponents}></input>
          <input id="i11" defaultValue={2} onBlur={updateOpponents}></input>
          <input id="i12" defaultValue={3} onBlur={updateOpponents}></input>
          vs.
          <input id="i20" defaultValue={4} onBlur={updateOpponents}></input>
          <input id="i21" defaultValue={5} onBlur={updateOpponents}></input>
          <input id="i22" defaultValue={6} onBlur={updateOpponents}></input>
        </div>
        <button onClick={performCombat}>Fight</button>
      </div>

      {teamCombat ? (
        <>
          <div className="combatWindow team">
            <div className="combatContent">
              <div className="header">
                <h2>Arena</h2>
                <button id="closeButton" onClick={closeCombatWindow}>
                  X
                </button>
              </div>
              <div className="hpToolbar">
                <div className="hpGroup reflect">
                  <div id="hp00" className="hpWrapper active">
                    <div
                      style={{
                        width:
                          getPercent(fullTeams[0][0].HP, teamHPs[0][0]) + "%",
                      }}
                      className="hpBar"
                    ></div>
                    <div className="hpStatus">
                      {teamHPs[0][0] > 0 ? teamHPs[0][0].toFixed(2) : "Dead"}
                    </div>
                  </div>
                  <div id="hp01" className="hpWrapper">
                    <div
                      style={{
                        width:
                          getPercent(fullTeams[0][1].HP, teamHPs[0][1]) + "%",
                      }}
                      className="hpBar"
                    ></div>
                    <div className="hpStatus">
                      {teamHPs[0][1] > 0 ? teamHPs[0][1].toFixed(2) : "Dead"}
                    </div>
                  </div>
                  <div id="hp02" className="hpWrapper">
                    <div
                      style={{
                        width:
                          getPercent(fullTeams[0][2].HP, teamHPs[0][2]) + "%",
                      }}
                      className="hpBar"
                    ></div>
                    <div className="hpStatus">
                      {teamHPs[0][2] > 0 ? teamHPs[0][2].toFixed(2) : "Dead"}
                    </div>
                  </div>
                </div>
                <div className="vs">VS</div>
                <div className="hpGroup">
                  <div id="hp10" className="hpWrapper active">
                    <div
                      style={{
                        width:
                          getPercent(fullTeams[1][0].HP, teamHPs[1][0]) + "%",
                      }}
                      className="hpBar"
                    ></div>
                    <div className="hpStatus">
                      {teamHPs[1][0] > 0 ? teamHPs[1][0].toFixed(2) : "Dead"}
                    </div>
                  </div>
                  <div id="hp11" className="hpWrapper">
                    <div
                      style={{
                        width:
                          getPercent(fullTeams[1][1].HP, teamHPs[1][1]) + "%",
                      }}
                      className="hpBar"
                    ></div>
                    <div className="hpStatus">
                      {teamHPs[1][1] > 0 ? teamHPs[1][1].toFixed(2) : "Dead"}
                    </div>
                  </div>
                  <div id="hp12" className="hpWrapper">
                    <div
                      style={{
                        width:
                          getPercent(fullTeams[1][2].HP, teamHPs[1][2]) + "%",
                      }}
                      className="hpBar"
                    ></div>
                    <div className="hpStatus">
                      {teamHPs[1][2] > 0 ? teamHPs[1][2].toFixed(2) : "Dead"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="arena">
                <div id="team0" className="teamFighters">
                  <div className="Boos">
                    <FighterImage
                      key={"b02Img" + teamHPs[0][2]}
                      id={"b02Img"}
                      booID={fullTeams[0][2].Id}
                      index={[0, 2]}
                    />
                    <FighterImage
                      key={"b01Img" + teamHPs[0][1]}
                      id={"b01Img"}
                      booID={fullTeams[0][1].Id}
                      index={[0, 1]}
                    />
                    <FighterImage
                      key={"b00Img" + teamHPs[0][0]}
                      id={"b00Img"}
                      booID={fullTeams[0][0].Id}
                      index={[0, 0]}
                    />
                  </div>
                  <div className="turnLabel">Attacking</div>
                  <div id="hitMessage0"></div>
                </div>
                <div id="team1" className="teamFighters">
                  <div className="Boos reflect">
                    <FighterImage
                      key={"b10Img" + teamHPs[1][0]}
                      id={"b10Img"}
                      booID={fullTeams[1][0].Id}
                      index={[1, 0]}
                    />
                    <FighterImage
                      key={"b11Img" + teamHPs[1][1]}
                      id={"b11Img"}
                      booID={fullTeams[1][1].Id}
                      index={[1, 1]}
                    />
                    <FighterImage
                      key={"b12Img" + teamHPs[1][2]}
                      id={"b12Img"}
                      booID={fullTeams[1][2].Id}
                      index={[1, 2]}
                    />
                  </div>
                  <div className="turnLabel">Attacking</div>
                  <div id="hitMessage1"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* {winner.length ? (
        <div className="battleHistory">
          <h3>Battle History</h3>
          <div className="battles">
            {winner.map((i, index) => (
              <div key={index} className="historyRow">
                <label>Battle {winner.length - index}</label>
                <img
                  src={
                    "https://desenhoeilustracao.com.br/wp-content/boo-things/" + i + ".png"
                  }
                />
                <label>🏆 #{i}</label>
              </div>
            ))}
          </div>
        </div>
      ) : null} */}
    </>
  );
}

export default TeamBattle;
