import React, { Component } from "react";

class FighterImage extends Component {
  state = {
    id: this.props.id,
    booID: this.props.booID,
    index: this.props.index,
  };

  render() {
    const { id, booID, index } = this.state;

    return (
      <div id={"b" + index[0] + index[1]} className="teamFighter">
        {/* <div className="strike"></div> */}
        <div
          id={id}
          className="teamPlayer"
          style={{
            backgroundImage:
              "url(https://desenhoeilustracao.com.br/wp-content/boo-things/" +
              booID +
              ".png)",
          }}
        ></div>
      </div>
    );
  }
}

export default FighterImage;
