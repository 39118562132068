export const BooAttributes = (id) => {
  return id > 0 ? BooPower[id - 1] : BooPower[0];
};

export const BooPower = [
  {
    Id: 1,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 2,
    Stamina: 1,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 101,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3,
    Stamina: 2,
    Intellect: 13,
    Strength: 1,
    Defense: 5,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 4,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 9,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 7,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 10,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 11,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 12,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 13,
    Stamina: 8,
    Intellect: 3,
    Strength: 1,
    Defense: 8,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 14,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 15,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 16,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 3,
    HP: 106,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 17,
    Stamina: 6,
    Intellect: 12,
    Strength: 1,
    Defense: 2,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 18,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 19,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 108,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 20,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 21,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 22,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 23,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 24,
    Stamina: 6,
    Intellect: 12,
    Strength: 2,
    Defense: 1,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 25,
    Stamina: 7,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 26,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 27,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 28,
    Stamina: 3,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 103,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 29,
    Stamina: 7,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 107,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 30,
    Stamina: 2,
    Intellect: 11,
    Strength: 5,
    Defense: 2,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 31,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 32,
    Stamina: 5,
    Intellect: 13,
    Strength: 1,
    Defense: 1,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 33,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 34,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 35,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 36,
    Stamina: 6,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 37,
    Stamina: 3,
    Intellect: 8,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 38,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 39,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 40,
    Stamina: 9,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 109,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 41,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 42,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 43,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 44,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 45,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 46,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 47,
    Stamina: 1,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 48,
    Stamina: 2,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 49,
    Stamina: 2,
    Intellect: 12,
    Strength: 5,
    Defense: 3,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 50,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 51,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 52,
    Stamina: 5,
    Intellect: 10,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 34,
  },
  {
    Id: 53,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 54,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 55,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 56,
    Stamina: 7,
    Intellect: 7,
    Strength: 6,
    Defense: 1,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 57,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 58,
    Stamina: 2,
    Intellect: 11,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 59,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 60,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 61,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 62,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 63,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 64,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 65,
    Stamina: 7,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 107,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 66,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 67,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 68,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 69,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 70,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 71,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 72,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 73,
    Stamina: 8,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 74,
    Stamina: 5,
    Intellect: 3,
    Strength: 12,
    Defense: 4,
    HP: 105,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 75,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 76,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 77,
    Stamina: 10,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 110,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 78,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 13,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 79,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 80,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 81,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 82,
    Stamina: 2,
    Intellect: 12,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 83,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 84,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 85,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 86,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 87,
    Stamina: 4,
    Intellect: 12,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 88,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 89,
    Stamina: 10,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 110,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 90,
    Stamina: 8,
    Intellect: 7,
    Strength: 8,
    Defense: 2,
    HP: 108,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 91,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 92,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 93,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 9,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 94,
    Stamina: 10,
    Intellect: 2,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 95,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 96,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 97,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 98,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 99,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 100,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 101,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 102,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 103,
    Stamina: 12,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 112,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 104,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 105,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 106,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 107,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 108,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 109,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 110,
    Stamina: 5,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 111,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 112,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 113,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 114,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 115,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 116,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 1,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 117,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 118,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 119,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 120,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 121,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 12,
    HP: 105,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 122,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 123,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 124,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 125,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 126,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 127,
    Stamina: 1,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 101,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 128,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 129,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 130,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 131,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 132,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 133,
    Stamina: 3,
    Intellect: 2,
    Strength: 14,
    Defense: 2,
    HP: 103,
    Attack: 24,
    Power: 28,
  },
  {
    Id: 134,
    Stamina: 1,
    Intellect: 10,
    Strength: 4,
    Defense: 7,
    HP: 101,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 135,
    Stamina: 10,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 110,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 136,
    Stamina: 8,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 108,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 137,
    Stamina: 8,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 108,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 138,
    Stamina: 2,
    Intellect: 9,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 139,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 140,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 106,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 141,
    Stamina: 2,
    Intellect: 2,
    Strength: 14,
    Defense: 4,
    HP: 102,
    Attack: 24,
    Power: 30,
  },
  {
    Id: 142,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 143,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 144,
    Stamina: 12,
    Intellect: 3,
    Strength: 2,
    Defense: 2,
    HP: 112,
    Attack: 12,
    Power: 17,
  },
  {
    Id: 145,
    Stamina: 9,
    Intellect: 2,
    Strength: 5,
    Defense: 2,
    HP: 109,
    Attack: 15,
    Power: 19,
  },
  {
    Id: 146,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 147,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 148,
    Stamina: 7,
    Intellect: 4,
    Strength: 11,
    Defense: 1,
    HP: 107,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 149,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 150,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 151,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 152,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 2,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 153,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 154,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 155,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 156,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 157,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 158,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 159,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 160,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 161,
    Stamina: 5,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 162,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 163,
    Stamina: 2,
    Intellect: 11,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 164,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 165,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 166,
    Stamina: 4,
    Intellect: 15,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 167,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 168,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 169,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 170,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 171,
    Stamina: 8,
    Intellect: 4,
    Strength: 9,
    Defense: 1,
    HP: 108,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 172,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 13,
    HP: 103,
    Attack: 12,
    Power: 33,
  },
  {
    Id: 173,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 174,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 175,
    Stamina: 8,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 176,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 177,
    Stamina: 11,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 111,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 178,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 179,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 180,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 181,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 106,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 182,
    Stamina: 3,
    Intellect: 3,
    Strength: 12,
    Defense: 2,
    HP: 103,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 183,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 184,
    Stamina: 5,
    Intellect: 11,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 185,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 186,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 187,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 188,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 189,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 190,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 191,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 192,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 193,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 194,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 195,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 196,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 197,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 109,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 198,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 199,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 200,
    Stamina: 9,
    Intellect: 3,
    Strength: 7,
    Defense: 1,
    HP: 109,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 201,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 202,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 203,
    Stamina: 10,
    Intellect: 2,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 204,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 205,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 206,
    Stamina: 1,
    Intellect: 9,
    Strength: 6,
    Defense: 6,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 207,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 208,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 209,
    Stamina: 5,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 210,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 211,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 107,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 212,
    Stamina: 1,
    Intellect: 8,
    Strength: 10,
    Defense: 2,
    HP: 101,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 213,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 214,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 10,
    HP: 104,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 215,
    Stamina: 1,
    Intellect: 3,
    Strength: 10,
    Defense: 8,
    HP: 101,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 216,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 217,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 218,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 10,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 219,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 12,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 220,
    Stamina: 6,
    Intellect: 12,
    Strength: 2,
    Defense: 2,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 221,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 222,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 223,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 224,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 225,
    Stamina: 8,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 226,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 227,
    Stamina: 2,
    Intellect: 2,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 228,
    Stamina: 6,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 106,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 229,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 230,
    Stamina: 8,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 108,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 231,
    Stamina: 10,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 110,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 232,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 233,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 234,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 235,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 236,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 237,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 238,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 239,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 240,
    Stamina: 7,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 241,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 242,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 243,
    Stamina: 2,
    Intellect: 4,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 244,
    Stamina: 5,
    Intellect: 12,
    Strength: 2,
    Defense: 2,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 245,
    Stamina: 1,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 246,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 247,
    Stamina: 4,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 104,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 248,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 249,
    Stamina: 2,
    Intellect: 10,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 250,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 251,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 252,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 253,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 254,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 255,
    Stamina: 5,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 256,
    Stamina: 9,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 109,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 257,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 258,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 259,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 260,
    Stamina: 9,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 261,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 262,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 263,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 264,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 265,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 266,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 267,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 268,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 269,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 270,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 271,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 272,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 273,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 274,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 275,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 276,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 277,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 278,
    Stamina: 7,
    Intellect: 8,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 279,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 280,
    Stamina: 11,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 111,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 281,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 282,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 283,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 284,
    Stamina: 10,
    Intellect: 2,
    Strength: 4,
    Defense: 1,
    HP: 110,
    Attack: 14,
    Power: 17,
  },
  {
    Id: 285,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 286,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 287,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 288,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 289,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 290,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 291,
    Stamina: 9,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 109,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 292,
    Stamina: 1,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 293,
    Stamina: 1,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 101,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 294,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 295,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 7,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 296,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 297,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 298,
    Stamina: 8,
    Intellect: 5,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 299,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 300,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 301,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 302,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 303,
    Stamina: 1,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 304,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 305,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 306,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 307,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 308,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 309,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 310,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 311,
    Stamina: 5,
    Intellect: 12,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 312,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 313,
    Stamina: 6,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 314,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 315,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 316,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 317,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 318,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 319,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 320,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 321,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 322,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 323,
    Stamina: 5,
    Intellect: 11,
    Strength: 3,
    Defense: 1,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 324,
    Stamina: 8,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 325,
    Stamina: 11,
    Intellect: 7,
    Strength: 1,
    Defense: 3,
    HP: 111,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 326,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 327,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 328,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 329,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 330,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 331,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 332,
    Stamina: 10,
    Intellect: 8,
    Strength: 1,
    Defense: 3,
    HP: 110,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 333,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 334,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 335,
    Stamina: 6,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 336,
    Stamina: 1,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 337,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 338,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 109,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 339,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 340,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 11,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 341,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 342,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 343,
    Stamina: 11,
    Intellect: 4,
    Strength: 2,
    Defense: 1,
    HP: 111,
    Attack: 12,
    Power: 17,
  },
  {
    Id: 344,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 345,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 346,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 347,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 348,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 349,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 350,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 103,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 351,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 108,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 352,
    Stamina: 8,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 108,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 353,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 354,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 33,
  },
  {
    Id: 355,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 356,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 357,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 358,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 359,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 360,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 361,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 109,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 362,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 363,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 364,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 365,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 106,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 366,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 367,
    Stamina: 10,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 110,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 368,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 369,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 370,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 102,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 371,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 372,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 7,
    HP: 105,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 373,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 374,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 375,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 376,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 377,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 378,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 379,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 380,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 24,
  },
  {
    Id: 381,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 382,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 383,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 384,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 385,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 386,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 387,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 11,
    HP: 103,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 388,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 389,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 390,
    Stamina: 4,
    Intellect: 11,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 391,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 392,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 393,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 394,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 395,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 396,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 397,
    Stamina: 4,
    Intellect: 13,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 398,
    Stamina: 8,
    Intellect: 5,
    Strength: 1,
    Defense: 4,
    HP: 108,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 399,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 400,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 401,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 402,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 403,
    Stamina: 6,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 404,
    Stamina: 7,
    Intellect: 7,
    Strength: 7,
    Defense: 6,
    HP: 107,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 405,
    Stamina: 1,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 101,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 406,
    Stamina: 10,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 110,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 407,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 11,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 408,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 409,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 410,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 12,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 411,
    Stamina: 3,
    Intellect: 2,
    Strength: 14,
    Defense: 3,
    HP: 103,
    Attack: 24,
    Power: 29,
  },
  {
    Id: 412,
    Stamina: 3,
    Intellect: 11,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 413,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 414,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 415,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 6,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 416,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 417,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 2,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 418,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 419,
    Stamina: 2,
    Intellect: 16,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 34,
  },
  {
    Id: 420,
    Stamina: 8,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 421,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 422,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 9,
    HP: 103,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 423,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 424,
    Stamina: 7,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 107,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 425,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 426,
    Stamina: 5,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 427,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 428,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 429,
    Stamina: 2,
    Intellect: 13,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 36,
  },
  {
    Id: 430,
    Stamina: 1,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 431,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 432,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 433,
    Stamina: 6,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 434,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 435,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 1,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 436,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 437,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 438,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 1,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 439,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 440,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 441,
    Stamina: 2,
    Intellect: 13,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 442,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 443,
    Stamina: 4,
    Intellect: 9,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 444,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 445,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 446,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 447,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 448,
    Stamina: 1,
    Intellect: 8,
    Strength: 8,
    Defense: 5,
    HP: 101,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 449,
    Stamina: 8,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 450,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 451,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 452,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 453,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 454,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 455,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 456,
    Stamina: 2,
    Intellect: 2,
    Strength: 13,
    Defense: 3,
    HP: 102,
    Attack: 23,
    Power: 28,
  },
  {
    Id: 457,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 458,
    Stamina: 2,
    Intellect: 11,
    Strength: 1,
    Defense: 4,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 459,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 460,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 461,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 462,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 463,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 464,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 465,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 466,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 467,
    Stamina: 8,
    Intellect: 2,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 468,
    Stamina: 7,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 469,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 470,
    Stamina: 8,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 108,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 471,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 472,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 473,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 2,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 474,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 475,
    Stamina: 3,
    Intellect: 12,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 476,
    Stamina: 8,
    Intellect: 2,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 17,
  },
  {
    Id: 477,
    Stamina: 1,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 478,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 479,
    Stamina: 5,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 480,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 481,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 482,
    Stamina: 11,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 111,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 483,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 484,
    Stamina: 6,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 485,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 486,
    Stamina: 3,
    Intellect: 3,
    Strength: 12,
    Defense: 6,
    HP: 103,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 487,
    Stamina: 3,
    Intellect: 7,
    Strength: 13,
    Defense: 5,
    HP: 103,
    Attack: 23,
    Power: 35,
  },
  {
    Id: 488,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 2,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 489,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 1,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 490,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 491,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 492,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 493,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 494,
    Stamina: 1,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 495,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 496,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 497,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 10,
    HP: 105,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 498,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 499,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 500,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 501,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 502,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 108,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 503,
    Stamina: 2,
    Intellect: 5,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 504,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 102,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 505,
    Stamina: 1,
    Intellect: 7,
    Strength: 2,
    Defense: 10,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 506,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 507,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 508,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 509,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 510,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 13,
    HP: 106,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 511,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 512,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 513,
    Stamina: 2,
    Intellect: 13,
    Strength: 1,
    Defense: 4,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 514,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 515,
    Stamina: 6,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 106,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 516,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 517,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 518,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 519,
    Stamina: 7,
    Intellect: 2,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 520,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 521,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 522,
    Stamina: 6,
    Intellect: 2,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 523,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 524,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 525,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 526,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 527,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 528,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 529,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 530,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 531,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 532,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 533,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 534,
    Stamina: 2,
    Intellect: 13,
    Strength: 2,
    Defense: 6,
    HP: 102,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 535,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 536,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 537,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 538,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 539,
    Stamina: 4,
    Intellect: 4,
    Strength: 11,
    Defense: 5,
    HP: 104,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 540,
    Stamina: 1,
    Intellect: 8,
    Strength: 11,
    Defense: 2,
    HP: 101,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 541,
    Stamina: 5,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 542,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 543,
    Stamina: 8,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 108,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 544,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 545,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 546,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 547,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 107,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 548,
    Stamina: 2,
    Intellect: 13,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 549,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 550,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 551,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 552,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 553,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 554,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 555,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 13,
    HP: 102,
    Attack: 14,
    Power: 33,
  },
  {
    Id: 556,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 557,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 558,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 559,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 560,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 561,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 11,
    HP: 102,
    Attack: 16,
    Power: 33,
  },
  {
    Id: 562,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 563,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 564,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 565,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 566,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 567,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 568,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 569,
    Stamina: 4,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 570,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 571,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 572,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 1,
    HP: 108,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 573,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 574,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 575,
    Stamina: 1,
    Intellect: 4,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 576,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 577,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 578,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 579,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 580,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 12,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 581,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 8,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 582,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 583,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 584,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 10,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 585,
    Stamina: 1,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 586,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 587,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 588,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 6,
    HP: 109,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 589,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 590,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 591,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 2,
    HP: 106,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 592,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 101,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 593,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 594,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 10,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 595,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 12,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 596,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 597,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 598,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 599,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 109,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 600,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 601,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 602,
    Stamina: 3,
    Intellect: 13,
    Strength: 2,
    Defense: 1,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 603,
    Stamina: 6,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 604,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 605,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 606,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 607,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 608,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 609,
    Stamina: 2,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 610,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 611,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 612,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 613,
    Stamina: 6,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 614,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 109,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 615,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 13,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 616,
    Stamina: 2,
    Intellect: 2,
    Strength: 12,
    Defense: 3,
    HP: 102,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 617,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 618,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 1,
    HP: 106,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 619,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 620,
    Stamina: 6,
    Intellect: 6,
    Strength: 17,
    Defense: 15,
    HP: 106,
    Attack: 27,
    Power: 48,
  },
  {
    Id: 621,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 12,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 622,
    Stamina: 3,
    Intellect: 4,
    Strength: 14,
    Defense: 5,
    HP: 103,
    Attack: 24,
    Power: 33,
  },
  {
    Id: 623,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 624,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 625,
    Stamina: 5,
    Intellect: 4,
    Strength: 13,
    Defense: 5,
    HP: 105,
    Attack: 23,
    Power: 32,
  },
  {
    Id: 626,
    Stamina: 1,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 627,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 628,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 629,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 630,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 631,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 632,
    Stamina: 1,
    Intellect: 3,
    Strength: 12,
    Defense: 6,
    HP: 101,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 633,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 634,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 8,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 635,
    Stamina: 7,
    Intellect: 9,
    Strength: 7,
    Defense: 6,
    HP: 107,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 636,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 637,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 638,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 639,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 640,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 641,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 642,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 643,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 644,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 645,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 646,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 647,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 648,
    Stamina: 4,
    Intellect: 10,
    Strength: 6,
    Defense: 1,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 649,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 650,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 651,
    Stamina: 5,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 652,
    Stamina: 7,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 653,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 654,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 655,
    Stamina: 4,
    Intellect: 4,
    Strength: 14,
    Defense: 6,
    HP: 104,
    Attack: 24,
    Power: 34,
  },
  {
    Id: 656,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 657,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 658,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 659,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 660,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 661,
    Stamina: 1,
    Intellect: 6,
    Strength: 9,
    Defense: 7,
    HP: 101,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 662,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 663,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 664,
    Stamina: 4,
    Intellect: 11,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 34,
  },
  {
    Id: 665,
    Stamina: 3,
    Intellect: 8,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 666,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 667,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 668,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 669,
    Stamina: 3,
    Intellect: 12,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 670,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 671,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 672,
    Stamina: 7,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 673,
    Stamina: 8,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 674,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 1,
    HP: 103,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 675,
    Stamina: 8,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 676,
    Stamina: 3,
    Intellect: 3,
    Strength: 2,
    Defense: 12,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 677,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 678,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 679,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 680,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 681,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 682,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 683,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 24,
  },
  {
    Id: 684,
    Stamina: 4,
    Intellect: 13,
    Strength: 5,
    Defense: 1,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 685,
    Stamina: 1,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 686,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 687,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 688,
    Stamina: 7,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 107,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 689,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 690,
    Stamina: 8,
    Intellect: 4,
    Strength: 6,
    Defense: 1,
    HP: 108,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 691,
    Stamina: 2,
    Intellect: 12,
    Strength: 3,
    Defense: 2,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 692,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 693,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 694,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 695,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 696,
    Stamina: 5,
    Intellect: 8,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 697,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 698,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 699,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 700,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 701,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 702,
    Stamina: 1,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 101,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 703,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 704,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 705,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 706,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 707,
    Stamina: 1,
    Intellect: 12,
    Strength: 3,
    Defense: 5,
    HP: 101,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 708,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 709,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 710,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 711,
    Stamina: 3,
    Intellect: 8,
    Strength: 10,
    Defense: 1,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 712,
    Stamina: 11,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 111,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 713,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 714,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 3,
    HP: 108,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 715,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 716,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 717,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 718,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 719,
    Stamina: 1,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 720,
    Stamina: 2,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 721,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 722,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 723,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 724,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 725,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 726,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 727,
    Stamina: 3,
    Intellect: 14,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 728,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 1,
    HP: 102,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 729,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 730,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 9,
    HP: 102,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 731,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 732,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 733,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 734,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 735,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 736,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 3,
    HP: 102,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 737,
    Stamina: 8,
    Intellect: 4,
    Strength: 8,
    Defense: 1,
    HP: 108,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 738,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 739,
    Stamina: 1,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 101,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 740,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 12,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 741,
    Stamina: 4,
    Intellect: 5,
    Strength: 11,
    Defense: 8,
    HP: 104,
    Attack: 21,
    Power: 34,
  },
  {
    Id: 742,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 743,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 744,
    Stamina: 11,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 111,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 745,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 746,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 747,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 748,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 749,
    Stamina: 5,
    Intellect: 9,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 750,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 751,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 752,
    Stamina: 1,
    Intellect: 3,
    Strength: 13,
    Defense: 6,
    HP: 101,
    Attack: 23,
    Power: 32,
  },
  {
    Id: 753,
    Stamina: 4,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 754,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 755,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 756,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 757,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 758,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 759,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 760,
    Stamina: 11,
    Intellect: 2,
    Strength: 3,
    Defense: 6,
    HP: 111,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 761,
    Stamina: 4,
    Intellect: 13,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 762,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 108,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 763,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 764,
    Stamina: 8,
    Intellect: 2,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 765,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 766,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 767,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 768,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 769,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 770,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 771,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 772,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 773,
    Stamina: 2,
    Intellect: 2,
    Strength: 3,
    Defense: 16,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 774,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 775,
    Stamina: 5,
    Intellect: 6,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 776,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 777,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 778,
    Stamina: 3,
    Intellect: 5,
    Strength: 12,
    Defense: 2,
    HP: 103,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 779,
    Stamina: 5,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 780,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 781,
    Stamina: 9,
    Intellect: 3,
    Strength: 1,
    Defense: 2,
    HP: 109,
    Attack: 11,
    Power: 16,
  },
  {
    Id: 782,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 783,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 784,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 785,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 786,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 787,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 788,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 789,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 790,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 791,
    Stamina: 1,
    Intellect: 6,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 792,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 793,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 794,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 795,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 796,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 797,
    Stamina: 2,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 798,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 799,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 800,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 801,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 802,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 803,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 804,
    Stamina: 6,
    Intellect: 8,
    Strength: 1,
    Defense: 5,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 805,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 12,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 806,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 807,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 808,
    Stamina: 8,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 108,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 809,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 810,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 811,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 812,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 813,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 814,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 815,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 816,
    Stamina: 11,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 111,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 817,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 818,
    Stamina: 1,
    Intellect: 5,
    Strength: 3,
    Defense: 12,
    HP: 101,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 819,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 820,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 105,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 821,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 822,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 823,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 824,
    Stamina: 6,
    Intellect: 7,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 825,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 826,
    Stamina: 1,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 827,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 828,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 829,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 830,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 831,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 832,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 833,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 834,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 835,
    Stamina: 9,
    Intellect: 7,
    Strength: 1,
    Defense: 2,
    HP: 109,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 836,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 837,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 838,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 839,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 7,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 840,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 841,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 842,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 843,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 844,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 845,
    Stamina: 8,
    Intellect: 2,
    Strength: 1,
    Defense: 8,
    HP: 108,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 846,
    Stamina: 2,
    Intellect: 2,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 847,
    Stamina: 4,
    Intellect: 10,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 848,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 849,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 850,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 11,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 851,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 852,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 853,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 854,
    Stamina: 5,
    Intellect: 9,
    Strength: 9,
    Defense: 1,
    HP: 105,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 855,
    Stamina: 1,
    Intellect: 8,
    Strength: 11,
    Defense: 4,
    HP: 101,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 856,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 857,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 858,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 859,
    Stamina: 4,
    Intellect: 12,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 860,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 861,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 862,
    Stamina: 8,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 863,
    Stamina: 3,
    Intellect: 11,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 36,
  },
  {
    Id: 864,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 865,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 866,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 867,
    Stamina: 4,
    Intellect: 4,
    Strength: 12,
    Defense: 5,
    HP: 104,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 868,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 869,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 870,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 871,
    Stamina: 7,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 872,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 873,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 15,
    HP: 102,
    Attack: 15,
    Power: 36,
  },
  {
    Id: 874,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 875,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 876,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 877,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 878,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 879,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 880,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 881,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 882,
    Stamina: 2,
    Intellect: 8,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 883,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 884,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 3,
    HP: 102,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 885,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 886,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 887,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 888,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 889,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 108,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 890,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 891,
    Stamina: 8,
    Intellect: 8,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 892,
    Stamina: 5,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 893,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 894,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 895,
    Stamina: 6,
    Intellect: 8,
    Strength: 5,
    Defense: 1,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 896,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 897,
    Stamina: 10,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 110,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 898,
    Stamina: 1,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 899,
    Stamina: 1,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 900,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 901,
    Stamina: 10,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 110,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 902,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 903,
    Stamina: 1,
    Intellect: 10,
    Strength: 2,
    Defense: 6,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 904,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 905,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 906,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 10,
    HP: 102,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 907,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 908,
    Stamina: 7,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 909,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 910,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 911,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 912,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 7,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 913,
    Stamina: 7,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 914,
    Stamina: 1,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 101,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 915,
    Stamina: 1,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 101,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 916,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 917,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 918,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 2,
    HP: 102,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 919,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 920,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 921,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 922,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 923,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 924,
    Stamina: 12,
    Intellect: 2,
    Strength: 3,
    Defense: 4,
    HP: 112,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 925,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 926,
    Stamina: 6,
    Intellect: 2,
    Strength: 15,
    Defense: 1,
    HP: 106,
    Attack: 25,
    Power: 28,
  },
  {
    Id: 927,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 928,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 929,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 930,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 931,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 932,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 933,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 14,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 934,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 935,
    Stamina: 4,
    Intellect: 14,
    Strength: 1,
    Defense: 4,
    HP: 104,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 936,
    Stamina: 9,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 109,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 937,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 107,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 938,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 939,
    Stamina: 6,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 940,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 941,
    Stamina: 1,
    Intellect: 8,
    Strength: 9,
    Defense: 3,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 942,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 943,
    Stamina: 6,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 944,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 945,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 946,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 106,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 947,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 2,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 948,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 949,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 950,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 951,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 14,
    HP: 102,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 952,
    Stamina: 6,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 953,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 954,
    Stamina: 3,
    Intellect: 2,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 955,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 956,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 957,
    Stamina: 4,
    Intellect: 3,
    Strength: 11,
    Defense: 7,
    HP: 104,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 958,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 959,
    Stamina: 5,
    Intellect: 7,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 960,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 961,
    Stamina: 8,
    Intellect: 4,
    Strength: 12,
    Defense: 2,
    HP: 108,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 962,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 963,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 964,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 965,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 7,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 966,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 967,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 107,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 968,
    Stamina: 1,
    Intellect: 11,
    Strength: 7,
    Defense: 3,
    HP: 101,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 969,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 970,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 971,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 972,
    Stamina: 10,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 110,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 973,
    Stamina: 7,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 974,
    Stamina: 3,
    Intellect: 9,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 975,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 12,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 976,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 977,
    Stamina: 5,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 978,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 979,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 980,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 981,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 982,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 983,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 984,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 985,
    Stamina: 8,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 986,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 987,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 110,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 988,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 989,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 990,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 991,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 992,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 993,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 994,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 995,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 996,
    Stamina: 2,
    Intellect: 12,
    Strength: 6,
    Defense: 2,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 997,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 998,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 999,
    Stamina: 4,
    Intellect: 9,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1000,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1001,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 108,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1002,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1003,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 1004,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 1005,
    Stamina: 9,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 109,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1006,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1007,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 1008,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1009,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 1010,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1011,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1012,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 35,
  },
  {
    Id: 1013,
    Stamina: 6,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 1014,
    Stamina: 1,
    Intellect: 13,
    Strength: 2,
    Defense: 7,
    HP: 101,
    Attack: 12,
    Power: 32,
  },
  {
    Id: 1015,
    Stamina: 4,
    Intellect: 2,
    Strength: 1,
    Defense: 12,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 1016,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 1017,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 1018,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 1019,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 1020,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1021,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 11,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1022,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1023,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 8,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 1024,
    Stamina: 3,
    Intellect: 9,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1025,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1026,
    Stamina: 1,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 101,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 1027,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 1028,
    Stamina: 7,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 1029,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1030,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 1031,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1032,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1033,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1034,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 1035,
    Stamina: 2,
    Intellect: 4,
    Strength: 12,
    Defense: 7,
    HP: 102,
    Attack: 22,
    Power: 33,
  },
  {
    Id: 1036,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 1037,
    Stamina: 1,
    Intellect: 7,
    Strength: 2,
    Defense: 10,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 1038,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1039,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1040,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1041,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1042,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1043,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 34,
  },
  {
    Id: 1044,
    Stamina: 2,
    Intellect: 6,
    Strength: 1,
    Defense: 9,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 1045,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1046,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1047,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1048,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1049,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1050,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1051,
    Stamina: 1,
    Intellect: 10,
    Strength: 6,
    Defense: 6,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 1052,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1053,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 8,
    HP: 105,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 1054,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 107,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 1055,
    Stamina: 1,
    Intellect: 14,
    Strength: 1,
    Defense: 10,
    HP: 101,
    Attack: 11,
    Power: 35,
  },
  {
    Id: 1056,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1057,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 110,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 1058,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1059,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1060,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1061,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 1062,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 1063,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1064,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 11,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 1065,
    Stamina: 2,
    Intellect: 11,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 1066,
    Stamina: 2,
    Intellect: 2,
    Strength: 12,
    Defense: 6,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 1067,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 1068,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 1069,
    Stamina: 9,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 109,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 1070,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1071,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1072,
    Stamina: 10,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 110,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1073,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1074,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 1075,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1076,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 1077,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1078,
    Stamina: 3,
    Intellect: 13,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 32,
  },
  {
    Id: 1079,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1080,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1081,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 1082,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1083,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 1084,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 1085,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1086,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1087,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1088,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1089,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1090,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1091,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 1092,
    Stamina: 4,
    Intellect: 9,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 1093,
    Stamina: 1,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 101,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 1094,
    Stamina: 7,
    Intellect: 11,
    Strength: 6,
    Defense: 1,
    HP: 107,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1095,
    Stamina: 8,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 1096,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1097,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1098,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1099,
    Stamina: 10,
    Intellect: 8,
    Strength: 3,
    Defense: 1,
    HP: 110,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1100,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1101,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1102,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 1103,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1104,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1105,
    Stamina: 3,
    Intellect: 11,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1106,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1107,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1108,
    Stamina: 1,
    Intellect: 2,
    Strength: 11,
    Defense: 8,
    HP: 101,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 1109,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1110,
    Stamina: 1,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 101,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1111,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 1112,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 1113,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1114,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 1115,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1116,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1117,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1118,
    Stamina: 1,
    Intellect: 5,
    Strength: 8,
    Defense: 9,
    HP: 101,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 1119,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1120,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1121,
    Stamina: 8,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1122,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 106,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 1123,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1124,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 1125,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 1126,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1127,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1128,
    Stamina: 2,
    Intellect: 14,
    Strength: 2,
    Defense: 6,
    HP: 102,
    Attack: 12,
    Power: 32,
  },
  {
    Id: 1129,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 1130,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1131,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 1132,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 1133,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 1134,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 1135,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 1136,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1137,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 1,
    HP: 108,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 1138,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1139,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1140,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 1141,
    Stamina: 4,
    Intellect: 2,
    Strength: 13,
    Defense: 5,
    HP: 104,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 1142,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1143,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 1144,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 1145,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 1146,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 1,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1147,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 1148,
    Stamina: 4,
    Intellect: 17,
    Strength: 3,
    Defense: 1,
    HP: 104,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 1149,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1150,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1151,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1152,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1153,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1154,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1155,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 1156,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 1157,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1158,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1159,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1160,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1161,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1162,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 1163,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1164,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1165,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 1,
    HP: 105,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 1166,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1167,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 9,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 1168,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 1169,
    Stamina: 2,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 1170,
    Stamina: 1,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1171,
    Stamina: 1,
    Intellect: 9,
    Strength: 10,
    Defense: 5,
    HP: 101,
    Attack: 20,
    Power: 34,
  },
  {
    Id: 1172,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1173,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1174,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 1175,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1176,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1177,
    Stamina: 1,
    Intellect: 3,
    Strength: 10,
    Defense: 10,
    HP: 101,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 1178,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1179,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1180,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1181,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1182,
    Stamina: 3,
    Intellect: 6,
    Strength: 12,
    Defense: 2,
    HP: 103,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 1183,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 1184,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 1185,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 2,
    HP: 110,
    Attack: 12,
    Power: 17,
  },
  {
    Id: 1186,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 1187,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 1188,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1189,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1190,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 1191,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 1192,
    Stamina: 4,
    Intellect: 2,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1193,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 1194,
    Stamina: 2,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1195,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 12,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 1196,
    Stamina: 1,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 101,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1197,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1198,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 1199,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1200,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1201,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1202,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 1203,
    Stamina: 3,
    Intellect: 11,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1204,
    Stamina: 8,
    Intellect: 5,
    Strength: 8,
    Defense: 1,
    HP: 108,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 1205,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1206,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1207,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 109,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1208,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1209,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1210,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 1211,
    Stamina: 6,
    Intellect: 8,
    Strength: 1,
    Defense: 3,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 1212,
    Stamina: 6,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1213,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1214,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1215,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 1216,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 1217,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 1218,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1219,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1220,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 1221,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 1222,
    Stamina: 7,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 107,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 1223,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1224,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 1225,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 1226,
    Stamina: 9,
    Intellect: 4,
    Strength: 1,
    Defense: 3,
    HP: 109,
    Attack: 11,
    Power: 18,
  },
  {
    Id: 1227,
    Stamina: 9,
    Intellect: 5,
    Strength: 1,
    Defense: 3,
    HP: 109,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 1228,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1229,
    Stamina: 3,
    Intellect: 12,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 1230,
    Stamina: 5,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 1231,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1232,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1233,
    Stamina: 7,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1234,
    Stamina: 10,
    Intellect: 2,
    Strength: 9,
    Defense: 2,
    HP: 110,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 1235,
    Stamina: 6,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 1236,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1237,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1238,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 1239,
    Stamina: 4,
    Intellect: 5,
    Strength: 12,
    Defense: 6,
    HP: 104,
    Attack: 22,
    Power: 33,
  },
  {
    Id: 1240,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1241,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1242,
    Stamina: 8,
    Intellect: 8,
    Strength: 3,
    Defense: 1,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1243,
    Stamina: 10,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 110,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 1244,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1245,
    Stamina: 1,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1246,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 108,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1247,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1248,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1249,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 2,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1250,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1251,
    Stamina: 10,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 110,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1252,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 1253,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 1254,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 1255,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1256,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1257,
    Stamina: 8,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 108,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 1258,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1259,
    Stamina: 8,
    Intellect: 5,
    Strength: 6,
    Defense: 1,
    HP: 108,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 1260,
    Stamina: 10,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 110,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1261,
    Stamina: 1,
    Intellect: 3,
    Strength: 10,
    Defense: 8,
    HP: 101,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 1262,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1263,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1264,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1265,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 1266,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 2,
    HP: 107,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 1267,
    Stamina: 2,
    Intellect: 3,
    Strength: 1,
    Defense: 13,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1268,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1269,
    Stamina: 5,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1270,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1271,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1272,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1273,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 1274,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1275,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1276,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 105,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1277,
    Stamina: 10,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 110,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1278,
    Stamina: 2,
    Intellect: 6,
    Strength: 1,
    Defense: 11,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 1279,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1280,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1281,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1282,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1283,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1284,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1285,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1286,
    Stamina: 2,
    Intellect: 9,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 1287,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 1288,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 107,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1289,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 1,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1290,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1291,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 1292,
    Stamina: 9,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1293,
    Stamina: 5,
    Intellect: 11,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 1294,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 12,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 1295,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1296,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1297,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 1298,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 1299,
    Stamina: 8,
    Intellect: 3,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 1300,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 1301,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1302,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1303,
    Stamina: 13,
    Intellect: 4,
    Strength: 2,
    Defense: 3,
    HP: 113,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 1304,
    Stamina: 4,
    Intellect: 3,
    Strength: 11,
    Defense: 2,
    HP: 104,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 1305,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1306,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1307,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 1308,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1309,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 1310,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1311,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 1312,
    Stamina: 4,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 104,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 1313,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1314,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1315,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 1316,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1317,
    Stamina: 8,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 108,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 1318,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1319,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 1320,
    Stamina: 2,
    Intellect: 11,
    Strength: 1,
    Defense: 6,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 1321,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 11,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1322,
    Stamina: 6,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1323,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1324,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 1325,
    Stamina: 6,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1326,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1327,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 1328,
    Stamina: 13,
    Intellect: 4,
    Strength: 2,
    Defense: 2,
    HP: 113,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 1329,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 1330,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 1331,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 33,
  },
  {
    Id: 1332,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 1333,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1334,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 1335,
    Stamina: 6,
    Intellect: 5,
    Strength: 12,
    Defense: 2,
    HP: 106,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 1336,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1337,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1338,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1339,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 1340,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1341,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 1342,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1343,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1344,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 1345,
    Stamina: 5,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1346,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1347,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1348,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 1349,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1350,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1351,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1352,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1353,
    Stamina: 10,
    Intellect: 4,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 1354,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 1355,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 1356,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1357,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 1358,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 1359,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 1360,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 101,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 1361,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 1362,
    Stamina: 4,
    Intellect: 10,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 1363,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1364,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1365,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1366,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 1367,
    Stamina: 5,
    Intellect: 8,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 1368,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1369,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 1370,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1371,
    Stamina: 3,
    Intellect: 4,
    Strength: 13,
    Defense: 3,
    HP: 103,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 1372,
    Stamina: 2,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1373,
    Stamina: 1,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 1374,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 1375,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 12,
    HP: 102,
    Attack: 15,
    Power: 33,
  },
  {
    Id: 1376,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 8,
    HP: 102,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 1377,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 1378,
    Stamina: 6,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 106,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 1379,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 1380,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 1,
    HP: 109,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 1381,
    Stamina: 2,
    Intellect: 2,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 1382,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1383,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1384,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1385,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 32,
  },
  {
    Id: 1386,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1387,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 1388,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 9,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1389,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1390,
    Stamina: 3,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1391,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 1392,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1393,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 105,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 1394,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1395,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1396,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1397,
    Stamina: 9,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 109,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1398,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1399,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 108,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1400,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 1401,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 1402,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1403,
    Stamina: 11,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 111,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 1404,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 8,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 1405,
    Stamina: 4,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 1406,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1407,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 1408,
    Stamina: 8,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 108,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 1409,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1410,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1411,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 1412,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1413,
    Stamina: 1,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1414,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1415,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1416,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 1417,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1418,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1419,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 1420,
    Stamina: 8,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1421,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1422,
    Stamina: 2,
    Intellect: 2,
    Strength: 12,
    Defense: 6,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 1423,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1424,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 1425,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1426,
    Stamina: 9,
    Intellect: 3,
    Strength: 7,
    Defense: 1,
    HP: 109,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 1427,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 1428,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1429,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 13,
    HP: 104,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 1430,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1431,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1432,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 1433,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1434,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1435,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 1436,
    Stamina: 1,
    Intellect: 3,
    Strength: 11,
    Defense: 8,
    HP: 101,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 1437,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 2,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1438,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1439,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1440,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1441,
    Stamina: 3,
    Intellect: 12,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1442,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1443,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1444,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1445,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1446,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1447,
    Stamina: 3,
    Intellect: 13,
    Strength: 4,
    Defense: 1,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1448,
    Stamina: 4,
    Intellect: 4,
    Strength: 13,
    Defense: 2,
    HP: 104,
    Attack: 23,
    Power: 29,
  },
  {
    Id: 1449,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1450,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1451,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1452,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1453,
    Stamina: 12,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 112,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 1454,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1455,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1456,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1457,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1458,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 14,
    HP: 104,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 1459,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1460,
    Stamina: 5,
    Intellect: 2,
    Strength: 11,
    Defense: 5,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 1461,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1462,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1463,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 1464,
    Stamina: 8,
    Intellect: 2,
    Strength: 8,
    Defense: 2,
    HP: 108,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 1465,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 1466,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1467,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 1468,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 107,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 1469,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 1470,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 1471,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 107,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1472,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1473,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 12,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 1474,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 1475,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1476,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 1477,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1478,
    Stamina: 1,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1479,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1480,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1481,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1482,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1483,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1484,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1485,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1486,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1487,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 110,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1488,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 1489,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 1490,
    Stamina: 1,
    Intellect: 4,
    Strength: 7,
    Defense: 9,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1491,
    Stamina: 9,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 1492,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1493,
    Stamina: 6,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 106,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 1494,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 1495,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 1496,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1497,
    Stamina: 1,
    Intellect: 3,
    Strength: 7,
    Defense: 10,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1498,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 1,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1499,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1500,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1501,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1502,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 1503,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1504,
    Stamina: 2,
    Intellect: 10,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 1505,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1506,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1507,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1508,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1509,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 1510,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1511,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1512,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 1513,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1514,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1515,
    Stamina: 2,
    Intellect: 7,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 1516,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1517,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 1518,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1519,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1520,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1521,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 34,
  },
  {
    Id: 1522,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1523,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1524,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1525,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 11,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1526,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1527,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1528,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1529,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 1530,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1531,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1532,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 1533,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1534,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1535,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1536,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1537,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1538,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 1539,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1540,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1541,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1542,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 1543,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1544,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 1545,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1546,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 1547,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1548,
    Stamina: 10,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 110,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 1549,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 1550,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1551,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1552,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 1553,
    Stamina: 1,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 101,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1554,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 1555,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1556,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1557,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1558,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 1559,
    Stamina: 5,
    Intellect: 10,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1560,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1561,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1562,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1563,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1564,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 1565,
    Stamina: 8,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 108,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 1566,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 108,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1567,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1568,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1569,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 1570,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1571,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1572,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1573,
    Stamina: 3,
    Intellect: 10,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 1574,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1575,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1576,
    Stamina: 5,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1577,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 1578,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1579,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1580,
    Stamina: 1,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 101,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 1581,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 9,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1582,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1583,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 1584,
    Stamina: 10,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 110,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 1585,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 1586,
    Stamina: 2,
    Intellect: 4,
    Strength: 12,
    Defense: 2,
    HP: 102,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 1587,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 107,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1588,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1589,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 6,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 1590,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1591,
    Stamina: 2,
    Intellect: 14,
    Strength: 2,
    Defense: 3,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 1592,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1593,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1594,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1595,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1596,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1597,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 1598,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1599,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1600,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 1601,
    Stamina: 5,
    Intellect: 13,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 1602,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1603,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1604,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1605,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1606,
    Stamina: 4,
    Intellect: 7,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 1607,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 1608,
    Stamina: 1,
    Intellect: 12,
    Strength: 4,
    Defense: 5,
    HP: 101,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 1609,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 1610,
    Stamina: 3,
    Intellect: 10,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 1611,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1612,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1613,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1614,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1615,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 10,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 1616,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1617,
    Stamina: 1,
    Intellect: 11,
    Strength: 4,
    Defense: 3,
    HP: 101,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1618,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1619,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1620,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1621,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 15,
    HP: 102,
    Attack: 12,
    Power: 32,
  },
  {
    Id: 1622,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1623,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1624,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 1625,
    Stamina: 3,
    Intellect: 3,
    Strength: 14,
    Defense: 2,
    HP: 103,
    Attack: 24,
    Power: 29,
  },
  {
    Id: 1626,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 6,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1627,
    Stamina: 5,
    Intellect: 3,
    Strength: 11,
    Defense: 7,
    HP: 105,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 1628,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 1629,
    Stamina: 1,
    Intellect: 3,
    Strength: 11,
    Defense: 8,
    HP: 101,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 1630,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 1631,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1632,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1633,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 107,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 1634,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1635,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1636,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1637,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 1638,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1639,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1640,
    Stamina: 6,
    Intellect: 9,
    Strength: 2,
    Defense: 2,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1641,
    Stamina: 1,
    Intellect: 4,
    Strength: 16,
    Defense: 4,
    HP: 101,
    Attack: 26,
    Power: 34,
  },
  {
    Id: 1642,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1643,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1644,
    Stamina: 7,
    Intellect: 2,
    Strength: 11,
    Defense: 5,
    HP: 107,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 1645,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 1646,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1647,
    Stamina: 1,
    Intellect: 12,
    Strength: 5,
    Defense: 3,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1648,
    Stamina: 3,
    Intellect: 12,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1649,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 1650,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 101,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1651,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1652,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1653,
    Stamina: 2,
    Intellect: 12,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1654,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1655,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 1656,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1657,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1658,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1659,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 1660,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 1661,
    Stamina: 12,
    Intellect: 2,
    Strength: 2,
    Defense: 4,
    HP: 112,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 1662,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1663,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1664,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1665,
    Stamina: 1,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 101,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1666,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1667,
    Stamina: 4,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 1668,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1669,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1670,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1671,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 1672,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 1,
    HP: 108,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 1673,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1674,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1675,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1676,
    Stamina: 5,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1677,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 1,
    HP: 106,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 1678,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 1679,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1680,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1681,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1682,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1683,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1684,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 1685,
    Stamina: 9,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 109,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 1686,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1687,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1688,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1689,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1690,
    Stamina: 1,
    Intellect: 2,
    Strength: 10,
    Defense: 9,
    HP: 101,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 1691,
    Stamina: 7,
    Intellect: 2,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 1692,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 1693,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1694,
    Stamina: 10,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 110,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1695,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1696,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1697,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1698,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1699,
    Stamina: 1,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 101,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1700,
    Stamina: 8,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1701,
    Stamina: 2,
    Intellect: 14,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1702,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 1703,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 1704,
    Stamina: 1,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 1705,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 6,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 1706,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 1707,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1708,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1709,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 1710,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1711,
    Stamina: 3,
    Intellect: 9,
    Strength: 9,
    Defense: 1,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 1712,
    Stamina: 4,
    Intellect: 11,
    Strength: 8,
    Defense: 1,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1713,
    Stamina: 9,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 109,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 1714,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1715,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 15,
    HP: 102,
    Attack: 13,
    Power: 34,
  },
  {
    Id: 1716,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 1717,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1718,
    Stamina: 11,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 111,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 1719,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 12,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 1720,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1721,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1722,
    Stamina: 10,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 110,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 1723,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1724,
    Stamina: 10,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 110,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1725,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1726,
    Stamina: 2,
    Intellect: 5,
    Strength: 14,
    Defense: 2,
    HP: 102,
    Attack: 24,
    Power: 31,
  },
  {
    Id: 1727,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 1728,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1729,
    Stamina: 5,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1730,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 1731,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1732,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1733,
    Stamina: 1,
    Intellect: 10,
    Strength: 5,
    Defense: 5,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1734,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1735,
    Stamina: 1,
    Intellect: 6,
    Strength: 2,
    Defense: 13,
    HP: 101,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 1736,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1737,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1738,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1739,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1740,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 13,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 1741,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 1742,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 12,
    HP: 101,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 1743,
    Stamina: 1,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 101,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 1744,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1745,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 1746,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1747,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 1748,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1749,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 1750,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 1751,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1752,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1753,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1754,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1755,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1756,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1757,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1758,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 1759,
    Stamina: 8,
    Intellect: 6,
    Strength: 9,
    Defense: 5,
    HP: 108,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 1760,
    Stamina: 1,
    Intellect: 6,
    Strength: 9,
    Defense: 6,
    HP: 101,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 1761,
    Stamina: 13,
    Intellect: 4,
    Strength: 3,
    Defense: 1,
    HP: 113,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 1762,
    Stamina: 6,
    Intellect: 8,
    Strength: 6,
    Defense: 1,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 1763,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 1764,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1765,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1766,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1767,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 1768,
    Stamina: 5,
    Intellect: 10,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1769,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 1770,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1771,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 101,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 1772,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1773,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1774,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 12,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 1775,
    Stamina: 6,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 106,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 1776,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1777,
    Stamina: 5,
    Intellect: 9,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 1778,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1779,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 106,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1780,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 1781,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 1782,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 1783,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 1784,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 1785,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 1786,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 11,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1787,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 1788,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1789,
    Stamina: 8,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1790,
    Stamina: 10,
    Intellect: 5,
    Strength: 2,
    Defense: 1,
    HP: 110,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 1791,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1792,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 1,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1793,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 1794,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1795,
    Stamina: 1,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1796,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 7,
    HP: 102,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 1797,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1798,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 1799,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1800,
    Stamina: 1,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 101,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 1801,
    Stamina: 3,
    Intellect: 9,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 1802,
    Stamina: 1,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 101,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 1803,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 1804,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1805,
    Stamina: 1,
    Intellect: 6,
    Strength: 4,
    Defense: 11,
    HP: 101,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 1806,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1807,
    Stamina: 1,
    Intellect: 2,
    Strength: 11,
    Defense: 7,
    HP: 101,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 1808,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1809,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 1810,
    Stamina: 7,
    Intellect: 2,
    Strength: 1,
    Defense: 10,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 1811,
    Stamina: 3,
    Intellect: 3,
    Strength: 13,
    Defense: 2,
    HP: 103,
    Attack: 23,
    Power: 28,
  },
  {
    Id: 1812,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 1813,
    Stamina: 1,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1814,
    Stamina: 4,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 1815,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1816,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 105,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1817,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1818,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 1819,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1820,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 1821,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1822,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1823,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1824,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1825,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 1826,
    Stamina: 2,
    Intellect: 6,
    Strength: 1,
    Defense: 11,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 1827,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 1828,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 1829,
    Stamina: 11,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 111,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 1830,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 3,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 1831,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 1832,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1833,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1834,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1835,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1836,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 13,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 1837,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 1838,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1839,
    Stamina: 4,
    Intellect: 11,
    Strength: 4,
    Defense: 1,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1840,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1841,
    Stamina: 4,
    Intellect: 11,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 1842,
    Stamina: 3,
    Intellect: 12,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 1843,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1844,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1845,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1846,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 11,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1847,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 101,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1848,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 12,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1849,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 1850,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 1851,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 1852,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1853,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1854,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1855,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1856,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 2,
    HP: 106,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 1857,
    Stamina: 3,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1858,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1859,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 1860,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1861,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1862,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1863,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1864,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1865,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 33,
  },
  {
    Id: 1866,
    Stamina: 7,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 107,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1867,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1868,
    Stamina: 1,
    Intellect: 9,
    Strength: 2,
    Defense: 8,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 1869,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1870,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1871,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1872,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 1873,
    Stamina: 1,
    Intellect: 6,
    Strength: 8,
    Defense: 8,
    HP: 101,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 1874,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1875,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 1876,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1877,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 1878,
    Stamina: 2,
    Intellect: 14,
    Strength: 1,
    Defense: 5,
    HP: 102,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 1879,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1880,
    Stamina: 3,
    Intellect: 2,
    Strength: 13,
    Defense: 2,
    HP: 103,
    Attack: 23,
    Power: 27,
  },
  {
    Id: 1881,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 1882,
    Stamina: 5,
    Intellect: 11,
    Strength: 3,
    Defense: 2,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1883,
    Stamina: 7,
    Intellect: 3,
    Strength: 1,
    Defense: 9,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 1884,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1885,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 1886,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1887,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 1888,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 1889,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 1890,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 2,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1891,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1892,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 1893,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1894,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1895,
    Stamina: 4,
    Intellect: 12,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 1896,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1897,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1898,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 1899,
    Stamina: 7,
    Intellect: 2,
    Strength: 9,
    Defense: 2,
    HP: 107,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 1900,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1901,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1902,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 1903,
    Stamina: 9,
    Intellect: 3,
    Strength: 8,
    Defense: 1,
    HP: 109,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 1904,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1905,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1906,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1907,
    Stamina: 7,
    Intellect: 3,
    Strength: 12,
    Defense: 2,
    HP: 107,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 1908,
    Stamina: 1,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 101,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1909,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1910,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 1911,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 1912,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 1913,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 11,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 1914,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 1915,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 13,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 1916,
    Stamina: 1,
    Intellect: 14,
    Strength: 4,
    Defense: 7,
    HP: 101,
    Attack: 14,
    Power: 35,
  },
  {
    Id: 1917,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1918,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1919,
    Stamina: 2,
    Intellect: 2,
    Strength: 12,
    Defense: 3,
    HP: 102,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 1920,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1921,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1922,
    Stamina: 1,
    Intellect: 4,
    Strength: 4,
    Defense: 12,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1923,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 1924,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 1925,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1926,
    Stamina: 6,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 1927,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1928,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 1929,
    Stamina: 1,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 101,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1930,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 1931,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 1932,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 1933,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 1934,
    Stamina: 10,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 110,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1935,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1936,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 1937,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 1938,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 1939,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 1940,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 1941,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 1942,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 1943,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 1944,
    Stamina: 2,
    Intellect: 14,
    Strength: 5,
    Defense: 1,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 1945,
    Stamina: 4,
    Intellect: 3,
    Strength: 12,
    Defense: 4,
    HP: 104,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 1946,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 101,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 1947,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 1948,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 107,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1949,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 1950,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 1,
    HP: 105,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 1951,
    Stamina: 10,
    Intellect: 6,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 1952,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 1953,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1954,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 1955,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1956,
    Stamina: 10,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 110,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 1957,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 1,
    HP: 109,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 1958,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 1959,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 1960,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 1961,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 1962,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 1963,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 1964,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1965,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 1966,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1967,
    Stamina: 2,
    Intellect: 9,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 1968,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 1969,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 1970,
    Stamina: 9,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 109,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1971,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 1972,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 1973,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1974,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 1975,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 1976,
    Stamina: 2,
    Intellect: 12,
    Strength: 5,
    Defense: 1,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1977,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1978,
    Stamina: 8,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 1979,
    Stamina: 9,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 109,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 1980,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 1981,
    Stamina: 3,
    Intellect: 8,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 1982,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 1983,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 1984,
    Stamina: 1,
    Intellect: 5,
    Strength: 11,
    Defense: 7,
    HP: 101,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 1985,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 1986,
    Stamina: 9,
    Intellect: 2,
    Strength: 12,
    Defense: 1,
    HP: 109,
    Attack: 22,
    Power: 25,
  },
  {
    Id: 1987,
    Stamina: 9,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 109,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 1988,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 1989,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 8,
    HP: 103,
    Attack: 21,
    Power: 34,
  },
  {
    Id: 1990,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 1991,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 1992,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 1993,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 1994,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 1995,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 1996,
    Stamina: 1,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 101,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 1997,
    Stamina: 8,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 1998,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 10,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 1999,
    Stamina: 1,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2000,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2001,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2002,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 2003,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2004,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 2005,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2006,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 5,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 2007,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2008,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2009,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2010,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2011,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2012,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2013,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 2014,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2015,
    Stamina: 7,
    Intellect: 6,
    Strength: 9,
    Defense: 5,
    HP: 107,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 2016,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2017,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 2018,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2019,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2020,
    Stamina: 10,
    Intellect: 7,
    Strength: 4,
    Defense: 2,
    HP: 110,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2021,
    Stamina: 9,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 109,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 2022,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 1,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2023,
    Stamina: 8,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 108,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2024,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 2025,
    Stamina: 3,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 103,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 2026,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2027,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2028,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 2029,
    Stamina: 2,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 2030,
    Stamina: 7,
    Intellect: 3,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 2031,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2032,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 2033,
    Stamina: 12,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 112,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2034,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2035,
    Stamina: 1,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 2036,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 2037,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2038,
    Stamina: 4,
    Intellect: 5,
    Strength: 12,
    Defense: 5,
    HP: 104,
    Attack: 22,
    Power: 32,
  },
  {
    Id: 2039,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 2040,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2041,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 2042,
    Stamina: 2,
    Intellect: 2,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2043,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2044,
    Stamina: 2,
    Intellect: 13,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 2045,
    Stamina: 1,
    Intellect: 4,
    Strength: 12,
    Defense: 5,
    HP: 101,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 2046,
    Stamina: 3,
    Intellect: 6,
    Strength: 12,
    Defense: 4,
    HP: 103,
    Attack: 22,
    Power: 32,
  },
  {
    Id: 2047,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2048,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2049,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2050,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 10,
    HP: 103,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 2051,
    Stamina: 4,
    Intellect: 4,
    Strength: 13,
    Defense: 6,
    HP: 104,
    Attack: 23,
    Power: 33,
  },
  {
    Id: 2052,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2053,
    Stamina: 10,
    Intellect: 9,
    Strength: 1,
    Defense: 2,
    HP: 110,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 2054,
    Stamina: 3,
    Intellect: 11,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 2055,
    Stamina: 9,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2056,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 105,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2057,
    Stamina: 5,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2058,
    Stamina: 4,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2059,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 2060,
    Stamina: 10,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 110,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2061,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2062,
    Stamina: 9,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 109,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2063,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2064,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 2065,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2066,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2067,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2068,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 2069,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 2070,
    Stamina: 11,
    Intellect: 2,
    Strength: 5,
    Defense: 2,
    HP: 111,
    Attack: 15,
    Power: 19,
  },
  {
    Id: 2071,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2072,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2073,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2074,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 2075,
    Stamina: 2,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2076,
    Stamina: 9,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 109,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 2077,
    Stamina: 11,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 111,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 2078,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 2079,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2080,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2081,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 12,
    HP: 105,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 2082,
    Stamina: 10,
    Intellect: 3,
    Strength: 1,
    Defense: 3,
    HP: 110,
    Attack: 11,
    Power: 17,
  },
  {
    Id: 2083,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2084,
    Stamina: 6,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2085,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 2086,
    Stamina: 2,
    Intellect: 4,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 2087,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2088,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2089,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2090,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 2091,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 2092,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 12,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2093,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2094,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2095,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2096,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2097,
    Stamina: 10,
    Intellect: 6,
    Strength: 1,
    Defense: 3,
    HP: 110,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 2098,
    Stamina: 9,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 109,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2099,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 2100,
    Stamina: 1,
    Intellect: 9,
    Strength: 8,
    Defense: 3,
    HP: 101,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2101,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2102,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2103,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 109,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2104,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 2105,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2106,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2107,
    Stamina: 1,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 101,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2108,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2109,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 2110,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2111,
    Stamina: 12,
    Intellect: 4,
    Strength: 3,
    Defense: 1,
    HP: 112,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 2112,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 2113,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2114,
    Stamina: 8,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2115,
    Stamina: 4,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2116,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 2117,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 1,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 2118,
    Stamina: 9,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 109,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2119,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2120,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2121,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 1,
    HP: 104,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 2122,
    Stamina: 8,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 108,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 2123,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2124,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2125,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2126,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2127,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 12,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 2128,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2129,
    Stamina: 8,
    Intellect: 4,
    Strength: 1,
    Defense: 12,
    HP: 108,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 2130,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 2131,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 2132,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2133,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 2134,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2135,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2136,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 2137,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2138,
    Stamina: 9,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 109,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2139,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2140,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2141,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2142,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 2143,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2144,
    Stamina: 12,
    Intellect: 2,
    Strength: 6,
    Defense: 2,
    HP: 112,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 2145,
    Stamina: 1,
    Intellect: 3,
    Strength: 13,
    Defense: 5,
    HP: 101,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 2146,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2147,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2148,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 109,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2149,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2150,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2151,
    Stamina: 8,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2152,
    Stamina: 7,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2153,
    Stamina: 7,
    Intellect: 11,
    Strength: 1,
    Defense: 2,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2154,
    Stamina: 4,
    Intellect: 3,
    Strength: 12,
    Defense: 2,
    HP: 104,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 2155,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 108,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 2156,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2157,
    Stamina: 9,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 109,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2158,
    Stamina: 10,
    Intellect: 7,
    Strength: 5,
    Defense: 1,
    HP: 110,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2159,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2160,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2161,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2162,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2163,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2164,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2165,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2166,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2167,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2168,
    Stamina: 9,
    Intellect: 2,
    Strength: 1,
    Defense: 11,
    HP: 109,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2169,
    Stamina: 4,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 104,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 2170,
    Stamina: 10,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 110,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2171,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2172,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2173,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2174,
    Stamina: 8,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2175,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 2176,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 2177,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2178,
    Stamina: 3,
    Intellect: 4,
    Strength: 12,
    Defense: 2,
    HP: 103,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 2179,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 2180,
    Stamina: 1,
    Intellect: 2,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2181,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2182,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 2183,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2184,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2185,
    Stamina: 12,
    Intellect: 3,
    Strength: 2,
    Defense: 2,
    HP: 112,
    Attack: 12,
    Power: 17,
  },
  {
    Id: 2186,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2187,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2188,
    Stamina: 10,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 110,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 2189,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2190,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 2191,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2192,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 2193,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2194,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2195,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2196,
    Stamina: 9,
    Intellect: 3,
    Strength: 1,
    Defense: 9,
    HP: 109,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 2197,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 2198,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 2199,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2200,
    Stamina: 4,
    Intellect: 10,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2201,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 9,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 2202,
    Stamina: 2,
    Intellect: 9,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 2203,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 2204,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2205,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2206,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2207,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2208,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2209,
    Stamina: 2,
    Intellect: 5,
    Strength: 15,
    Defense: 5,
    HP: 102,
    Attack: 25,
    Power: 35,
  },
  {
    Id: 2210,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2211,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2212,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 2213,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2214,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 108,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 2215,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2216,
    Stamina: 1,
    Intellect: 6,
    Strength: 8,
    Defense: 11,
    HP: 101,
    Attack: 18,
    Power: 35,
  },
  {
    Id: 2217,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2218,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2219,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2220,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 2221,
    Stamina: 10,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 110,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2222,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2223,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2224,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2225,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2226,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2227,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2228,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 2229,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2230,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2231,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2232,
    Stamina: 2,
    Intellect: 10,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2233,
    Stamina: 2,
    Intellect: 12,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 2234,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2235,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2236,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 2237,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2238,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2239,
    Stamina: 8,
    Intellect: 12,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2240,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2241,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2242,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2243,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2244,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2245,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2246,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2247,
    Stamina: 1,
    Intellect: 13,
    Strength: 2,
    Defense: 5,
    HP: 101,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 2248,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2249,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2250,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2251,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 2252,
    Stamina: 1,
    Intellect: 4,
    Strength: 13,
    Defense: 4,
    HP: 101,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 2253,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 2254,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2255,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 2256,
    Stamina: 1,
    Intellect: 3,
    Strength: 7,
    Defense: 10,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 2257,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 1,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2258,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 2259,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2260,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2261,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2262,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2263,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 107,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2264,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 2265,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2266,
    Stamina: 6,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2267,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 2268,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2269,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2270,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 2271,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2272,
    Stamina: 6,
    Intellect: 3,
    Strength: 11,
    Defense: 1,
    HP: 106,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 2273,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2274,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 13,
    HP: 103,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 2275,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2276,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2277,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2278,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 24,
  },
  {
    Id: 2279,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 2280,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2281,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 2282,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2283,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 2284,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2285,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2286,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2287,
    Stamina: 10,
    Intellect: 6,
    Strength: 2,
    Defense: 2,
    HP: 110,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 2288,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2289,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 2290,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2291,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2292,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2293,
    Stamina: 6,
    Intellect: 8,
    Strength: 7,
    Defense: 1,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2294,
    Stamina: 2,
    Intellect: 10,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 2295,
    Stamina: 5,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 2296,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2297,
    Stamina: 7,
    Intellect: 5,
    Strength: 11,
    Defense: 7,
    HP: 107,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 2298,
    Stamina: 1,
    Intellect: 12,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 35,
  },
  {
    Id: 2299,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2300,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2301,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2302,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2303,
    Stamina: 4,
    Intellect: 2,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2304,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 2305,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2306,
    Stamina: 4,
    Intellect: 2,
    Strength: 12,
    Defense: 3,
    HP: 104,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 2307,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 10,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2308,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2309,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 2310,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2311,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 2312,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2313,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2314,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2315,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2316,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2317,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2318,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2319,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2320,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2321,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2322,
    Stamina: 11,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 111,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 2323,
    Stamina: 9,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 109,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 2324,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 2325,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2326,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2327,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 10,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 2328,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2329,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2330,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 2331,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2332,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2333,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2334,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2335,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2336,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2337,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2338,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2339,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2340,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2341,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2342,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2343,
    Stamina: 5,
    Intellect: 14,
    Strength: 1,
    Defense: 1,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 2344,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2345,
    Stamina: 3,
    Intellect: 12,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 2346,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2347,
    Stamina: 6,
    Intellect: 11,
    Strength: 2,
    Defense: 2,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2348,
    Stamina: 4,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2349,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2350,
    Stamina: 4,
    Intellect: 11,
    Strength: 3,
    Defense: 1,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2351,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 2352,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2353,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 2354,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2355,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2356,
    Stamina: 1,
    Intellect: 7,
    Strength: 1,
    Defense: 13,
    HP: 101,
    Attack: 11,
    Power: 31,
  },
  {
    Id: 2357,
    Stamina: 1,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2358,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2359,
    Stamina: 6,
    Intellect: 11,
    Strength: 2,
    Defense: 2,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2360,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 2361,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 2362,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 2363,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2364,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 2365,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 109,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 2366,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2367,
    Stamina: 1,
    Intellect: 11,
    Strength: 4,
    Defense: 8,
    HP: 101,
    Attack: 14,
    Power: 33,
  },
  {
    Id: 2368,
    Stamina: 6,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2369,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 1,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2370,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2371,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 1,
    HP: 105,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 2372,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 2373,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2374,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2375,
    Stamina: 6,
    Intellect: 2,
    Strength: 2,
    Defense: 11,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2376,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2377,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 13,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2378,
    Stamina: 3,
    Intellect: 11,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 2379,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 2380,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2381,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 2382,
    Stamina: 2,
    Intellect: 7,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 2383,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 2384,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2385,
    Stamina: 9,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 2386,
    Stamina: 1,
    Intellect: 8,
    Strength: 5,
    Defense: 7,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2387,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2388,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 102,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2389,
    Stamina: 1,
    Intellect: 8,
    Strength: 10,
    Defense: 5,
    HP: 101,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 2390,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2391,
    Stamina: 1,
    Intellect: 7,
    Strength: 5,
    Defense: 8,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2392,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2393,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2394,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2395,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2396,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2397,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 2398,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2399,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2400,
    Stamina: 6,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2401,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 2402,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2403,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 11,
    HP: 107,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2404,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2405,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2406,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 2407,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2408,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 2409,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2410,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2411,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2412,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 107,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2413,
    Stamina: 7,
    Intellect: 5,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 2414,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 11,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2415,
    Stamina: 8,
    Intellect: 7,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 2416,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2417,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 108,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2418,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 2419,
    Stamina: 4,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 2420,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2421,
    Stamina: 1,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2422,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2423,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2424,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2425,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2426,
    Stamina: 9,
    Intellect: 5,
    Strength: 10,
    Defense: 1,
    HP: 109,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 2427,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2428,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2429,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2430,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2431,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 2432,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2433,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2434,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 2,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2435,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2436,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 107,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2437,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2438,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2439,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2440,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2441,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2442,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2443,
    Stamina: 3,
    Intellect: 5,
    Strength: 13,
    Defense: 3,
    HP: 103,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 2444,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 107,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2445,
    Stamina: 1,
    Intellect: 9,
    Strength: 7,
    Defense: 3,
    HP: 101,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2446,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2447,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2448,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2449,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 2450,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2451,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2452,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 2453,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2454,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2455,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2456,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2457,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2458,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2459,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 107,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2460,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2461,
    Stamina: 4,
    Intellect: 11,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2462,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2463,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2464,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 2465,
    Stamina: 5,
    Intellect: 7,
    Strength: 8,
    Defense: 1,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2466,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 2467,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 2468,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2469,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 2470,
    Stamina: 4,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2471,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 2472,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2473,
    Stamina: 2,
    Intellect: 12,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 2474,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2475,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2476,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2477,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 7,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 2478,
    Stamina: 11,
    Intellect: 6,
    Strength: 1,
    Defense: 1,
    HP: 111,
    Attack: 11,
    Power: 18,
  },
  {
    Id: 2479,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2480,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 1,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2481,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 2482,
    Stamina: 2,
    Intellect: 11,
    Strength: 7,
    Defense: 1,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 2483,
    Stamina: 7,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2484,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2485,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 2486,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 2487,
    Stamina: 5,
    Intellect: 11,
    Strength: 1,
    Defense: 2,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2488,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2489,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 108,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2490,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2491,
    Stamina: 1,
    Intellect: 2,
    Strength: 9,
    Defense: 7,
    HP: 101,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2492,
    Stamina: 5,
    Intellect: 10,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 2493,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2494,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2495,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 109,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2496,
    Stamina: 8,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 108,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2497,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2498,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2499,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2500,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2501,
    Stamina: 9,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 109,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2502,
    Stamina: 3,
    Intellect: 11,
    Strength: 1,
    Defense: 3,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2503,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2504,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2505,
    Stamina: 10,
    Intellect: 6,
    Strength: 1,
    Defense: 3,
    HP: 110,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 2506,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2507,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2508,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2509,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 2510,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2511,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2512,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2513,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2514,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2515,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2516,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 7,
    HP: 105,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 2517,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 6,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 2518,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 2519,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2520,
    Stamina: 1,
    Intellect: 2,
    Strength: 10,
    Defense: 8,
    HP: 101,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 2521,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 2522,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 107,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2523,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2524,
    Stamina: 12,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 112,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 2525,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 2526,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 2527,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 2528,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 2529,
    Stamina: 2,
    Intellect: 4,
    Strength: 12,
    Defense: 5,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 2530,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2531,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 2532,
    Stamina: 8,
    Intellect: 2,
    Strength: 7,
    Defense: 9,
    HP: 108,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2533,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2534,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2535,
    Stamina: 1,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2536,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 2537,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2538,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 2539,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2540,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2541,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2542,
    Stamina: 1,
    Intellect: 7,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 2543,
    Stamina: 1,
    Intellect: 3,
    Strength: 12,
    Defense: 3,
    HP: 101,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 2544,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 2545,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 7,
    HP: 103,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 2546,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 2547,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2548,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2549,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2550,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 2551,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2552,
    Stamina: 9,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 109,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2553,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2554,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2555,
    Stamina: 7,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2556,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 2557,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 2558,
    Stamina: 4,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2559,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 2560,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 2561,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2562,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2563,
    Stamina: 9,
    Intellect: 6,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 2564,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2565,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 2,
    HP: 106,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 2566,
    Stamina: 10,
    Intellect: 2,
    Strength: 3,
    Defense: 5,
    HP: 110,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 2567,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2568,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 24,
  },
  {
    Id: 2569,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2570,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2571,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2572,
    Stamina: 2,
    Intellect: 12,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 2573,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2574,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 2575,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 2576,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2577,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 7,
    HP: 103,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 2578,
    Stamina: 1,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2579,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2580,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 2581,
    Stamina: 3,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2582,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2583,
    Stamina: 1,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2584,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2585,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2586,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2587,
    Stamina: 5,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 2588,
    Stamina: 2,
    Intellect: 5,
    Strength: 13,
    Defense: 2,
    HP: 102,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 2589,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 2590,
    Stamina: 7,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2591,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2592,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2593,
    Stamina: 1,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 2594,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 2595,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 2596,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 3,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2597,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2598,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2599,
    Stamina: 5,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2600,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2601,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2602,
    Stamina: 10,
    Intellect: 8,
    Strength: 1,
    Defense: 1,
    HP: 110,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 2603,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2604,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2605,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 2606,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2607,
    Stamina: 7,
    Intellect: 10,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 2608,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 2609,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 2610,
    Stamina: 1,
    Intellect: 7,
    Strength: 7,
    Defense: 11,
    HP: 101,
    Attack: 17,
    Power: 35,
  },
  {
    Id: 2611,
    Stamina: 8,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 108,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2612,
    Stamina: 1,
    Intellect: 5,
    Strength: 4,
    Defense: 14,
    HP: 101,
    Attack: 14,
    Power: 33,
  },
  {
    Id: 2613,
    Stamina: 3,
    Intellect: 3,
    Strength: 14,
    Defense: 4,
    HP: 103,
    Attack: 24,
    Power: 31,
  },
  {
    Id: 2614,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 2615,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2616,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 12,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2617,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 106,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 2618,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2619,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2620,
    Stamina: 7,
    Intellect: 13,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2621,
    Stamina: 4,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 2622,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2623,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2624,
    Stamina: 8,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 108,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2625,
    Stamina: 2,
    Intellect: 11,
    Strength: 2,
    Defense: 3,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2626,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2627,
    Stamina: 9,
    Intellect: 2,
    Strength: 2,
    Defense: 5,
    HP: 109,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 2628,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2629,
    Stamina: 1,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2630,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2631,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 2632,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 2633,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2634,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2635,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2636,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 108,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2637,
    Stamina: 8,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 108,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 2638,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2639,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2640,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2641,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2642,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2643,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2644,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2645,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2646,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2647,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2648,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2649,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2650,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2651,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 2652,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 34,
  },
  {
    Id: 2653,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 10,
    HP: 104,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 2654,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2655,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 1,
    HP: 109,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 2656,
    Stamina: 2,
    Intellect: 11,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 2657,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2658,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 2659,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 2660,
    Stamina: 2,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 2661,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2662,
    Stamina: 5,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2663,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 2664,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 2665,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 2666,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2667,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 2668,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2669,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 103,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 2670,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2671,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2672,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 2673,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2674,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2675,
    Stamina: 2,
    Intellect: 12,
    Strength: 5,
    Defense: 1,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2676,
    Stamina: 8,
    Intellect: 3,
    Strength: 1,
    Defense: 11,
    HP: 108,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2677,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 10,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 2678,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2679,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 12,
    HP: 104,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 2680,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2681,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 109,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2682,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2683,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 2684,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2685,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2686,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 2687,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2688,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2689,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2690,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2691,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2692,
    Stamina: 1,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2693,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2694,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 2695,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2696,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 2697,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 2698,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 2699,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2700,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2701,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2702,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2703,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2704,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2705,
    Stamina: 6,
    Intellect: 2,
    Strength: 7,
    Defense: 11,
    HP: 106,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 2706,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2707,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2708,
    Stamina: 5,
    Intellect: 12,
    Strength: 4,
    Defense: 1,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2709,
    Stamina: 1,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2710,
    Stamina: 2,
    Intellect: 3,
    Strength: 2,
    Defense: 13,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 2711,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 2712,
    Stamina: 1,
    Intellect: 4,
    Strength: 1,
    Defense: 15,
    HP: 101,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 2713,
    Stamina: 1,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2714,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2715,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2716,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2717,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 2718,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 2719,
    Stamina: 3,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2720,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2721,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2722,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2723,
    Stamina: 6,
    Intellect: 10,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 2724,
    Stamina: 7,
    Intellect: 2,
    Strength: 1,
    Defense: 10,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 2725,
    Stamina: 11,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 111,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 2726,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2727,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2728,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2729,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2730,
    Stamina: 1,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 101,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2731,
    Stamina: 2,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 2732,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 2733,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2734,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2735,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 2736,
    Stamina: 6,
    Intellect: 6,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2737,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2738,
    Stamina: 11,
    Intellect: 4,
    Strength: 1,
    Defense: 4,
    HP: 111,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 2739,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2740,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2741,
    Stamina: 1,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 101,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2742,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2743,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 106,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 2744,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 2745,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2746,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 2747,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2748,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 2749,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 2750,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 2751,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2752,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2753,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 2754,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2755,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2756,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2757,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2758,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 2,
    HP: 107,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 2759,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2760,
    Stamina: 8,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 108,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 2761,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2762,
    Stamina: 6,
    Intellect: 3,
    Strength: 11,
    Defense: 2,
    HP: 106,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 2763,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2764,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2765,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2766,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2767,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2768,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2769,
    Stamina: 9,
    Intellect: 5,
    Strength: 1,
    Defense: 3,
    HP: 109,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 2770,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2771,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2772,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2773,
    Stamina: 9,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 109,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2774,
    Stamina: 6,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2775,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2776,
    Stamina: 8,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2777,
    Stamina: 7,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2778,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2779,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2780,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2781,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2782,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2783,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 2784,
    Stamina: 4,
    Intellect: 3,
    Strength: 12,
    Defense: 3,
    HP: 104,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 2785,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2786,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2787,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2788,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2789,
    Stamina: 9,
    Intellect: 3,
    Strength: 8,
    Defense: 1,
    HP: 109,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 2790,
    Stamina: 1,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 2791,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 2792,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2793,
    Stamina: 4,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 2794,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2795,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 2796,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2797,
    Stamina: 4,
    Intellect: 6,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 2798,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 2799,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2800,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 107,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2801,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 2802,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 2803,
    Stamina: 10,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 110,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2804,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2805,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2806,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2807,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2808,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2809,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 106,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2810,
    Stamina: 6,
    Intellect: 2,
    Strength: 11,
    Defense: 2,
    HP: 106,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 2811,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2812,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2813,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2814,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2815,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 2816,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 2817,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 2818,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 2819,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2820,
    Stamina: 3,
    Intellect: 6,
    Strength: 12,
    Defense: 2,
    HP: 103,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 2821,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2822,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 1,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2823,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 2824,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2825,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 2826,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 7,
    HP: 103,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 2827,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2828,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2829,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2830,
    Stamina: 9,
    Intellect: 3,
    Strength: 1,
    Defense: 6,
    HP: 109,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 2831,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2832,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 2833,
    Stamina: 9,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 2834,
    Stamina: 8,
    Intellect: 8,
    Strength: 1,
    Defense: 3,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 2835,
    Stamina: 7,
    Intellect: 6,
    Strength: 7,
    Defense: 1,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2836,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2837,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2838,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 2839,
    Stamina: 10,
    Intellect: 3,
    Strength: 4,
    Defense: 1,
    HP: 110,
    Attack: 14,
    Power: 18,
  },
  {
    Id: 2840,
    Stamina: 9,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 109,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 2841,
    Stamina: 7,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2842,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 108,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 2843,
    Stamina: 5,
    Intellect: 11,
    Strength: 3,
    Defense: 1,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2844,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2845,
    Stamina: 2,
    Intellect: 2,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 2846,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2847,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 107,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2848,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 2849,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2850,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 2851,
    Stamina: 2,
    Intellect: 7,
    Strength: 1,
    Defense: 11,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 2852,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2853,
    Stamina: 5,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 2854,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2855,
    Stamina: 3,
    Intellect: 13,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 2856,
    Stamina: 6,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 106,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 2857,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 2858,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 2859,
    Stamina: 5,
    Intellect: 11,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 32,
  },
  {
    Id: 2860,
    Stamina: 4,
    Intellect: 3,
    Strength: 12,
    Defense: 8,
    HP: 104,
    Attack: 22,
    Power: 33,
  },
  {
    Id: 2861,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2862,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2863,
    Stamina: 8,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 108,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2864,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 12,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 2865,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 13,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 2866,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 9,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 2867,
    Stamina: 2,
    Intellect: 4,
    Strength: 12,
    Defense: 6,
    HP: 102,
    Attack: 22,
    Power: 32,
  },
  {
    Id: 2868,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 2869,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 2870,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 2871,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2872,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 2873,
    Stamina: 10,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 110,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 2874,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2875,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 2876,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2877,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 2878,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 2879,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 2880,
    Stamina: 12,
    Intellect: 5,
    Strength: 1,
    Defense: 2,
    HP: 112,
    Attack: 11,
    Power: 18,
  },
  {
    Id: 2881,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 2882,
    Stamina: 8,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2883,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 2884,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 2885,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 2886,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2887,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 12,
    HP: 104,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 2888,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2889,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 2890,
    Stamina: 11,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 111,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 2891,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2892,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 2893,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 3,
    HP: 108,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 2894,
    Stamina: 9,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 109,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 2895,
    Stamina: 12,
    Intellect: 3,
    Strength: 1,
    Defense: 6,
    HP: 112,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 2896,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 2897,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 2898,
    Stamina: 9,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2899,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2900,
    Stamina: 5,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 105,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 2901,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 2902,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2903,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2904,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 2905,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2906,
    Stamina: 1,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 101,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2907,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2908,
    Stamina: 8,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2909,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2910,
    Stamina: 8,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 108,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 2911,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 2912,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2913,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2914,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 2915,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 1,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2916,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 2917,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 2918,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2919,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 2920,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2921,
    Stamina: 11,
    Intellect: 5,
    Strength: 1,
    Defense: 3,
    HP: 111,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 2922,
    Stamina: 3,
    Intellect: 10,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 2923,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 2924,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2925,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 2926,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2927,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2928,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 2929,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 2930,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2931,
    Stamina: 1,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 101,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2932,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 2933,
    Stamina: 4,
    Intellect: 3,
    Strength: 11,
    Defense: 2,
    HP: 104,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 2934,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2935,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 13,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 2936,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 2937,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 2938,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2939,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2940,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 12,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 2941,
    Stamina: 7,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 2942,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 2943,
    Stamina: 4,
    Intellect: 12,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 2944,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2945,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 110,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 2946,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 2947,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 2948,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 9,
    HP: 103,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 2949,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 2950,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2951,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2952,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 2953,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 2954,
    Stamina: 11,
    Intellect: 2,
    Strength: 5,
    Defense: 2,
    HP: 111,
    Attack: 15,
    Power: 19,
  },
  {
    Id: 2955,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2956,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2957,
    Stamina: 1,
    Intellect: 10,
    Strength: 6,
    Defense: 6,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 2958,
    Stamina: 7,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 107,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 2959,
    Stamina: 2,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2960,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2961,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 107,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 2962,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 2963,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 2964,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2965,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2966,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 2967,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2968,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 2969,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 2970,
    Stamina: 10,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 110,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 2971,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 2972,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 2973,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 2974,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2975,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 2976,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 2977,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 2978,
    Stamina: 2,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 2979,
    Stamina: 6,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 2980,
    Stamina: 4,
    Intellect: 2,
    Strength: 13,
    Defense: 5,
    HP: 104,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 2981,
    Stamina: 6,
    Intellect: 9,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 2982,
    Stamina: 8,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 108,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 2983,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2984,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 2985,
    Stamina: 7,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 107,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 2986,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 2987,
    Stamina: 8,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 2988,
    Stamina: 6,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 2989,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 2990,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 2991,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 2992,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 10,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 2993,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2994,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 2995,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 2996,
    Stamina: 8,
    Intellect: 6,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 2997,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 2998,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 2999,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 3000,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3001,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3002,
    Stamina: 7,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 107,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 3003,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3004,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 3005,
    Stamina: 2,
    Intellect: 2,
    Strength: 2,
    Defense: 13,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3006,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3007,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 3008,
    Stamina: 4,
    Intellect: 6,
    Strength: 1,
    Defense: 11,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 3009,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3010,
    Stamina: 8,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3011,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 3012,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3013,
    Stamina: 10,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 110,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 3014,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3015,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3016,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 6,
    HP: 101,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 3017,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 7,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 3018,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3019,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3020,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 11,
    HP: 101,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 3021,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3022,
    Stamina: 9,
    Intellect: 5,
    Strength: 1,
    Defense: 5,
    HP: 109,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 3023,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3024,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 12,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 3025,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 3026,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3027,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 3028,
    Stamina: 2,
    Intellect: 4,
    Strength: 15,
    Defense: 3,
    HP: 102,
    Attack: 25,
    Power: 32,
  },
  {
    Id: 3029,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3030,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3031,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3032,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3033,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 12,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 3034,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3035,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3036,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 12,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3037,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3038,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 3039,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3040,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3041,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 3042,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 110,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 3043,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3044,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3045,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 9,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 3046,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3047,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3048,
    Stamina: 10,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 110,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 3049,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 3050,
    Stamina: 1,
    Intellect: 13,
    Strength: 4,
    Defense: 4,
    HP: 101,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 3051,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3052,
    Stamina: 6,
    Intellect: 11,
    Strength: 1,
    Defense: 2,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3053,
    Stamina: 2,
    Intellect: 12,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 35,
  },
  {
    Id: 3054,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3055,
    Stamina: 5,
    Intellect: 8,
    Strength: 11,
    Defense: 8,
    HP: 105,
    Attack: 21,
    Power: 37,
  },
  {
    Id: 3056,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3057,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 1,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 3058,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3059,
    Stamina: 3,
    Intellect: 9,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 3060,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3061,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 109,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3062,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 3063,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3064,
    Stamina: 9,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 109,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3065,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3066,
    Stamina: 2,
    Intellect: 12,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 3067,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3068,
    Stamina: 8,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 108,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 3069,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 3070,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 9,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 3071,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3072,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3073,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 8,
    HP: 103,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 3074,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3075,
    Stamina: 2,
    Intellect: 6,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 3076,
    Stamina: 12,
    Intellect: 5,
    Strength: 1,
    Defense: 3,
    HP: 112,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 3077,
    Stamina: 2,
    Intellect: 2,
    Strength: 12,
    Defense: 8,
    HP: 102,
    Attack: 22,
    Power: 32,
  },
  {
    Id: 3078,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 3079,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3080,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 3081,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3082,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3083,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3084,
    Stamina: 2,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 102,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3085,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3086,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 3087,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3088,
    Stamina: 5,
    Intellect: 9,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3089,
    Stamina: 9,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 109,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 3090,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 3091,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3092,
    Stamina: 1,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 101,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 3093,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3094,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 106,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3095,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 3096,
    Stamina: 2,
    Intellect: 11,
    Strength: 6,
    Defense: 1,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 3097,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3098,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3099,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3100,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3101,
    Stamina: 10,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 110,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3102,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3103,
    Stamina: 13,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 113,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 3104,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3105,
    Stamina: 5,
    Intellect: 11,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 3106,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 3107,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 3108,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3109,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3110,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3111,
    Stamina: 8,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 108,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3112,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 12,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 3113,
    Stamina: 11,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 111,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3114,
    Stamina: 4,
    Intellect: 2,
    Strength: 15,
    Defense: 4,
    HP: 104,
    Attack: 25,
    Power: 31,
  },
  {
    Id: 3115,
    Stamina: 7,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 107,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3116,
    Stamina: 3,
    Intellect: 4,
    Strength: 13,
    Defense: 2,
    HP: 103,
    Attack: 23,
    Power: 29,
  },
  {
    Id: 3117,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3118,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3119,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3120,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3121,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3122,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3123,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3124,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3125,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 3126,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3127,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3128,
    Stamina: 1,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 3129,
    Stamina: 2,
    Intellect: 7,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 3130,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3131,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3132,
    Stamina: 2,
    Intellect: 2,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3133,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3134,
    Stamina: 1,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 101,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3135,
    Stamina: 10,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 110,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3136,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3137,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3138,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3139,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 3140,
    Stamina: 7,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3141,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 3142,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3143,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3144,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 3145,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 3146,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3147,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 3148,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3149,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3150,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3151,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 3,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3152,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3153,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 3154,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3155,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3156,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3157,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3158,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3159,
    Stamina: 3,
    Intellect: 3,
    Strength: 13,
    Defense: 2,
    HP: 103,
    Attack: 23,
    Power: 28,
  },
  {
    Id: 3160,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3161,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3162,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 3163,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3164,
    Stamina: 10,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 110,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 3165,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3166,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 3167,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 3168,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3169,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 3170,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3171,
    Stamina: 1,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 101,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3172,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3173,
    Stamina: 2,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3174,
    Stamina: 1,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 101,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3175,
    Stamina: 8,
    Intellect: 7,
    Strength: 1,
    Defense: 3,
    HP: 108,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 3176,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3177,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3178,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3179,
    Stamina: 6,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3180,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3181,
    Stamina: 8,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 108,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 3182,
    Stamina: 1,
    Intellect: 11,
    Strength: 1,
    Defense: 6,
    HP: 101,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 3183,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3184,
    Stamina: 12,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 112,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 3185,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3186,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 107,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 3187,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 8,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 3188,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3189,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 3190,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3191,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3192,
    Stamina: 2,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3193,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3194,
    Stamina: 1,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3195,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 3196,
    Stamina: 4,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 3197,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3198,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3199,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3200,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 3201,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 1,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 3202,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3203,
    Stamina: 6,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 106,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 3204,
    Stamina: 1,
    Intellect: 11,
    Strength: 2,
    Defense: 8,
    HP: 101,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 3205,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3206,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 3207,
    Stamina: 7,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3208,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 1,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3209,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3210,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3211,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3212,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 3213,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 3214,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 3215,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 3216,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3217,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 3218,
    Stamina: 10,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 110,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 3219,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 3220,
    Stamina: 1,
    Intellect: 6,
    Strength: 10,
    Defense: 6,
    HP: 101,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 3221,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 7,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 3222,
    Stamina: 6,
    Intellect: 11,
    Strength: 1,
    Defense: 2,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3223,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3224,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 3225,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3226,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3227,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 3228,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 3229,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 3230,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3231,
    Stamina: 3,
    Intellect: 5,
    Strength: 12,
    Defense: 3,
    HP: 103,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 3232,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 3233,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 108,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3234,
    Stamina: 5,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 3235,
    Stamina: 10,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 110,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 3236,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3237,
    Stamina: 8,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 3238,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 3239,
    Stamina: 3,
    Intellect: 6,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 3240,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3241,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3242,
    Stamina: 1,
    Intellect: 8,
    Strength: 4,
    Defense: 11,
    HP: 101,
    Attack: 14,
    Power: 33,
  },
  {
    Id: 3243,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3244,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 3245,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 7,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 3246,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 3247,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3248,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3249,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 3250,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3251,
    Stamina: 4,
    Intellect: 12,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 3252,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3253,
    Stamina: 4,
    Intellect: 13,
    Strength: 1,
    Defense: 4,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 3254,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3255,
    Stamina: 7,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 3256,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3257,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 3258,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3259,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 3260,
    Stamina: 5,
    Intellect: 10,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 3261,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 3262,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 3263,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 3264,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3265,
    Stamina: 1,
    Intellect: 4,
    Strength: 13,
    Defense: 1,
    HP: 101,
    Attack: 23,
    Power: 28,
  },
  {
    Id: 3266,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3267,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3268,
    Stamina: 1,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 3269,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3270,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3271,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3272,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 110,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 3273,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 3274,
    Stamina: 1,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3275,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3276,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3277,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 11,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 3278,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 3279,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 3280,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3281,
    Stamina: 8,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3282,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3283,
    Stamina: 6,
    Intellect: 2,
    Strength: 12,
    Defense: 3,
    HP: 106,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 3284,
    Stamina: 9,
    Intellect: 2,
    Strength: 4,
    Defense: 3,
    HP: 109,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 3285,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3286,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3287,
    Stamina: 8,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 3288,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 33,
  },
  {
    Id: 3289,
    Stamina: 8,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 3290,
    Stamina: 10,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 110,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 3291,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3292,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3293,
    Stamina: 10,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 110,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3294,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3295,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 3,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3296,
    Stamina: 1,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 101,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3297,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 1,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 3298,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 9,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 3299,
    Stamina: 9,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3300,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3301,
    Stamina: 7,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 107,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3302,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3303,
    Stamina: 10,
    Intellect: 10,
    Strength: 3,
    Defense: 7,
    HP: 110,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 3304,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3305,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3306,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 3307,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 3308,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3309,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3310,
    Stamina: 4,
    Intellect: 7,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3311,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3312,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3313,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3314,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 3315,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 106,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 3316,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3317,
    Stamina: 6,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3318,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3319,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3320,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 3321,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 3322,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3323,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 3324,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 107,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3325,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3326,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3327,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3328,
    Stamina: 7,
    Intellect: 4,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3329,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 3330,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3331,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3332,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 7,
    HP: 104,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 3333,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3334,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3335,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3336,
    Stamina: 3,
    Intellect: 5,
    Strength: 13,
    Defense: 4,
    HP: 103,
    Attack: 23,
    Power: 32,
  },
  {
    Id: 3337,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3338,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3339,
    Stamina: 5,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3340,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3341,
    Stamina: 1,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3342,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3343,
    Stamina: 10,
    Intellect: 4,
    Strength: 6,
    Defense: 1,
    HP: 110,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 3344,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 3345,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 1,
    HP: 106,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 3346,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3347,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 8,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3348,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 11,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 3349,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 3350,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 3351,
    Stamina: 1,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 101,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3352,
    Stamina: 8,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 3353,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 3354,
    Stamina: 3,
    Intellect: 7,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 3355,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 3356,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 3357,
    Stamina: 5,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3358,
    Stamina: 1,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3359,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3360,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 3361,
    Stamina: 1,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 101,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3362,
    Stamina: 2,
    Intellect: 3,
    Strength: 13,
    Defense: 6,
    HP: 102,
    Attack: 23,
    Power: 32,
  },
  {
    Id: 3363,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 12,
    HP: 105,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 3364,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3365,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3366,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 3367,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 3368,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3369,
    Stamina: 6,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 106,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 3370,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 3371,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3372,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3373,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 3374,
    Stamina: 11,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 111,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3375,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 3376,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 3377,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 3378,
    Stamina: 5,
    Intellect: 4,
    Strength: 11,
    Defense: 7,
    HP: 105,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 3379,
    Stamina: 3,
    Intellect: 12,
    Strength: 6,
    Defense: 1,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3380,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3381,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3382,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3383,
    Stamina: 9,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 109,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3384,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 3385,
    Stamina: 7,
    Intellect: 12,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 3386,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3387,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 3388,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3389,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3390,
    Stamina: 7,
    Intellect: 3,
    Strength: 19,
    Defense: 11,
    HP: 107,
    Attack: 29,
    Power: 43,
  },
  {
    Id: 3391,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3392,
    Stamina: 7,
    Intellect: 8,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3393,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3394,
    Stamina: 6,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3395,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3396,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 3397,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3398,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3399,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3400,
    Stamina: 2,
    Intellect: 6,
    Strength: 13,
    Defense: 3,
    HP: 102,
    Attack: 23,
    Power: 32,
  },
  {
    Id: 3401,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3402,
    Stamina: 2,
    Intellect: 12,
    Strength: 6,
    Defense: 1,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3403,
    Stamina: 3,
    Intellect: 14,
    Strength: 3,
    Defense: 2,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 3404,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3405,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 3406,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3407,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3408,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3409,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 3410,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3411,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3412,
    Stamina: 3,
    Intellect: 12,
    Strength: 3,
    Defense: 1,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3413,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3414,
    Stamina: 5,
    Intellect: 13,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3415,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 9,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 3416,
    Stamina: 4,
    Intellect: 3,
    Strength: 12,
    Defense: 6,
    HP: 104,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 3417,
    Stamina: 7,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 107,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3418,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3419,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 14,
    HP: 102,
    Attack: 14,
    Power: 34,
  },
  {
    Id: 3420,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3421,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 3422,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3423,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3424,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3425,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 107,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 3426,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 3427,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 9,
    HP: 105,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 3428,
    Stamina: 1,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 101,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 3429,
    Stamina: 4,
    Intellect: 11,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 3430,
    Stamina: 7,
    Intellect: 9,
    Strength: 4,
    Defense: 1,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3431,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3432,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3433,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3434,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3435,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3436,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3437,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 3438,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3439,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3440,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 3441,
    Stamina: 1,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 101,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3442,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3443,
    Stamina: 10,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 110,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 3444,
    Stamina: 3,
    Intellect: 3,
    Strength: 14,
    Defense: 2,
    HP: 103,
    Attack: 24,
    Power: 29,
  },
  {
    Id: 3445,
    Stamina: 2,
    Intellect: 10,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 3446,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3447,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3448,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3449,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3450,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3451,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3452,
    Stamina: 10,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 110,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3453,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 3454,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3455,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 3456,
    Stamina: 7,
    Intellect: 9,
    Strength: 2,
    Defense: 2,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 3457,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3458,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 3459,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 9,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3460,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3461,
    Stamina: 4,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3462,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3463,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 3464,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 109,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 3465,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 3466,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3467,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 3468,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 6,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 3469,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3470,
    Stamina: 9,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 3471,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3472,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 3473,
    Stamina: 10,
    Intellect: 3,
    Strength: 1,
    Defense: 4,
    HP: 110,
    Attack: 11,
    Power: 18,
  },
  {
    Id: 3474,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3475,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3476,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3477,
    Stamina: 6,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3478,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 3479,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3480,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3481,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3482,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3483,
    Stamina: 1,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 101,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 3484,
    Stamina: 9,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 109,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 3485,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3486,
    Stamina: 11,
    Intellect: 2,
    Strength: 4,
    Defense: 3,
    HP: 111,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 3487,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 1,
    HP: 105,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 3488,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 3489,
    Stamina: 7,
    Intellect: 2,
    Strength: 1,
    Defense: 8,
    HP: 107,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 3490,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3491,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3492,
    Stamina: 6,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3493,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3494,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 3495,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 3496,
    Stamina: 4,
    Intellect: 5,
    Strength: 11,
    Defense: 7,
    HP: 104,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 3497,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 16,
    HP: 103,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 3498,
    Stamina: 10,
    Intellect: 5,
    Strength: 6,
    Defense: 1,
    HP: 110,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 3499,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 3500,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 3501,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3502,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3503,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 3504,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3505,
    Stamina: 6,
    Intellect: 5,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3506,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3507,
    Stamina: 5,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3508,
    Stamina: 8,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3509,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3510,
    Stamina: 1,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 101,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 3511,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3512,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 107,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3513,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 3514,
    Stamina: 5,
    Intellect: 12,
    Strength: 3,
    Defense: 2,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3515,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3516,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3517,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3518,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3519,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3520,
    Stamina: 5,
    Intellect: 12,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 3521,
    Stamina: 6,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 106,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 3522,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3523,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3524,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 3525,
    Stamina: 4,
    Intellect: 11,
    Strength: 7,
    Defense: 1,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3526,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3527,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3528,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 3529,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 3530,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3531,
    Stamina: 3,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3532,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 109,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 3533,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 2,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 3534,
    Stamina: 10,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 110,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3535,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3536,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3537,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 3538,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3539,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 11,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 3540,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3541,
    Stamina: 7,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 3542,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3543,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3544,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3545,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3546,
    Stamina: 9,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 109,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3547,
    Stamina: 4,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3548,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3549,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3550,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3551,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3552,
    Stamina: 8,
    Intellect: 5,
    Strength: 1,
    Defense: 7,
    HP: 108,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 3553,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3554,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3555,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3556,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3557,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3558,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3559,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3560,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3561,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3562,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3563,
    Stamina: 2,
    Intellect: 9,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 3564,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3565,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 9,
    HP: 105,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 3566,
    Stamina: 2,
    Intellect: 3,
    Strength: 13,
    Defense: 8,
    HP: 102,
    Attack: 23,
    Power: 34,
  },
  {
    Id: 3567,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3568,
    Stamina: 9,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 109,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 3569,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 3570,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 3571,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 3572,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3573,
    Stamina: 1,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 3574,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3575,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 3576,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3577,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3578,
    Stamina: 1,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 101,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 3579,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3580,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3581,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3582,
    Stamina: 3,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 3583,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 3584,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 3585,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3586,
    Stamina: 10,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 110,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3587,
    Stamina: 3,
    Intellect: 6,
    Strength: 12,
    Defense: 5,
    HP: 103,
    Attack: 22,
    Power: 33,
  },
  {
    Id: 3588,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3589,
    Stamina: 7,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 107,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3590,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 3591,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 7,
    HP: 102,
    Attack: 20,
    Power: 34,
  },
  {
    Id: 3592,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3593,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3594,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3595,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3596,
    Stamina: 7,
    Intellect: 11,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 3597,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3598,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3599,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 9,
    HP: 104,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 3600,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3601,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3602,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3603,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3604,
    Stamina: 7,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 107,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3605,
    Stamina: 3,
    Intellect: 7,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 3606,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 3607,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3608,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3609,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 3610,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3611,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 3612,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3613,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 3614,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 3615,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 3616,
    Stamina: 4,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3617,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3618,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 1,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3619,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 3620,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3621,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3622,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3623,
    Stamina: 9,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 3624,
    Stamina: 5,
    Intellect: 4,
    Strength: 12,
    Defense: 1,
    HP: 105,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 3625,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3626,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3627,
    Stamina: 10,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 110,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 3628,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3629,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 14,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 3630,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3631,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 3632,
    Stamina: 2,
    Intellect: 6,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 3633,
    Stamina: 3,
    Intellect: 11,
    Strength: 1,
    Defense: 3,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3634,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3635,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3636,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3637,
    Stamina: 5,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3638,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 12,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3639,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 3640,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 3641,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3642,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3643,
    Stamina: 9,
    Intellect: 5,
    Strength: 6,
    Defense: 1,
    HP: 109,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 3644,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 13,
    HP: 105,
    Attack: 13,
    Power: 32,
  },
  {
    Id: 3645,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3646,
    Stamina: 2,
    Intellect: 9,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3647,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 3648,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3649,
    Stamina: 7,
    Intellect: 4,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3650,
    Stamina: 8,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 3651,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3652,
    Stamina: 1,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 101,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 3653,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3654,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3655,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 108,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3656,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3657,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 3658,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 3659,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3660,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 12,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3661,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3662,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 107,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3663,
    Stamina: 10,
    Intellect: 2,
    Strength: 4,
    Defense: 1,
    HP: 110,
    Attack: 14,
    Power: 17,
  },
  {
    Id: 3664,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 3665,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 3666,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3667,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3668,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 3669,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3670,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3671,
    Stamina: 11,
    Intellect: 9,
    Strength: 2,
    Defense: 1,
    HP: 111,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 3672,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 3673,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 2,
    HP: 108,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 3674,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 3675,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 3676,
    Stamina: 1,
    Intellect: 10,
    Strength: 10,
    Defense: 2,
    HP: 101,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 3677,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 3678,
    Stamina: 5,
    Intellect: 11,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 3679,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3680,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 3681,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3682,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3683,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3684,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3685,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3686,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3687,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 7,
    HP: 105,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 3688,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 108,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3689,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3690,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 3691,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 3692,
    Stamina: 1,
    Intellect: 7,
    Strength: 7,
    Defense: 6,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 3693,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3694,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 8,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3695,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3696,
    Stamina: 7,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 107,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3697,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 12,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 3698,
    Stamina: 9,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 109,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 3699,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3700,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3701,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3702,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3703,
    Stamina: 11,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 111,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 3704,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3705,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3706,
    Stamina: 5,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 3707,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 3708,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3709,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 3710,
    Stamina: 6,
    Intellect: 10,
    Strength: 3,
    Defense: 1,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3711,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 106,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3712,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3713,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3714,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 3715,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 107,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 3716,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3717,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3718,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3719,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3720,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3721,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3722,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3723,
    Stamina: 5,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 105,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 3724,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 8,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3725,
    Stamina: 1,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 101,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 3726,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 109,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3727,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3728,
    Stamina: 1,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 101,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3729,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 3730,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3731,
    Stamina: 8,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3732,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3733,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3734,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 3735,
    Stamina: 8,
    Intellect: 2,
    Strength: 9,
    Defense: 1,
    HP: 108,
    Attack: 19,
    Power: 22,
  },
  {
    Id: 3736,
    Stamina: 1,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 101,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3737,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 3738,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3739,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3740,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3741,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3742,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 3743,
    Stamina: 1,
    Intellect: 11,
    Strength: 6,
    Defense: 5,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 3744,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3745,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 1,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3746,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3747,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3748,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 3749,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3750,
    Stamina: 11,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 111,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 3751,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3752,
    Stamina: 8,
    Intellect: 2,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 3753,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 3754,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3755,
    Stamina: 6,
    Intellect: 11,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3756,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 3757,
    Stamina: 10,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 110,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 3758,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 3759,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3760,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 3761,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 3762,
    Stamina: 1,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 101,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3763,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3764,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 106,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 3765,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3766,
    Stamina: 6,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 3767,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 3768,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3769,
    Stamina: 7,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 107,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 3770,
    Stamina: 3,
    Intellect: 2,
    Strength: 2,
    Defense: 13,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3771,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3772,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3773,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3774,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3775,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3776,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3777,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 1,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3778,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3779,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 3780,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3781,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3782,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 3783,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 3784,
    Stamina: 10,
    Intellect: 8,
    Strength: 1,
    Defense: 2,
    HP: 110,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 3785,
    Stamina: 1,
    Intellect: 3,
    Strength: 9,
    Defense: 10,
    HP: 101,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 3786,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3787,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 3788,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3789,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3790,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3791,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3792,
    Stamina: 9,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 3793,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 3794,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3795,
    Stamina: 1,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 3796,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3797,
    Stamina: 3,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 3798,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 3799,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 14,
    HP: 105,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 3800,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 3801,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3802,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3803,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3804,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 3805,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3806,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3807,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3808,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3809,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3810,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3811,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3812,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3813,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 3814,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3815,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 3816,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3817,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 3818,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3819,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3820,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3821,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3822,
    Stamina: 13,
    Intellect: 3,
    Strength: 3,
    Defense: 1,
    HP: 113,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 3823,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3824,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 3825,
    Stamina: 7,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 107,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 3826,
    Stamina: 2,
    Intellect: 11,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 34,
  },
  {
    Id: 3827,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3828,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3829,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3830,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 3831,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 3832,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3833,
    Stamina: 10,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 110,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 3834,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 3835,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3836,
    Stamina: 1,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 101,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 3837,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3838,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 3839,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 3840,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 3841,
    Stamina: 7,
    Intellect: 6,
    Strength: 6,
    Defense: 9,
    HP: 107,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 3842,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 3843,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3844,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3845,
    Stamina: 11,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 111,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3846,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 3847,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 11,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 3848,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3849,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3850,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 3851,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3852,
    Stamina: 9,
    Intellect: 6,
    Strength: 1,
    Defense: 5,
    HP: 109,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3853,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3854,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3855,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 3856,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 3857,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 3858,
    Stamina: 9,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3859,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 3860,
    Stamina: 10,
    Intellect: 3,
    Strength: 6,
    Defense: 1,
    HP: 110,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 3861,
    Stamina: 9,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 3862,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3863,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 3864,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 3865,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 3866,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3867,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3868,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3869,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 106,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3870,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3871,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3872,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 3873,
    Stamina: 9,
    Intellect: 3,
    Strength: 1,
    Defense: 8,
    HP: 109,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 3874,
    Stamina: 2,
    Intellect: 2,
    Strength: 13,
    Defense: 5,
    HP: 102,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 3875,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3876,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3877,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3878,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3879,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 3880,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3881,
    Stamina: 1,
    Intellect: 4,
    Strength: 10,
    Defense: 7,
    HP: 101,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 3882,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 3883,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 3884,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3885,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3886,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 3887,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3888,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 3889,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3890,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 3891,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3892,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 1,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3893,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3894,
    Stamina: 7,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 3895,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 3896,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 3897,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 3898,
    Stamina: 1,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 101,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3899,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 3900,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 3901,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 3902,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3903,
    Stamina: 8,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 108,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 3904,
    Stamina: 2,
    Intellect: 14,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 33,
  },
  {
    Id: 3905,
    Stamina: 1,
    Intellect: 6,
    Strength: 12,
    Defense: 5,
    HP: 101,
    Attack: 22,
    Power: 33,
  },
  {
    Id: 3906,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 3907,
    Stamina: 2,
    Intellect: 11,
    Strength: 1,
    Defense: 5,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 3908,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 13,
    HP: 103,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 3909,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3910,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 3911,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3912,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 3913,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3914,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3915,
    Stamina: 3,
    Intellect: 9,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3916,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3917,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 3918,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 3919,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 3920,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3921,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3922,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3923,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 3924,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3925,
    Stamina: 11,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 111,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 3926,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3927,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 1,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3928,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3929,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3930,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 3931,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 106,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 3932,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 3933,
    Stamina: 11,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 111,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 3934,
    Stamina: 9,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3935,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3936,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3937,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3938,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 3939,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3940,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 3941,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 3942,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 3943,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 14,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 3944,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 3945,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3946,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 3947,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3948,
    Stamina: 11,
    Intellect: 4,
    Strength: 3,
    Defense: 2,
    HP: 111,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 3949,
    Stamina: 5,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 3950,
    Stamina: 2,
    Intellect: 13,
    Strength: 3,
    Defense: 2,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 3951,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 3952,
    Stamina: 11,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 111,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 3953,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 3954,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 3955,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 3956,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3957,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 106,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 3958,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 3959,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 3960,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3961,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 3962,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 3963,
    Stamina: 7,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 3964,
    Stamina: 8,
    Intellect: 2,
    Strength: 6,
    Defense: 2,
    HP: 108,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 3965,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3966,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 3967,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 3968,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3969,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 3970,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 3971,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 3972,
    Stamina: 4,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 3973,
    Stamina: 2,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 3974,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 3975,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 3976,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 3977,
    Stamina: 1,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 3978,
    Stamina: 8,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3979,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 3980,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 3981,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 3982,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3983,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3984,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 3985,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 8,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 3986,
    Stamina: 8,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 108,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 3987,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 3988,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 3989,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 3990,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 106,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3991,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 3992,
    Stamina: 1,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 101,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 3993,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 3994,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 3995,
    Stamina: 5,
    Intellect: 4,
    Strength: 11,
    Defense: 5,
    HP: 105,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 3996,
    Stamina: 2,
    Intellect: 2,
    Strength: 7,
    Defense: 9,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 3997,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 3998,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 3999,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4000,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4001,
    Stamina: 5,
    Intellect: 8,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 4002,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4003,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4004,
    Stamina: 1,
    Intellect: 5,
    Strength: 2,
    Defense: 13,
    HP: 101,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 4005,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4006,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4007,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 4008,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 4009,
    Stamina: 1,
    Intellect: 7,
    Strength: 9,
    Defense: 3,
    HP: 101,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4010,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4011,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4012,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4013,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4014,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4015,
    Stamina: 9,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 109,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4016,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4017,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4018,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 4019,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 4020,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 2,
    HP: 106,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 4021,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4022,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 4023,
    Stamina: 9,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 109,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4024,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4025,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 4026,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4027,
    Stamina: 8,
    Intellect: 2,
    Strength: 6,
    Defense: 2,
    HP: 108,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 4028,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4029,
    Stamina: 8,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 108,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4030,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4031,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 4032,
    Stamina: 3,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 4033,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4034,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4035,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4036,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 4037,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 4038,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4039,
    Stamina: 1,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4040,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4041,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4042,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 13,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 4043,
    Stamina: 4,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 4044,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 1,
    HP: 104,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 4045,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4046,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 4047,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4048,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4049,
    Stamina: 7,
    Intellect: 2,
    Strength: 2,
    Defense: 9,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 4050,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4051,
    Stamina: 7,
    Intellect: 10,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4052,
    Stamina: 11,
    Intellect: 3,
    Strength: 1,
    Defense: 4,
    HP: 111,
    Attack: 11,
    Power: 18,
  },
  {
    Id: 4053,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4054,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 4055,
    Stamina: 6,
    Intellect: 12,
    Strength: 1,
    Defense: 3,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 4056,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4057,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4058,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 4059,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4060,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4061,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4062,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4063,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4064,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4065,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 4066,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4067,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4068,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4069,
    Stamina: 1,
    Intellect: 2,
    Strength: 4,
    Defense: 11,
    HP: 101,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4070,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 4071,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4072,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 4073,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 4074,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4075,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4076,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 4077,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4078,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4079,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 1,
    HP: 109,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 4080,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4081,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4082,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4083,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 4084,
    Stamina: 8,
    Intellect: 5,
    Strength: 5,
    Defense: 1,
    HP: 108,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 4085,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4086,
    Stamina: 8,
    Intellect: 7,
    Strength: 1,
    Defense: 3,
    HP: 108,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 4087,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 4088,
    Stamina: 9,
    Intellect: 6,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 4089,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 4090,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4091,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4092,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4093,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4094,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4095,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4096,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4097,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 4098,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4099,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 108,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4100,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4101,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4102,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 4103,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 12,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 4104,
    Stamina: 6,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 4105,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4106,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 4107,
    Stamina: 6,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 106,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4108,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 4109,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4110,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4111,
    Stamina: 8,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 108,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4112,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4113,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4114,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4115,
    Stamina: 11,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 111,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 4116,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 1,
    HP: 105,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4117,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 34,
  },
  {
    Id: 4118,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 4119,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4120,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4121,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 4122,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4123,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4124,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 4125,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4126,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4127,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4128,
    Stamina: 4,
    Intellect: 11,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4129,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4130,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4131,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4132,
    Stamina: 3,
    Intellect: 6,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 4133,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4134,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4135,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4136,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4137,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 105,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4138,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4139,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 1,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4140,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 4141,
    Stamina: 6,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 4142,
    Stamina: 1,
    Intellect: 9,
    Strength: 10,
    Defense: 4,
    HP: 101,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 4143,
    Stamina: 9,
    Intellect: 2,
    Strength: 8,
    Defense: 2,
    HP: 109,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 4144,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4145,
    Stamina: 5,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4146,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4147,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4148,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4149,
    Stamina: 4,
    Intellect: 13,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 4150,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 4151,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4152,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4153,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 4154,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 1,
    HP: 109,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 4155,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 4156,
    Stamina: 5,
    Intellect: 6,
    Strength: 12,
    Defense: 3,
    HP: 105,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 4157,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4158,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4159,
    Stamina: 10,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 110,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 4160,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4161,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4162,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 4163,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4164,
    Stamina: 7,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 107,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4165,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4166,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 4167,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4168,
    Stamina: 5,
    Intellect: 3,
    Strength: 12,
    Defense: 3,
    HP: 105,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 4169,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 4170,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4171,
    Stamina: 3,
    Intellect: 2,
    Strength: 13,
    Defense: 5,
    HP: 103,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 4172,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 10,
    HP: 102,
    Attack: 18,
    Power: 34,
  },
  {
    Id: 4173,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4174,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4175,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4176,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 4177,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4178,
    Stamina: 1,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 101,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4179,
    Stamina: 3,
    Intellect: 7,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 4180,
    Stamina: 6,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 106,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 4181,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4182,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 105,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 4183,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4184,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 4185,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4186,
    Stamina: 1,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4187,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4188,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 4189,
    Stamina: 1,
    Intellect: 11,
    Strength: 3,
    Defense: 6,
    HP: 101,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 4190,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 4191,
    Stamina: 4,
    Intellect: 3,
    Strength: 11,
    Defense: 2,
    HP: 104,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 4192,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4193,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4194,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 9,
    HP: 102,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 4195,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4196,
    Stamina: 2,
    Intellect: 11,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 4197,
    Stamina: 2,
    Intellect: 4,
    Strength: 13,
    Defense: 3,
    HP: 102,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 4198,
    Stamina: 10,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 110,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4199,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 110,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 4200,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 110,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 4201,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4202,
    Stamina: 4,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 4203,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 4204,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4205,
    Stamina: 6,
    Intellect: 2,
    Strength: 12,
    Defense: 3,
    HP: 106,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 4206,
    Stamina: 5,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 4207,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 4208,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4209,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4210,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 4211,
    Stamina: 9,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 109,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4212,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4213,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4214,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4215,
    Stamina: 9,
    Intellect: 8,
    Strength: 1,
    Defense: 3,
    HP: 109,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 4216,
    Stamina: 2,
    Intellect: 11,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 4217,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4218,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4219,
    Stamina: 2,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4220,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4221,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 4222,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4223,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 4224,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4225,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4226,
    Stamina: 14,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 114,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 4227,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 4228,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4229,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4230,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4231,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4232,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4233,
    Stamina: 1,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4234,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4235,
    Stamina: 1,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 101,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4236,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 4237,
    Stamina: 4,
    Intellect: 13,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4238,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 110,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 4239,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 4240,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4241,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4242,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 4243,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 4244,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4245,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 4246,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 4247,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 4248,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4249,
    Stamina: 4,
    Intellect: 10,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 33,
  },
  {
    Id: 4250,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4251,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 9,
    HP: 106,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 4252,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4253,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4254,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4255,
    Stamina: 1,
    Intellect: 7,
    Strength: 9,
    Defense: 2,
    HP: 101,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4256,
    Stamina: 4,
    Intellect: 2,
    Strength: 13,
    Defense: 7,
    HP: 104,
    Attack: 23,
    Power: 32,
  },
  {
    Id: 4257,
    Stamina: 2,
    Intellect: 2,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 4258,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4259,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4260,
    Stamina: 3,
    Intellect: 3,
    Strength: 13,
    Defense: 3,
    HP: 103,
    Attack: 23,
    Power: 29,
  },
  {
    Id: 4261,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4262,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4263,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4264,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4265,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4266,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4267,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 10,
    HP: 105,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 4268,
    Stamina: 1,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4269,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 4270,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4271,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 107,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4272,
    Stamina: 8,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 108,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4273,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4274,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 4275,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4276,
    Stamina: 8,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 108,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4277,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4278,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 4279,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4280,
    Stamina: 5,
    Intellect: 8,
    Strength: 7,
    Defense: 1,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4281,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 108,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4282,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4283,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4284,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4285,
    Stamina: 8,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 108,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4286,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4287,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4288,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4289,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 4290,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4291,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4292,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 4293,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4294,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4295,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4296,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4297,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4298,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4299,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4300,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4301,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4302,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4303,
    Stamina: 2,
    Intellect: 2,
    Strength: 7,
    Defense: 10,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 4304,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4305,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 4306,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 1,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 4307,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 109,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4308,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 4309,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4310,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4311,
    Stamina: 9,
    Intellect: 2,
    Strength: 2,
    Defense: 6,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 4312,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4313,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4314,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4315,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 4316,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 4317,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4318,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 4319,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4320,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4321,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4322,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4323,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4324,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4325,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4326,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4327,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4328,
    Stamina: 4,
    Intellect: 14,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 4329,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4330,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4331,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 4332,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4333,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4334,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 4335,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4336,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 8,
    HP: 104,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 4337,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4338,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 4339,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4340,
    Stamina: 1,
    Intellect: 13,
    Strength: 2,
    Defense: 4,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 4341,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 4342,
    Stamina: 1,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 101,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 4343,
    Stamina: 4,
    Intellect: 2,
    Strength: 1,
    Defense: 12,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4344,
    Stamina: 10,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 110,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4345,
    Stamina: 6,
    Intellect: 6,
    Strength: 10,
    Defense: 5,
    HP: 106,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 4346,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 4347,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4348,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4349,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 8,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 4350,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 4351,
    Stamina: 3,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 103,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 4352,
    Stamina: 9,
    Intellect: 11,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4353,
    Stamina: 9,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 4354,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4355,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4356,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4357,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4358,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4359,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4360,
    Stamina: 4,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 4361,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4362,
    Stamina: 3,
    Intellect: 11,
    Strength: 6,
    Defense: 1,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4363,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4364,
    Stamina: 9,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4365,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 1,
    HP: 105,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 4366,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4367,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4368,
    Stamina: 5,
    Intellect: 9,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 4369,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 105,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4370,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 4371,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4372,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4373,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 7,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 4374,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4375,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 4376,
    Stamina: 10,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 110,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 4377,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4378,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 4379,
    Stamina: 11,
    Intellect: 2,
    Strength: 1,
    Defense: 8,
    HP: 111,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 4380,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4381,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4382,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4383,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4384,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4385,
    Stamina: 9,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 4386,
    Stamina: 8,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 108,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4387,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4388,
    Stamina: 5,
    Intellect: 2,
    Strength: 9,
    Defense: 7,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4389,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4390,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 106,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4391,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4392,
    Stamina: 4,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 104,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 4393,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4394,
    Stamina: 11,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 111,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4395,
    Stamina: 9,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 4396,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4397,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 4398,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 4399,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 1,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4400,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4401,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4402,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4403,
    Stamina: 10,
    Intellect: 3,
    Strength: 1,
    Defense: 7,
    HP: 110,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 4404,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4405,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 107,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4406,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 4407,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4408,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4409,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4410,
    Stamina: 1,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 4411,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4412,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 4413,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4414,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4415,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4416,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4417,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 4418,
    Stamina: 3,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 103,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 4419,
    Stamina: 4,
    Intellect: 13,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 4420,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4421,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4422,
    Stamina: 1,
    Intellect: 3,
    Strength: 2,
    Defense: 11,
    HP: 101,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4423,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 11,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 4424,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 4425,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 12,
    HP: 105,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 4426,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4427,
    Stamina: 10,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 110,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 4428,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4429,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4430,
    Stamina: 1,
    Intellect: 7,
    Strength: 4,
    Defense: 10,
    HP: 101,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 4431,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4432,
    Stamina: 1,
    Intellect: 4,
    Strength: 2,
    Defense: 13,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 4433,
    Stamina: 5,
    Intellect: 2,
    Strength: 14,
    Defense: 4,
    HP: 105,
    Attack: 24,
    Power: 30,
  },
  {
    Id: 4434,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 4435,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4436,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4437,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 4438,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4439,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 9,
    HP: 101,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 4440,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4441,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 4442,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 4443,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4444,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4445,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4446,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 1,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 4447,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4448,
    Stamina: 10,
    Intellect: 10,
    Strength: 5,
    Defense: 2,
    HP: 110,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4449,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4450,
    Stamina: 8,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4451,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4452,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4453,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4454,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 4455,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4456,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4457,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4458,
    Stamina: 2,
    Intellect: 11,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 32,
  },
  {
    Id: 4459,
    Stamina: 8,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 108,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4460,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 2,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 4461,
    Stamina: 6,
    Intellect: 7,
    Strength: 6,
    Defense: 9,
    HP: 106,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 4462,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 4463,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 4464,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 4465,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 19,
  },
  {
    Id: 4466,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4467,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 4468,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4469,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 108,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 4470,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4471,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 4472,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4473,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4474,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 4475,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 1,
    HP: 106,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 4476,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4477,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4478,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 4479,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 4480,
    Stamina: 1,
    Intellect: 13,
    Strength: 3,
    Defense: 4,
    HP: 101,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 4481,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4482,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4483,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4484,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4485,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4486,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4487,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 4488,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 4489,
    Stamina: 4,
    Intellect: 12,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 4490,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 101,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4491,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4492,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 4493,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 4494,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4495,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4496,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4497,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4498,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 13,
    HP: 105,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 4499,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 10,
    HP: 106,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 4500,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4501,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4502,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4503,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4504,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 4505,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 4506,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4507,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 1,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 4508,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4509,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4510,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 4511,
    Stamina: 7,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4512,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4513,
    Stamina: 11,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 111,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 4514,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4515,
    Stamina: 8,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 108,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4516,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4517,
    Stamina: 6,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4518,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4519,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4520,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 4521,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 4522,
    Stamina: 5,
    Intellect: 9,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 4523,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4524,
    Stamina: 1,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 101,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4525,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4526,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4527,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4528,
    Stamina: 1,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 4529,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 4530,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4531,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 4532,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4533,
    Stamina: 8,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 108,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4534,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4535,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4536,
    Stamina: 9,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 4537,
    Stamina: 1,
    Intellect: 10,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 33,
  },
  {
    Id: 4538,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 106,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4539,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 4540,
    Stamina: 11,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 111,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 4541,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4542,
    Stamina: 2,
    Intellect: 4,
    Strength: 13,
    Defense: 2,
    HP: 102,
    Attack: 23,
    Power: 29,
  },
  {
    Id: 4543,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4544,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4545,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 1,
    HP: 105,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 4546,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4547,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4548,
    Stamina: 2,
    Intellect: 12,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 33,
  },
  {
    Id: 4549,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4550,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4551,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4552,
    Stamina: 10,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 110,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 4553,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4554,
    Stamina: 6,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4555,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4556,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4557,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4558,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4559,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 4560,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4561,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4562,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4563,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4564,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4565,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4566,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4567,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 4568,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 4569,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4570,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4571,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4572,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 101,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4573,
    Stamina: 1,
    Intellect: 6,
    Strength: 12,
    Defense: 1,
    HP: 101,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 4574,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 4575,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 4576,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4577,
    Stamina: 2,
    Intellect: 12,
    Strength: 6,
    Defense: 2,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 4578,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 4579,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 4580,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4581,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 4582,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4583,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4584,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4585,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 4586,
    Stamina: 4,
    Intellect: 11,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4587,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 4588,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4589,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4590,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 4591,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 11,
    HP: 102,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 4592,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 4593,
    Stamina: 8,
    Intellect: 11,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4594,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 4595,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 4596,
    Stamina: 9,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 109,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 4597,
    Stamina: 2,
    Intellect: 12,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 4598,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4599,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4600,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4601,
    Stamina: 4,
    Intellect: 11,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 33,
  },
  {
    Id: 4602,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4603,
    Stamina: 3,
    Intellect: 3,
    Strength: 12,
    Defense: 3,
    HP: 103,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 4604,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4605,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4606,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4607,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4608,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 110,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 4609,
    Stamina: 1,
    Intellect: 10,
    Strength: 8,
    Defense: 1,
    HP: 101,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4610,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4611,
    Stamina: 2,
    Intellect: 3,
    Strength: 2,
    Defense: 13,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 4612,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 2,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 4613,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4614,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4615,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4616,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 8,
    HP: 104,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 4617,
    Stamina: 6,
    Intellect: 8,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 4618,
    Stamina: 5,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 4619,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 4620,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 4621,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 4622,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4623,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4624,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 4625,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4626,
    Stamina: 9,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 109,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 4627,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4628,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4629,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4630,
    Stamina: 12,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 112,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4631,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 9,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4632,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4633,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 106,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 4634,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4635,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 4636,
    Stamina: 2,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4637,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 4638,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 4639,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4640,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4641,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4642,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4643,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4644,
    Stamina: 1,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4645,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4646,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 4,
    HP: 104,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 4647,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4648,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 4649,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4650,
    Stamina: 9,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 109,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 4651,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4652,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4653,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4654,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4655,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 4656,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 4657,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 4658,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 11,
    HP: 101,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 4659,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4660,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4661,
    Stamina: 4,
    Intellect: 3,
    Strength: 1,
    Defense: 11,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4662,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4663,
    Stamina: 2,
    Intellect: 4,
    Strength: 1,
    Defense: 14,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 4664,
    Stamina: 9,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 109,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4665,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4666,
    Stamina: 4,
    Intellect: 7,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 4667,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4668,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4669,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4670,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 4671,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 1,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4672,
    Stamina: 8,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4673,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 4674,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 4675,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4676,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 1,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4677,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 4678,
    Stamina: 8,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 4679,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 4680,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4681,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4682,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 4683,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4684,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 4685,
    Stamina: 5,
    Intellect: 12,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 4686,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 110,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 4687,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4688,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4689,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4690,
    Stamina: 1,
    Intellect: 3,
    Strength: 7,
    Defense: 11,
    HP: 101,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 4691,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 4692,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4693,
    Stamina: 1,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 101,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4694,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4695,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4696,
    Stamina: 6,
    Intellect: 10,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 4697,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4698,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 4699,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4700,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 4701,
    Stamina: 9,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 109,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 4702,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 4703,
    Stamina: 5,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 4704,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 4705,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 4706,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4707,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 4708,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4709,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4710,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4711,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 4712,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 4713,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4714,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4715,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4716,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 4717,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4718,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4719,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4720,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4721,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4722,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 4723,
    Stamina: 4,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 4724,
    Stamina: 2,
    Intellect: 2,
    Strength: 5,
    Defense: 12,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 4725,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4726,
    Stamina: 2,
    Intellect: 3,
    Strength: 2,
    Defense: 12,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4727,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4728,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4729,
    Stamina: 10,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 110,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 4730,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4731,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4732,
    Stamina: 5,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4733,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 4734,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4735,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 9,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 4736,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4737,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 4738,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4739,
    Stamina: 3,
    Intellect: 3,
    Strength: 13,
    Defense: 5,
    HP: 103,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 4740,
    Stamina: 4,
    Intellect: 7,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 4741,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4742,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 4743,
    Stamina: 10,
    Intellect: 5,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4744,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 4745,
    Stamina: 2,
    Intellect: 7,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 4746,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4747,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 4748,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4749,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4750,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4751,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 4752,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 4753,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4754,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 4755,
    Stamina: 5,
    Intellect: 11,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4756,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4757,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4758,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4759,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 4760,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4761,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 4762,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4763,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4764,
    Stamina: 11,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 111,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4765,
    Stamina: 6,
    Intellect: 2,
    Strength: 1,
    Defense: 11,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4766,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4767,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4768,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4769,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 109,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4770,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4771,
    Stamina: 9,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 109,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 4772,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 106,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4773,
    Stamina: 1,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 101,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 4774,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4775,
    Stamina: 3,
    Intellect: 14,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 33,
  },
  {
    Id: 4776,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 4777,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4778,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 4779,
    Stamina: 5,
    Intellect: 11,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 4780,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 108,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4781,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 12,
    HP: 102,
    Attack: 18,
    Power: 34,
  },
  {
    Id: 4782,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4783,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4784,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 4785,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4786,
    Stamina: 1,
    Intellect: 9,
    Strength: 2,
    Defense: 8,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 4787,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4788,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4789,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 4790,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4791,
    Stamina: 2,
    Intellect: 7,
    Strength: 13,
    Defense: 4,
    HP: 102,
    Attack: 23,
    Power: 34,
  },
  {
    Id: 4792,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4793,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 4794,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 4795,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 4796,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 4797,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4798,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4799,
    Stamina: 1,
    Intellect: 3,
    Strength: 11,
    Defense: 7,
    HP: 101,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 4800,
    Stamina: 9,
    Intellect: 5,
    Strength: 1,
    Defense: 5,
    HP: 109,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 4801,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 4802,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4803,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4804,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4805,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 4806,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4807,
    Stamina: 5,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 4808,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 4809,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 4810,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4811,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 4812,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4813,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 4814,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4815,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4816,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 109,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4817,
    Stamina: 5,
    Intellect: 12,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4818,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4819,
    Stamina: 9,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 109,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4820,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4821,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4822,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4823,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 13,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 4824,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4825,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4826,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 4827,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 3,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 4828,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4829,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4830,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4831,
    Stamina: 4,
    Intellect: 2,
    Strength: 12,
    Defense: 4,
    HP: 104,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 4832,
    Stamina: 6,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4833,
    Stamina: 9,
    Intellect: 6,
    Strength: 1,
    Defense: 2,
    HP: 109,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 4834,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 4835,
    Stamina: 10,
    Intellect: 6,
    Strength: 4,
    Defense: 1,
    HP: 110,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 4836,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4837,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 108,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 4838,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4839,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 12,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 4840,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 4841,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 4842,
    Stamina: 11,
    Intellect: 2,
    Strength: 2,
    Defense: 4,
    HP: 111,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 4843,
    Stamina: 1,
    Intellect: 9,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 4844,
    Stamina: 5,
    Intellect: 2,
    Strength: 1,
    Defense: 9,
    HP: 105,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 4845,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4846,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4847,
    Stamina: 4,
    Intellect: 2,
    Strength: 12,
    Defense: 1,
    HP: 104,
    Attack: 22,
    Power: 25,
  },
  {
    Id: 4848,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 4849,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4850,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 105,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 4851,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 4852,
    Stamina: 11,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 111,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4853,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 4854,
    Stamina: 1,
    Intellect: 12,
    Strength: 5,
    Defense: 3,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4855,
    Stamina: 6,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 106,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4856,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4857,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 4858,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4859,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 4860,
    Stamina: 5,
    Intellect: 8,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 4861,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 4862,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4863,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4864,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 4865,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4866,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4867,
    Stamina: 7,
    Intellect: 12,
    Strength: 2,
    Defense: 1,
    HP: 107,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4868,
    Stamina: 2,
    Intellect: 10,
    Strength: 1,
    Defense: 9,
    HP: 102,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 4869,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4870,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 4871,
    Stamina: 4,
    Intellect: 10,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 4872,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 4873,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4874,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4875,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 4876,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4877,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4878,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 4879,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4880,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4881,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 4882,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4883,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 4884,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4885,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4886,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 4887,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 4888,
    Stamina: 1,
    Intellect: 5,
    Strength: 11,
    Defense: 7,
    HP: 101,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 4889,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4890,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4891,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4892,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4893,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 4894,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 4895,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 4896,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 4897,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 7,
    HP: 106,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 4898,
    Stamina: 5,
    Intellect: 8,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 4899,
    Stamina: 1,
    Intellect: 9,
    Strength: 9,
    Defense: 3,
    HP: 101,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 4900,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 10,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 4901,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 4902,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 4903,
    Stamina: 6,
    Intellect: 9,
    Strength: 5,
    Defense: 8,
    HP: 106,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 4904,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 4905,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 4906,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 4907,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 4908,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4909,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 4910,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 4911,
    Stamina: 2,
    Intellect: 9,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 4912,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4913,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4914,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4915,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 4916,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4917,
    Stamina: 13,
    Intellect: 2,
    Strength: 6,
    Defense: 2,
    HP: 113,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 4918,
    Stamina: 6,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4919,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 4920,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 4921,
    Stamina: 1,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 4922,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 4923,
    Stamina: 2,
    Intellect: 12,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 4924,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4925,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4926,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 4927,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 11,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 4928,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 4929,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 4930,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 1,
    HP: 107,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 4931,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4932,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 4933,
    Stamina: 10,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 110,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 4934,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4935,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4936,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 101,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4937,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4938,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4939,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 4940,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4941,
    Stamina: 1,
    Intellect: 6,
    Strength: 5,
    Defense: 10,
    HP: 101,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 4942,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 4943,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 4944,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 4945,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4946,
    Stamina: 1,
    Intellect: 6,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 4947,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 4948,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4949,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 4950,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 4951,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 4952,
    Stamina: 4,
    Intellect: 12,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 4953,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 4954,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4955,
    Stamina: 1,
    Intellect: 14,
    Strength: 2,
    Defense: 8,
    HP: 101,
    Attack: 12,
    Power: 34,
  },
  {
    Id: 4956,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 4957,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4958,
    Stamina: 3,
    Intellect: 3,
    Strength: 13,
    Defense: 5,
    HP: 103,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 4959,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4960,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4961,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 4962,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 4963,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4964,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4965,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 4966,
    Stamina: 10,
    Intellect: 2,
    Strength: 4,
    Defense: 2,
    HP: 110,
    Attack: 14,
    Power: 18,
  },
  {
    Id: 4967,
    Stamina: 9,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4968,
    Stamina: 1,
    Intellect: 3,
    Strength: 8,
    Defense: 10,
    HP: 101,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 4969,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 4970,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 4971,
    Stamina: 7,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4972,
    Stamina: 5,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 4973,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 4974,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 4975,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 4976,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4977,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4978,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 4979,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 4980,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 4981,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 4982,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 4983,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 4984,
    Stamina: 7,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 4985,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 1,
    HP: 108,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 4986,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 4987,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 4988,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 4989,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 4990,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 4991,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 4992,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 4993,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 4994,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 4995,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 107,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 4996,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 4997,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 4998,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 4999,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5000,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 5001,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5002,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5003,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 11,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5004,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 5005,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 5006,
    Stamina: 1,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 5007,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5008,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 108,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 5009,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5010,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5011,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 7,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 5012,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 5013,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 5014,
    Stamina: 12,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 112,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 5015,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 5016,
    Stamina: 1,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 5017,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5018,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 5019,
    Stamina: 4,
    Intellect: 4,
    Strength: 12,
    Defense: 6,
    HP: 104,
    Attack: 22,
    Power: 32,
  },
  {
    Id: 5020,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5021,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5022,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5023,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5024,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5025,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5026,
    Stamina: 8,
    Intellect: 7,
    Strength: 1,
    Defense: 4,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 5027,
    Stamina: 1,
    Intellect: 10,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 5028,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 13,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 5029,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5030,
    Stamina: 7,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5031,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5032,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 110,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 5033,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5034,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 1,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5035,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5036,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 5037,
    Stamina: 2,
    Intellect: 11,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 5038,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5039,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 103,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 5040,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 6,
    HP: 102,
    Attack: 22,
    Power: 33,
  },
  {
    Id: 5041,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5042,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5043,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5044,
    Stamina: 3,
    Intellect: 10,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 5045,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 5046,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5047,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5048,
    Stamina: 4,
    Intellect: 7,
    Strength: 9,
    Defense: 1,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5049,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5050,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5051,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5052,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 5053,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5054,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 5055,
    Stamina: 9,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 109,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 5056,
    Stamina: 10,
    Intellect: 3,
    Strength: 4,
    Defense: 2,
    HP: 110,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 5057,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 5058,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 1,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 5059,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5060,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5061,
    Stamina: 4,
    Intellect: 2,
    Strength: 1,
    Defense: 11,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5062,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5063,
    Stamina: 2,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 102,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5064,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5065,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 13,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5066,
    Stamina: 4,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 5067,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5068,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 1,
    HP: 106,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 5069,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 5070,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 5071,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 5072,
    Stamina: 8,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 108,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 5073,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 1,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 5074,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5075,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5076,
    Stamina: 8,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5077,
    Stamina: 14,
    Intellect: 7,
    Strength: 3,
    Defense: 1,
    HP: 114,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 5078,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 5079,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 5080,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5081,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5082,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5083,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5084,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 1,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5085,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 2,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5086,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 5087,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5088,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5089,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 105,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 5090,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 11,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5091,
    Stamina: 1,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5092,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 5093,
    Stamina: 1,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5094,
    Stamina: 3,
    Intellect: 3,
    Strength: 12,
    Defense: 5,
    HP: 103,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 5095,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5096,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5097,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 5098,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 5099,
    Stamina: 2,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5100,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5101,
    Stamina: 1,
    Intellect: 3,
    Strength: 9,
    Defense: 9,
    HP: 101,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 5102,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5103,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 7,
    HP: 103,
    Attack: 20,
    Power: 34,
  },
  {
    Id: 5104,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5105,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 109,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 5106,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5107,
    Stamina: 1,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5108,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5109,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5110,
    Stamina: 3,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5111,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 11,
    HP: 104,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 5112,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5113,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5114,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5115,
    Stamina: 9,
    Intellect: 4,
    Strength: 5,
    Defense: 1,
    HP: 109,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 5116,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5117,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 109,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 5118,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 3,
    HP: 102,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 5119,
    Stamina: 11,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 111,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5120,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 5121,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 11,
    HP: 102,
    Attack: 16,
    Power: 33,
  },
  {
    Id: 5122,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5123,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5124,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5125,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 5126,
    Stamina: 4,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 5127,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5128,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 5129,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 11,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 5130,
    Stamina: 2,
    Intellect: 11,
    Strength: 6,
    Defense: 1,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5131,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 5132,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5133,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5134,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 5135,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 5136,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5137,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 1,
    HP: 107,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 5138,
    Stamina: 4,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5139,
    Stamina: 5,
    Intellect: 3,
    Strength: 10,
    Defense: 8,
    HP: 105,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 5140,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5141,
    Stamina: 1,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5142,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5143,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5144,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5145,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5146,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5147,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5148,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5149,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 1,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5150,
    Stamina: 2,
    Intellect: 8,
    Strength: 11,
    Defense: 1,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 5151,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 5152,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 5153,
    Stamina: 3,
    Intellect: 3,
    Strength: 12,
    Defense: 4,
    HP: 103,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 5154,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5155,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 12,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5156,
    Stamina: 2,
    Intellect: 6,
    Strength: 1,
    Defense: 12,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 5157,
    Stamina: 8,
    Intellect: 11,
    Strength: 1,
    Defense: 3,
    HP: 108,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5158,
    Stamina: 1,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5159,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5160,
    Stamina: 2,
    Intellect: 2,
    Strength: 5,
    Defense: 11,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5161,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5162,
    Stamina: 1,
    Intellect: 4,
    Strength: 4,
    Defense: 12,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5163,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5164,
    Stamina: 5,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 5165,
    Stamina: 1,
    Intellect: 10,
    Strength: 6,
    Defense: 5,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 5166,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 5167,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5168,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 5,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 5169,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5170,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 5171,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 13,
    HP: 106,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5172,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 109,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 5173,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 5174,
    Stamina: 4,
    Intellect: 11,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5175,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5176,
    Stamina: 4,
    Intellect: 9,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5177,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5178,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5179,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5180,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5181,
    Stamina: 6,
    Intellect: 11,
    Strength: 3,
    Defense: 2,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5182,
    Stamina: 1,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 5183,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 10,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5184,
    Stamina: 4,
    Intellect: 6,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5185,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 5186,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5187,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5188,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 5189,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5190,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5191,
    Stamina: 4,
    Intellect: 10,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 5192,
    Stamina: 5,
    Intellect: 12,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5193,
    Stamina: 7,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5194,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 5195,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5196,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5197,
    Stamina: 1,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5198,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 5199,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 5200,
    Stamina: 2,
    Intellect: 7,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 34,
  },
  {
    Id: 5201,
    Stamina: 4,
    Intellect: 2,
    Strength: 12,
    Defense: 5,
    HP: 104,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 5202,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5203,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 1,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5204,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5205,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5206,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 5207,
    Stamina: 1,
    Intellect: 11,
    Strength: 4,
    Defense: 6,
    HP: 101,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 5208,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5209,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 7,
    HP: 102,
    Attack: 20,
    Power: 34,
  },
  {
    Id: 5210,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5211,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5212,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5213,
    Stamina: 1,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 101,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5214,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5215,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5216,
    Stamina: 3,
    Intellect: 2,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5217,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5218,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 5219,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5220,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 5221,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 5222,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 5223,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5224,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5225,
    Stamina: 5,
    Intellect: 6,
    Strength: 11,
    Defense: 4,
    HP: 105,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 5226,
    Stamina: 5,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5227,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5228,
    Stamina: 1,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 5229,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 5230,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5231,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 10,
    HP: 105,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 5232,
    Stamina: 1,
    Intellect: 2,
    Strength: 9,
    Defense: 11,
    HP: 101,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 5233,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5234,
    Stamina: 3,
    Intellect: 2,
    Strength: 15,
    Defense: 3,
    HP: 103,
    Attack: 25,
    Power: 30,
  },
  {
    Id: 5235,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5236,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5237,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5238,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5239,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 6,
    HP: 102,
    Attack: 22,
    Power: 33,
  },
  {
    Id: 5240,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5241,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5242,
    Stamina: 6,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 106,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 5243,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5244,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5245,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 5246,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5247,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 5248,
    Stamina: 1,
    Intellect: 8,
    Strength: 2,
    Defense: 10,
    HP: 101,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 5249,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 12,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5250,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5251,
    Stamina: 2,
    Intellect: 12,
    Strength: 2,
    Defense: 4,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5252,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5253,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5254,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5255,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5256,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 107,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5257,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 5258,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5259,
    Stamina: 2,
    Intellect: 7,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 5260,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5261,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 5262,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 10,
    HP: 105,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5263,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 5264,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5265,
    Stamina: 8,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 108,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 5266,
    Stamina: 7,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 107,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5267,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 12,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5268,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 5269,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5270,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5271,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 9,
    HP: 101,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 5272,
    Stamina: 9,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 109,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5273,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 11,
    HP: 101,
    Attack: 16,
    Power: 34,
  },
  {
    Id: 5274,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 3,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5275,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5276,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5277,
    Stamina: 1,
    Intellect: 6,
    Strength: 9,
    Defense: 9,
    HP: 101,
    Attack: 19,
    Power: 34,
  },
  {
    Id: 5278,
    Stamina: 1,
    Intellect: 6,
    Strength: 4,
    Defense: 10,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5279,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5280,
    Stamina: 2,
    Intellect: 12,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 5281,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5282,
    Stamina: 4,
    Intellect: 12,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5283,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5284,
    Stamina: 8,
    Intellect: 7,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 5285,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5286,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5287,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5288,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5289,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 5290,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5291,
    Stamina: 9,
    Intellect: 2,
    Strength: 2,
    Defense: 6,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 5292,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5293,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 1,
    HP: 105,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 5294,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5295,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 5296,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5297,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5298,
    Stamina: 8,
    Intellect: 2,
    Strength: 10,
    Defense: 2,
    HP: 108,
    Attack: 20,
    Power: 24,
  },
  {
    Id: 5299,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5300,
    Stamina: 5,
    Intellect: 4,
    Strength: 11,
    Defense: 5,
    HP: 105,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 5301,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 5302,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5303,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 108,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5304,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5305,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 5306,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5307,
    Stamina: 8,
    Intellect: 5,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 5308,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5309,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5310,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 5311,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5312,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5313,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5314,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5315,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 5316,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5317,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5318,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 5319,
    Stamina: 8,
    Intellect: 2,
    Strength: 11,
    Defense: 2,
    HP: 108,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 5320,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 5321,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5322,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 5323,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 5324,
    Stamina: 3,
    Intellect: 2,
    Strength: 2,
    Defense: 13,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5325,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5326,
    Stamina: 6,
    Intellect: 10,
    Strength: 7,
    Defense: 1,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5327,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5328,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 5329,
    Stamina: 7,
    Intellect: 5,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 5330,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5331,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 5332,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 11,
    HP: 103,
    Attack: 18,
    Power: 36,
  },
  {
    Id: 5333,
    Stamina: 7,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5334,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5335,
    Stamina: 3,
    Intellect: 12,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5336,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5337,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5338,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 1,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5339,
    Stamina: 6,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5340,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5341,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5342,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5343,
    Stamina: 12,
    Intellect: 2,
    Strength: 3,
    Defense: 2,
    HP: 112,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 5344,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 10,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5345,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 5346,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5347,
    Stamina: 1,
    Intellect: 3,
    Strength: 10,
    Defense: 11,
    HP: 101,
    Attack: 20,
    Power: 34,
  },
  {
    Id: 5348,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 5349,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 5350,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 34,
  },
  {
    Id: 5351,
    Stamina: 10,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 110,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 5352,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5353,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5354,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 5355,
    Stamina: 7,
    Intellect: 2,
    Strength: 9,
    Defense: 2,
    HP: 107,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 5356,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 5357,
    Stamina: 14,
    Intellect: 2,
    Strength: 1,
    Defense: 2,
    HP: 114,
    Attack: 11,
    Power: 15,
  },
  {
    Id: 5358,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5359,
    Stamina: 6,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5360,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5361,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5362,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5363,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5364,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 5365,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5366,
    Stamina: 9,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5367,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5368,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5369,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 5370,
    Stamina: 9,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 109,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5371,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 5372,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5373,
    Stamina: 7,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 107,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5374,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5375,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5376,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 12,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 5377,
    Stamina: 1,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5378,
    Stamina: 3,
    Intellect: 13,
    Strength: 1,
    Defense: 3,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5379,
    Stamina: 5,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5380,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5381,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5382,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 5383,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5384,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5385,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 5386,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 5387,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5388,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5389,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5390,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 5391,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5392,
    Stamina: 6,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 106,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 5393,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 6,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 5394,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5395,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5396,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5397,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5398,
    Stamina: 9,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5399,
    Stamina: 1,
    Intellect: 7,
    Strength: 5,
    Defense: 12,
    HP: 101,
    Attack: 15,
    Power: 34,
  },
  {
    Id: 5400,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 2,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5401,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5402,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 5403,
    Stamina: 8,
    Intellect: 2,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 5404,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5405,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 106,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5406,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 5407,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 10,
    HP: 104,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 5408,
    Stamina: 5,
    Intellect: 2,
    Strength: 12,
    Defense: 2,
    HP: 105,
    Attack: 22,
    Power: 26,
  },
  {
    Id: 5409,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5410,
    Stamina: 9,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 109,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5411,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 5412,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 10,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 5413,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5414,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5415,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5416,
    Stamina: 10,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 110,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 5417,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 5418,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 5419,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5420,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5421,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5422,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5423,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 5424,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5425,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5426,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5427,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 5428,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 5429,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5430,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5431,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5432,
    Stamina: 1,
    Intellect: 4,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 5433,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5434,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5435,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5436,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5437,
    Stamina: 4,
    Intellect: 4,
    Strength: 13,
    Defense: 2,
    HP: 104,
    Attack: 23,
    Power: 29,
  },
  {
    Id: 5438,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 5439,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5440,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5441,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5442,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5443,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5444,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 5445,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5446,
    Stamina: 2,
    Intellect: 12,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 35,
  },
  {
    Id: 5447,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5448,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5449,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5450,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 9,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5451,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5452,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 5453,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 105,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 5454,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 5455,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 5456,
    Stamina: 5,
    Intellect: 3,
    Strength: 12,
    Defense: 2,
    HP: 105,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 5457,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 5458,
    Stamina: 1,
    Intellect: 7,
    Strength: 9,
    Defense: 3,
    HP: 101,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5459,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5460,
    Stamina: 1,
    Intellect: 4,
    Strength: 9,
    Defense: 10,
    HP: 101,
    Attack: 19,
    Power: 33,
  },
  {
    Id: 5461,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5462,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5463,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 5464,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5465,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 5466,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5467,
    Stamina: 3,
    Intellect: 9,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 5468,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5469,
    Stamina: 6,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 5470,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5471,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5472,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 5473,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 12,
    HP: 108,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 5474,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5475,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 5476,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5477,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5478,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 2,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5479,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 5480,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 5481,
    Stamina: 1,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 101,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5482,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5483,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 5484,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5485,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5486,
    Stamina: 7,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 107,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5487,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5488,
    Stamina: 7,
    Intellect: 7,
    Strength: 9,
    Defense: 2,
    HP: 107,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 5489,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5490,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5491,
    Stamina: 7,
    Intellect: 10,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5492,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5493,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 5494,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 5495,
    Stamina: 7,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 5496,
    Stamina: 1,
    Intellect: 11,
    Strength: 3,
    Defense: 5,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5497,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5498,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 5499,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5500,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5501,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5502,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5503,
    Stamina: 2,
    Intellect: 14,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 32,
  },
  {
    Id: 5504,
    Stamina: 1,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 101,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5505,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5506,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5507,
    Stamina: 9,
    Intellect: 2,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 5508,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5509,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5510,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5511,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5512,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 5513,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 6,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5514,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5515,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5516,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5517,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5518,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5519,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5520,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5521,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5522,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 5523,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 5524,
    Stamina: 7,
    Intellect: 9,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 5525,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5526,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 5527,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5528,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 5529,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5530,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 5531,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 5532,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5533,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 5534,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 2,
    HP: 102,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 5535,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 5536,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5537,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5538,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5539,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5540,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5541,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5542,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5543,
    Stamina: 1,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 101,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5544,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5545,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5546,
    Stamina: 3,
    Intellect: 9,
    Strength: 1,
    Defense: 7,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5547,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 5548,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 106,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 5549,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 12,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5550,
    Stamina: 6,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5551,
    Stamina: 5,
    Intellect: 13,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5552,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5553,
    Stamina: 3,
    Intellect: 15,
    Strength: 3,
    Defense: 2,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5554,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5555,
    Stamina: 10,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5556,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5557,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5558,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 5559,
    Stamina: 11,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 111,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 5560,
    Stamina: 11,
    Intellect: 6,
    Strength: 2,
    Defense: 2,
    HP: 111,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 5561,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 7,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 5562,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5563,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5564,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5565,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 5566,
    Stamina: 1,
    Intellect: 11,
    Strength: 1,
    Defense: 9,
    HP: 101,
    Attack: 11,
    Power: 31,
  },
  {
    Id: 5567,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5568,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5569,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5570,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 5571,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 5572,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 7,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 5573,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 9,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5574,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5575,
    Stamina: 10,
    Intellect: 2,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 5576,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 5577,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5578,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5579,
    Stamina: 5,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5580,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5581,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5582,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5583,
    Stamina: 10,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 110,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 5584,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5585,
    Stamina: 2,
    Intellect: 11,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 34,
  },
  {
    Id: 5586,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5587,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5588,
    Stamina: 12,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 112,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 5589,
    Stamina: 3,
    Intellect: 2,
    Strength: 13,
    Defense: 5,
    HP: 103,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 5590,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5591,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5592,
    Stamina: 4,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5593,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 5594,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5595,
    Stamina: 11,
    Intellect: 2,
    Strength: 2,
    Defense: 1,
    HP: 111,
    Attack: 12,
    Power: 15,
  },
  {
    Id: 5596,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5597,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 5598,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5599,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5600,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 5601,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 13,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5602,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5603,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5604,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 108,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 5605,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5606,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5607,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 5608,
    Stamina: 6,
    Intellect: 13,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5609,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5610,
    Stamina: 9,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 109,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5611,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5612,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5613,
    Stamina: 14,
    Intellect: 3,
    Strength: 4,
    Defense: 2,
    HP: 114,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 5614,
    Stamina: 1,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 101,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5615,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5616,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5617,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 108,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5618,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5619,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 13,
    HP: 102,
    Attack: 11,
    Power: 32,
  },
  {
    Id: 5620,
    Stamina: 7,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5621,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 5622,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5623,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 5624,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5625,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 5626,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 108,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5627,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5628,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 5629,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5630,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5631,
    Stamina: 2,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5632,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5633,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 5634,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5635,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 5636,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 5637,
    Stamina: 6,
    Intellect: 10,
    Strength: 4,
    Defense: 2,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5638,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 5639,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 12,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5640,
    Stamina: 1,
    Intellect: 11,
    Strength: 3,
    Defense: 6,
    HP: 101,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5641,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 5642,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5643,
    Stamina: 1,
    Intellect: 9,
    Strength: 8,
    Defense: 3,
    HP: 101,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5644,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5645,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5646,
    Stamina: 3,
    Intellect: 5,
    Strength: 12,
    Defense: 2,
    HP: 103,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 5647,
    Stamina: 1,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5648,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5649,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 5650,
    Stamina: 1,
    Intellect: 8,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 5651,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5652,
    Stamina: 2,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5653,
    Stamina: 6,
    Intellect: 5,
    Strength: 14,
    Defense: 5,
    HP: 106,
    Attack: 24,
    Power: 34,
  },
  {
    Id: 5654,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5655,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 5656,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 5657,
    Stamina: 1,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5658,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5659,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 5660,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5661,
    Stamina: 11,
    Intellect: 2,
    Strength: 1,
    Defense: 7,
    HP: 111,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 5662,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5663,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 5664,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5665,
    Stamina: 3,
    Intellect: 12,
    Strength: 1,
    Defense: 3,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5666,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5667,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5668,
    Stamina: 12,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 112,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 5669,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5670,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 5671,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5672,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 5673,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5674,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5675,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5676,
    Stamina: 2,
    Intellect: 13,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 5677,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 5678,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 11,
    HP: 106,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 5679,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5680,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5681,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5682,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5683,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 5684,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5685,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5686,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5687,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5688,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 5689,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5690,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5691,
    Stamina: 10,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 110,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5692,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5693,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 10,
    HP: 104,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 5694,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 5695,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 109,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5696,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5697,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5698,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5699,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5700,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5701,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5702,
    Stamina: 5,
    Intellect: 5,
    Strength: 16,
    Defense: 15,
    HP: 105,
    Attack: 26,
    Power: 46,
  },
  {
    Id: 5703,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5704,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 5705,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 106,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 5706,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 5707,
    Stamina: 9,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5708,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 5709,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5710,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 5711,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 5712,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 5713,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5714,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5715,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5716,
    Stamina: 11,
    Intellect: 2,
    Strength: 1,
    Defense: 3,
    HP: 111,
    Attack: 11,
    Power: 16,
  },
  {
    Id: 5717,
    Stamina: 6,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5718,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5719,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5720,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5721,
    Stamina: 6,
    Intellect: 10,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 5722,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 5723,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 5724,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5725,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 5726,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 105,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 5727,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 5728,
    Stamina: 3,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5729,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5730,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5731,
    Stamina: 1,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 101,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 5732,
    Stamina: 1,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5733,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 107,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 5734,
    Stamina: 9,
    Intellect: 3,
    Strength: 8,
    Defense: 1,
    HP: 109,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 5735,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 5736,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 5737,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5738,
    Stamina: 8,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 108,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5739,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5740,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 5741,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5742,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5743,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5744,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5745,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5746,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5747,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5748,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5749,
    Stamina: 1,
    Intellect: 3,
    Strength: 7,
    Defense: 12,
    HP: 101,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 5750,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5751,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5752,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5753,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5754,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 5755,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5756,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 5757,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5758,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5759,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5760,
    Stamina: 2,
    Intellect: 10,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 5761,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5762,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 5763,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 5764,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 5765,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 103,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 5766,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 5767,
    Stamina: 6,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5768,
    Stamina: 5,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 5769,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5770,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5771,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5772,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5773,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 108,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 5774,
    Stamina: 2,
    Intellect: 9,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 5775,
    Stamina: 1,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 5776,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5777,
    Stamina: 4,
    Intellect: 10,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 5778,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5779,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5780,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5781,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5782,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5783,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5784,
    Stamina: 3,
    Intellect: 8,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 5785,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5786,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5787,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 5788,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 5789,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 5790,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5791,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5792,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5793,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5794,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5795,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5796,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 106,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 5797,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5798,
    Stamina: 5,
    Intellect: 3,
    Strength: 13,
    Defense: 4,
    HP: 105,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 5799,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 5800,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5801,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 5802,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5803,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5804,
    Stamina: 2,
    Intellect: 11,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 5805,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 5806,
    Stamina: 1,
    Intellect: 13,
    Strength: 4,
    Defense: 2,
    HP: 101,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5807,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5808,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 5809,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5810,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 5811,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5812,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5813,
    Stamina: 6,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 106,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 5814,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5815,
    Stamina: 1,
    Intellect: 7,
    Strength: 13,
    Defense: 1,
    HP: 101,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 5816,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5817,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5818,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5819,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 107,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5820,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5821,
    Stamina: 6,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 106,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 5822,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 107,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5823,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5824,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5825,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5826,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5827,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5828,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5829,
    Stamina: 1,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 101,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 5830,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 5831,
    Stamina: 8,
    Intellect: 6,
    Strength: 4,
    Defense: 10,
    HP: 108,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5832,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 5833,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5834,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5835,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5836,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5837,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5838,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 5839,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 5840,
    Stamina: 3,
    Intellect: 9,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 5841,
    Stamina: 3,
    Intellect: 3,
    Strength: 14,
    Defense: 2,
    HP: 103,
    Attack: 24,
    Power: 29,
  },
  {
    Id: 5842,
    Stamina: 3,
    Intellect: 4,
    Strength: 15,
    Defense: 5,
    HP: 103,
    Attack: 25,
    Power: 34,
  },
  {
    Id: 5843,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 5844,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5845,
    Stamina: 1,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 101,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 5846,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5847,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 11,
    HP: 105,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 5848,
    Stamina: 3,
    Intellect: 4,
    Strength: 14,
    Defense: 1,
    HP: 103,
    Attack: 24,
    Power: 29,
  },
  {
    Id: 5849,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 5850,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 5851,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5852,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5853,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 5854,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 13,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 5855,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 5856,
    Stamina: 4,
    Intellect: 14,
    Strength: 6,
    Defense: 1,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 5857,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5858,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5859,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5860,
    Stamina: 2,
    Intellect: 2,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5861,
    Stamina: 2,
    Intellect: 7,
    Strength: 12,
    Defense: 3,
    HP: 102,
    Attack: 22,
    Power: 32,
  },
  {
    Id: 5862,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5863,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5864,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 5865,
    Stamina: 6,
    Intellect: 9,
    Strength: 5,
    Defense: 1,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5866,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5867,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 5868,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 5869,
    Stamina: 1,
    Intellect: 12,
    Strength: 3,
    Defense: 4,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5870,
    Stamina: 8,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 108,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 5871,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5872,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5873,
    Stamina: 5,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5874,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5875,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5876,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5877,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5878,
    Stamina: 3,
    Intellect: 12,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5879,
    Stamina: 1,
    Intellect: 6,
    Strength: 14,
    Defense: 6,
    HP: 101,
    Attack: 24,
    Power: 36,
  },
  {
    Id: 5880,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 1,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 5881,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5882,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5883,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 1,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5884,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5885,
    Stamina: 4,
    Intellect: 12,
    Strength: 1,
    Defense: 2,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 5886,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 5887,
    Stamina: 7,
    Intellect: 2,
    Strength: 1,
    Defense: 9,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 5888,
    Stamina: 10,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 110,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 5889,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5890,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5891,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 34,
  },
  {
    Id: 5892,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5893,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 5894,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 107,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 5895,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5896,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 107,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 5897,
    Stamina: 1,
    Intellect: 5,
    Strength: 10,
    Defense: 1,
    HP: 101,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 5898,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 5899,
    Stamina: 6,
    Intellect: 2,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5900,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 5901,
    Stamina: 11,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 111,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5902,
    Stamina: 7,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 107,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 5903,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5904,
    Stamina: 9,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 109,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5905,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 10,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 5906,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5907,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 5908,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 5909,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 5910,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 1,
    HP: 109,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 5911,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 5912,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5913,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 5914,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5915,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5916,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 5917,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 5918,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5919,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 5920,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5921,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5922,
    Stamina: 7,
    Intellect: 8,
    Strength: 6,
    Defense: 1,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5923,
    Stamina: 3,
    Intellect: 13,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 5924,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 5925,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 5926,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 5927,
    Stamina: 10,
    Intellect: 4,
    Strength: 2,
    Defense: 1,
    HP: 110,
    Attack: 12,
    Power: 17,
  },
  {
    Id: 5928,
    Stamina: 8,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 108,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5929,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 1,
    HP: 107,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 5930,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 5931,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5932,
    Stamina: 11,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 111,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 5933,
    Stamina: 11,
    Intellect: 2,
    Strength: 2,
    Defense: 6,
    HP: 111,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 5934,
    Stamina: 9,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5935,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 5936,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 105,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 5937,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 5938,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5939,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5940,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 5941,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 5942,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5943,
    Stamina: 1,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 101,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5944,
    Stamina: 5,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 5945,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 5946,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5947,
    Stamina: 12,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 112,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 5948,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 5949,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 5950,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 5951,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 5952,
    Stamina: 10,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 110,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 5953,
    Stamina: 10,
    Intellect: 6,
    Strength: 3,
    Defense: 4,
    HP: 110,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5954,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 5955,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 5956,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 5957,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 5958,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5959,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5960,
    Stamina: 3,
    Intellect: 12,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5961,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5962,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 5963,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 5964,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 5965,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 5966,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5967,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 5968,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 5969,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 5970,
    Stamina: 3,
    Intellect: 2,
    Strength: 2,
    Defense: 12,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5971,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 5972,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 5973,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5974,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 5975,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 5976,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5977,
    Stamina: 5,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5978,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 5979,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 5980,
    Stamina: 1,
    Intellect: 8,
    Strength: 1,
    Defense: 10,
    HP: 101,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 5981,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 5982,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 9,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 5983,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 5984,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 5985,
    Stamina: 7,
    Intellect: 13,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 5986,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 108,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 5987,
    Stamina: 1,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 5988,
    Stamina: 2,
    Intellect: 12,
    Strength: 2,
    Defense: 2,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 5989,
    Stamina: 2,
    Intellect: 2,
    Strength: 13,
    Defense: 4,
    HP: 102,
    Attack: 23,
    Power: 29,
  },
  {
    Id: 5990,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 5991,
    Stamina: 5,
    Intellect: 10,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 5992,
    Stamina: 11,
    Intellect: 2,
    Strength: 3,
    Defense: 2,
    HP: 111,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 5993,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 5994,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 5995,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 5996,
    Stamina: 4,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 5997,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 5998,
    Stamina: 2,
    Intellect: 3,
    Strength: 1,
    Defense: 14,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 5999,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 107,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6000,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6001,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6002,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6003,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 6004,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 6005,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 6006,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6007,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 6008,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6009,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6010,
    Stamina: 10,
    Intellect: 3,
    Strength: 1,
    Defense: 3,
    HP: 110,
    Attack: 11,
    Power: 17,
  },
  {
    Id: 6011,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 6012,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6013,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6014,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6015,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6016,
    Stamina: 10,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 110,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 6017,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 12,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6018,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6019,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 6020,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 106,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 6021,
    Stamina: 2,
    Intellect: 3,
    Strength: 14,
    Defense: 3,
    HP: 102,
    Attack: 24,
    Power: 30,
  },
  {
    Id: 6022,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6023,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6024,
    Stamina: 3,
    Intellect: 9,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 6025,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6026,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6027,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 8,
    HP: 105,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 6028,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6029,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 6030,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6031,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6032,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6033,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6034,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6035,
    Stamina: 11,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 111,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6036,
    Stamina: 2,
    Intellect: 5,
    Strength: 14,
    Defense: 1,
    HP: 102,
    Attack: 24,
    Power: 30,
  },
  {
    Id: 6037,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6038,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6039,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6040,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6041,
    Stamina: 3,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6042,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6043,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6044,
    Stamina: 3,
    Intellect: 2,
    Strength: 13,
    Defense: 3,
    HP: 103,
    Attack: 23,
    Power: 28,
  },
  {
    Id: 6045,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6046,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6047,
    Stamina: 4,
    Intellect: 9,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 6048,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6049,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6050,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6051,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6052,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6053,
    Stamina: 7,
    Intellect: 3,
    Strength: 1,
    Defense: 8,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 6054,
    Stamina: 1,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 101,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6055,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6056,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 6057,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6058,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6059,
    Stamina: 1,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 101,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6060,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 6061,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 10,
    HP: 107,
    Attack: 13,
    Power: 32,
  },
  {
    Id: 6062,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 6063,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6064,
    Stamina: 3,
    Intellect: 13,
    Strength: 1,
    Defense: 2,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6065,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 6066,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6067,
    Stamina: 1,
    Intellect: 9,
    Strength: 9,
    Defense: 2,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 6068,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6069,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 6070,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6071,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6072,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 6073,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 6074,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 16,
    HP: 103,
    Attack: 13,
    Power: 34,
  },
  {
    Id: 6075,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6076,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 6077,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6078,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 6079,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6080,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 6081,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6082,
    Stamina: 7,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6083,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6084,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6085,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6086,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6087,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6088,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6089,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6090,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 6091,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6092,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6093,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6094,
    Stamina: 6,
    Intellect: 9,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 6095,
    Stamina: 7,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6096,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6097,
    Stamina: 10,
    Intellect: 2,
    Strength: 4,
    Defense: 3,
    HP: 110,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 6098,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6099,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 107,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6100,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6101,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6102,
    Stamina: 4,
    Intellect: 11,
    Strength: 2,
    Defense: 1,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6103,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 6104,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 110,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 6105,
    Stamina: 11,
    Intellect: 4,
    Strength: 1,
    Defense: 2,
    HP: 111,
    Attack: 11,
    Power: 17,
  },
  {
    Id: 6106,
    Stamina: 3,
    Intellect: 10,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6107,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 6108,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6109,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6110,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6111,
    Stamina: 11,
    Intellect: 7,
    Strength: 1,
    Defense: 3,
    HP: 111,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 6112,
    Stamina: 9,
    Intellect: 8,
    Strength: 2,
    Defense: 1,
    HP: 109,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6113,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6114,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 10,
    HP: 106,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 6115,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6116,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6117,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6118,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6119,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 6120,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6121,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 6122,
    Stamina: 4,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 6123,
    Stamina: 7,
    Intellect: 9,
    Strength: 1,
    Defense: 2,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 6124,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6125,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6126,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6127,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6128,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6129,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6130,
    Stamina: 1,
    Intellect: 3,
    Strength: 5,
    Defense: 12,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 6131,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 6132,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6133,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 1,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6134,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6135,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6136,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6137,
    Stamina: 1,
    Intellect: 8,
    Strength: 6,
    Defense: 8,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 6138,
    Stamina: 1,
    Intellect: 8,
    Strength: 2,
    Defense: 9,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 6139,
    Stamina: 5,
    Intellect: 10,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 6140,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6141,
    Stamina: 6,
    Intellect: 2,
    Strength: 12,
    Defense: 1,
    HP: 106,
    Attack: 22,
    Power: 25,
  },
  {
    Id: 6142,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6143,
    Stamina: 8,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 108,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6144,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6145,
    Stamina: 9,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 6146,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6147,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6148,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6149,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 6150,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6151,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6152,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6153,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 109,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6154,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6155,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6156,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6157,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6158,
    Stamina: 11,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 111,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6159,
    Stamina: 6,
    Intellect: 10,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 6160,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6161,
    Stamina: 3,
    Intellect: 11,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 6162,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6163,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 6164,
    Stamina: 5,
    Intellect: 11,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 6165,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6166,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6167,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 11,
    HP: 101,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 6168,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6169,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 6170,
    Stamina: 7,
    Intellect: 3,
    Strength: 1,
    Defense: 9,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 6171,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6172,
    Stamina: 2,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6173,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6174,
    Stamina: 10,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 110,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6175,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 6176,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6177,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6178,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 109,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6179,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 6180,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6181,
    Stamina: 8,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6182,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6183,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6184,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6185,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 6186,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6187,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 6188,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6189,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6190,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 1,
    HP: 108,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 6191,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 6192,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6193,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6194,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6195,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 6196,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6197,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6198,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6199,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6200,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 6201,
    Stamina: 3,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6202,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 9,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 6203,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 6204,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6205,
    Stamina: 5,
    Intellect: 2,
    Strength: 13,
    Defense: 3,
    HP: 105,
    Attack: 23,
    Power: 28,
  },
  {
    Id: 6206,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6207,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6208,
    Stamina: 6,
    Intellect: 7,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 6209,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 12,
    HP: 104,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 6210,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6211,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 6212,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6213,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6214,
    Stamina: 1,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 101,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6215,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6216,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 6217,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6218,
    Stamina: 1,
    Intellect: 2,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6219,
    Stamina: 7,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6220,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 6221,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6222,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6223,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6224,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6225,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6226,
    Stamina: 8,
    Intellect: 6,
    Strength: 6,
    Defense: 7,
    HP: 108,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6227,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6228,
    Stamina: 2,
    Intellect: 14,
    Strength: 1,
    Defense: 5,
    HP: 102,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 6229,
    Stamina: 8,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 108,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6230,
    Stamina: 4,
    Intellect: 2,
    Strength: 13,
    Defense: 5,
    HP: 104,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 6231,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6232,
    Stamina: 5,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6233,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6234,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6235,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6236,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 6237,
    Stamina: 1,
    Intellect: 3,
    Strength: 12,
    Defense: 4,
    HP: 101,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 6238,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 6239,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 6240,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6241,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6242,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6243,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6244,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6245,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 107,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6246,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6247,
    Stamina: 13,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 113,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6248,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6249,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6250,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6251,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 107,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6252,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 6253,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6254,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6255,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 6256,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 6257,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6258,
    Stamina: 3,
    Intellect: 12,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 32,
  },
  {
    Id: 6259,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 6260,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6261,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6262,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 1,
    HP: 105,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 6263,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 6264,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6265,
    Stamina: 4,
    Intellect: 11,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6266,
    Stamina: 4,
    Intellect: 11,
    Strength: 4,
    Defense: 1,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6267,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 6268,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6269,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6270,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6271,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 6272,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6273,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 6274,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6275,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6276,
    Stamina: 3,
    Intellect: 3,
    Strength: 12,
    Defense: 1,
    HP: 103,
    Attack: 22,
    Power: 26,
  },
  {
    Id: 6277,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 6278,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6279,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 6280,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 106,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6281,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6282,
    Stamina: 1,
    Intellect: 13,
    Strength: 3,
    Defense: 2,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6283,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6284,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 6285,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6286,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6287,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 6288,
    Stamina: 11,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 111,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 6289,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6290,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6291,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6292,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6293,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6294,
    Stamina: 5,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6295,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6296,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6297,
    Stamina: 5,
    Intellect: 12,
    Strength: 1,
    Defense: 3,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6298,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 6299,
    Stamina: 1,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6300,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6301,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 13,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 6302,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6303,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6304,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 6305,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6306,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 6307,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6308,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6309,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 6310,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6311,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 6312,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6313,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6314,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6315,
    Stamina: 8,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 108,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 6316,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 6317,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6318,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6319,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6320,
    Stamina: 1,
    Intellect: 11,
    Strength: 5,
    Defense: 3,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6321,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6322,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6323,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6324,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6325,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 11,
    HP: 105,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 6326,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6327,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6328,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6329,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 110,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6330,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6331,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6332,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6333,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6334,
    Stamina: 9,
    Intellect: 9,
    Strength: 5,
    Defense: 1,
    HP: 109,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6335,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6336,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6337,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6338,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6339,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6340,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6341,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 1,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6342,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6343,
    Stamina: 8,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6344,
    Stamina: 10,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 110,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6345,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6346,
    Stamina: 9,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 109,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 6347,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6348,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 1,
    HP: 103,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 6349,
    Stamina: 10,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 110,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6350,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6351,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 6352,
    Stamina: 9,
    Intellect: 7,
    Strength: 6,
    Defense: 1,
    HP: 109,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 6353,
    Stamina: 5,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6354,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 108,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6355,
    Stamina: 2,
    Intellect: 8,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 6356,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6357,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 6358,
    Stamina: 4,
    Intellect: 14,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 6359,
    Stamina: 6,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6360,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6361,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 107,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6362,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6363,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6364,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 6365,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 6366,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 5,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 6367,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6368,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6369,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6370,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 6371,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6372,
    Stamina: 9,
    Intellect: 7,
    Strength: 1,
    Defense: 5,
    HP: 109,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 6373,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6374,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6375,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 108,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6376,
    Stamina: 8,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 108,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6377,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6378,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 6379,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 108,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 6380,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6381,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 6382,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6383,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6384,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 6385,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6386,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 6387,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 6388,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6389,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6390,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6391,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6392,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6393,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6394,
    Stamina: 5,
    Intellect: 4,
    Strength: 12,
    Defense: 4,
    HP: 105,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 6395,
    Stamina: 5,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6396,
    Stamina: 5,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6397,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6398,
    Stamina: 4,
    Intellect: 12,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6399,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 107,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 6400,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 6401,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6402,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6403,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 6404,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6405,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6406,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6407,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6408,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 6409,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6410,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6411,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6412,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6413,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6414,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 6415,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6416,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6417,
    Stamina: 6,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 6418,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 6419,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6420,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 108,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 6421,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6422,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6423,
    Stamina: 8,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6424,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6425,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 14,
    HP: 102,
    Attack: 13,
    Power: 32,
  },
  {
    Id: 6426,
    Stamina: 8,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6427,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6428,
    Stamina: 7,
    Intellect: 4,
    Strength: 10,
    Defense: 8,
    HP: 107,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 6429,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 6430,
    Stamina: 7,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6431,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 106,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 6432,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6433,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 6434,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 6435,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6436,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6437,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6438,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6439,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6440,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 3,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6441,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6442,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6443,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 6444,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 6445,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6446,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6447,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 4,
    HP: 104,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 6448,
    Stamina: 3,
    Intellect: 14,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 6449,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 6450,
    Stamina: 4,
    Intellect: 13,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 33,
  },
  {
    Id: 6451,
    Stamina: 8,
    Intellect: 6,
    Strength: 4,
    Defense: 1,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 6452,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6453,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6454,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 6455,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6456,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6457,
    Stamina: 6,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 106,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 6458,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6459,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6460,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6461,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 6462,
    Stamina: 10,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 110,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6463,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 9,
    HP: 102,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 6464,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6465,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6466,
    Stamina: 6,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6467,
    Stamina: 1,
    Intellect: 7,
    Strength: 9,
    Defense: 3,
    HP: 101,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 6468,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6469,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 6470,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6471,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6472,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6473,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6474,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6475,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 6476,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 1,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6477,
    Stamina: 11,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 111,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6478,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6479,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6480,
    Stamina: 1,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 101,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6481,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6482,
    Stamina: 1,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 6483,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6484,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6485,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6486,
    Stamina: 2,
    Intellect: 14,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 33,
  },
  {
    Id: 6487,
    Stamina: 5,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6488,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6489,
    Stamina: 1,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 101,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6490,
    Stamina: 5,
    Intellect: 6,
    Strength: 9,
    Defense: 5,
    HP: 105,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 6491,
    Stamina: 5,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6492,
    Stamina: 8,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6493,
    Stamina: 1,
    Intellect: 9,
    Strength: 7,
    Defense: 4,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6494,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6495,
    Stamina: 9,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 109,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6496,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 33,
  },
  {
    Id: 6497,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6498,
    Stamina: 2,
    Intellect: 2,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6499,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6500,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 6501,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6502,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6503,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 109,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6504,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6505,
    Stamina: 7,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6506,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6507,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6508,
    Stamina: 1,
    Intellect: 11,
    Strength: 3,
    Defense: 9,
    HP: 101,
    Attack: 13,
    Power: 33,
  },
  {
    Id: 6509,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6510,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 6511,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6512,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6513,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6514,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 13,
    HP: 102,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 6515,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6516,
    Stamina: 10,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 110,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6517,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6518,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 12,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 6519,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 6520,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6521,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6522,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 6523,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6524,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 6525,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6526,
    Stamina: 11,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 111,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 6527,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6528,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 6529,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6530,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 6531,
    Stamina: 3,
    Intellect: 13,
    Strength: 1,
    Defense: 2,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6532,
    Stamina: 11,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 111,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6533,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6534,
    Stamina: 12,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 112,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 6535,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6536,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6537,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6538,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6539,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 6540,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 6541,
    Stamina: 8,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 108,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6542,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6543,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 6544,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 6545,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6546,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 6547,
    Stamina: 1,
    Intellect: 9,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 6548,
    Stamina: 6,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6549,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6550,
    Stamina: 5,
    Intellect: 12,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6551,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6552,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 6553,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6554,
    Stamina: 1,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 101,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6555,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6556,
    Stamina: 8,
    Intellect: 7,
    Strength: 1,
    Defense: 4,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 6557,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 6558,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6559,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 1,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6560,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 6561,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6562,
    Stamina: 8,
    Intellect: 7,
    Strength: 3,
    Defense: 1,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6563,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6564,
    Stamina: 2,
    Intellect: 3,
    Strength: 13,
    Defense: 2,
    HP: 102,
    Attack: 23,
    Power: 28,
  },
  {
    Id: 6565,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6566,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6567,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 6568,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 6569,
    Stamina: 14,
    Intellect: 4,
    Strength: 3,
    Defense: 1,
    HP: 114,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 6570,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6571,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6572,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 6573,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6574,
    Stamina: 7,
    Intellect: 2,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 6575,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6576,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 6577,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6578,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6579,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6580,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6581,
    Stamina: 4,
    Intellect: 13,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 6582,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6583,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 6584,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 6585,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 2,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6586,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6587,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6588,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6589,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6590,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6591,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6592,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 6593,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 6594,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6595,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 1,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6596,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 6597,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6598,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 6599,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6600,
    Stamina: 11,
    Intellect: 2,
    Strength: 5,
    Defense: 6,
    HP: 111,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 6601,
    Stamina: 8,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6602,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6603,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 6604,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6605,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6606,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 6607,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6608,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 6609,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6610,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6611,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6612,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 6613,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 6614,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 6615,
    Stamina: 6,
    Intellect: 10,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 6616,
    Stamina: 1,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 101,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 6617,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6618,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6619,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 6620,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 6621,
    Stamina: 10,
    Intellect: 3,
    Strength: 1,
    Defense: 6,
    HP: 110,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 6622,
    Stamina: 1,
    Intellect: 3,
    Strength: 7,
    Defense: 10,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6623,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6624,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6625,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6626,
    Stamina: 8,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6627,
    Stamina: 11,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 111,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 6628,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6629,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6630,
    Stamina: 8,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 108,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 6631,
    Stamina: 10,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 110,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6632,
    Stamina: 3,
    Intellect: 3,
    Strength: 13,
    Defense: 3,
    HP: 103,
    Attack: 23,
    Power: 29,
  },
  {
    Id: 6633,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6634,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 6635,
    Stamina: 2,
    Intellect: 9,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 34,
  },
  {
    Id: 6636,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6637,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6638,
    Stamina: 15,
    Intellect: 4,
    Strength: 1,
    Defense: 3,
    HP: 115,
    Attack: 11,
    Power: 18,
  },
  {
    Id: 6639,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6640,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6641,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6642,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6643,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6644,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6645,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6646,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6647,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6648,
    Stamina: 1,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6649,
    Stamina: 9,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 109,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 6650,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6651,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 6652,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6653,
    Stamina: 8,
    Intellect: 9,
    Strength: 8,
    Defense: 2,
    HP: 108,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 6654,
    Stamina: 1,
    Intellect: 8,
    Strength: 8,
    Defense: 4,
    HP: 101,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 6655,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 6656,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 7,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 6657,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6658,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 6659,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 6660,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6661,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6662,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6663,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 6664,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6665,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 6666,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6667,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 6668,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6669,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 13,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 6670,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6671,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6672,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 6673,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6674,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6675,
    Stamina: 2,
    Intellect: 14,
    Strength: 1,
    Defense: 2,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 6676,
    Stamina: 9,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 6677,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 6678,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6679,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6680,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6681,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 6682,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 12,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 6683,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6684,
    Stamina: 9,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 109,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6685,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6686,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 6687,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6688,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6689,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 12,
    HP: 103,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 6690,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 108,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6691,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 6692,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6693,
    Stamina: 1,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 101,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 6694,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 6695,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 6696,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 6697,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6698,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 6699,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6700,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6701,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6702,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6703,
    Stamina: 2,
    Intellect: 12,
    Strength: 5,
    Defense: 3,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 6704,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6705,
    Stamina: 2,
    Intellect: 7,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 33,
  },
  {
    Id: 6706,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6707,
    Stamina: 5,
    Intellect: 2,
    Strength: 12,
    Defense: 4,
    HP: 105,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 6708,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6709,
    Stamina: 10,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 110,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6710,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6711,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 108,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6712,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6713,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6714,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 9,
    HP: 102,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 6715,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6716,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 6717,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6718,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6719,
    Stamina: 11,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 111,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6720,
    Stamina: 9,
    Intellect: 2,
    Strength: 4,
    Defense: 3,
    HP: 109,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 6721,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 12,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6722,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 6723,
    Stamina: 7,
    Intellect: 2,
    Strength: 8,
    Defense: 2,
    HP: 107,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 6724,
    Stamina: 10,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 110,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6725,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6726,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6727,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6728,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6729,
    Stamina: 8,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 6730,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 7,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 6731,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6732,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 6733,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 6734,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6735,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 6736,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6737,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6738,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6739,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 6740,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6741,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 6742,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6743,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6744,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6745,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 23,
  },
  {
    Id: 6746,
    Stamina: 6,
    Intellect: 2,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6747,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6748,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 6749,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6750,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6751,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6752,
    Stamina: 7,
    Intellect: 10,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6753,
    Stamina: 5,
    Intellect: 11,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 6754,
    Stamina: 10,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 110,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6755,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6756,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6757,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6758,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6759,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6760,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6761,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 6762,
    Stamina: 7,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6763,
    Stamina: 5,
    Intellect: 10,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 6764,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 6765,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6766,
    Stamina: 1,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 101,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6767,
    Stamina: 4,
    Intellect: 8,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 6768,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6769,
    Stamina: 4,
    Intellect: 10,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6770,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6771,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 108,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6772,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6773,
    Stamina: 5,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 6774,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6775,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6776,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6777,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 6778,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 6779,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6780,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6781,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 5,
    HP: 101,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 6782,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6783,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6784,
    Stamina: 4,
    Intellect: 12,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 6785,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6786,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6787,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6788,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6789,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6790,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 6791,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6792,
    Stamina: 3,
    Intellect: 8,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 6793,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6794,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6795,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6796,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6797,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6798,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 13,
    HP: 105,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 6799,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6800,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 9,
    HP: 103,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 6801,
    Stamina: 7,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 107,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 6802,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6803,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 6804,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6805,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6806,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6807,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6808,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6809,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6810,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 6811,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 1,
    HP: 106,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 6812,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6813,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 6814,
    Stamina: 11,
    Intellect: 5,
    Strength: 3,
    Defense: 2,
    HP: 111,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 6815,
    Stamina: 7,
    Intellect: 5,
    Strength: 9,
    Defense: 1,
    HP: 107,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 6816,
    Stamina: 5,
    Intellect: 11,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 6817,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6818,
    Stamina: 1,
    Intellect: 6,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 6819,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6820,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6821,
    Stamina: 5,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6822,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6823,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 6824,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6825,
    Stamina: 9,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6826,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6827,
    Stamina: 9,
    Intellect: 6,
    Strength: 7,
    Defense: 1,
    HP: 109,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6828,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6829,
    Stamina: 4,
    Intellect: 11,
    Strength: 1,
    Defense: 11,
    HP: 104,
    Attack: 11,
    Power: 33,
  },
  {
    Id: 6830,
    Stamina: 9,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 109,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6831,
    Stamina: 4,
    Intellect: 6,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 6832,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6833,
    Stamina: 1,
    Intellect: 6,
    Strength: 9,
    Defense: 7,
    HP: 101,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 6834,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6835,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6836,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6837,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6838,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6839,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 6840,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6841,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6842,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6843,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 6844,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6845,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 6846,
    Stamina: 5,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6847,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 6848,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6849,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6850,
    Stamina: 10,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 110,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 6851,
    Stamina: 5,
    Intellect: 12,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6852,
    Stamina: 2,
    Intellect: 7,
    Strength: 1,
    Defense: 14,
    HP: 102,
    Attack: 11,
    Power: 32,
  },
  {
    Id: 6853,
    Stamina: 5,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 105,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 6854,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 6855,
    Stamina: 10,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 110,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6856,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 6857,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6858,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 106,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6859,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6860,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 6861,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6862,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6863,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6864,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6865,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6866,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6867,
    Stamina: 3,
    Intellect: 9,
    Strength: 10,
    Defense: 7,
    HP: 103,
    Attack: 20,
    Power: 36,
  },
  {
    Id: 6868,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 6869,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6870,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 6871,
    Stamina: 3,
    Intellect: 12,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 6872,
    Stamina: 8,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 108,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6873,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 6874,
    Stamina: 5,
    Intellect: 10,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 6875,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6876,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6877,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6878,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 6879,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 107,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 6880,
    Stamina: 6,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6881,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 6882,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6883,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6884,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6885,
    Stamina: 1,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 101,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 6886,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 6887,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6888,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6889,
    Stamina: 4,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6890,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6891,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 6892,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6893,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 7,
    HP: 105,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 6894,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6895,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 6896,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6897,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6898,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 6899,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 6900,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6901,
    Stamina: 7,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 107,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 6902,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6903,
    Stamina: 2,
    Intellect: 3,
    Strength: 15,
    Defense: 3,
    HP: 102,
    Attack: 25,
    Power: 31,
  },
  {
    Id: 6904,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6905,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6906,
    Stamina: 2,
    Intellect: 11,
    Strength: 1,
    Defense: 6,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 6907,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6908,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 6909,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6910,
    Stamina: 2,
    Intellect: 2,
    Strength: 5,
    Defense: 13,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 6911,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 6912,
    Stamina: 3,
    Intellect: 11,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 6913,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 6914,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6915,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6916,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6917,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6918,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 6919,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 6920,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6921,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 6922,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 6923,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 6924,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6925,
    Stamina: 1,
    Intellect: 5,
    Strength: 12,
    Defense: 3,
    HP: 101,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 6926,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 6927,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 6928,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 6929,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6930,
    Stamina: 3,
    Intellect: 14,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 6931,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 6932,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 6933,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 6934,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6935,
    Stamina: 4,
    Intellect: 2,
    Strength: 2,
    Defense: 12,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6936,
    Stamina: 8,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 108,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6937,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 7,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 6938,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 6939,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 6940,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 109,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6941,
    Stamina: 6,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 6942,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 6943,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6944,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 6945,
    Stamina: 9,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 109,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 6946,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 6947,
    Stamina: 6,
    Intellect: 2,
    Strength: 10,
    Defense: 2,
    HP: 106,
    Attack: 20,
    Power: 24,
  },
  {
    Id: 6948,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 6949,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 6950,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 11,
    HP: 105,
    Attack: 14,
    Power: 35,
  },
  {
    Id: 6951,
    Stamina: 10,
    Intellect: 2,
    Strength: 5,
    Defense: 2,
    HP: 110,
    Attack: 15,
    Power: 19,
  },
  {
    Id: 6952,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 6953,
    Stamina: 8,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 108,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6954,
    Stamina: 1,
    Intellect: 4,
    Strength: 2,
    Defense: 13,
    HP: 101,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 6955,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 6956,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6957,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 6958,
    Stamina: 7,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 6959,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 6960,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 6961,
    Stamina: 2,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 6962,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 6963,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6964,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 6965,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 6966,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 106,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 6967,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 6968,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 6969,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 6970,
    Stamina: 2,
    Intellect: 6,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 6971,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 1,
    HP: 110,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 6972,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 6973,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 6974,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 6975,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 1,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6976,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 6977,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 6978,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 6979,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 6980,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 6981,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 6982,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 6983,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 106,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 6984,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 6985,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 6986,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 6987,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 6988,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 6989,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 107,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 6990,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 6991,
    Stamina: 6,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6992,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 109,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 6993,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 6994,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 6995,
    Stamina: 12,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 112,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 6996,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 12,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 6997,
    Stamina: 9,
    Intellect: 3,
    Strength: 1,
    Defense: 6,
    HP: 109,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 6998,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 6999,
    Stamina: 1,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7000,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 7001,
    Stamina: 7,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7002,
    Stamina: 6,
    Intellect: 10,
    Strength: 3,
    Defense: 2,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7003,
    Stamina: 6,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 106,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 7004,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7005,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7006,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 7007,
    Stamina: 2,
    Intellect: 5,
    Strength: 1,
    Defense: 12,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 7008,
    Stamina: 8,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7009,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 7,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 7010,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7011,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 105,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7012,
    Stamina: 6,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7013,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7014,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7015,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7016,
    Stamina: 11,
    Intellect: 2,
    Strength: 4,
    Defense: 3,
    HP: 111,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 7017,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7018,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7019,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7020,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 7021,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7022,
    Stamina: 3,
    Intellect: 9,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 7023,
    Stamina: 9,
    Intellect: 4,
    Strength: 1,
    Defense: 4,
    HP: 109,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 7024,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7025,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7026,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7027,
    Stamina: 6,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 106,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 7028,
    Stamina: 9,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 109,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7029,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7030,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7031,
    Stamina: 7,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7032,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7033,
    Stamina: 4,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 7034,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 7035,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 7036,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 7037,
    Stamina: 1,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7038,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7039,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7040,
    Stamina: 6,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 7041,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7042,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 7043,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 105,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7044,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7045,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 7046,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7047,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7048,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7049,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 7050,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 7051,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 7052,
    Stamina: 6,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7053,
    Stamina: 2,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7054,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 7055,
    Stamina: 5,
    Intellect: 10,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 7056,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7057,
    Stamina: 9,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 109,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 7058,
    Stamina: 5,
    Intellect: 2,
    Strength: 13,
    Defense: 2,
    HP: 105,
    Attack: 23,
    Power: 27,
  },
  {
    Id: 7059,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7060,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7061,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7062,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7063,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 7064,
    Stamina: 8,
    Intellect: 4,
    Strength: 1,
    Defense: 7,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 7065,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 109,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7066,
    Stamina: 3,
    Intellect: 13,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7067,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7068,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7069,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7070,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7071,
    Stamina: 3,
    Intellect: 7,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 7072,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 7073,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7074,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7075,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 7076,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 12,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7077,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 7078,
    Stamina: 6,
    Intellect: 7,
    Strength: 9,
    Defense: 7,
    HP: 106,
    Attack: 19,
    Power: 33,
  },
  {
    Id: 7079,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 7080,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 32,
  },
  {
    Id: 7081,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7082,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7083,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7084,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7085,
    Stamina: 8,
    Intellect: 5,
    Strength: 1,
    Defense: 7,
    HP: 108,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 7086,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7087,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 7088,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7089,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7090,
    Stamina: 5,
    Intellect: 3,
    Strength: 10,
    Defense: 8,
    HP: 105,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 7091,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7092,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7093,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 7094,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7095,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7096,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 1,
    HP: 109,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 7097,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 7098,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7099,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7100,
    Stamina: 6,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 106,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 7101,
    Stamina: 6,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 7102,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 7103,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7104,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 7105,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 7106,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7107,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7108,
    Stamina: 8,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 108,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 7109,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 3,
    HP: 106,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 7110,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7111,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7112,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7113,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 7114,
    Stamina: 5,
    Intellect: 3,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 7115,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7116,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 7117,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7118,
    Stamina: 5,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7119,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 7120,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 7121,
    Stamina: 3,
    Intellect: 4,
    Strength: 12,
    Defense: 2,
    HP: 103,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 7122,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7123,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 7124,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 7125,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 107,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7126,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 108,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7127,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7128,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 7129,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7130,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 7131,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 109,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7132,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7133,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7134,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7135,
    Stamina: 2,
    Intellect: 2,
    Strength: 4,
    Defense: 13,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7136,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7137,
    Stamina: 4,
    Intellect: 13,
    Strength: 1,
    Defense: 2,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7138,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7139,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7140,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7141,
    Stamina: 11,
    Intellect: 4,
    Strength: 1,
    Defense: 7,
    HP: 111,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 7142,
    Stamina: 1,
    Intellect: 5,
    Strength: 12,
    Defense: 2,
    HP: 101,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 7143,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 7144,
    Stamina: 1,
    Intellect: 9,
    Strength: 6,
    Defense: 1,
    HP: 101,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7145,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7146,
    Stamina: 2,
    Intellect: 2,
    Strength: 12,
    Defense: 5,
    HP: 102,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 7147,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 110,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7148,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 108,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 7149,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7150,
    Stamina: 7,
    Intellect: 9,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 7151,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 7152,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7153,
    Stamina: 1,
    Intellect: 9,
    Strength: 6,
    Defense: 6,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 7154,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 7155,
    Stamina: 5,
    Intellect: 9,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 7156,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 110,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 7157,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7158,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 107,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7159,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7160,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 7161,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 12,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7162,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7163,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7164,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7165,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7166,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7167,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7168,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7169,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7170,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 7171,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7172,
    Stamina: 6,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7173,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 7174,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7175,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7176,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7177,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7178,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 7179,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 10,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7180,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7181,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 7182,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7183,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 7184,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7185,
    Stamina: 8,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 108,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7186,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7187,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7188,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7189,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 11,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7190,
    Stamina: 7,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 107,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7191,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7192,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7193,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7194,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 7195,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7196,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7197,
    Stamina: 6,
    Intellect: 11,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7198,
    Stamina: 8,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 108,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 7199,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7200,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7201,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 7202,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 9,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7203,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7204,
    Stamina: 4,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7205,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7206,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7207,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 7208,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7209,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 107,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 7210,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7211,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7212,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7213,
    Stamina: 2,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 7214,
    Stamina: 3,
    Intellect: 2,
    Strength: 14,
    Defense: 3,
    HP: 103,
    Attack: 24,
    Power: 29,
  },
  {
    Id: 7215,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7216,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7217,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7218,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7219,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7220,
    Stamina: 1,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 101,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7221,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 13,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7222,
    Stamina: 7,
    Intellect: 8,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 7223,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 108,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7224,
    Stamina: 2,
    Intellect: 9,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 7225,
    Stamina: 11,
    Intellect: 3,
    Strength: 1,
    Defense: 6,
    HP: 111,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 7226,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7227,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7228,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 7229,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 7230,
    Stamina: 6,
    Intellect: 13,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 7231,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7232,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7233,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7234,
    Stamina: 2,
    Intellect: 6,
    Strength: 13,
    Defense: 2,
    HP: 102,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 7235,
    Stamina: 6,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7236,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 7237,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7238,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7239,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7240,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 7241,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 106,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7242,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7243,
    Stamina: 4,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 104,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 7244,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 7245,
    Stamina: 3,
    Intellect: 7,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7246,
    Stamina: 3,
    Intellect: 2,
    Strength: 12,
    Defense: 4,
    HP: 103,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 7247,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7248,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7249,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 7250,
    Stamina: 4,
    Intellect: 13,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7251,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 7252,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 7253,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 7254,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7255,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7256,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7257,
    Stamina: 11,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 111,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 7258,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7259,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7260,
    Stamina: 4,
    Intellect: 3,
    Strength: 16,
    Defense: 3,
    HP: 104,
    Attack: 26,
    Power: 32,
  },
  {
    Id: 7261,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 7262,
    Stamina: 2,
    Intellect: 9,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 7263,
    Stamina: 4,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7264,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7265,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7266,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 7267,
    Stamina: 7,
    Intellect: 9,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 7268,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7269,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7270,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 7271,
    Stamina: 9,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7272,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7273,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 7274,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7275,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7276,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 11,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 7277,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7278,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7279,
    Stamina: 7,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7280,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7281,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7282,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 7283,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7284,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7285,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7286,
    Stamina: 6,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 106,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 7287,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 2,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7288,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 1,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7289,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7290,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7291,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7292,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7293,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7294,
    Stamina: 10,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 110,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7295,
    Stamina: 2,
    Intellect: 13,
    Strength: 3,
    Defense: 3,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7296,
    Stamina: 1,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7297,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7298,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7299,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7300,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 7301,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 11,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7302,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 7303,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7304,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 7305,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 7306,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7307,
    Stamina: 1,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 101,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7308,
    Stamina: 5,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 7309,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7310,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7311,
    Stamina: 9,
    Intellect: 2,
    Strength: 2,
    Defense: 7,
    HP: 109,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7312,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7313,
    Stamina: 3,
    Intellect: 13,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 7314,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7315,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7316,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7317,
    Stamina: 9,
    Intellect: 2,
    Strength: 2,
    Defense: 3,
    HP: 109,
    Attack: 12,
    Power: 17,
  },
  {
    Id: 7318,
    Stamina: 8,
    Intellect: 13,
    Strength: 3,
    Defense: 1,
    HP: 108,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7319,
    Stamina: 7,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7320,
    Stamina: 5,
    Intellect: 4,
    Strength: 12,
    Defense: 3,
    HP: 105,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 7321,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 7322,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7323,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7324,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7325,
    Stamina: 4,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 7326,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7327,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 7328,
    Stamina: 1,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7329,
    Stamina: 8,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 108,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7330,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 7331,
    Stamina: 7,
    Intellect: 9,
    Strength: 5,
    Defense: 1,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7332,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7333,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7334,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7335,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7336,
    Stamina: 8,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 108,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7337,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 109,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 7338,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7339,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7340,
    Stamina: 7,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 107,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 7341,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7342,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7343,
    Stamina: 9,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 109,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7344,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7345,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7346,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7347,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7348,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7349,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7350,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7351,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 7352,
    Stamina: 5,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7353,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 7354,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7355,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 7356,
    Stamina: 6,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7357,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 7358,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7359,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7360,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7361,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7362,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7363,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7364,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 7365,
    Stamina: 7,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7366,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 7367,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 7368,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7369,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7370,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 7371,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7372,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7373,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7374,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7375,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7376,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7377,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 3,
    HP: 102,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 7378,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7379,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7380,
    Stamina: 11,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 111,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7381,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 7382,
    Stamina: 9,
    Intellect: 6,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 7383,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7384,
    Stamina: 7,
    Intellect: 10,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7385,
    Stamina: 7,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7386,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7387,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 7388,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7389,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7390,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7391,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 7392,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7393,
    Stamina: 10,
    Intellect: 5,
    Strength: 1,
    Defense: 7,
    HP: 110,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 7394,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7395,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7396,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7397,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7398,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 7399,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7400,
    Stamina: 10,
    Intellect: 7,
    Strength: 1,
    Defense: 2,
    HP: 110,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 7401,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7402,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7403,
    Stamina: 3,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 7404,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7405,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7406,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 13,
    HP: 102,
    Attack: 14,
    Power: 33,
  },
  {
    Id: 7407,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7408,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7409,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7410,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7411,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 7412,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7413,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7414,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 7415,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 7416,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 7417,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7418,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 2,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7419,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7420,
    Stamina: 3,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7421,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7422,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7423,
    Stamina: 8,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7424,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7425,
    Stamina: 7,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7426,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 7427,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7428,
    Stamina: 1,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 7429,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7430,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7431,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7432,
    Stamina: 2,
    Intellect: 11,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 7433,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 109,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7434,
    Stamina: 4,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 7435,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7436,
    Stamina: 10,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 110,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7437,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 7438,
    Stamina: 6,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 106,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 7439,
    Stamina: 5,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7440,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7441,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 7442,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7443,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7444,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7445,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7446,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 7447,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7448,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7449,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 7450,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7451,
    Stamina: 1,
    Intellect: 11,
    Strength: 2,
    Defense: 3,
    HP: 101,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7452,
    Stamina: 3,
    Intellect: 8,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 7453,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 7454,
    Stamina: 7,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 107,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 7455,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7456,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7457,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 7458,
    Stamina: 1,
    Intellect: 8,
    Strength: 13,
    Defense: 3,
    HP: 101,
    Attack: 23,
    Power: 34,
  },
  {
    Id: 7459,
    Stamina: 7,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 7460,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7461,
    Stamina: 3,
    Intellect: 2,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7462,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7463,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7464,
    Stamina: 6,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7465,
    Stamina: 8,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7466,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7467,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7468,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 2,
    HP: 104,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7469,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7470,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 7471,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 7472,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7473,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7474,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 7,
    HP: 104,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 7475,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 108,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7476,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7477,
    Stamina: 7,
    Intellect: 4,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 7478,
    Stamina: 5,
    Intellect: 5,
    Strength: 12,
    Defense: 1,
    HP: 105,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 7479,
    Stamina: 7,
    Intellect: 5,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 7480,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 7481,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 1,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7482,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7483,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7484,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7485,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7486,
    Stamina: 9,
    Intellect: 5,
    Strength: 1,
    Defense: 3,
    HP: 109,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 7487,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 7488,
    Stamina: 10,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 110,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 7489,
    Stamina: 3,
    Intellect: 9,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7490,
    Stamina: 7,
    Intellect: 2,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 7491,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 10,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 7492,
    Stamina: 6,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7493,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7494,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7495,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7496,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 7497,
    Stamina: 9,
    Intellect: 6,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7498,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7499,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7500,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7501,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7502,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 12,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 7503,
    Stamina: 10,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 110,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7504,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7505,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7506,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7507,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 105,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7508,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7509,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7510,
    Stamina: 10,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 110,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7511,
    Stamina: 4,
    Intellect: 2,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7512,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7513,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 7514,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7515,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7516,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 7517,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 7518,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7519,
    Stamina: 5,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 105,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 7520,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7521,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7522,
    Stamina: 10,
    Intellect: 6,
    Strength: 1,
    Defense: 3,
    HP: 110,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 7523,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7524,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7525,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7526,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 9,
    HP: 102,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 7527,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7528,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7529,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7530,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7531,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 7532,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7533,
    Stamina: 11,
    Intellect: 2,
    Strength: 1,
    Defense: 3,
    HP: 111,
    Attack: 11,
    Power: 16,
  },
  {
    Id: 7534,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 7535,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7536,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 7537,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 7538,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7539,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7540,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 12,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7541,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 7542,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7543,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 7544,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 7545,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7546,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7547,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7548,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7549,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7550,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 1,
    HP: 104,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 7551,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 7552,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7553,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 7554,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7555,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 7556,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7557,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 11,
    HP: 107,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 7558,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7559,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7560,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 7561,
    Stamina: 2,
    Intellect: 4,
    Strength: 1,
    Defense: 14,
    HP: 102,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 7562,
    Stamina: 2,
    Intellect: 7,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7563,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7564,
    Stamina: 4,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 7565,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 7566,
    Stamina: 2,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 7567,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 7568,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 107,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7569,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 13,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7570,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7571,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7572,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7573,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7574,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7575,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7576,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7577,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7578,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 7579,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7580,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 106,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7581,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7582,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 7583,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7584,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7585,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7586,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 7587,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7588,
    Stamina: 3,
    Intellect: 10,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 7589,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7590,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7591,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7592,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7593,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7594,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 7595,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7596,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 7597,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7598,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 7599,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7600,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7601,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 7602,
    Stamina: 2,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 7603,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 7604,
    Stamina: 9,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 109,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 7605,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 7606,
    Stamina: 2,
    Intellect: 5,
    Strength: 14,
    Defense: 9,
    HP: 102,
    Attack: 24,
    Power: 38,
  },
  {
    Id: 7607,
    Stamina: 1,
    Intellect: 8,
    Strength: 2,
    Defense: 8,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7608,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7609,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 106,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7610,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7611,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7612,
    Stamina: 2,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 7613,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 7614,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7615,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7616,
    Stamina: 10,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 110,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7617,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7618,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 7619,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7620,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 7621,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7622,
    Stamina: 4,
    Intellect: 12,
    Strength: 1,
    Defense: 2,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7623,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 7624,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7625,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7626,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 7627,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7628,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7629,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7630,
    Stamina: 7,
    Intellect: 8,
    Strength: 2,
    Defense: 1,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 7631,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7632,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7633,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7634,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 7635,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7636,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 101,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7637,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7638,
    Stamina: 4,
    Intellect: 5,
    Strength: 12,
    Defense: 4,
    HP: 104,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 7639,
    Stamina: 1,
    Intellect: 7,
    Strength: 3,
    Defense: 15,
    HP: 101,
    Attack: 13,
    Power: 35,
  },
  {
    Id: 7640,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7641,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7642,
    Stamina: 1,
    Intellect: 2,
    Strength: 13,
    Defense: 5,
    HP: 101,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 7643,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7644,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7645,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7646,
    Stamina: 1,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7647,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7648,
    Stamina: 9,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 109,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7649,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 7650,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 12,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 7651,
    Stamina: 2,
    Intellect: 12,
    Strength: 5,
    Defense: 1,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7652,
    Stamina: 12,
    Intellect: 6,
    Strength: 2,
    Defense: 1,
    HP: 112,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 7653,
    Stamina: 3,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7654,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7655,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 7656,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7657,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 7658,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 7659,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7660,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7661,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7662,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 7663,
    Stamina: 3,
    Intellect: 10,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7664,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 7665,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7666,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7667,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 2,
    HP: 109,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 7668,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7669,
    Stamina: 1,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7670,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7671,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 110,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 7672,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7673,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7674,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 7675,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7676,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7677,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 7678,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7679,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7680,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 7681,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 7682,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7683,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 7684,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7685,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 108,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7686,
    Stamina: 7,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 7687,
    Stamina: 3,
    Intellect: 13,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7688,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7689,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7690,
    Stamina: 2,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 7691,
    Stamina: 1,
    Intellect: 11,
    Strength: 11,
    Defense: 2,
    HP: 101,
    Attack: 21,
    Power: 34,
  },
  {
    Id: 7692,
    Stamina: 3,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7693,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7694,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7695,
    Stamina: 1,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7696,
    Stamina: 2,
    Intellect: 11,
    Strength: 6,
    Defense: 2,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7697,
    Stamina: 2,
    Intellect: 5,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 7698,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7699,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 10,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 7700,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7701,
    Stamina: 3,
    Intellect: 12,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7702,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7703,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7704,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7705,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7706,
    Stamina: 4,
    Intellect: 13,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 7707,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7708,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7709,
    Stamina: 6,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7710,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7711,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7712,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 7713,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7714,
    Stamina: 4,
    Intellect: 5,
    Strength: 11,
    Defense: 2,
    HP: 104,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 7715,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7716,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7717,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7718,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7719,
    Stamina: 8,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 7720,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7721,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7722,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 7723,
    Stamina: 7,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 7724,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7725,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7726,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 7727,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7728,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7729,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 106,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7730,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7731,
    Stamina: 4,
    Intellect: 14,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 37,
  },
  {
    Id: 7732,
    Stamina: 3,
    Intellect: 9,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 7733,
    Stamina: 3,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 7734,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 7735,
    Stamina: 8,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 7736,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 1,
    HP: 105,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 7737,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7738,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7739,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7740,
    Stamina: 1,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 101,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7741,
    Stamina: 11,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 111,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 7742,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7743,
    Stamina: 1,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 7744,
    Stamina: 1,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 101,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7745,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7746,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7747,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7748,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7749,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7750,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7751,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7752,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 7753,
    Stamina: 5,
    Intellect: 10,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 7754,
    Stamina: 2,
    Intellect: 6,
    Strength: 12,
    Defense: 2,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 7755,
    Stamina: 2,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7756,
    Stamina: 4,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 7757,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7758,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7759,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7760,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7761,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 7762,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7763,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 7764,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7765,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 7766,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7767,
    Stamina: 6,
    Intellect: 2,
    Strength: 1,
    Defense: 11,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 7768,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7769,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7770,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7771,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7772,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 9,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7773,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 7774,
    Stamina: 9,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 109,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7775,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 108,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7776,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7777,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7778,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7779,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7780,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 7781,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7782,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 11,
    HP: 105,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 7783,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 6,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 7784,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7785,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 107,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 7786,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7787,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7788,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7789,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 7790,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7791,
    Stamina: 2,
    Intellect: 9,
    Strength: 1,
    Defense: 5,
    HP: 102,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7792,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7793,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7794,
    Stamina: 1,
    Intellect: 3,
    Strength: 9,
    Defense: 6,
    HP: 101,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 7795,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 11,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7796,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7797,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 7798,
    Stamina: 3,
    Intellect: 9,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 7799,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7800,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7801,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7802,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 4,
    HP: 104,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 7803,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 101,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 7804,
    Stamina: 2,
    Intellect: 10,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 7805,
    Stamina: 4,
    Intellect: 6,
    Strength: 11,
    Defense: 5,
    HP: 104,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 7806,
    Stamina: 5,
    Intellect: 11,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7807,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7808,
    Stamina: 4,
    Intellect: 12,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7809,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7810,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7811,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7812,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7813,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7814,
    Stamina: 10,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 110,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7815,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 1,
    HP: 109,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 7816,
    Stamina: 8,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 108,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7817,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 12,
    HP: 102,
    Attack: 12,
    Power: 33,
  },
  {
    Id: 7818,
    Stamina: 8,
    Intellect: 8,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 7819,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7820,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 7821,
    Stamina: 4,
    Intellect: 4,
    Strength: 13,
    Defense: 4,
    HP: 104,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 7822,
    Stamina: 5,
    Intellect: 13,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7823,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 7824,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7825,
    Stamina: 8,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7826,
    Stamina: 1,
    Intellect: 5,
    Strength: 13,
    Defense: 3,
    HP: 101,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 7827,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7828,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 7829,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 13,
    HP: 103,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 7830,
    Stamina: 3,
    Intellect: 7,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 7831,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7832,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 7833,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7834,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7835,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 13,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 7836,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 7837,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7838,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 7839,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7840,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 1,
    HP: 105,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 7841,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 7842,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7843,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7844,
    Stamina: 4,
    Intellect: 12,
    Strength: 4,
    Defense: 1,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7845,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7846,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7847,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7848,
    Stamina: 12,
    Intellect: 4,
    Strength: 2,
    Defense: 3,
    HP: 112,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 7849,
    Stamina: 1,
    Intellect: 7,
    Strength: 10,
    Defense: 6,
    HP: 101,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 7850,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 7851,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7852,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7853,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7854,
    Stamina: 3,
    Intellect: 15,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 7855,
    Stamina: 1,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7856,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 109,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7857,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7858,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 7859,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7860,
    Stamina: 9,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 109,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 7861,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7862,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 7863,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 7864,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 108,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 7865,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7866,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 6,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 7867,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7868,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7869,
    Stamina: 4,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 7870,
    Stamina: 2,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7871,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7872,
    Stamina: 6,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 106,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 7873,
    Stamina: 1,
    Intellect: 5,
    Strength: 5,
    Defense: 9,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7874,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7875,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 7876,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7877,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7878,
    Stamina: 1,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 101,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7879,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7880,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 7881,
    Stamina: 1,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 101,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7882,
    Stamina: 9,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 109,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 7883,
    Stamina: 4,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 7884,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 7885,
    Stamina: 12,
    Intellect: 3,
    Strength: 1,
    Defense: 8,
    HP: 112,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 7886,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 7887,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7888,
    Stamina: 5,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7889,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7890,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 7891,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7892,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7893,
    Stamina: 1,
    Intellect: 8,
    Strength: 2,
    Defense: 10,
    HP: 101,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 7894,
    Stamina: 1,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7895,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7896,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 11,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7897,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 109,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 7898,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7899,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7900,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7901,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 7902,
    Stamina: 9,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 109,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7903,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 109,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 7904,
    Stamina: 5,
    Intellect: 12,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7905,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 7906,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 7907,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 7908,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 7909,
    Stamina: 4,
    Intellect: 7,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 7910,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7911,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 7912,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7913,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 13,
    HP: 104,
    Attack: 12,
    Power: 33,
  },
  {
    Id: 7914,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 7915,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7916,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7917,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7918,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 7919,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 10,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 7920,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7921,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 12,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 7922,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7923,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7924,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 9,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7925,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 106,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7926,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7927,
    Stamina: 11,
    Intellect: 5,
    Strength: 1,
    Defense: 3,
    HP: 111,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 7928,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7929,
    Stamina: 2,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7930,
    Stamina: 8,
    Intellect: 2,
    Strength: 7,
    Defense: 1,
    HP: 108,
    Attack: 17,
    Power: 20,
  },
  {
    Id: 7931,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7932,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7933,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 7934,
    Stamina: 1,
    Intellect: 7,
    Strength: 7,
    Defense: 8,
    HP: 101,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 7935,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7936,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7937,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7938,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7939,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 7940,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7941,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 7942,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 7943,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 7944,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7945,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 7946,
    Stamina: 5,
    Intellect: 10,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 7947,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 7948,
    Stamina: 9,
    Intellect: 2,
    Strength: 1,
    Defense: 7,
    HP: 109,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 7949,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 7950,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 7951,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 7952,
    Stamina: 1,
    Intellect: 10,
    Strength: 1,
    Defense: 10,
    HP: 101,
    Attack: 11,
    Power: 31,
  },
  {
    Id: 7953,
    Stamina: 2,
    Intellect: 2,
    Strength: 13,
    Defense: 5,
    HP: 102,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 7954,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7955,
    Stamina: 6,
    Intellect: 11,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 7956,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 7957,
    Stamina: 6,
    Intellect: 11,
    Strength: 3,
    Defense: 1,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7958,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 7959,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 7960,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 7961,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 7962,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7963,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 7964,
    Stamina: 1,
    Intellect: 4,
    Strength: 10,
    Defense: 7,
    HP: 101,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 7965,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 106,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 7966,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 7967,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 7968,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 7969,
    Stamina: 4,
    Intellect: 14,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 7970,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 7971,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7972,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 110,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 7973,
    Stamina: 5,
    Intellect: 8,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 7974,
    Stamina: 3,
    Intellect: 13,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 7975,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 7976,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 7977,
    Stamina: 5,
    Intellect: 3,
    Strength: 12,
    Defense: 2,
    HP: 105,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 7978,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 7979,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 7980,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 7981,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 7982,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 7983,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7984,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 7985,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 7986,
    Stamina: 8,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 108,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 7987,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 7988,
    Stamina: 2,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 7989,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 7990,
    Stamina: 4,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 7991,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 7992,
    Stamina: 3,
    Intellect: 10,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 7993,
    Stamina: 2,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 7994,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 7995,
    Stamina: 5,
    Intellect: 11,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 7996,
    Stamina: 7,
    Intellect: 6,
    Strength: 7,
    Defense: 1,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 7997,
    Stamina: 4,
    Intellect: 2,
    Strength: 12,
    Defense: 3,
    HP: 104,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 7998,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 7999,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8000,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8001,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8002,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8003,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8004,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8005,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8006,
    Stamina: 2,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8007,
    Stamina: 1,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 101,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8008,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8009,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8010,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 8011,
    Stamina: 11,
    Intellect: 2,
    Strength: 6,
    Defense: 2,
    HP: 111,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 8012,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 7,
    HP: 105,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 8013,
    Stamina: 3,
    Intellect: 4,
    Strength: 13,
    Defense: 4,
    HP: 103,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 8014,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8015,
    Stamina: 14,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 114,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8016,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 8017,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8018,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 8019,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 2,
    HP: 109,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 8020,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 8021,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8022,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8023,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8024,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 8025,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 8026,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 8027,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8028,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 8029,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8030,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 8031,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 8032,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 2,
    HP: 109,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 8033,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8034,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 9,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 8035,
    Stamina: 5,
    Intellect: 11,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8036,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8037,
    Stamina: 4,
    Intellect: 2,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8038,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 108,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 8039,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 8040,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8041,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8042,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 8043,
    Stamina: 2,
    Intellect: 8,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 8044,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 8045,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8046,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8047,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 5,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 8048,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8049,
    Stamina: 6,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8050,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8051,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 8052,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 1,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8053,
    Stamina: 4,
    Intellect: 2,
    Strength: 12,
    Defense: 4,
    HP: 104,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 8054,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 8055,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8056,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8057,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 8058,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8059,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 8060,
    Stamina: 5,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 8061,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 106,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8062,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8063,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8064,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8065,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 8066,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8067,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8068,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8069,
    Stamina: 4,
    Intellect: 2,
    Strength: 12,
    Defense: 1,
    HP: 104,
    Attack: 22,
    Power: 25,
  },
  {
    Id: 8070,
    Stamina: 5,
    Intellect: 9,
    Strength: 8,
    Defense: 1,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8071,
    Stamina: 9,
    Intellect: 2,
    Strength: 5,
    Defense: 2,
    HP: 109,
    Attack: 15,
    Power: 19,
  },
  {
    Id: 8072,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 8073,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8074,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8075,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 8076,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 8077,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8078,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8079,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8080,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 5,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 8081,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 8082,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8083,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 8084,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 8085,
    Stamina: 9,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 109,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8086,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8087,
    Stamina: 1,
    Intellect: 10,
    Strength: 5,
    Defense: 8,
    HP: 101,
    Attack: 15,
    Power: 33,
  },
  {
    Id: 8088,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8089,
    Stamina: 1,
    Intellect: 8,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 8090,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8091,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8092,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 8093,
    Stamina: 6,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8094,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 1,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 8095,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8096,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 109,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 8097,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8098,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8099,
    Stamina: 7,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8100,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 8101,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 10,
    HP: 105,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 8102,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 8103,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8104,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 8105,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8106,
    Stamina: 1,
    Intellect: 10,
    Strength: 1,
    Defense: 7,
    HP: 101,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 8107,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 8108,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8109,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8110,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8111,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8112,
    Stamina: 7,
    Intellect: 11,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8113,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 8114,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8115,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 8116,
    Stamina: 2,
    Intellect: 9,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 8117,
    Stamina: 1,
    Intellect: 5,
    Strength: 2,
    Defense: 14,
    HP: 101,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 8118,
    Stamina: 1,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 101,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8119,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8120,
    Stamina: 3,
    Intellect: 6,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8121,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8122,
    Stamina: 11,
    Intellect: 5,
    Strength: 1,
    Defense: 3,
    HP: 111,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 8123,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 8124,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8125,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 8126,
    Stamina: 7,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 107,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 8127,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8128,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 8129,
    Stamina: 3,
    Intellect: 13,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 8130,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 8131,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 8132,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8133,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8134,
    Stamina: 7,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8135,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8136,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8137,
    Stamina: 2,
    Intellect: 12,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8138,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 14,
    HP: 106,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 8139,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 8140,
    Stamina: 7,
    Intellect: 6,
    Strength: 10,
    Defense: 3,
    HP: 107,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 8141,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 8142,
    Stamina: 11,
    Intellect: 8,
    Strength: 1,
    Defense: 1,
    HP: 111,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 8143,
    Stamina: 4,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 8144,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8145,
    Stamina: 7,
    Intellect: 2,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 8146,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8147,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 8148,
    Stamina: 1,
    Intellect: 10,
    Strength: 6,
    Defense: 2,
    HP: 101,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8149,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 108,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 8150,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8151,
    Stamina: 3,
    Intellect: 2,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 8152,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 8153,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 8154,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8155,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8156,
    Stamina: 1,
    Intellect: 5,
    Strength: 6,
    Defense: 11,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 8157,
    Stamina: 11,
    Intellect: 3,
    Strength: 1,
    Defense: 7,
    HP: 111,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 8158,
    Stamina: 10,
    Intellect: 5,
    Strength: 3,
    Defense: 1,
    HP: 110,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 8159,
    Stamina: 8,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 108,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8160,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 110,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 8161,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8162,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8163,
    Stamina: 6,
    Intellect: 5,
    Strength: 12,
    Defense: 3,
    HP: 106,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 8164,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 8165,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8166,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8167,
    Stamina: 1,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8168,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8169,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8170,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 7,
    HP: 102,
    Attack: 20,
    Power: 33,
  },
  {
    Id: 8171,
    Stamina: 1,
    Intellect: 5,
    Strength: 8,
    Defense: 10,
    HP: 101,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 8172,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 9,
    HP: 102,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 8173,
    Stamina: 2,
    Intellect: 3,
    Strength: 13,
    Defense: 4,
    HP: 102,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 8174,
    Stamina: 1,
    Intellect: 13,
    Strength: 2,
    Defense: 5,
    HP: 101,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 8175,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8176,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 10,
    HP: 102,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 8177,
    Stamina: 10,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 110,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8178,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8179,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8180,
    Stamina: 8,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 108,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 8181,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8182,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8183,
    Stamina: 8,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 108,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 8184,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8185,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 12,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 8186,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8187,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8188,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8189,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8190,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8191,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8192,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8193,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8194,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8195,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 8196,
    Stamina: 5,
    Intellect: 10,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 35,
  },
  {
    Id: 8197,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 8198,
    Stamina: 1,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 101,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 8199,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8200,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 109,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8201,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8202,
    Stamina: 12,
    Intellect: 3,
    Strength: 4,
    Defense: 2,
    HP: 112,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 8203,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 8204,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8205,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 8206,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 8207,
    Stamina: 4,
    Intellect: 13,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 8208,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8209,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8210,
    Stamina: 2,
    Intellect: 7,
    Strength: 1,
    Defense: 9,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 8211,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8212,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 9,
    HP: 106,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 8213,
    Stamina: 1,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 101,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8214,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 8215,
    Stamina: 5,
    Intellect: 10,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 8216,
    Stamina: 6,
    Intellect: 3,
    Strength: 10,
    Defense: 1,
    HP: 106,
    Attack: 20,
    Power: 24,
  },
  {
    Id: 8217,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 8218,
    Stamina: 1,
    Intellect: 12,
    Strength: 1,
    Defense: 7,
    HP: 101,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 8219,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8220,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8221,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8222,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8223,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 11,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8224,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8225,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8226,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 8227,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8228,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 101,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8229,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8230,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8231,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 8232,
    Stamina: 6,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 106,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 8233,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 8234,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8235,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 8236,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8237,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 8238,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8239,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 8240,
    Stamina: 6,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 106,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 8241,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 8242,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8243,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8244,
    Stamina: 8,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 108,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8245,
    Stamina: 6,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 8246,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8247,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8248,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 8,
    HP: 104,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 8249,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8250,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8251,
    Stamina: 4,
    Intellect: 7,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 8252,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 11,
    HP: 102,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 8253,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8254,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 8255,
    Stamina: 6,
    Intellect: 3,
    Strength: 11,
    Defense: 5,
    HP: 106,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 8256,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 8257,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8258,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 101,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8259,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8260,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8261,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 12,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8262,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 8263,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8264,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8265,
    Stamina: 2,
    Intellect: 9,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 8266,
    Stamina: 1,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 101,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8267,
    Stamina: 2,
    Intellect: 2,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8268,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 8269,
    Stamina: 7,
    Intellect: 3,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 8270,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8271,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 14,
    HP: 103,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 8272,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 101,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8273,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8274,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8275,
    Stamina: 2,
    Intellect: 6,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 8276,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8277,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 8278,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8279,
    Stamina: 7,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8280,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8281,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 9,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8282,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8283,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8284,
    Stamina: 7,
    Intellect: 2,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 19,
  },
  {
    Id: 8285,
    Stamina: 5,
    Intellect: 6,
    Strength: 11,
    Defense: 1,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 8286,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8287,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8288,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8289,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8290,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8291,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8292,
    Stamina: 5,
    Intellect: 5,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8293,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8294,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 8295,
    Stamina: 1,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 101,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8296,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8297,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 8298,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8299,
    Stamina: 10,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 110,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8300,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8301,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 8302,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8303,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8304,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8305,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 8306,
    Stamina: 10,
    Intellect: 5,
    Strength: 3,
    Defense: 1,
    HP: 110,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 8307,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8308,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 8309,
    Stamina: 2,
    Intellect: 2,
    Strength: 4,
    Defense: 12,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8310,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 7,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 8311,
    Stamina: 5,
    Intellect: 2,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8312,
    Stamina: 5,
    Intellect: 10,
    Strength: 8,
    Defense: 1,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 8313,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8314,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 8315,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8316,
    Stamina: 2,
    Intellect: 3,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8317,
    Stamina: 5,
    Intellect: 13,
    Strength: 1,
    Defense: 2,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8318,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8319,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8320,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 102,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 8321,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8322,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8323,
    Stamina: 1,
    Intellect: 6,
    Strength: 14,
    Defense: 3,
    HP: 101,
    Attack: 24,
    Power: 33,
  },
  {
    Id: 8324,
    Stamina: 1,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8325,
    Stamina: 10,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 110,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 8326,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8327,
    Stamina: 4,
    Intellect: 7,
    Strength: 13,
    Defense: 4,
    HP: 104,
    Attack: 23,
    Power: 34,
  },
  {
    Id: 8328,
    Stamina: 9,
    Intellect: 2,
    Strength: 1,
    Defense: 10,
    HP: 109,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 8329,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8330,
    Stamina: 5,
    Intellect: 2,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 8331,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 8332,
    Stamina: 6,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8333,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8334,
    Stamina: 1,
    Intellect: 4,
    Strength: 12,
    Defense: 4,
    HP: 101,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 8335,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8336,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 1,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8337,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 8338,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8339,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8340,
    Stamina: 1,
    Intellect: 8,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 8341,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 8342,
    Stamina: 6,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8343,
    Stamina: 8,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8344,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8345,
    Stamina: 6,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8346,
    Stamina: 2,
    Intellect: 9,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 8347,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 8348,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8349,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 110,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8350,
    Stamina: 6,
    Intellect: 8,
    Strength: 6,
    Defense: 1,
    HP: 106,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8351,
    Stamina: 5,
    Intellect: 3,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 8352,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8353,
    Stamina: 5,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 8354,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8355,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 8356,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 8357,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 8358,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8359,
    Stamina: 2,
    Intellect: 4,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 8360,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8361,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8362,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8363,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 10,
    HP: 103,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 8364,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8365,
    Stamina: 2,
    Intellect: 9,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 8366,
    Stamina: 1,
    Intellect: 6,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 8367,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8368,
    Stamina: 2,
    Intellect: 9,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 8369,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8370,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8371,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 106,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8372,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 6,
    HP: 105,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 8373,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 8374,
    Stamina: 6,
    Intellect: 13,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 8375,
    Stamina: 10,
    Intellect: 4,
    Strength: 2,
    Defense: 3,
    HP: 110,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 8376,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 8377,
    Stamina: 1,
    Intellect: 9,
    Strength: 6,
    Defense: 7,
    HP: 101,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 8378,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8379,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8380,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 12,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 8381,
    Stamina: 4,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8382,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8383,
    Stamina: 6,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 106,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 8384,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 1,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 8385,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 8386,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8387,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8388,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 8389,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 13,
    HP: 103,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 8390,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8391,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8392,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8393,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8394,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8395,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 8396,
    Stamina: 6,
    Intellect: 9,
    Strength: 7,
    Defense: 1,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8397,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 8398,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8399,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8400,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 8401,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 7,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 8402,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8403,
    Stamina: 4,
    Intellect: 8,
    Strength: 12,
    Defense: 4,
    HP: 104,
    Attack: 22,
    Power: 34,
  },
  {
    Id: 8404,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8405,
    Stamina: 1,
    Intellect: 6,
    Strength: 12,
    Defense: 3,
    HP: 101,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 8406,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8407,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8408,
    Stamina: 3,
    Intellect: 11,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 8409,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 13,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 8410,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8411,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8412,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8413,
    Stamina: 12,
    Intellect: 5,
    Strength: 2,
    Defense: 3,
    HP: 112,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 8414,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8415,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 107,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8416,
    Stamina: 2,
    Intellect: 10,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 8417,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 8418,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8419,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 105,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 8420,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8421,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8422,
    Stamina: 9,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 109,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8423,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 8424,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8425,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8426,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 8427,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 8428,
    Stamina: 9,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 109,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 8429,
    Stamina: 5,
    Intellect: 8,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 8430,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8431,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8432,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8433,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8434,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8435,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 8436,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 8437,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8438,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 8439,
    Stamina: 1,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8440,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8441,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 8442,
    Stamina: 8,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 108,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8443,
    Stamina: 8,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 8444,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8445,
    Stamina: 5,
    Intellect: 12,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8446,
    Stamina: 3,
    Intellect: 13,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8447,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8448,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8449,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 8450,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 8451,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 8452,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 8453,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8454,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8455,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8456,
    Stamina: 1,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8457,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8458,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 8459,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8460,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8461,
    Stamina: 7,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 107,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8462,
    Stamina: 6,
    Intellect: 8,
    Strength: 3,
    Defense: 1,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 8463,
    Stamina: 4,
    Intellect: 4,
    Strength: 12,
    Defense: 2,
    HP: 104,
    Attack: 22,
    Power: 28,
  },
  {
    Id: 8464,
    Stamina: 6,
    Intellect: 6,
    Strength: 10,
    Defense: 1,
    HP: 106,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8465,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 8466,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 1,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8467,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 8468,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8469,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8470,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8471,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 105,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 8472,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 2,
    HP: 107,
    Attack: 17,
    Power: 21,
  },
  {
    Id: 8473,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8474,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8475,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 10,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8476,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8477,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8478,
    Stamina: 11,
    Intellect: 2,
    Strength: 5,
    Defense: 3,
    HP: 111,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 8479,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8480,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8481,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8482,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8483,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8484,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8485,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8486,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8487,
    Stamina: 5,
    Intellect: 13,
    Strength: 4,
    Defense: 5,
    HP: 105,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 8488,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 8489,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8490,
    Stamina: 2,
    Intellect: 13,
    Strength: 4,
    Defense: 1,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8491,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 8492,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 109,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8493,
    Stamina: 10,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 110,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 8494,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8495,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8496,
    Stamina: 3,
    Intellect: 7,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 8497,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 108,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 8498,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 8499,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8500,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 8501,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 8502,
    Stamina: 1,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 101,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8503,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 8504,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 12,
    HP: 102,
    Attack: 20,
    Power: 36,
  },
  {
    Id: 8505,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8506,
    Stamina: 1,
    Intellect: 10,
    Strength: 3,
    Defense: 8,
    HP: 101,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 8507,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 8508,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 7,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8509,
    Stamina: 11,
    Intellect: 4,
    Strength: 3,
    Defense: 2,
    HP: 111,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 8510,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 107,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8511,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8512,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 8513,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8514,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 8515,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 8516,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 8517,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8518,
    Stamina: 7,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 107,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 8519,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8520,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8521,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 5,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 8522,
    Stamina: 5,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 105,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8523,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 8524,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8525,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8526,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 8527,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8528,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8529,
    Stamina: 3,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8530,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 9,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 8531,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8532,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 6,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 8533,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 8534,
    Stamina: 7,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8535,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8536,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 8537,
    Stamina: 1,
    Intellect: 9,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 8538,
    Stamina: 3,
    Intellect: 13,
    Strength: 1,
    Defense: 2,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8539,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8540,
    Stamina: 5,
    Intellect: 12,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8541,
    Stamina: 6,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8542,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 8543,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 8544,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8545,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 8546,
    Stamina: 2,
    Intellect: 8,
    Strength: 10,
    Defense: 1,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 8547,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8548,
    Stamina: 3,
    Intellect: 12,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 8549,
    Stamina: 5,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8550,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 8551,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 8552,
    Stamina: 2,
    Intellect: 4,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8553,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 8554,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 8555,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8556,
    Stamina: 2,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8557,
    Stamina: 4,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8558,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 5,
    HP: 103,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 8559,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 10,
    HP: 105,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 8560,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8561,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 8562,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8563,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 5,
    HP: 103,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 8564,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8565,
    Stamina: 5,
    Intellect: 5,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 8566,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8567,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8568,
    Stamina: 6,
    Intellect: 3,
    Strength: 1,
    Defense: 6,
    HP: 106,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 8569,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 8570,
    Stamina: 4,
    Intellect: 11,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8571,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8572,
    Stamina: 10,
    Intellect: 5,
    Strength: 3,
    Defense: 5,
    HP: 110,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8573,
    Stamina: 3,
    Intellect: 3,
    Strength: 1,
    Defense: 13,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 8574,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 8575,
    Stamina: 2,
    Intellect: 6,
    Strength: 2,
    Defense: 14,
    HP: 102,
    Attack: 12,
    Power: 32,
  },
  {
    Id: 8576,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8577,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8578,
    Stamina: 1,
    Intellect: 3,
    Strength: 5,
    Defense: 12,
    HP: 101,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 8579,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8580,
    Stamina: 9,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 109,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8581,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8582,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8583,
    Stamina: 4,
    Intellect: 13,
    Strength: 5,
    Defense: 4,
    HP: 104,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 8584,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8585,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8586,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 8587,
    Stamina: 1,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 8588,
    Stamina: 1,
    Intellect: 4,
    Strength: 11,
    Defense: 7,
    HP: 101,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 8589,
    Stamina: 1,
    Intellect: 8,
    Strength: 3,
    Defense: 8,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8590,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8591,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8592,
    Stamina: 6,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8593,
    Stamina: 8,
    Intellect: 7,
    Strength: 5,
    Defense: 1,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 8594,
    Stamina: 2,
    Intellect: 4,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 8595,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 1,
    HP: 105,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 8596,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 8597,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8598,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8599,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 8600,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 8601,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8602,
    Stamina: 1,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8603,
    Stamina: 6,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8604,
    Stamina: 2,
    Intellect: 5,
    Strength: 11,
    Defense: 8,
    HP: 102,
    Attack: 21,
    Power: 34,
  },
  {
    Id: 8605,
    Stamina: 10,
    Intellect: 3,
    Strength: 1,
    Defense: 9,
    HP: 110,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 8606,
    Stamina: 9,
    Intellect: 4,
    Strength: 1,
    Defense: 11,
    HP: 109,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8607,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8608,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 8609,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 8,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 8610,
    Stamina: 5,
    Intellect: 9,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 8611,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8612,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8613,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8614,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8615,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8616,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 8617,
    Stamina: 5,
    Intellect: 11,
    Strength: 13,
    Defense: 17,
    HP: 105,
    Attack: 23,
    Power: 51,
  },
  {
    Id: 8618,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8619,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8620,
    Stamina: 8,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 108,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8621,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8622,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8623,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8624,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8625,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8626,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8627,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 8628,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8629,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8630,
    Stamina: 2,
    Intellect: 13,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 8631,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8632,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 1,
    HP: 103,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 8633,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8634,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 8635,
    Stamina: 9,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 109,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8636,
    Stamina: 2,
    Intellect: 7,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 8637,
    Stamina: 2,
    Intellect: 2,
    Strength: 14,
    Defense: 3,
    HP: 102,
    Attack: 24,
    Power: 29,
  },
  {
    Id: 8638,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8639,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8640,
    Stamina: 7,
    Intellect: 2,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 8641,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 8642,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8643,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 8644,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8645,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8646,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 8647,
    Stamina: 4,
    Intellect: 13,
    Strength: 2,
    Defense: 2,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8648,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8649,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8650,
    Stamina: 1,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 101,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8651,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8652,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8653,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8654,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 106,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8655,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8656,
    Stamina: 11,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 111,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 8657,
    Stamina: 3,
    Intellect: 8,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8658,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 8659,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 1,
    HP: 104,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 8660,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8661,
    Stamina: 5,
    Intellect: 10,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8662,
    Stamina: 1,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 101,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8663,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 8664,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 8665,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 8666,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8667,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8668,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 8669,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 8670,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8671,
    Stamina: 9,
    Intellect: 9,
    Strength: 2,
    Defense: 2,
    HP: 109,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8672,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 10,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 8673,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8674,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8675,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 8676,
    Stamina: 9,
    Intellect: 12,
    Strength: 3,
    Defense: 1,
    HP: 109,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8677,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 2,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 8678,
    Stamina: 6,
    Intellect: 10,
    Strength: 4,
    Defense: 1,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8679,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 8680,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 8681,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 10,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 8682,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8683,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8684,
    Stamina: 9,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 109,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8685,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 8686,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8687,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8688,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 8689,
    Stamina: 4,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8690,
    Stamina: 6,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8691,
    Stamina: 8,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 108,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8692,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8693,
    Stamina: 6,
    Intellect: 10,
    Strength: 3,
    Defense: 1,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8694,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8695,
    Stamina: 2,
    Intellect: 15,
    Strength: 3,
    Defense: 1,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8696,
    Stamina: 4,
    Intellect: 13,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8697,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8698,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 9,
    HP: 106,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 8699,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8700,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8701,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8702,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 8703,
    Stamina: 2,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 8704,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8705,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 8706,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8707,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8708,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 12,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8709,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8710,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8711,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8712,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8713,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 8714,
    Stamina: 3,
    Intellect: 9,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 8715,
    Stamina: 9,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 109,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8716,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 6,
    HP: 101,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 8717,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 8718,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8719,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 8720,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8721,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8722,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8723,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 12,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8724,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8725,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 8726,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 7,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8727,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 8728,
    Stamina: 2,
    Intellect: 7,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 8729,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 7,
    HP: 106,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 8730,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8731,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8732,
    Stamina: 5,
    Intellect: 8,
    Strength: 3,
    Defense: 2,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8733,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8734,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8735,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8736,
    Stamina: 7,
    Intellect: 11,
    Strength: 1,
    Defense: 3,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 8737,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8738,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8739,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8740,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 8741,
    Stamina: 3,
    Intellect: 10,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8742,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8743,
    Stamina: 6,
    Intellect: 10,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 32,
  },
  {
    Id: 8744,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8745,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 8746,
    Stamina: 5,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8747,
    Stamina: 3,
    Intellect: 2,
    Strength: 15,
    Defense: 1,
    HP: 103,
    Attack: 25,
    Power: 28,
  },
  {
    Id: 8748,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 8749,
    Stamina: 4,
    Intellect: 2,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8750,
    Stamina: 9,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 109,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8751,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8752,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8753,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 8754,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 8755,
    Stamina: 2,
    Intellect: 6,
    Strength: 1,
    Defense: 13,
    HP: 102,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 8756,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 8757,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8758,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 8759,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8760,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8761,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 8762,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8763,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 8764,
    Stamina: 3,
    Intellect: 6,
    Strength: 12,
    Defense: 3,
    HP: 103,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 8765,
    Stamina: 3,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 8766,
    Stamina: 11,
    Intellect: 3,
    Strength: 1,
    Defense: 9,
    HP: 111,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 8767,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 8768,
    Stamina: 1,
    Intellect: 2,
    Strength: 10,
    Defense: 7,
    HP: 101,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 8769,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 8770,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8771,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8772,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8773,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8774,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8775,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8776,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 1,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8777,
    Stamina: 1,
    Intellect: 12,
    Strength: 1,
    Defense: 6,
    HP: 101,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 8778,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8779,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 102,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8780,
    Stamina: 5,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 105,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 8781,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 8782,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8783,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8784,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8785,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 8786,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8787,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8788,
    Stamina: 11,
    Intellect: 2,
    Strength: 4,
    Defense: 5,
    HP: 111,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 8789,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 8790,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8791,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 8792,
    Stamina: 8,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 108,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8793,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8794,
    Stamina: 3,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8795,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8796,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 13,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8797,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 8798,
    Stamina: 9,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 8799,
    Stamina: 8,
    Intellect: 7,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8800,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8801,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 8802,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8803,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8804,
    Stamina: 2,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 8805,
    Stamina: 1,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 101,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8806,
    Stamina: 6,
    Intellect: 11,
    Strength: 3,
    Defense: 2,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8807,
    Stamina: 7,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8808,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8809,
    Stamina: 6,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8810,
    Stamina: 2,
    Intellect: 11,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 33,
  },
  {
    Id: 8811,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8812,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 8813,
    Stamina: 5,
    Intellect: 8,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8814,
    Stamina: 4,
    Intellect: 12,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8815,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 2,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8816,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8817,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8818,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8819,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 8820,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8821,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 8822,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8823,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8824,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8825,
    Stamina: 13,
    Intellect: 4,
    Strength: 1,
    Defense: 6,
    HP: 113,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 8826,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8827,
    Stamina: 1,
    Intellect: 13,
    Strength: 5,
    Defense: 3,
    HP: 101,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 8828,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8829,
    Stamina: 7,
    Intellect: 11,
    Strength: 1,
    Defense: 8,
    HP: 107,
    Attack: 11,
    Power: 30,
  },
  {
    Id: 8830,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8831,
    Stamina: 1,
    Intellect: 8,
    Strength: 6,
    Defense: 9,
    HP: 101,
    Attack: 16,
    Power: 33,
  },
  {
    Id: 8832,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 8833,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 13,
    HP: 104,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 8834,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 8835,
    Stamina: 8,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 108,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8836,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 8837,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 109,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8838,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8839,
    Stamina: 8,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8840,
    Stamina: 6,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 106,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 8841,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8842,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8843,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 8844,
    Stamina: 7,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 8845,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 8846,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 8847,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 7,
    HP: 104,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 8848,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 9,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 8849,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 10,
    HP: 106,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 8850,
    Stamina: 4,
    Intellect: 5,
    Strength: 6,
    Defense: 11,
    HP: 104,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 8851,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8852,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8853,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 8854,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 8855,
    Stamina: 5,
    Intellect: 12,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8856,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8857,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8858,
    Stamina: 2,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 8859,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 3,
    HP: 103,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 8860,
    Stamina: 11,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 111,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 8861,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 12,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 8862,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8863,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 8864,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8865,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 8866,
    Stamina: 3,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 8867,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 8868,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 8869,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 8870,
    Stamina: 5,
    Intellect: 3,
    Strength: 11,
    Defense: 4,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 8871,
    Stamina: 5,
    Intellect: 13,
    Strength: 2,
    Defense: 1,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8872,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 11,
    HP: 104,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 8873,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 2,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8874,
    Stamina: 9,
    Intellect: 6,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8875,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 8876,
    Stamina: 5,
    Intellect: 11,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 30,
  },
  {
    Id: 8877,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 8878,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8879,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8880,
    Stamina: 8,
    Intellect: 2,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 8881,
    Stamina: 11,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 111,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8882,
    Stamina: 1,
    Intellect: 8,
    Strength: 8,
    Defense: 5,
    HP: 101,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 8883,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 106,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8884,
    Stamina: 6,
    Intellect: 6,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8885,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8886,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8887,
    Stamina: 10,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 110,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8888,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8889,
    Stamina: 6,
    Intellect: 3,
    Strength: 11,
    Defense: 1,
    HP: 106,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 8890,
    Stamina: 4,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 8891,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 8892,
    Stamina: 11,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 111,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8893,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 8894,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8895,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8896,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8897,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 8898,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8899,
    Stamina: 1,
    Intellect: 9,
    Strength: 7,
    Defense: 8,
    HP: 101,
    Attack: 17,
    Power: 34,
  },
  {
    Id: 8900,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8901,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8902,
    Stamina: 4,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8903,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 1,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 8904,
    Stamina: 2,
    Intellect: 9,
    Strength: 8,
    Defense: 5,
    HP: 102,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 8905,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 8906,
    Stamina: 1,
    Intellect: 4,
    Strength: 3,
    Defense: 12,
    HP: 101,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8907,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8908,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8909,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 8910,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8911,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 104,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 8912,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 8913,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 106,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 8914,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8915,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 8916,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8917,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8918,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 8919,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8920,
    Stamina: 6,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8921,
    Stamina: 4,
    Intellect: 9,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 8922,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8923,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 11,
    HP: 102,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 8924,
    Stamina: 2,
    Intellect: 5,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 8925,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 8,
    HP: 106,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8926,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 8927,
    Stamina: 1,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 101,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 8928,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 8929,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8930,
    Stamina: 8,
    Intellect: 2,
    Strength: 6,
    Defense: 5,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 8931,
    Stamina: 8,
    Intellect: 9,
    Strength: 7,
    Defense: 4,
    HP: 108,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 8932,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 8933,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8934,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8935,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 107,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8936,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 1,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 8937,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 8938,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 8939,
    Stamina: 1,
    Intellect: 7,
    Strength: 8,
    Defense: 7,
    HP: 101,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 8940,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8941,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 8942,
    Stamina: 4,
    Intellect: 15,
    Strength: 2,
    Defense: 2,
    HP: 104,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 8943,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8944,
    Stamina: 1,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 101,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 8945,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 8946,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 8947,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8948,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8949,
    Stamina: 6,
    Intellect: 3,
    Strength: 8,
    Defense: 2,
    HP: 106,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 8950,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 8951,
    Stamina: 6,
    Intellect: 11,
    Strength: 4,
    Defense: 1,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8952,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8953,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 8954,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 11,
    HP: 106,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8955,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 8956,
    Stamina: 8,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8957,
    Stamina: 5,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8958,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 8959,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 8960,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 8961,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 6,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 8962,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 8963,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 8964,
    Stamina: 2,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 8965,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 11,
    HP: 106,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 8966,
    Stamina: 3,
    Intellect: 9,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 32,
  },
  {
    Id: 8967,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 8968,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 8969,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 1,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 8970,
    Stamina: 3,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 8971,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 8972,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 11,
    HP: 104,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 8973,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 8974,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 8975,
    Stamina: 1,
    Intellect: 3,
    Strength: 2,
    Defense: 13,
    HP: 101,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 8976,
    Stamina: 1,
    Intellect: 8,
    Strength: 8,
    Defense: 6,
    HP: 101,
    Attack: 18,
    Power: 32,
  },
  {
    Id: 8977,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 8978,
    Stamina: 8,
    Intellect: 8,
    Strength: 2,
    Defense: 2,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 8979,
    Stamina: 2,
    Intellect: 11,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 33,
  },
  {
    Id: 8980,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 8981,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8982,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 8983,
    Stamina: 2,
    Intellect: 10,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 8984,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8985,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 8986,
    Stamina: 6,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8987,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 12,
    HP: 105,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 8988,
    Stamina: 1,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 101,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 8989,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 8990,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 8991,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 109,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 8992,
    Stamina: 2,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 8993,
    Stamina: 2,
    Intellect: 12,
    Strength: 2,
    Defense: 2,
    HP: 102,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 8994,
    Stamina: 4,
    Intellect: 11,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 8995,
    Stamina: 4,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 104,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 8996,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 8997,
    Stamina: 6,
    Intellect: 8,
    Strength: 7,
    Defense: 2,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 8998,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 8999,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9000,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9001,
    Stamina: 6,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9002,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 23,
  },
  {
    Id: 9003,
    Stamina: 2,
    Intellect: 2,
    Strength: 5,
    Defense: 16,
    HP: 102,
    Attack: 15,
    Power: 33,
  },
  {
    Id: 9004,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 11,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9005,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9006,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9007,
    Stamina: 6,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9008,
    Stamina: 4,
    Intellect: 3,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 9009,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9010,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9011,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9012,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9013,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9014,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 12,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9015,
    Stamina: 7,
    Intellect: 12,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 9016,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 110,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 9017,
    Stamina: 4,
    Intellect: 11,
    Strength: 7,
    Defense: 7,
    HP: 104,
    Attack: 17,
    Power: 35,
  },
  {
    Id: 9018,
    Stamina: 4,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9019,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9020,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9021,
    Stamina: 4,
    Intellect: 11,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 9022,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9023,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9024,
    Stamina: 1,
    Intellect: 8,
    Strength: 9,
    Defense: 5,
    HP: 101,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 9025,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9026,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9027,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 8,
    HP: 106,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9028,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9029,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 9030,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9031,
    Stamina: 3,
    Intellect: 5,
    Strength: 12,
    Defense: 3,
    HP: 103,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 9032,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 2,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9033,
    Stamina: 6,
    Intellect: 6,
    Strength: 1,
    Defense: 5,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 9034,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9035,
    Stamina: 5,
    Intellect: 9,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9036,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 9037,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9038,
    Stamina: 9,
    Intellect: 9,
    Strength: 4,
    Defense: 1,
    HP: 109,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9039,
    Stamina: 2,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9040,
    Stamina: 10,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 110,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9041,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9042,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9043,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9044,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9045,
    Stamina: 8,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 108,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 9046,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9047,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 9048,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9049,
    Stamina: 6,
    Intellect: 2,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 9050,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9051,
    Stamina: 4,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9052,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 106,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9053,
    Stamina: 7,
    Intellect: 8,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9054,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9055,
    Stamina: 9,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9056,
    Stamina: 10,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 110,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9057,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9058,
    Stamina: 7,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 9059,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9060,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9061,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 9062,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 9063,
    Stamina: 3,
    Intellect: 7,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9064,
    Stamina: 5,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9065,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 11,
    HP: 104,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 9066,
    Stamina: 2,
    Intellect: 10,
    Strength: 1,
    Defense: 7,
    HP: 102,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 9067,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9068,
    Stamina: 5,
    Intellect: 3,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 9069,
    Stamina: 5,
    Intellect: 3,
    Strength: 3,
    Defense: 11,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9070,
    Stamina: 4,
    Intellect: 13,
    Strength: 1,
    Defense: 3,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 9071,
    Stamina: 5,
    Intellect: 2,
    Strength: 11,
    Defense: 2,
    HP: 105,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 9072,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9073,
    Stamina: 3,
    Intellect: 11,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 9074,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 9075,
    Stamina: 1,
    Intellect: 5,
    Strength: 6,
    Defense: 10,
    HP: 101,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 9076,
    Stamina: 4,
    Intellect: 11,
    Strength: 2,
    Defense: 2,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9077,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9078,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9079,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9080,
    Stamina: 8,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 108,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9081,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9082,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9083,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9084,
    Stamina: 4,
    Intellect: 8,
    Strength: 10,
    Defense: 1,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9085,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 7,
    HP: 103,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 9086,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9087,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9088,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9089,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 9,
    HP: 103,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 9090,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9091,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9092,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9093,
    Stamina: 5,
    Intellect: 4,
    Strength: 17,
    Defense: 2,
    HP: 105,
    Attack: 27,
    Power: 33,
  },
  {
    Id: 9094,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9095,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9096,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9097,
    Stamina: 4,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 104,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 9098,
    Stamina: 5,
    Intellect: 9,
    Strength: 8,
    Defense: 7,
    HP: 105,
    Attack: 18,
    Power: 34,
  },
  {
    Id: 9099,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 10,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9100,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9101,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 9102,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 2,
    HP: 108,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 9103,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9104,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9105,
    Stamina: 8,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9106,
    Stamina: 1,
    Intellect: 6,
    Strength: 9,
    Defense: 4,
    HP: 101,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9107,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9108,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9109,
    Stamina: 10,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 110,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9110,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 3,
    HP: 102,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9111,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9112,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9113,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 9114,
    Stamina: 6,
    Intellect: 4,
    Strength: 10,
    Defense: 1,
    HP: 106,
    Attack: 20,
    Power: 25,
  },
  {
    Id: 9115,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 9116,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9117,
    Stamina: 5,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 9118,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9119,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 9120,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9121,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 9122,
    Stamina: 9,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 109,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 9123,
    Stamina: 4,
    Intellect: 14,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 32,
  },
  {
    Id: 9124,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9125,
    Stamina: 1,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 101,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9126,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9127,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9128,
    Stamina: 5,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9129,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 7,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9130,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9131,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 9132,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9133,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9134,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9135,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9136,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 9137,
    Stamina: 2,
    Intellect: 7,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 34,
  },
  {
    Id: 9138,
    Stamina: 1,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 101,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9139,
    Stamina: 6,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9140,
    Stamina: 2,
    Intellect: 2,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9141,
    Stamina: 7,
    Intellect: 4,
    Strength: 11,
    Defense: 1,
    HP: 107,
    Attack: 21,
    Power: 26,
  },
  {
    Id: 9142,
    Stamina: 1,
    Intellect: 5,
    Strength: 7,
    Defense: 9,
    HP: 101,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 9143,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9144,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9145,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9146,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9147,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 9148,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9149,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 105,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9150,
    Stamina: 6,
    Intellect: 2,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 9151,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 10,
    HP: 103,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 9152,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9153,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9154,
    Stamina: 2,
    Intellect: 11,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 31,
  },
  {
    Id: 9155,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 2,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9156,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9157,
    Stamina: 2,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9158,
    Stamina: 6,
    Intellect: 12,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9159,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 10,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 9160,
    Stamina: 9,
    Intellect: 4,
    Strength: 5,
    Defense: 2,
    HP: 109,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 9161,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9162,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 9163,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 1,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9164,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9165,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9166,
    Stamina: 9,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 109,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 9167,
    Stamina: 5,
    Intellect: 5,
    Strength: 3,
    Defense: 11,
    HP: 105,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 9168,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 9169,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9170,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 9171,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9172,
    Stamina: 5,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9173,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 11,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9174,
    Stamina: 6,
    Intellect: 6,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9175,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 9176,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9177,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9178,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 9,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 9179,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9180,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 108,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 9181,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 1,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 9182,
    Stamina: 10,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 110,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9183,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 9,
    HP: 105,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9184,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9185,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 9186,
    Stamina: 5,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9187,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 9188,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9189,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9190,
    Stamina: 12,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 112,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9191,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 1,
    HP: 105,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9192,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9193,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 9194,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9195,
    Stamina: 2,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9196,
    Stamina: 1,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 101,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9197,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 12,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 9198,
    Stamina: 5,
    Intellect: 2,
    Strength: 10,
    Defense: 4,
    HP: 105,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 9199,
    Stamina: 3,
    Intellect: 7,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9200,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9201,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9202,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9203,
    Stamina: 8,
    Intellect: 7,
    Strength: 1,
    Defense: 4,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 9204,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9205,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9206,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 11,
    HP: 105,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9207,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 4,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 9208,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9209,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9210,
    Stamina: 9,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 109,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 9211,
    Stamina: 1,
    Intellect: 10,
    Strength: 6,
    Defense: 3,
    HP: 101,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9212,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 9213,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9214,
    Stamina: 1,
    Intellect: 8,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 9215,
    Stamina: 3,
    Intellect: 8,
    Strength: 9,
    Defense: 1,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9216,
    Stamina: 6,
    Intellect: 10,
    Strength: 2,
    Defense: 3,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9217,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 9218,
    Stamina: 5,
    Intellect: 12,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9219,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9220,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 9221,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9222,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9223,
    Stamina: 3,
    Intellect: 5,
    Strength: 1,
    Defense: 11,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 9224,
    Stamina: 4,
    Intellect: 2,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9225,
    Stamina: 1,
    Intellect: 2,
    Strength: 7,
    Defense: 12,
    HP: 101,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 9226,
    Stamina: 9,
    Intellect: 4,
    Strength: 1,
    Defense: 4,
    HP: 109,
    Attack: 11,
    Power: 19,
  },
  {
    Id: 9227,
    Stamina: 5,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9228,
    Stamina: 8,
    Intellect: 3,
    Strength: 10,
    Defense: 6,
    HP: 108,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9229,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 9230,
    Stamina: 9,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 109,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9231,
    Stamina: 3,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 9232,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9233,
    Stamina: 3,
    Intellect: 3,
    Strength: 2,
    Defense: 10,
    HP: 103,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9234,
    Stamina: 5,
    Intellect: 10,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 9235,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9236,
    Stamina: 10,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 110,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9237,
    Stamina: 9,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 109,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9238,
    Stamina: 3,
    Intellect: 6,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 9239,
    Stamina: 5,
    Intellect: 12,
    Strength: 2,
    Defense: 3,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9240,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 9241,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 8,
    HP: 102,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 9242,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 105,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9243,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 9244,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9245,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9246,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 9247,
    Stamina: 7,
    Intellect: 8,
    Strength: 1,
    Defense: 6,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9248,
    Stamina: 2,
    Intellect: 9,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 9249,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9250,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9251,
    Stamina: 7,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9252,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9253,
    Stamina: 7,
    Intellect: 8,
    Strength: 4,
    Defense: 2,
    HP: 107,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9254,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9255,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 9256,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9257,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 32,
  },
  {
    Id: 9258,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9259,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9260,
    Stamina: 10,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 110,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9261,
    Stamina: 1,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9262,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 9263,
    Stamina: 3,
    Intellect: 6,
    Strength: 11,
    Defense: 3,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 9264,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 9265,
    Stamina: 7,
    Intellect: 6,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 21,
  },
  {
    Id: 9266,
    Stamina: 7,
    Intellect: 4,
    Strength: 5,
    Defense: 2,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 9267,
    Stamina: 7,
    Intellect: 9,
    Strength: 2,
    Defense: 2,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9268,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 2,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 9269,
    Stamina: 2,
    Intellect: 3,
    Strength: 7,
    Defense: 11,
    HP: 102,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 9270,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 9271,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 9272,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 1,
    HP: 105,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9273,
    Stamina: 6,
    Intellect: 7,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9274,
    Stamina: 5,
    Intellect: 8,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9275,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9276,
    Stamina: 9,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 109,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9277,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 9278,
    Stamina: 11,
    Intellect: 10,
    Strength: 1,
    Defense: 1,
    HP: 111,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 9279,
    Stamina: 4,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9280,
    Stamina: 8,
    Intellect: 2,
    Strength: 5,
    Defense: 6,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 9281,
    Stamina: 7,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9282,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 9283,
    Stamina: 2,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9284,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 11,
    HP: 103,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9285,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 3,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9286,
    Stamina: 6,
    Intellect: 10,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9287,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9288,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9289,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 103,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 9290,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9291,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9292,
    Stamina: 1,
    Intellect: 4,
    Strength: 12,
    Defense: 5,
    HP: 101,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 9293,
    Stamina: 5,
    Intellect: 7,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9294,
    Stamina: 6,
    Intellect: 4,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 9295,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 34,
  },
  {
    Id: 9296,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9297,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9298,
    Stamina: 1,
    Intellect: 5,
    Strength: 3,
    Defense: 13,
    HP: 101,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 9299,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9300,
    Stamina: 11,
    Intellect: 2,
    Strength: 1,
    Defense: 7,
    HP: 111,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 9301,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 107,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 9302,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 10,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9303,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9304,
    Stamina: 7,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9305,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9306,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9307,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9308,
    Stamina: 6,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9309,
    Stamina: 1,
    Intellect: 9,
    Strength: 9,
    Defense: 1,
    HP: 101,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9310,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9311,
    Stamina: 6,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 9312,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9313,
    Stamina: 3,
    Intellect: 9,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 9314,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9315,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 105,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9316,
    Stamina: 5,
    Intellect: 10,
    Strength: 1,
    Defense: 4,
    HP: 105,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9317,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9318,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9319,
    Stamina: 5,
    Intellect: 11,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 9320,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9321,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9322,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 9323,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9324,
    Stamina: 7,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 107,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9325,
    Stamina: 4,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 9326,
    Stamina: 8,
    Intellect: 6,
    Strength: 7,
    Defense: 1,
    HP: 108,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9327,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 9328,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9329,
    Stamina: 4,
    Intellect: 6,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9330,
    Stamina: 2,
    Intellect: 10,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9331,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9332,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9333,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9334,
    Stamina: 3,
    Intellect: 7,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9335,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9336,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9337,
    Stamina: 7,
    Intellect: 7,
    Strength: 6,
    Defense: 2,
    HP: 107,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 9338,
    Stamina: 2,
    Intellect: 3,
    Strength: 11,
    Defense: 8,
    HP: 102,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 9339,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9340,
    Stamina: 8,
    Intellect: 3,
    Strength: 8,
    Defense: 1,
    HP: 108,
    Attack: 18,
    Power: 22,
  },
  {
    Id: 9341,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9342,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9343,
    Stamina: 4,
    Intellect: 7,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9344,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 6,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9345,
    Stamina: 3,
    Intellect: 8,
    Strength: 1,
    Defense: 9,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 9346,
    Stamina: 2,
    Intellect: 5,
    Strength: 1,
    Defense: 10,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 9347,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9348,
    Stamina: 6,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9349,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9350,
    Stamina: 9,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 109,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 9351,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9352,
    Stamina: 4,
    Intellect: 7,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 9353,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 9354,
    Stamina: 5,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9355,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9356,
    Stamina: 7,
    Intellect: 5,
    Strength: 1,
    Defense: 4,
    HP: 107,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 9357,
    Stamina: 6,
    Intellect: 4,
    Strength: 8,
    Defense: 1,
    HP: 106,
    Attack: 18,
    Power: 23,
  },
  {
    Id: 9358,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 9359,
    Stamina: 1,
    Intellect: 6,
    Strength: 9,
    Defense: 8,
    HP: 101,
    Attack: 19,
    Power: 33,
  },
  {
    Id: 9360,
    Stamina: 2,
    Intellect: 5,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 9361,
    Stamina: 2,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9362,
    Stamina: 3,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9363,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 9364,
    Stamina: 1,
    Intellect: 11,
    Strength: 5,
    Defense: 3,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9365,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9366,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9367,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 9368,
    Stamina: 6,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9369,
    Stamina: 6,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9370,
    Stamina: 3,
    Intellect: 4,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9371,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 3,
    HP: 102,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 9372,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 9,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9373,
    Stamina: 8,
    Intellect: 5,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 9374,
    Stamina: 6,
    Intellect: 3,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9375,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 3,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9376,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 108,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 9377,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 9378,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 9379,
    Stamina: 4,
    Intellect: 10,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9380,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 9381,
    Stamina: 9,
    Intellect: 3,
    Strength: 5,
    Defense: 4,
    HP: 109,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 9382,
    Stamina: 7,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9383,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 9384,
    Stamina: 8,
    Intellect: 4,
    Strength: 8,
    Defense: 8,
    HP: 108,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9385,
    Stamina: 6,
    Intellect: 12,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 29,
  },
  {
    Id: 9386,
    Stamina: 11,
    Intellect: 5,
    Strength: 2,
    Defense: 4,
    HP: 111,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 9387,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 9388,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9389,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9390,
    Stamina: 5,
    Intellect: 6,
    Strength: 1,
    Defense: 7,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 9391,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 9392,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 6,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9393,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9394,
    Stamina: 7,
    Intellect: 10,
    Strength: 4,
    Defense: 1,
    HP: 107,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9395,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9396,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9397,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 9398,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9399,
    Stamina: 6,
    Intellect: 9,
    Strength: 1,
    Defense: 3,
    HP: 106,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 9400,
    Stamina: 3,
    Intellect: 12,
    Strength: 3,
    Defense: 5,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 9401,
    Stamina: 5,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9402,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9403,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9404,
    Stamina: 6,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9405,
    Stamina: 4,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9406,
    Stamina: 1,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 101,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9407,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 3,
    HP: 109,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 9408,
    Stamina: 3,
    Intellect: 7,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9409,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9410,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 5,
    HP: 104,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 9411,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9412,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9413,
    Stamina: 3,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9414,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9415,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 5,
    HP: 105,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9416,
    Stamina: 3,
    Intellect: 3,
    Strength: 8,
    Defense: 9,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9417,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9418,
    Stamina: 2,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 9419,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 1,
    HP: 102,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9420,
    Stamina: 6,
    Intellect: 11,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9421,
    Stamina: 5,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9422,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9423,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9424,
    Stamina: 6,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9425,
    Stamina: 2,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9426,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 5,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 9427,
    Stamina: 2,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 102,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9428,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9429,
    Stamina: 2,
    Intellect: 6,
    Strength: 11,
    Defense: 9,
    HP: 102,
    Attack: 21,
    Power: 36,
  },
  {
    Id: 9430,
    Stamina: 4,
    Intellect: 6,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 9431,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 3,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9432,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 9433,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9434,
    Stamina: 8,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 108,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9435,
    Stamina: 6,
    Intellect: 3,
    Strength: 11,
    Defense: 1,
    HP: 106,
    Attack: 21,
    Power: 25,
  },
  {
    Id: 9436,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9437,
    Stamina: 8,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 108,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9438,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 9439,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9440,
    Stamina: 9,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 109,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9441,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9442,
    Stamina: 5,
    Intellect: 2,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9443,
    Stamina: 5,
    Intellect: 3,
    Strength: 11,
    Defense: 3,
    HP: 105,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 9444,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9445,
    Stamina: 2,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 102,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 9446,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9447,
    Stamina: 10,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 110,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9448,
    Stamina: 1,
    Intellect: 5,
    Strength: 8,
    Defense: 8,
    HP: 101,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 9449,
    Stamina: 4,
    Intellect: 5,
    Strength: 11,
    Defense: 3,
    HP: 104,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 9450,
    Stamina: 1,
    Intellect: 3,
    Strength: 9,
    Defense: 11,
    HP: 101,
    Attack: 19,
    Power: 33,
  },
  {
    Id: 9451,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 7,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 9452,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 9453,
    Stamina: 4,
    Intellect: 7,
    Strength: 9,
    Defense: 2,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9454,
    Stamina: 2,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 9455,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9456,
    Stamina: 3,
    Intellect: 10,
    Strength: 1,
    Defense: 6,
    HP: 103,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 9457,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 9458,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9459,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 8,
    HP: 103,
    Attack: 19,
    Power: 32,
  },
  {
    Id: 9460,
    Stamina: 5,
    Intellect: 8,
    Strength: 1,
    Defense: 9,
    HP: 105,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 9461,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9462,
    Stamina: 4,
    Intellect: 7,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9463,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 10,
    HP: 107,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9464,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 106,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9465,
    Stamina: 4,
    Intellect: 4,
    Strength: 1,
    Defense: 10,
    HP: 104,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9466,
    Stamina: 3,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9467,
    Stamina: 6,
    Intellect: 2,
    Strength: 12,
    Defense: 3,
    HP: 106,
    Attack: 22,
    Power: 27,
  },
  {
    Id: 9468,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 108,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9469,
    Stamina: 7,
    Intellect: 2,
    Strength: 2,
    Defense: 7,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 9470,
    Stamina: 6,
    Intellect: 2,
    Strength: 9,
    Defense: 6,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9471,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 9472,
    Stamina: 10,
    Intellect: 2,
    Strength: 4,
    Defense: 4,
    HP: 110,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 9473,
    Stamina: 1,
    Intellect: 8,
    Strength: 6,
    Defense: 6,
    HP: 101,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 9474,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9475,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 1,
    HP: 110,
    Attack: 13,
    Power: 17,
  },
  {
    Id: 9476,
    Stamina: 2,
    Intellect: 6,
    Strength: 12,
    Defense: 3,
    HP: 102,
    Attack: 22,
    Power: 31,
  },
  {
    Id: 9477,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9478,
    Stamina: 4,
    Intellect: 10,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9479,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9480,
    Stamina: 3,
    Intellect: 14,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 9481,
    Stamina: 2,
    Intellect: 3,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 9482,
    Stamina: 6,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9483,
    Stamina: 3,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9484,
    Stamina: 5,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 9485,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9486,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9487,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 11,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9488,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 3,
    HP: 102,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9489,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9490,
    Stamina: 9,
    Intellect: 6,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 9491,
    Stamina: 2,
    Intellect: 7,
    Strength: 6,
    Defense: 5,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9492,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9493,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9494,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9495,
    Stamina: 3,
    Intellect: 12,
    Strength: 2,
    Defense: 3,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9496,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9497,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9498,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 4,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9499,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 3,
    HP: 107,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 9500,
    Stamina: 1,
    Intellect: 7,
    Strength: 7,
    Defense: 12,
    HP: 101,
    Attack: 17,
    Power: 36,
  },
  {
    Id: 9501,
    Stamina: 7,
    Intellect: 2,
    Strength: 6,
    Defense: 3,
    HP: 107,
    Attack: 16,
    Power: 21,
  },
  {
    Id: 9502,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 9503,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 9504,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 9505,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 1,
    HP: 104,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 9506,
    Stamina: 2,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9507,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9508,
    Stamina: 6,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9509,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 5,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9510,
    Stamina: 7,
    Intellect: 12,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 9511,
    Stamina: 7,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9512,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9513,
    Stamina: 6,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 106,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9514,
    Stamina: 3,
    Intellect: 3,
    Strength: 11,
    Defense: 8,
    HP: 103,
    Attack: 21,
    Power: 32,
  },
  {
    Id: 9515,
    Stamina: 3,
    Intellect: 7,
    Strength: 1,
    Defense: 10,
    HP: 103,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 9516,
    Stamina: 6,
    Intellect: 5,
    Strength: 4,
    Defense: 6,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9517,
    Stamina: 3,
    Intellect: 8,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 9518,
    Stamina: 3,
    Intellect: 8,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9519,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9520,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9521,
    Stamina: 13,
    Intellect: 4,
    Strength: 1,
    Defense: 2,
    HP: 113,
    Attack: 11,
    Power: 17,
  },
  {
    Id: 9522,
    Stamina: 9,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9523,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9524,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 9,
    HP: 106,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9525,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9526,
    Stamina: 4,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9527,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9528,
    Stamina: 1,
    Intellect: 8,
    Strength: 3,
    Defense: 7,
    HP: 101,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9529,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 9530,
    Stamina: 9,
    Intellect: 3,
    Strength: 5,
    Defense: 3,
    HP: 109,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 9531,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 9532,
    Stamina: 3,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9533,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9534,
    Stamina: 3,
    Intellect: 4,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9535,
    Stamina: 3,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9536,
    Stamina: 7,
    Intellect: 3,
    Strength: 4,
    Defense: 5,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9537,
    Stamina: 9,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 109,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9538,
    Stamina: 10,
    Intellect: 3,
    Strength: 3,
    Defense: 2,
    HP: 110,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 9539,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9540,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9541,
    Stamina: 2,
    Intellect: 7,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9542,
    Stamina: 1,
    Intellect: 8,
    Strength: 3,
    Defense: 11,
    HP: 101,
    Attack: 13,
    Power: 32,
  },
  {
    Id: 9543,
    Stamina: 7,
    Intellect: 5,
    Strength: 1,
    Defense: 8,
    HP: 107,
    Attack: 11,
    Power: 24,
  },
  {
    Id: 9544,
    Stamina: 6,
    Intellect: 6,
    Strength: 12,
    Defense: 2,
    HP: 106,
    Attack: 22,
    Power: 30,
  },
  {
    Id: 9545,
    Stamina: 2,
    Intellect: 6,
    Strength: 7,
    Defense: 4,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9546,
    Stamina: 4,
    Intellect: 7,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9547,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9548,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 107,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 9549,
    Stamina: 3,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 9550,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 11,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9551,
    Stamina: 4,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9552,
    Stamina: 3,
    Intellect: 13,
    Strength: 5,
    Defense: 4,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 9553,
    Stamina: 6,
    Intellect: 2,
    Strength: 2,
    Defense: 8,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9554,
    Stamina: 4,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 104,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9555,
    Stamina: 4,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 104,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9556,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9557,
    Stamina: 2,
    Intellect: 4,
    Strength: 9,
    Defense: 2,
    HP: 102,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 9558,
    Stamina: 5,
    Intellect: 4,
    Strength: 7,
    Defense: 2,
    HP: 105,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 9559,
    Stamina: 6,
    Intellect: 7,
    Strength: 10,
    Defense: 8,
    HP: 106,
    Attack: 20,
    Power: 35,
  },
  {
    Id: 9560,
    Stamina: 4,
    Intellect: 2,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9561,
    Stamina: 6,
    Intellect: 6,
    Strength: 4,
    Defense: 5,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9562,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9563,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9564,
    Stamina: 2,
    Intellect: 8,
    Strength: 4,
    Defense: 5,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9565,
    Stamina: 3,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9566,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9567,
    Stamina: 2,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 102,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9568,
    Stamina: 2,
    Intellect: 5,
    Strength: 13,
    Defense: 2,
    HP: 102,
    Attack: 23,
    Power: 30,
  },
  {
    Id: 9569,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9570,
    Stamina: 11,
    Intellect: 4,
    Strength: 12,
    Defense: 9,
    HP: 111,
    Attack: 22,
    Power: 35,
  },
  {
    Id: 9571,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9572,
    Stamina: 2,
    Intellect: 9,
    Strength: 5,
    Defense: 8,
    HP: 102,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 9573,
    Stamina: 5,
    Intellect: 3,
    Strength: 7,
    Defense: 11,
    HP: 105,
    Attack: 17,
    Power: 31,
  },
  {
    Id: 9574,
    Stamina: 6,
    Intellect: 2,
    Strength: 8,
    Defense: 8,
    HP: 106,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 9575,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9576,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9577,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9578,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9579,
    Stamina: 4,
    Intellect: 12,
    Strength: 1,
    Defense: 5,
    HP: 104,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 9580,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9581,
    Stamina: 3,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9582,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9583,
    Stamina: 3,
    Intellect: 8,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9584,
    Stamina: 5,
    Intellect: 6,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9585,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 19,
  },
  {
    Id: 9586,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 3,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9587,
    Stamina: 11,
    Intellect: 7,
    Strength: 2,
    Defense: 6,
    HP: 111,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9588,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9589,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9590,
    Stamina: 8,
    Intellect: 8,
    Strength: 4,
    Defense: 1,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 9591,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9592,
    Stamina: 2,
    Intellect: 8,
    Strength: 8,
    Defense: 2,
    HP: 102,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 9593,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 2,
    HP: 108,
    Attack: 15,
    Power: 21,
  },
  {
    Id: 9594,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9595,
    Stamina: 6,
    Intellect: 5,
    Strength: 8,
    Defense: 5,
    HP: 106,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 9596,
    Stamina: 7,
    Intellect: 3,
    Strength: 3,
    Defense: 5,
    HP: 107,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 9597,
    Stamina: 1,
    Intellect: 9,
    Strength: 5,
    Defense: 11,
    HP: 101,
    Attack: 15,
    Power: 35,
  },
  {
    Id: 9598,
    Stamina: 1,
    Intellect: 2,
    Strength: 13,
    Defense: 4,
    HP: 101,
    Attack: 23,
    Power: 29,
  },
  {
    Id: 9599,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 9600,
    Stamina: 1,
    Intellect: 11,
    Strength: 5,
    Defense: 2,
    HP: 101,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9601,
    Stamina: 1,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9602,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 5,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 9603,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9604,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 2,
    HP: 107,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 9605,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 110,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 9606,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 102,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9607,
    Stamina: 3,
    Intellect: 12,
    Strength: 3,
    Defense: 6,
    HP: 103,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 9608,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9609,
    Stamina: 9,
    Intellect: 7,
    Strength: 3,
    Defense: 2,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9610,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 3,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9611,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 10,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 9612,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9613,
    Stamina: 7,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 107,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9614,
    Stamina: 6,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9615,
    Stamina: 8,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 108,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 9616,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 9,
    HP: 105,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 9617,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9618,
    Stamina: 5,
    Intellect: 11,
    Strength: 1,
    Defense: 5,
    HP: 105,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 9619,
    Stamina: 3,
    Intellect: 12,
    Strength: 3,
    Defense: 2,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9620,
    Stamina: 1,
    Intellect: 3,
    Strength: 2,
    Defense: 11,
    HP: 101,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9621,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9622,
    Stamina: 4,
    Intellect: 10,
    Strength: 5,
    Defense: 1,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9623,
    Stamina: 8,
    Intellect: 2,
    Strength: 3,
    Defense: 5,
    HP: 108,
    Attack: 13,
    Power: 20,
  },
  {
    Id: 9624,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 3,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 9625,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9626,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 12,
    HP: 104,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 9627,
    Stamina: 2,
    Intellect: 3,
    Strength: 8,
    Defense: 10,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 9628,
    Stamina: 3,
    Intellect: 7,
    Strength: 2,
    Defense: 7,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9629,
    Stamina: 4,
    Intellect: 3,
    Strength: 6,
    Defense: 7,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9630,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 9631,
    Stamina: 7,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 107,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9632,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9633,
    Stamina: 7,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9634,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 9635,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 9636,
    Stamina: 5,
    Intellect: 3,
    Strength: 1,
    Defense: 8,
    HP: 105,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 9637,
    Stamina: 2,
    Intellect: 3,
    Strength: 12,
    Defense: 4,
    HP: 102,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 9638,
    Stamina: 9,
    Intellect: 3,
    Strength: 8,
    Defense: 5,
    HP: 109,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9639,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 106,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9640,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 5,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9641,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 9642,
    Stamina: 10,
    Intellect: 3,
    Strength: 7,
    Defense: 2,
    HP: 110,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 9643,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9644,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9645,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 103,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 9646,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9647,
    Stamina: 7,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9648,
    Stamina: 2,
    Intellect: 4,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9649,
    Stamina: 12,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 112,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 9650,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9651,
    Stamina: 2,
    Intellect: 7,
    Strength: 4,
    Defense: 10,
    HP: 102,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 9652,
    Stamina: 8,
    Intellect: 4,
    Strength: 4,
    Defense: 2,
    HP: 108,
    Attack: 14,
    Power: 20,
  },
  {
    Id: 9653,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9654,
    Stamina: 3,
    Intellect: 4,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9655,
    Stamina: 3,
    Intellect: 5,
    Strength: 12,
    Defense: 5,
    HP: 103,
    Attack: 22,
    Power: 32,
  },
  {
    Id: 9656,
    Stamina: 4,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9657,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9658,
    Stamina: 6,
    Intellect: 3,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9659,
    Stamina: 10,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 110,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 9660,
    Stamina: 2,
    Intellect: 6,
    Strength: 6,
    Defense: 9,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 9661,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 5,
    HP: 102,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 9662,
    Stamina: 7,
    Intellect: 2,
    Strength: 2,
    Defense: 8,
    HP: 107,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9663,
    Stamina: 3,
    Intellect: 8,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9664,
    Stamina: 6,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9665,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9666,
    Stamina: 2,
    Intellect: 7,
    Strength: 16,
    Defense: 1,
    HP: 102,
    Attack: 26,
    Power: 34,
  },
  {
    Id: 9667,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9668,
    Stamina: 3,
    Intellect: 5,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9669,
    Stamina: 4,
    Intellect: 3,
    Strength: 3,
    Defense: 8,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9670,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 107,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9671,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 9672,
    Stamina: 9,
    Intellect: 7,
    Strength: 1,
    Defense: 5,
    HP: 109,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 9673,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 9674,
    Stamina: 2,
    Intellect: 4,
    Strength: 3,
    Defense: 8,
    HP: 102,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9675,
    Stamina: 7,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 107,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 9676,
    Stamina: 5,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 105,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 9677,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9678,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9679,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9680,
    Stamina: 7,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9681,
    Stamina: 4,
    Intellect: 6,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9682,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9683,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 6,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9684,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 9685,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9686,
    Stamina: 10,
    Intellect: 3,
    Strength: 6,
    Defense: 1,
    HP: 110,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 9687,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 8,
    HP: 102,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9688,
    Stamina: 3,
    Intellect: 5,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9689,
    Stamina: 6,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 9690,
    Stamina: 3,
    Intellect: 3,
    Strength: 14,
    Defense: 2,
    HP: 103,
    Attack: 24,
    Power: 29,
  },
  {
    Id: 9691,
    Stamina: 1,
    Intellect: 4,
    Strength: 5,
    Defense: 10,
    HP: 101,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9692,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 108,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9693,
    Stamina: 1,
    Intellect: 6,
    Strength: 7,
    Defense: 7,
    HP: 101,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 9694,
    Stamina: 12,
    Intellect: 6,
    Strength: 5,
    Defense: 2,
    HP: 112,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 9695,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 8,
    HP: 103,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9696,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 2,
    HP: 106,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9697,
    Stamina: 7,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 9698,
    Stamina: 4,
    Intellect: 9,
    Strength: 3,
    Defense: 9,
    HP: 104,
    Attack: 13,
    Power: 31,
  },
  {
    Id: 9699,
    Stamina: 4,
    Intellect: 8,
    Strength: 1,
    Defense: 8,
    HP: 104,
    Attack: 11,
    Power: 27,
  },
  {
    Id: 9700,
    Stamina: 1,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 101,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9701,
    Stamina: 2,
    Intellect: 10,
    Strength: 5,
    Defense: 3,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9702,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 105,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9703,
    Stamina: 9,
    Intellect: 5,
    Strength: 2,
    Defense: 2,
    HP: 109,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 9704,
    Stamina: 3,
    Intellect: 9,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9705,
    Stamina: 5,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 105,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9706,
    Stamina: 2,
    Intellect: 8,
    Strength: 3,
    Defense: 5,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9707,
    Stamina: 2,
    Intellect: 7,
    Strength: 1,
    Defense: 8,
    HP: 102,
    Attack: 11,
    Power: 26,
  },
  {
    Id: 9708,
    Stamina: 3,
    Intellect: 3,
    Strength: 9,
    Defense: 7,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9709,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9710,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9711,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9712,
    Stamina: 7,
    Intellect: 2,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 9713,
    Stamina: 4,
    Intellect: 8,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 9714,
    Stamina: 5,
    Intellect: 3,
    Strength: 4,
    Defense: 6,
    HP: 105,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 9715,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9716,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 7,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 9717,
    Stamina: 8,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 108,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 9718,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 9719,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 9720,
    Stamina: 4,
    Intellect: 2,
    Strength: 10,
    Defense: 5,
    HP: 104,
    Attack: 20,
    Power: 27,
  },
  {
    Id: 9721,
    Stamina: 8,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 108,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9722,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9723,
    Stamina: 3,
    Intellect: 2,
    Strength: 6,
    Defense: 9,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9724,
    Stamina: 3,
    Intellect: 8,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 9725,
    Stamina: 6,
    Intellect: 9,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9726,
    Stamina: 2,
    Intellect: 8,
    Strength: 5,
    Defense: 4,
    HP: 102,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9727,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 12,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9728,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9729,
    Stamina: 3,
    Intellect: 5,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9730,
    Stamina: 3,
    Intellect: 11,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9731,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 2,
    HP: 104,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9732,
    Stamina: 6,
    Intellect: 7,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9733,
    Stamina: 6,
    Intellect: 2,
    Strength: 9,
    Defense: 3,
    HP: 106,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 9734,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9735,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 2,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9736,
    Stamina: 1,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 101,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9737,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9738,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9739,
    Stamina: 3,
    Intellect: 5,
    Strength: 13,
    Defense: 3,
    HP: 103,
    Attack: 23,
    Power: 31,
  },
  {
    Id: 9740,
    Stamina: 8,
    Intellect: 3,
    Strength: 6,
    Defense: 4,
    HP: 108,
    Attack: 16,
    Power: 23,
  },
  {
    Id: 9741,
    Stamina: 4,
    Intellect: 7,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 30,
  },
  {
    Id: 9742,
    Stamina: 3,
    Intellect: 10,
    Strength: 5,
    Defense: 2,
    HP: 103,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9743,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 7,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9744,
    Stamina: 9,
    Intellect: 6,
    Strength: 3,
    Defense: 3,
    HP: 109,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9745,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9746,
    Stamina: 8,
    Intellect: 3,
    Strength: 3,
    Defense: 3,
    HP: 108,
    Attack: 13,
    Power: 19,
  },
  {
    Id: 9747,
    Stamina: 2,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 102,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9748,
    Stamina: 7,
    Intellect: 4,
    Strength: 6,
    Defense: 6,
    HP: 107,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9749,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9750,
    Stamina: 6,
    Intellect: 2,
    Strength: 4,
    Defense: 13,
    HP: 106,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9751,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9752,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9753,
    Stamina: 5,
    Intellect: 7,
    Strength: 6,
    Defense: 8,
    HP: 105,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 9754,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9755,
    Stamina: 3,
    Intellect: 9,
    Strength: 2,
    Defense: 5,
    HP: 103,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9756,
    Stamina: 1,
    Intellect: 3,
    Strength: 9,
    Defense: 5,
    HP: 101,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9757,
    Stamina: 4,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9758,
    Stamina: 3,
    Intellect: 2,
    Strength: 7,
    Defense: 7,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9759,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 3,
    HP: 103,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9760,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 28,
  },
  {
    Id: 9761,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 7,
    HP: 104,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9762,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 10,
    HP: 104,
    Attack: 18,
    Power: 33,
  },
  {
    Id: 9763,
    Stamina: 3,
    Intellect: 2,
    Strength: 5,
    Defense: 15,
    HP: 103,
    Attack: 15,
    Power: 32,
  },
  {
    Id: 9764,
    Stamina: 3,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 9765,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 9766,
    Stamina: 2,
    Intellect: 2,
    Strength: 10,
    Defense: 6,
    HP: 102,
    Attack: 20,
    Power: 28,
  },
  {
    Id: 9767,
    Stamina: 4,
    Intellect: 4,
    Strength: 16,
    Defense: 4,
    HP: 104,
    Attack: 26,
    Power: 34,
  },
  {
    Id: 9768,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9769,
    Stamina: 2,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 102,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9770,
    Stamina: 2,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 102,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 9771,
    Stamina: 6,
    Intellect: 5,
    Strength: 5,
    Defense: 10,
    HP: 106,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 9772,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 12,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9773,
    Stamina: 6,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 106,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9774,
    Stamina: 4,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 104,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 9775,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 10,
    HP: 105,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9776,
    Stamina: 4,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9777,
    Stamina: 5,
    Intellect: 6,
    Strength: 4,
    Defense: 10,
    HP: 105,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 9778,
    Stamina: 11,
    Intellect: 3,
    Strength: 1,
    Defense: 6,
    HP: 111,
    Attack: 11,
    Power: 20,
  },
  {
    Id: 9779,
    Stamina: 6,
    Intellect: 6,
    Strength: 3,
    Defense: 6,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9780,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9781,
    Stamina: 3,
    Intellect: 8,
    Strength: 9,
    Defense: 2,
    HP: 103,
    Attack: 19,
    Power: 29,
  },
  {
    Id: 9782,
    Stamina: 3,
    Intellect: 2,
    Strength: 12,
    Defense: 5,
    HP: 103,
    Attack: 22,
    Power: 29,
  },
  {
    Id: 9783,
    Stamina: 5,
    Intellect: 8,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9784,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 3,
    HP: 108,
    Attack: 12,
    Power: 19,
  },
  {
    Id: 9785,
    Stamina: 1,
    Intellect: 4,
    Strength: 7,
    Defense: 8,
    HP: 101,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9786,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9787,
    Stamina: 3,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9788,
    Stamina: 7,
    Intellect: 8,
    Strength: 1,
    Defense: 9,
    HP: 107,
    Attack: 11,
    Power: 28,
  },
  {
    Id: 9789,
    Stamina: 7,
    Intellect: 3,
    Strength: 5,
    Defense: 5,
    HP: 107,
    Attack: 15,
    Power: 23,
  },
  {
    Id: 9790,
    Stamina: 2,
    Intellect: 2,
    Strength: 4,
    Defense: 11,
    HP: 102,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9791,
    Stamina: 4,
    Intellect: 7,
    Strength: 3,
    Defense: 5,
    HP: 104,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9792,
    Stamina: 2,
    Intellect: 8,
    Strength: 7,
    Defense: 3,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9793,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 10,
    HP: 103,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 9794,
    Stamina: 2,
    Intellect: 5,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9795,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 106,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9796,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 4,
    HP: 104,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9797,
    Stamina: 7,
    Intellect: 4,
    Strength: 7,
    Defense: 3,
    HP: 107,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9798,
    Stamina: 5,
    Intellect: 6,
    Strength: 7,
    Defense: 5,
    HP: 105,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9799,
    Stamina: 3,
    Intellect: 7,
    Strength: 11,
    Defense: 2,
    HP: 103,
    Attack: 21,
    Power: 30,
  },
  {
    Id: 9800,
    Stamina: 6,
    Intellect: 7,
    Strength: 1,
    Defense: 7,
    HP: 106,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9801,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9802,
    Stamina: 4,
    Intellect: 9,
    Strength: 5,
    Defense: 6,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 9803,
    Stamina: 6,
    Intellect: 6,
    Strength: 10,
    Defense: 5,
    HP: 106,
    Attack: 20,
    Power: 31,
  },
  {
    Id: 9804,
    Stamina: 3,
    Intellect: 9,
    Strength: 4,
    Defense: 3,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9805,
    Stamina: 8,
    Intellect: 6,
    Strength: 1,
    Defense: 5,
    HP: 108,
    Attack: 11,
    Power: 22,
  },
  {
    Id: 9806,
    Stamina: 3,
    Intellect: 2,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9807,
    Stamina: 4,
    Intellect: 10,
    Strength: 6,
    Defense: 3,
    HP: 104,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9808,
    Stamina: 3,
    Intellect: 5,
    Strength: 2,
    Defense: 11,
    HP: 103,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9809,
    Stamina: 5,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9810,
    Stamina: 4,
    Intellect: 5,
    Strength: 2,
    Defense: 10,
    HP: 104,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9811,
    Stamina: 2,
    Intellect: 11,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 28,
  },
  {
    Id: 9812,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9813,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9814,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9815,
    Stamina: 5,
    Intellect: 5,
    Strength: 8,
    Defense: 6,
    HP: 105,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 9816,
    Stamina: 7,
    Intellect: 5,
    Strength: 5,
    Defense: 4,
    HP: 107,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9817,
    Stamina: 4,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 104,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9818,
    Stamina: 9,
    Intellect: 6,
    Strength: 5,
    Defense: 1,
    HP: 109,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 9819,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 104,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 9820,
    Stamina: 3,
    Intellect: 6,
    Strength: 6,
    Defense: 4,
    HP: 103,
    Attack: 16,
    Power: 26,
  },
  {
    Id: 9821,
    Stamina: 8,
    Intellect: 4,
    Strength: 8,
    Defense: 2,
    HP: 108,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 9822,
    Stamina: 2,
    Intellect: 9,
    Strength: 3,
    Defense: 7,
    HP: 102,
    Attack: 13,
    Power: 29,
  },
  {
    Id: 9823,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9824,
    Stamina: 7,
    Intellect: 2,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9825,
    Stamina: 3,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 103,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9826,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 7,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9827,
    Stamina: 7,
    Intellect: 3,
    Strength: 2,
    Defense: 6,
    HP: 107,
    Attack: 12,
    Power: 21,
  },
  {
    Id: 9828,
    Stamina: 5,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 105,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9829,
    Stamina: 6,
    Intellect: 5,
    Strength: 2,
    Defense: 5,
    HP: 106,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9830,
    Stamina: 2,
    Intellect: 2,
    Strength: 11,
    Defense: 6,
    HP: 102,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 9831,
    Stamina: 6,
    Intellect: 5,
    Strength: 3,
    Defense: 8,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9832,
    Stamina: 8,
    Intellect: 4,
    Strength: 5,
    Defense: 7,
    HP: 108,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9833,
    Stamina: 3,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 103,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9834,
    Stamina: 7,
    Intellect: 5,
    Strength: 3,
    Defense: 7,
    HP: 107,
    Attack: 13,
    Power: 25,
  },
  {
    Id: 9835,
    Stamina: 3,
    Intellect: 9,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9836,
    Stamina: 4,
    Intellect: 11,
    Strength: 5,
    Defense: 1,
    HP: 104,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9837,
    Stamina: 2,
    Intellect: 5,
    Strength: 5,
    Defense: 6,
    HP: 102,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9838,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 2,
    HP: 108,
    Attack: 15,
    Power: 20,
  },
  {
    Id: 9839,
    Stamina: 5,
    Intellect: 6,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9840,
    Stamina: 4,
    Intellect: 7,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9841,
    Stamina: 2,
    Intellect: 2,
    Strength: 11,
    Defense: 4,
    HP: 102,
    Attack: 21,
    Power: 27,
  },
  {
    Id: 9842,
    Stamina: 3,
    Intellect: 11,
    Strength: 4,
    Defense: 5,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 9843,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9844,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 2,
    HP: 105,
    Attack: 19,
    Power: 24,
  },
  {
    Id: 9845,
    Stamina: 2,
    Intellect: 2,
    Strength: 8,
    Defense: 7,
    HP: 102,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9846,
    Stamina: 4,
    Intellect: 5,
    Strength: 3,
    Defense: 6,
    HP: 104,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9847,
    Stamina: 2,
    Intellect: 3,
    Strength: 3,
    Defense: 10,
    HP: 102,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9848,
    Stamina: 6,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 106,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9849,
    Stamina: 6,
    Intellect: 4,
    Strength: 4,
    Defense: 7,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9850,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 9,
    HP: 105,
    Attack: 15,
    Power: 27,
  },
  {
    Id: 9851,
    Stamina: 8,
    Intellect: 5,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 22,
  },
  {
    Id: 9852,
    Stamina: 2,
    Intellect: 7,
    Strength: 9,
    Defense: 5,
    HP: 102,
    Attack: 19,
    Power: 31,
  },
  {
    Id: 9853,
    Stamina: 6,
    Intellect: 9,
    Strength: 3,
    Defense: 4,
    HP: 106,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9854,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9855,
    Stamina: 4,
    Intellect: 8,
    Strength: 2,
    Defense: 5,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9856,
    Stamina: 6,
    Intellect: 6,
    Strength: 2,
    Defense: 7,
    HP: 106,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9857,
    Stamina: 8,
    Intellect: 8,
    Strength: 2,
    Defense: 7,
    HP: 108,
    Attack: 12,
    Power: 27,
  },
  {
    Id: 9858,
    Stamina: 3,
    Intellect: 5,
    Strength: 6,
    Defense: 6,
    HP: 103,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9859,
    Stamina: 10,
    Intellect: 2,
    Strength: 6,
    Defense: 4,
    HP: 110,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9860,
    Stamina: 8,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 108,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9861,
    Stamina: 7,
    Intellect: 6,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9862,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 10,
    HP: 102,
    Attack: 17,
    Power: 32,
  },
  {
    Id: 9863,
    Stamina: 4,
    Intellect: 6,
    Strength: 5,
    Defense: 9,
    HP: 104,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 9864,
    Stamina: 2,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 102,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9865,
    Stamina: 5,
    Intellect: 5,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 27,
  },
  {
    Id: 9866,
    Stamina: 2,
    Intellect: 2,
    Strength: 3,
    Defense: 12,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9867,
    Stamina: 10,
    Intellect: 4,
    Strength: 2,
    Defense: 2,
    HP: 110,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 9868,
    Stamina: 5,
    Intellect: 6,
    Strength: 6,
    Defense: 3,
    HP: 105,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 9869,
    Stamina: 6,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9870,
    Stamina: 4,
    Intellect: 9,
    Strength: 4,
    Defense: 6,
    HP: 104,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9871,
    Stamina: 4,
    Intellect: 3,
    Strength: 8,
    Defense: 8,
    HP: 104,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 9872,
    Stamina: 4,
    Intellect: 3,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9873,
    Stamina: 10,
    Intellect: 5,
    Strength: 2,
    Defense: 6,
    HP: 110,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9874,
    Stamina: 3,
    Intellect: 6,
    Strength: 12,
    Defense: 5,
    HP: 103,
    Attack: 22,
    Power: 33,
  },
  {
    Id: 9875,
    Stamina: 4,
    Intellect: 7,
    Strength: 4,
    Defense: 5,
    HP: 104,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9876,
    Stamina: 6,
    Intellect: 4,
    Strength: 6,
    Defense: 2,
    HP: 106,
    Attack: 16,
    Power: 22,
  },
  {
    Id: 9877,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 4,
    HP: 103,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 9878,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 4,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9879,
    Stamina: 7,
    Intellect: 3,
    Strength: 1,
    Defense: 11,
    HP: 107,
    Attack: 11,
    Power: 25,
  },
  {
    Id: 9880,
    Stamina: 3,
    Intellect: 10,
    Strength: 4,
    Defense: 6,
    HP: 103,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 9881,
    Stamina: 3,
    Intellect: 11,
    Strength: 1,
    Defense: 7,
    HP: 103,
    Attack: 11,
    Power: 29,
  },
  {
    Id: 9882,
    Stamina: 5,
    Intellect: 4,
    Strength: 9,
    Defense: 3,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 9883,
    Stamina: 5,
    Intellect: 8,
    Strength: 5,
    Defense: 2,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9884,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 9885,
    Stamina: 3,
    Intellect: 6,
    Strength: 8,
    Defense: 4,
    HP: 103,
    Attack: 18,
    Power: 28,
  },
  {
    Id: 9886,
    Stamina: 6,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 106,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 9887,
    Stamina: 2,
    Intellect: 7,
    Strength: 8,
    Defense: 4,
    HP: 102,
    Attack: 18,
    Power: 29,
  },
  {
    Id: 9888,
    Stamina: 3,
    Intellect: 6,
    Strength: 10,
    Defense: 3,
    HP: 103,
    Attack: 20,
    Power: 29,
  },
  {
    Id: 9889,
    Stamina: 4,
    Intellect: 2,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9890,
    Stamina: 5,
    Intellect: 3,
    Strength: 9,
    Defense: 4,
    HP: 105,
    Attack: 19,
    Power: 26,
  },
  {
    Id: 9891,
    Stamina: 4,
    Intellect: 6,
    Strength: 8,
    Defense: 6,
    HP: 104,
    Attack: 18,
    Power: 30,
  },
  {
    Id: 9892,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9893,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 4,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9894,
    Stamina: 5,
    Intellect: 7,
    Strength: 3,
    Defense: 4,
    HP: 105,
    Attack: 13,
    Power: 24,
  },
  {
    Id: 9895,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 102,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9896,
    Stamina: 5,
    Intellect: 4,
    Strength: 5,
    Defense: 11,
    HP: 105,
    Attack: 15,
    Power: 30,
  },
  {
    Id: 9897,
    Stamina: 4,
    Intellect: 5,
    Strength: 9,
    Defense: 3,
    HP: 104,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9898,
    Stamina: 3,
    Intellect: 2,
    Strength: 4,
    Defense: 10,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9899,
    Stamina: 3,
    Intellect: 8,
    Strength: 2,
    Defense: 4,
    HP: 103,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9900,
    Stamina: 4,
    Intellect: 11,
    Strength: 4,
    Defense: 7,
    HP: 104,
    Attack: 14,
    Power: 32,
  },
  {
    Id: 9901,
    Stamina: 3,
    Intellect: 5,
    Strength: 3,
    Defense: 9,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9902,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 4,
    HP: 105,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9903,
    Stamina: 4,
    Intellect: 5,
    Strength: 8,
    Defense: 2,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9904,
    Stamina: 4,
    Intellect: 2,
    Strength: 8,
    Defense: 5,
    HP: 104,
    Attack: 18,
    Power: 25,
  },
  {
    Id: 9905,
    Stamina: 6,
    Intellect: 4,
    Strength: 3,
    Defense: 10,
    HP: 106,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9906,
    Stamina: 5,
    Intellect: 4,
    Strength: 6,
    Defense: 7,
    HP: 105,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9907,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 5,
    HP: 104,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9908,
    Stamina: 2,
    Intellect: 6,
    Strength: 4,
    Defense: 9,
    HP: 102,
    Attack: 14,
    Power: 29,
  },
  {
    Id: 9909,
    Stamina: 4,
    Intellect: 4,
    Strength: 10,
    Defense: 2,
    HP: 104,
    Attack: 20,
    Power: 26,
  },
  {
    Id: 9910,
    Stamina: 3,
    Intellect: 4,
    Strength: 8,
    Defense: 5,
    HP: 103,
    Attack: 18,
    Power: 27,
  },
  {
    Id: 9911,
    Stamina: 5,
    Intellect: 3,
    Strength: 8,
    Defense: 3,
    HP: 105,
    Attack: 18,
    Power: 24,
  },
  {
    Id: 9912,
    Stamina: 5,
    Intellect: 4,
    Strength: 2,
    Defense: 6,
    HP: 105,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9913,
    Stamina: 5,
    Intellect: 3,
    Strength: 5,
    Defense: 7,
    HP: 105,
    Attack: 15,
    Power: 25,
  },
  {
    Id: 9914,
    Stamina: 7,
    Intellect: 7,
    Strength: 3,
    Defense: 3,
    HP: 107,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 9915,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 4,
    HP: 105,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9916,
    Stamina: 5,
    Intellect: 2,
    Strength: 3,
    Defense: 8,
    HP: 105,
    Attack: 13,
    Power: 23,
  },
  {
    Id: 9917,
    Stamina: 4,
    Intellect: 5,
    Strength: 7,
    Defense: 2,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9918,
    Stamina: 2,
    Intellect: 5,
    Strength: 7,
    Defense: 6,
    HP: 102,
    Attack: 17,
    Power: 28,
  },
  {
    Id: 9919,
    Stamina: 4,
    Intellect: 5,
    Strength: 5,
    Defense: 11,
    HP: 104,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 9920,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 8,
    HP: 110,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9921,
    Stamina: 3,
    Intellect: 4,
    Strength: 1,
    Defense: 8,
    HP: 103,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 9922,
    Stamina: 4,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 104,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9923,
    Stamina: 9,
    Intellect: 2,
    Strength: 1,
    Defense: 5,
    HP: 109,
    Attack: 11,
    Power: 18,
  },
  {
    Id: 9924,
    Stamina: 7,
    Intellect: 4,
    Strength: 8,
    Defense: 4,
    HP: 107,
    Attack: 18,
    Power: 26,
  },
  {
    Id: 9925,
    Stamina: 6,
    Intellect: 10,
    Strength: 5,
    Defense: 4,
    HP: 106,
    Attack: 15,
    Power: 29,
  },
  {
    Id: 9926,
    Stamina: 8,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 108,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 9927,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 9,
    HP: 105,
    Attack: 12,
    Power: 26,
  },
  {
    Id: 9928,
    Stamina: 7,
    Intellect: 2,
    Strength: 4,
    Defense: 7,
    HP: 107,
    Attack: 14,
    Power: 23,
  },
  {
    Id: 9929,
    Stamina: 12,
    Intellect: 2,
    Strength: 6,
    Defense: 2,
    HP: 112,
    Attack: 16,
    Power: 20,
  },
  {
    Id: 9930,
    Stamina: 6,
    Intellect: 10,
    Strength: 6,
    Defense: 1,
    HP: 106,
    Attack: 16,
    Power: 27,
  },
  {
    Id: 9931,
    Stamina: 4,
    Intellect: 6,
    Strength: 2,
    Defense: 6,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9932,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 8,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9933,
    Stamina: 3,
    Intellect: 5,
    Strength: 8,
    Defense: 8,
    HP: 103,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 9934,
    Stamina: 5,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 105,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 9935,
    Stamina: 6,
    Intellect: 2,
    Strength: 2,
    Defense: 10,
    HP: 106,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9936,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 9937,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9938,
    Stamina: 8,
    Intellect: 4,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 9939,
    Stamina: 4,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 104,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9940,
    Stamina: 3,
    Intellect: 4,
    Strength: 11,
    Defense: 4,
    HP: 103,
    Attack: 21,
    Power: 29,
  },
  {
    Id: 9941,
    Stamina: 4,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 104,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9942,
    Stamina: 3,
    Intellect: 3,
    Strength: 5,
    Defense: 6,
    HP: 103,
    Attack: 15,
    Power: 24,
  },
  {
    Id: 9943,
    Stamina: 6,
    Intellect: 7,
    Strength: 4,
    Defense: 4,
    HP: 106,
    Attack: 14,
    Power: 25,
  },
  {
    Id: 9944,
    Stamina: 4,
    Intellect: 8,
    Strength: 4,
    Defense: 8,
    HP: 104,
    Attack: 14,
    Power: 30,
  },
  {
    Id: 9945,
    Stamina: 3,
    Intellect: 3,
    Strength: 4,
    Defense: 9,
    HP: 103,
    Attack: 14,
    Power: 26,
  },
  {
    Id: 9946,
    Stamina: 5,
    Intellect: 5,
    Strength: 2,
    Defense: 8,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9947,
    Stamina: 6,
    Intellect: 6,
    Strength: 7,
    Defense: 3,
    HP: 106,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9948,
    Stamina: 1,
    Intellect: 4,
    Strength: 9,
    Defense: 5,
    HP: 101,
    Attack: 19,
    Power: 28,
  },
  {
    Id: 9949,
    Stamina: 3,
    Intellect: 3,
    Strength: 7,
    Defense: 3,
    HP: 103,
    Attack: 17,
    Power: 23,
  },
  {
    Id: 9950,
    Stamina: 4,
    Intellect: 10,
    Strength: 2,
    Defense: 2,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9951,
    Stamina: 6,
    Intellect: 6,
    Strength: 9,
    Defense: 2,
    HP: 106,
    Attack: 19,
    Power: 27,
  },
  {
    Id: 9952,
    Stamina: 3,
    Intellect: 10,
    Strength: 3,
    Defense: 7,
    HP: 103,
    Attack: 13,
    Power: 30,
  },
  {
    Id: 9953,
    Stamina: 3,
    Intellect: 4,
    Strength: 7,
    Defense: 5,
    HP: 103,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9954,
    Stamina: 8,
    Intellect: 3,
    Strength: 6,
    Defense: 6,
    HP: 108,
    Attack: 16,
    Power: 25,
  },
  {
    Id: 9955,
    Stamina: 2,
    Intellect: 10,
    Strength: 3,
    Defense: 4,
    HP: 102,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9956,
    Stamina: 2,
    Intellect: 3,
    Strength: 5,
    Defense: 10,
    HP: 102,
    Attack: 15,
    Power: 28,
  },
  {
    Id: 9957,
    Stamina: 2,
    Intellect: 4,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 28,
  },
  {
    Id: 9958,
    Stamina: 5,
    Intellect: 5,
    Strength: 7,
    Defense: 7,
    HP: 105,
    Attack: 17,
    Power: 29,
  },
  {
    Id: 9959,
    Stamina: 9,
    Intellect: 3,
    Strength: 2,
    Defense: 3,
    HP: 109,
    Attack: 12,
    Power: 18,
  },
  {
    Id: 9960,
    Stamina: 5,
    Intellect: 9,
    Strength: 2,
    Defense: 4,
    HP: 105,
    Attack: 12,
    Power: 25,
  },
  {
    Id: 9961,
    Stamina: 2,
    Intellect: 9,
    Strength: 6,
    Defense: 6,
    HP: 102,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 9962,
    Stamina: 2,
    Intellect: 2,
    Strength: 7,
    Defense: 8,
    HP: 102,
    Attack: 17,
    Power: 27,
  },
  {
    Id: 9963,
    Stamina: 8,
    Intellect: 5,
    Strength: 4,
    Defense: 3,
    HP: 108,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9964,
    Stamina: 11,
    Intellect: 3,
    Strength: 6,
    Defense: 5,
    HP: 111,
    Attack: 16,
    Power: 24,
  },
  {
    Id: 9965,
    Stamina: 3,
    Intellect: 6,
    Strength: 5,
    Defense: 5,
    HP: 103,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9966,
    Stamina: 4,
    Intellect: 3,
    Strength: 2,
    Defense: 9,
    HP: 104,
    Attack: 12,
    Power: 24,
  },
  {
    Id: 9967,
    Stamina: 2,
    Intellect: 7,
    Strength: 5,
    Defense: 9,
    HP: 102,
    Attack: 15,
    Power: 31,
  },
  {
    Id: 9968,
    Stamina: 4,
    Intellect: 3,
    Strength: 10,
    Defense: 7,
    HP: 104,
    Attack: 20,
    Power: 30,
  },
  {
    Id: 9969,
    Stamina: 3,
    Intellect: 10,
    Strength: 6,
    Defense: 5,
    HP: 103,
    Attack: 16,
    Power: 31,
  },
  {
    Id: 9970,
    Stamina: 3,
    Intellect: 5,
    Strength: 7,
    Defense: 8,
    HP: 103,
    Attack: 17,
    Power: 30,
  },
  {
    Id: 9971,
    Stamina: 7,
    Intellect: 7,
    Strength: 4,
    Defense: 1,
    HP: 107,
    Attack: 14,
    Power: 22,
  },
  {
    Id: 9972,
    Stamina: 8,
    Intellect: 3,
    Strength: 4,
    Defense: 4,
    HP: 108,
    Attack: 14,
    Power: 21,
  },
  {
    Id: 9973,
    Stamina: 8,
    Intellect: 6,
    Strength: 2,
    Defense: 4,
    HP: 108,
    Attack: 12,
    Power: 22,
  },
  {
    Id: 9974,
    Stamina: 4,
    Intellect: 4,
    Strength: 11,
    Defense: 6,
    HP: 104,
    Attack: 21,
    Power: 31,
  },
  {
    Id: 9975,
    Stamina: 2,
    Intellect: 10,
    Strength: 8,
    Defense: 3,
    HP: 102,
    Attack: 18,
    Power: 31,
  },
  {
    Id: 9976,
    Stamina: 5,
    Intellect: 9,
    Strength: 4,
    Defense: 8,
    HP: 105,
    Attack: 14,
    Power: 31,
  },
  {
    Id: 9977,
    Stamina: 2,
    Intellect: 10,
    Strength: 6,
    Defense: 4,
    HP: 102,
    Attack: 16,
    Power: 30,
  },
  {
    Id: 9978,
    Stamina: 7,
    Intellect: 7,
    Strength: 1,
    Defense: 5,
    HP: 107,
    Attack: 11,
    Power: 23,
  },
  {
    Id: 9979,
    Stamina: 10,
    Intellect: 3,
    Strength: 2,
    Defense: 5,
    HP: 110,
    Attack: 12,
    Power: 20,
  },
  {
    Id: 9980,
    Stamina: 14,
    Intellect: 2,
    Strength: 3,
    Defense: 3,
    HP: 114,
    Attack: 13,
    Power: 18,
  },
  {
    Id: 9981,
    Stamina: 3,
    Intellect: 6,
    Strength: 3,
    Defense: 8,
    HP: 103,
    Attack: 13,
    Power: 27,
  },
  {
    Id: 9982,
    Stamina: 5,
    Intellect: 2,
    Strength: 11,
    Defense: 5,
    HP: 105,
    Attack: 21,
    Power: 28,
  },
  {
    Id: 9983,
    Stamina: 10,
    Intellect: 4,
    Strength: 3,
    Defense: 4,
    HP: 110,
    Attack: 13,
    Power: 21,
  },
  {
    Id: 9984,
    Stamina: 4,
    Intellect: 6,
    Strength: 3,
    Defense: 7,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9985,
    Stamina: 7,
    Intellect: 7,
    Strength: 2,
    Defense: 4,
    HP: 107,
    Attack: 12,
    Power: 23,
  },
  {
    Id: 9986,
    Stamina: 9,
    Intellect: 4,
    Strength: 4,
    Defense: 6,
    HP: 109,
    Attack: 14,
    Power: 24,
  },
  {
    Id: 9987,
    Stamina: 4,
    Intellect: 8,
    Strength: 5,
    Defense: 3,
    HP: 104,
    Attack: 15,
    Power: 26,
  },
  {
    Id: 9988,
    Stamina: 10,
    Intellect: 4,
    Strength: 5,
    Defense: 3,
    HP: 110,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 9989,
    Stamina: 5,
    Intellect: 2,
    Strength: 7,
    Defense: 3,
    HP: 105,
    Attack: 17,
    Power: 22,
  },
  {
    Id: 9990,
    Stamina: 4,
    Intellect: 11,
    Strength: 3,
    Defense: 2,
    HP: 104,
    Attack: 13,
    Power: 26,
  },
  {
    Id: 9991,
    Stamina: 7,
    Intellect: 8,
    Strength: 7,
    Defense: 1,
    HP: 107,
    Attack: 17,
    Power: 26,
  },
  {
    Id: 9992,
    Stamina: 2,
    Intellect: 5,
    Strength: 6,
    Defense: 8,
    HP: 102,
    Attack: 16,
    Power: 29,
  },
  {
    Id: 9993,
    Stamina: 3,
    Intellect: 2,
    Strength: 9,
    Defense: 4,
    HP: 103,
    Attack: 19,
    Power: 25,
  },
  {
    Id: 9994,
    Stamina: 4,
    Intellect: 3,
    Strength: 7,
    Defense: 4,
    HP: 104,
    Attack: 17,
    Power: 24,
  },
  {
    Id: 9995,
    Stamina: 4,
    Intellect: 8,
    Strength: 6,
    Defense: 11,
    HP: 104,
    Attack: 16,
    Power: 35,
  },
  {
    Id: 9996,
    Stamina: 10,
    Intellect: 2,
    Strength: 5,
    Defense: 5,
    HP: 110,
    Attack: 15,
    Power: 22,
  },
  {
    Id: 9997,
    Stamina: 3,
    Intellect: 6,
    Strength: 7,
    Defense: 2,
    HP: 103,
    Attack: 17,
    Power: 25,
  },
  {
    Id: 9998,
    Stamina: 2,
    Intellect: 7,
    Strength: 2,
    Defense: 9,
    HP: 102,
    Attack: 12,
    Power: 28,
  },
  {
    Id: 9999,
    Stamina: 6,
    Intellect: 8,
    Strength: 9,
    Defense: 9,
    HP: 106,
    Attack: 19,
    Power: 36,
  },
  {
    Id: 10000,
    Stamina: 5,
    Intellect: 7,
    Strength: 1,
    Defense: 6,
    HP: 105,
    Attack: 11,
    Power: 24,
  },
];
