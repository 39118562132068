import React from "react";

const ranks = [
  "8617",
  "3390",
  "9570",
  "5702",
  "620",
  "6905",
  "2264",
  "9559",
  "9510",
  "2239",
  "5610",
  "873",
  "6829",
  "9056",
  "6635",
  "9975",
  "7606",
  "7588",
  "8403",
  "8743",
  "1288",
  "6940",
  "6638",
  "6486",
  "9716",
  "9514",
  "8498",
  "7731",
  "9123",
  "2436",
  "6600",
  "7582",
  "7699",
  "613",
  "4843",
  "2222",
  "4156",
  "957",
  "6534",
  "4794",
  "5488",
  "2819",
  "6195",
  "5639",
  "1220",
  "2873",
  "6532",
  "2511",
  "765",
  "1898",
  "2170",
  "1280",
  "3324",
  "9029",
  "1912",
  "8415",
  "8169",
  "9069",
  "6895",
  "8429",
  "5077",
  "8716",
  "555",
  "7618",
  "9482",
  "5003",
  "2297",
  "7257",
  "4739",
  "8764",
  "9260",
  "3949",
  "9933",
  "7003",
  "2746",
  "3472",
  "3657",
  "9230",
  "6705",
  "5320",
  "6749",
  "3164",
  "6603",
  "6953",
  "5638",
  "1759",
  "7271",
  "7031",
  "9991",
  "2957",
  "4934",
  "1167",
  "3299",
  "4402",
  "2298",
  "7014",
  "7051",
  "463",
  "4345",
  "8229",
  "3580",
  "3064",
  "3013",
  "6737",
  "3671",
  "8544",
  "5022",
  "5028",
  "4111",
  "5581",
  "9384",
  "5187",
  "4887",
  "696",
  "1338",
  "1307",
  "8932",
  "4190",
  "9694",
  "2654",
  "3208",
  "3273",
  "9005",
  "2607",
  "1345",
  "6126",
  "6279",
  "2422",
  "7709",
  "9721",
  "8931",
  "9236",
  "7064",
  "7674",
  "4871",
  "1410",
  "1043",
  "2468",
  "6861",
  "7301",
  "3610",
  "4122",
  "3910",
  "6817",
  "3036",
  "1007",
  "6783",
  "6631",
  "3784",
  "6449",
  "2406",
  "25",
  "4391",
  "5393",
  "356",
  "9821",
  "4900",
  "42",
  "3061",
  "5607",
  "6899",
  "7275",
  "3389",
  "9518",
  "9471",
  "7463",
  "8553",
  "329",
  "6110",
  "4493",
  "1985",
  "2492",
  "5473",
  "3055",
  "4860",
  "6689",
  "8829",
  "2055",
  "8887",
  "9713",
  "6428",
  "4182",
  "2894",
  "3496",
  "6114",
  "6226",
  "9447",
  "5398",
  "3069",
  "6867",
  "5705",
  "6969",
  "8107",
  "9597",
  "7198",
  "6258",
  "5847",
  "6983",
  "607",
  "8833",
  "8727",
  "7723",
  "4172",
  "6508",
  "510",
  "2216",
  "8196",
  "664",
  "6208",
  "470",
  "9080",
  "9117",
  "1420",
  "4405",
  "1624",
  "3521",
  "4771",
  "3869",
  "7564",
  "8252",
  "4458",
  "5152",
  "9974",
  "7474",
  "4141",
  "8684",
  "5678",
  "1366",
  "8548",
  "5089",
  "52",
  "892",
  "5653",
  "3020",
  "2823",
  "7785",
  "6047",
  "3662",
  "4617",
  "95",
  "9338",
  "6289",
  "3135",
  "935",
  "1128",
  "3620",
  "4498",
  "6325",
  "9986",
  "3301",
  "4859",
  "4522",
  "8686",
  "5270",
  "6061",
  "7209",
  "6450",
  "5486",
  "7055",
  "9463",
  "6164",
  "7553",
  "6230",
  "5262",
  "9319",
  "5675",
  "1001",
  "7772",
  "4709",
  "2919",
  "2015",
  "279",
  "1012",
  "7852",
  "5650",
  "9638",
  "1421",
  "2743",
  "2495",
  "4601",
  "974",
  "7973",
  "2999",
  "1782",
  "9815",
  "1916",
  "8504",
  "4042",
  "7241",
  "1751",
  "3779",
  "1559",
  "5453",
  "7489",
  "5039",
  "1472",
  "4479",
  "9017",
  "3045",
  "833",
  "422",
  "3655",
  "6331",
  "3497",
  "5435",
  "6740",
  "7181",
  "6229",
  "5373",
  "4106",
  "6522",
  "3841",
  "6864",
  "9354",
  "9051",
  "2984",
  "5963",
  "8691",
  "2023",
  "1397",
  "6301",
  "6273",
  "2020",
  "8253",
  "5902",
  "6516",
  "2044",
  "4051",
  "2985",
  "6800",
  "3251",
  "4538",
  "8248",
  "8251",
  "7356",
  "1755",
  "9842",
  "7234",
  "2544",
  "625",
  "3952",
  "6804",
  "8327",
  "2405",
  "1069",
  "2009",
  "1801",
  "5721",
  "2652",
  "635",
  "9763",
  "7118",
  "8212",
  "9392",
  "2412",
  "7600",
  "5766",
  "3891",
  "7638",
  "1081",
  "2963",
  "7519",
  "4690",
  "9366",
  "443",
  "9093",
  "9616",
  "655",
  "8101",
  "9692",
  "7357",
  "471",
  "1024",
  "6181",
  "863",
  "387",
  "3534",
  "1525",
  "1399",
  "1971",
  "4194",
  "4320",
  "815",
  "2818",
  "4216",
  "3596",
  "312",
  "7430",
  "271",
  "7406",
  "8775",
  "9360",
  "9870",
  "6202",
  "2773",
  "6767",
  "5891",
  "7300",
  "365",
  "4578",
  "6338",
  "5027",
  "5936",
  "2129",
  "2742",
  "2849",
  "8655",
  "1777",
  "160",
  "1088",
  "2961",
  "3296",
  "8683",
  "9880",
  "3231",
  "777",
  "754",
  "3154",
  "44",
  "7554",
  "2978",
  "419",
  "4220",
  "8850",
  "752",
  "2860",
  "4550",
  "802",
  "1992",
  "8163",
  "2800",
  "9003",
  "1740",
  "3026",
  "4902",
  "1989",
  "4923",
  "6615",
  "7078",
  "5489",
  "2274",
  "4779",
  "4780",
  "7964",
  "5389",
  "206",
  "623",
  "8172",
  "7706",
  "1429",
  "3423",
  "3021",
  "3315",
  "68",
  "4055",
  "4328",
  "9717",
  "9328",
  "3260",
  "8226",
  "632",
  "5103",
  "9767",
  "9190",
  "7171",
  "6067",
  "8183",
  "9459",
  "4211",
  "2798",
  "3908",
  "5412",
  "9038",
  "7018",
  "2281",
  "3083",
  "8416",
  "1602",
  "3589",
  "2367",
  "5905",
  "3218",
  "4797",
  "1246",
  "4968",
  "6754",
  "9394",
  "908",
  "9613",
  "213",
  "7684",
  "5232",
  "3494",
  "2365",
  "2853",
  "6178",
  "2976",
  "3235",
  "7372",
  "7139",
  "148",
  "5019",
  "2157",
  "8064",
  "1835",
  "4212",
  "7782",
  "5326",
  "3509",
  "4206",
  "7819",
  "9015",
  "8943",
  "6080",
  "3498",
  "5535",
  "6858",
  "3532",
  "5589",
  "3766",
  "348",
  "5693",
  "7856",
  "7617",
  "4461",
  "305",
  "2596",
  "9009",
  "1629",
  "7984",
  "8676",
  "4993",
  "8574",
  "8168",
  "7214",
  "8355",
  "5548",
  "2344",
  "6966",
  "1907",
  "673",
  "497",
  "3205",
  "7975",
  "4513",
  "7380",
  "126",
  "5863",
  "411",
  "1814",
  "7075",
  "2623",
  "4423",
  "2524",
  "6324",
  "1078",
  "9555",
  "9108",
  "1977",
  "4226",
  "5139",
  "9100",
  "2576",
  "4109",
  "6244",
  "4352",
  "9359",
  "2317",
  "9280",
  "34",
  "9771",
  "8876",
  "2364",
  "3979",
  "3629",
  "8408",
  "1122",
  "5714",
  "9172",
  "4652",
  "7117",
  "9027",
  "7988",
  "5838",
  "8171",
  "5647",
  "408",
  "2202",
  "1125",
  "5439",
  "7845",
  "5901",
  "249",
  "8105",
  "5234",
  "8380",
  "849",
  "4105",
  "6335",
  "1039",
  "7006",
  "407",
  "2204",
  "2477",
  "8698",
  "9131",
  "7925",
  "9995",
  "6370",
  "8541",
  "5111",
  "211",
  "1172",
  "8017",
  "4330",
  "5075",
  "7636",
  "9144",
  "7230",
  "1311",
  "311",
  "7774",
  "7814",
  "6296",
  "8477",
  "1623",
  "6361",
  "865",
  "5695",
  "5505",
  "7318",
  "3818",
  "351",
  "7595",
  "5065",
  "1633",
  "7347",
  "6227",
  "1169",
  "2051",
  "9329",
  "2699",
  "2127",
  "6536",
  "2549",
  "5318",
  "2698",
  "3115",
  "8385",
  "3958",
  "7108",
  "7113",
  "4980",
  "960",
  "6996",
  "780",
  "858",
  "4965",
  "5551",
  "8217",
  "2871",
  "6247",
  "4272",
  "4528",
  "9651",
  "7880",
  "1862",
  "4728",
  "4410",
  "3079",
  "9944",
  "429",
  "2600",
  "4685",
  "6251",
  "154",
  "7639",
  "1872",
  "9969",
  "3845",
  "6995",
  "8396",
  "8170",
  "9857",
  "4364",
  "4950",
  "2501",
  "8700",
  "37",
  "3579",
  "8948",
  "6017",
  "9513",
  "9775",
  "1053",
  "7366",
  "2038",
  "3492",
  "8034",
  "4336",
  "5642",
  "2040",
  "7126",
  "4355",
  "8587",
  "136",
  "9788",
  "4331",
  "8844",
  "455",
  "2532",
  "3293",
  "9019",
  "1655",
  "1761",
  "230",
  "1680",
  "4383",
  "2866",
  "1648",
  "9967",
  "7365",
  "8134",
  "2497",
  "7232",
  "3821",
  "7592",
  "4440",
  "1409",
  "2826",
  "4371",
  "7515",
  "3066",
  "4349",
  "4138",
  "9797",
  "5822",
  "9607",
  "1515",
  "6388",
  "6342",
  "1721",
  "2500",
  "1310",
  "5225",
  "1350",
  "246",
  "6593",
  "6205",
  "58",
  "4127",
  "9418",
  "2138",
  "4137",
  "8656",
  "6490",
  "9666",
  "9961",
  "631",
  "9147",
  "8813",
  "2808",
  "3418",
  "1347",
  "9816",
  "6355",
  "547",
  "90",
  "9083",
  "4854",
  "5271",
  "1003",
  "1563",
  "8087",
  "1855",
  "6716",
  "7879",
  "5319",
  "3990",
  "6231",
  "6780",
  "820",
  "3119",
  "2363",
  "8113",
  "9772",
  "628",
  "7904",
  "9492",
  "8609",
  "5502",
  "1239",
  "4911",
  "3716",
  "4143",
  "5842",
  "3182",
  "7512",
  "3739",
  "1292",
  "8443",
  "3795",
  "6698",
  "4517",
  "1182",
  "8239",
  "218",
  "5324",
  "4099",
  "6669",
  "6372",
  "8482",
  "2177",
  "1305",
  "1490",
  "3278",
  "5768",
  "937",
  "7483",
  "3279",
  "4280",
  "4187",
  "8126",
  "7915",
  "7499",
  "6581",
  "6683",
  "8277",
  "1954",
  "139",
  "2390",
  "4755",
  "4237",
  "7913",
  "6785",
  "2921",
  "3665",
  "6763",
  "4325",
  "6715",
  "3444",
  "9355",
  "8534",
  "982",
  "7146",
  "4830",
  "1852",
  "3261",
  "8487",
  "1148",
  "3348",
  "4249",
  "6816",
  "7158",
  "7411",
  "1769",
  "5652",
  "2457",
  "7101",
  "9800",
  "6344",
  "678",
  "1294",
  "1458",
  "8227",
  "6094",
  "7951",
  "2145",
  "6460",
  "9863",
  "1004",
  "1626",
  "2192",
  "675",
  "2832",
  "3332",
  "4825",
  "9854",
  "2012",
  "2723",
  "8075",
  "8814",
  "421",
  "5472",
  "561",
  "4341",
  "9982",
  "910",
  "5066",
  "1150",
  "4004",
  "9781",
  "723",
  "6265",
  "1733",
  "5856",
  "1177",
  "8282",
  "7993",
  "603",
  "2266",
  "1427",
  "2167",
  "5940",
  "965",
  "5993",
  "2650",
  "5363",
  "1184",
  "5879",
  "9599",
  "7311",
  "8181",
  "1556",
  "2093",
  "7855",
  "3687",
  "9422",
  "1489",
  "1807",
  "5941",
  "6448",
  "1141",
  "308",
  "5836",
  "9670",
  "2898",
  "7134",
  "6981",
  "1816",
  "5241",
  "9237",
  "2176",
  "3644",
  "3846",
  "8837",
  "2153",
  "9976",
  "288",
  "5011",
  "7067",
  "3402",
  "9429",
  "6332",
  "1403",
  "5725",
  "3073",
  "784",
  "2191",
  "1582",
  "3070",
  "89",
  "3487",
  "9590",
  "4439",
  "8423",
  "9437",
  "9778",
  "9295",
  "8015",
  "8022",
  "8364",
  "59",
  "523",
  "5558",
  "5347",
  "8323",
  "493",
  "1388",
  "4144",
  "7828",
  "3277",
  "2253",
  "575",
  "3185",
  "8966",
  "2727",
  "6004",
  "4053",
  "5183",
  "7438",
  "1017",
  "7634",
  "1829",
  "8255",
  "3018",
  "2033",
  "4791",
  "9403",
  "3238",
  "4408",
  "741",
  "8223",
  "135",
  "2882",
  "681",
  "242",
  "4273",
  "8203",
  "8533",
  "4337",
  "8461",
  "4551",
  "5566",
  "5227",
  "7817",
  "770",
  "7714",
  "2545",
  "3566",
  "4437",
  "3462",
  "4354",
  "3404",
  "6608",
  "1072",
  "1881",
  "2578",
  "1966",
  "4297",
  "4684",
  "1121",
  "1413",
  "4180",
  "7147",
  "4416",
  "4738",
  "729",
  "214",
  "1653",
  "303",
  "9858",
  "225",
  "7302",
  "3303",
  "1884",
  "9920",
  "7771",
  "9430",
  "1900",
  "1199",
  "8088",
  "889",
  "5572",
  "7514",
  "3525",
  "4068",
  "9611",
  "7267",
  "8702",
  "6839",
  "1679",
  "2103",
  "2952",
  "3731",
  "2612",
  "1238",
  "2343",
  "7808",
  "4989",
  "9749",
  "1216",
  "1391",
  "9509",
  "1641",
  "7896",
  "1408",
  "4147",
  "7405",
  "496",
  "5957",
  "2837",
  "2074",
  "2648",
  "3770",
  "5841",
  "4602",
  "3837",
  "8208",
  "267",
  "7264",
  "8593",
  "8860",
  "2681",
  "3919",
  "5942",
  "357",
  "893",
  "8540",
  "5570",
  "2801",
  "2061",
  "3615",
  "7510",
  "2315",
  "8728",
  "8714",
  "5519",
  "2905",
  "3996",
  "6888",
  "4898",
  "2571",
  "7946",
  "1526",
  "3690",
  "3463",
  "3961",
  "2210",
  "7663",
  "1477",
  "5025",
  "8492",
  "5305",
  "1549",
  "1583",
  "9861",
  "748",
  "8772",
  "2620",
  "2705",
  "354",
  "7143",
  "6329",
  "9926",
  "3991",
  "8530",
  "9010",
  "30",
  "1767",
  "9517",
  "6491",
  "9642",
  "4576",
  "4599",
  "1273",
  "9891",
  "5531",
  "4777",
  "6602",
  "7646",
  "9875",
  "6505",
  "241",
  "6982",
  "6395",
  "5989",
  "3171",
  "7427",
  "4656",
  "8246",
  "5513",
  "9544",
  "3965",
  "4571",
  "9519",
  "5446",
  "7999",
  "817",
  "8218",
  "4148",
  "3940",
  "6463",
  "8207",
  "7155",
  "9068",
  "6547",
  "7745",
  "3249",
  "9124",
  "1600",
  "6871",
  "4747",
  "4436",
  "4627",
  "2539",
  "3095",
  "6977",
  "3053",
  "8557",
  "1622",
  "3150",
  "7029",
  "7068",
  "5093",
  "4516",
  "7743",
  "5209",
  "1741",
  "7934",
  "2948",
  "430",
  "6935",
  "6423",
  "1996",
  "4463",
  "4594",
  "9769",
  "6016",
  "5764",
  "3474",
  "3900",
  "4250",
  "3510",
  "6261",
  "6546",
  "4480",
  "4865",
  "4344",
  "8567",
  "7351",
  "866",
  "2209",
  "8140",
  "7261",
  "8572",
  "1675",
  "3302",
  "8271",
  "3924",
  "9762",
  "6185",
  "8026",
  "9330",
  "98",
  "6236",
  "6365",
  "8514",
  "9929",
  "8909",
  "6218",
  "840",
  "961",
  "1115",
  "1453",
  "8559",
  "1776",
  "9075",
  "2039",
  "7035",
  "4919",
  "2387",
  "2067",
  "5765",
  "3193",
  "5991",
  "3626",
  "6266",
  "482",
  "5541",
  "8114",
  "67",
  "8904",
  "8454",
  "8392",
  "1942",
  "353",
  "331",
  "4082",
  "8916",
  "9213",
  "2851",
  "9373",
  "5366",
  "665",
  "9655",
  "1865",
  "3571",
  "9141",
  "4348",
  "8071",
  "5981",
  "716",
  "6139",
  "9206",
  "1452",
  "3362",
  "4390",
  "7873",
  "3546",
  "7650",
  "9110",
  "7466",
  "1178",
  "3507",
  "3987",
  "1635",
  "6005",
  "7465",
  "4386",
  "8606",
  "3720",
  "5273",
  "5281",
  "3114",
  "4332",
  "8518",
  "5741",
  "9595",
  "2726",
  "1256",
  "4195",
  "9745",
  "2987",
  "1257",
  "4303",
  "238",
  "1096",
  "5959",
  "6656",
  "2935",
  "8550",
  "3562",
  "8651",
  "4184",
  "3978",
  "9105",
  "4882",
  "5798",
  "2820",
  "5904",
  "5597",
  "868",
  "917",
  "3093",
  "1331",
  "9473",
  "9362",
  "5699",
  "9528",
  "7765",
  "2195",
  "3994",
  "7102",
  "6381",
  "2265",
  "5584",
  "5977",
  "5409",
  "5200",
  "549",
  "4910",
  "4638",
  "9070",
  "2100",
  "5617",
  "5767",
  "491",
  "6980",
  "3419",
  "4029",
  "7713",
  "557",
  "1905",
  "6037",
  "8521",
  "9390",
  "651",
  "2441",
  "5965",
  "8608",
  "4392",
  "1854",
  "3040",
  "867",
  "6950",
  "3068",
  "5830",
  "6810",
  "3082",
  "7560",
  "5016",
  "4775",
  "4886",
  "5040",
  "366",
  "4000",
  "9385",
  "2749",
  "4616",
  "7343",
  "1010",
  "8826",
  "9304",
  "6818",
  "9335",
  "1407",
  "6275",
  "9516",
  "9573",
  "2136",
  "3588",
  "1455",
  "947",
  "5101",
  "2526",
  "5455",
  "9663",
  "2744",
  "4435",
  "8000",
  "4832",
  "8976",
  "8630",
  "7919",
  "4315",
  "39",
  "2081",
  "5813",
  "5004",
  "7557",
  "2156",
  "2613",
  "404",
  "3102",
  "2444",
  "6965",
  "3604",
  "9753",
  "579",
  "6567",
  "5636",
  "2456",
  "8053",
  "556",
  "2426",
  "6075",
  "8810",
  "4140",
  "9793",
  "7221",
  "5201",
  "3504",
  "8835",
  "3050",
  "5199",
  "2173",
  "8755",
  "735",
  "7857",
  "9261",
  "2669",
  "2788",
  "6521",
  "8724",
  "8315",
  "3242",
  "9884",
  "4202",
  "9257",
  "3062",
  "1260",
  "669",
  "5194",
  "3516",
  "9317",
  "5224",
  "923",
  "8062",
  "8668",
  "2261",
  "7159",
  "7992",
  "1991",
  "8452",
  "8583",
  "9032",
  "1240",
  "2460",
  "9194",
  "5437",
  "4725",
  "8735",
  "3535",
  "6187",
  "3271",
  "7753",
  "1222",
  "5568",
  "414",
  "441",
  "5804",
  "8604",
  "9450",
  "637",
  "827",
  "5629",
  "7854",
  "1505",
  "3768",
  "6167",
  "5908",
  "8156",
  "8956",
  "1810",
  "6830",
  "129",
  "2246",
  "2328",
  "220",
  "1268",
  "5374",
  "5340",
  "209",
  "1504",
  "3517",
  "3688",
  "9006",
  "4620",
  "8694",
  "4877",
  "102",
  "2887",
  "6446",
  "9040",
  "2875",
  "2327",
  "9708",
  "2662",
  "8901",
  "8044",
  "8331",
  "8620",
  "6299",
  "5375",
  "5552",
  "8554",
  "229",
  "8359",
  "7899",
  "2",
  "7328",
  "1775",
  "2198",
  "512",
  "121",
  "4955",
  "2745",
  "7500",
  "7505",
  "7977",
  "2459",
  "1066",
  "2777",
  "6514",
  "3255",
  "8843",
  "1903",
  "29",
  "6978",
  "9970",
  "5272",
  "1145",
  "3263",
  "8499",
  "8561",
  "7884",
  "2118",
  "2872",
  "8117",
  "4720",
  "880",
  "3569",
  "4404",
  "4312",
  "9827",
  "2653",
  "9569",
  "8902",
  "1011",
  "4798",
  "5422",
  "4695",
  "1242",
  "5809",
  "7149",
  "2972",
  "1644",
  "6928",
  "8580",
  "5849",
  "2572",
  "1014",
  "2105",
  "4658",
  "8427",
  "2599",
  "3256",
  "6565",
  "8198",
  "8300",
  "5256",
  "2471",
  "5279",
  "8997",
  "1502",
  "779",
  "9940",
  "6159",
  "9241",
  "2110",
  "4132",
  "1161",
  "5018",
  "5092",
  "3051",
  "9148",
  "3403",
  "939",
  "9954",
  "1560",
  "1073",
  "2908",
  "2522",
  "2113",
  "2379",
  "3698",
  "9546",
  "1040",
  "3711",
  "1979",
  "1303",
  "5062",
  "9089",
  "9426",
  "1715",
  "9930",
  "7180",
  "6462",
  "9315",
  "2811",
  "3429",
  "454",
  "4967",
  "1547",
  "4313",
  "6209",
  "7043",
  "1778",
  "3986",
  "3647",
  "9166",
  "5061",
  "6693",
  "6085",
  "1615",
  "2998",
  "6994",
  "7998",
  "9626",
  "1051",
  "7532",
  "4899",
  "3339",
  "3581",
  "7635",
  "3669",
  "69",
  "4281",
  "1156",
  "99",
  "8987",
  "168",
  "8317",
  "1425",
  "7052",
  "9119",
  "6357",
  "8493",
  "349",
  "1476",
  "6074",
  "7848",
  "5878",
  "2989",
  "2896",
  "8259",
  "4347",
  "6420",
  "8299",
  "3723",
  "4448",
  "4857",
  "92",
  "7008",
  "7258",
  "9464",
  "2165",
  "3334",
  "2034",
  "2841",
  "7111",
  "4370",
  "5771",
  "5821",
  "9326",
  "112",
  "8374",
  "8690",
  "1008",
  "8135",
  "890",
  "4553",
  "7281",
  "1454",
  "1269",
  "6500",
  "9132",
  "2967",
  "3439",
  "9400",
  "6751",
  "2738",
  "3288",
  "2338",
  "8737",
  "6687",
  "5866",
  "2228",
  "7643",
  "1203",
  "6285",
  "8464",
  "1984",
  "281",
  "2308",
  "8746",
  "3168",
  "3603",
  "9953",
  "1961",
  "3741",
  "5719",
  "3842",
  "7017",
  "2914",
  "5222",
  "7538",
  "9316",
  "8138",
  "2196",
  "758",
  "1487",
  "2763",
  "3785",
  "5618",
  "2052",
  "2588",
  "5165",
  "5622",
  "3028",
  "1133",
  "3859",
  "8720",
  "3561",
  "2622",
  "5136",
  "2817",
  "1279",
  "2892",
  "7388",
  "8200",
  "882",
  "2430",
  "4284",
  "2700",
  "5776",
  "8465",
  "172",
  "5921",
  "8839",
  "2859",
  "1262",
  "1285",
  "1323",
  "8116",
  "4533",
  "9276",
  "2247",
  "4251",
  "9758",
  "3976",
  "4905",
  "821",
  "5071",
  "8940",
  "7013",
  "1064",
  "4657",
  "7498",
  "2834",
  "4154",
  "6717",
  "8043",
  "928",
  "1565",
  "5556",
  "1716",
  "3995",
  "9065",
  "4497",
  "5410",
  "6270",
  "2473",
  "3549",
  "4650",
  "5811",
  "1878",
  "1272",
  "2112",
  "2593",
  "4142",
  "4888",
  "2194",
  "1718",
  "4294",
  "2586",
  "9698",
  "992",
  "4952",
  "1322",
  "2386",
  "2469",
  "3014",
  "1173",
  "9981",
  "9704",
  "3151",
  "2252",
  "1276",
  "2443",
  "4071",
  "682",
  "6433",
  "1251",
  "4230",
  "7360",
  "5938",
  "78",
  "2642",
  "4072",
  "9845",
  "3584",
  "4298",
  "8270",
  "4591",
  "5044",
  "7326",
  "8089",
  "86",
  "2577",
  "1953",
  "4095",
  "3023",
  "6893",
  "2647",
  "3508",
  "884",
  "1111",
  "3506",
  "181",
  "4457",
  "7796",
  "9007",
  "701",
  "3544",
  "24",
  "4058",
  "5360",
  "6833",
  "8654",
  "8897",
  "4942",
  "2877",
  "2537",
  "5930",
  "7673",
  "6796",
  "7583",
  "3319",
  "2673",
  "5986",
  "6191",
  "9094",
  "5454",
  "887",
  "166",
  "247",
  "9466",
  "2863",
  "2282",
  "9055",
  "2721",
  "3955",
  "5492",
  "7712",
  "2934",
  "4749",
  "6410",
  "926",
  "8972",
  "4677",
  "7148",
  "9263",
  "1827",
  "6327",
  "870",
  "4735",
  "480",
  "9906",
  "1986",
  "347",
  "6340",
  "5718",
  "171",
  "5774",
  "4157",
  "1695",
  "326",
  "9881",
  "3091",
  "5245",
  "7243",
  "9739",
  "5498",
  "111",
  "2389",
  "9021",
  "4581",
  "6397",
  "1700",
  "5162",
  "7112",
  "35",
  "2515",
  "6036",
  "2361",
  "7162",
  "1479",
  "9138",
  "4171",
  "8142",
  "6729",
  "9732",
  "4471",
  "6392",
  "7236",
  "516",
  "4460",
  "6545",
  "4164",
  "8481",
  "1975",
  "6183",
  "1293",
  "4838",
  "4015",
  "6828",
  "6857",
  "8960",
  "9598",
  "2983",
  "3825",
  "1958",
  "5562",
  "5228",
  "6128",
  "7586",
  "7798",
  "2310",
  "3417",
  "9284",
  "2891",
  "4034",
  "4587",
  "7456",
  "661",
  "7898",
  "9734",
  "1713",
  "6457",
  "3832",
  "4368",
  "5233",
  "9678",
  "5246",
  "7703",
  "712",
  "1327",
  "9818",
  "3943",
  "4244",
  "8376",
  "6046",
  "6883",
  "6052",
  "894",
  "1846",
  "4425",
  "3099",
  "4537",
  "6701",
  "1362",
  "8506",
  "9223",
  "9999",
  "2035",
  "2066",
  "3392",
  "4548",
  "8662",
  "857",
  "8474",
  "793",
  "7580",
  "2693",
  "8892",
  "7224",
  "1805",
  "1142",
  "2870",
  "3460",
  "4702",
  "948",
  "1519",
  "2720",
  "5843",
  "2610",
  "2062",
  "2233",
  "9298",
  "2463",
  "1785",
  "2089",
  "1393",
  "2521",
  "6113",
  "2174",
  "3861",
  "8906",
  "4189",
  "7436",
  "8918",
  "3274",
  "9235",
  "8847",
  "448",
  "5432",
  "5895",
  "7027",
  "8545",
  "7452",
  "888",
  "1225",
  "6622",
  "2587",
  "6407",
  "1521",
  "7572",
  "7127",
  "7824",
  "6789",
  "4708",
  "4764",
  "3459",
  "4030",
  "5231",
  "1108",
  "1102",
  "2080",
  "4378",
  "9106",
  "3259",
  "4521",
  "457",
  "4407",
  "6280",
  "6611",
  "8233",
  "6790",
  "3197",
  "4917",
  "3409",
  "5738",
  "1724",
  "5035",
  "9742",
  "5839",
  "3906",
  "8523",
  "9737",
  "6711",
  "3957",
  "542",
  "7266",
  "2294",
  "4295",
  "2251",
  "2730",
  "5371",
  "5460",
  "6837",
  "7428",
  "4379",
  "5898",
  "8166",
  "416",
  "4227",
  "9711",
  "282",
  "2639",
  "3184",
  "8991",
  "5023",
  "8543",
  "515",
  "3104",
  "4504",
  "9195",
  "6970",
  "9826",
  "3587",
  "1271",
  "7206",
  "7485",
  "8129",
  "4401",
  "4495",
  "427",
  "2790",
  "4075",
  "7325",
  "8802",
  "1229",
  "9951",
  "3468",
  "9322",
  "4909",
  "8489",
  "1710",
  "3047",
  "5153",
  "7822",
  "8708",
  "9098",
  "362",
  "906",
  "7805",
  "8571",
  "9649",
  "4603",
  "619",
  "7847",
  "1208",
  "8442",
  "8974",
  "2411",
  "6237",
  "1359",
  "3945",
  "6137",
  "21",
  "7050",
  "5042",
  "6936",
  "1645",
  "9306",
  "2915",
  "6910",
  "3001",
  "4478",
  "1928",
  "2806",
  "3178",
  "5244",
  "258",
  "5861",
  "7831",
  "740",
  "2392",
  "4731",
  "9220",
  "621",
  "9534",
  "1922",
  "8605",
  "3352",
  "5726",
  "8411",
  "175",
  "6890",
  "9077",
  "5756",
  "5524",
  "5685",
  "5950",
  "9013",
  "495",
  "1566",
  "9878",
  "987",
  "5332",
  "8013",
  "589",
  "3129",
  "7069",
  "49",
  "6811",
  "1638",
  "767",
  "4666",
  "5280",
  "6421",
  "3233",
  "2676",
  "8056",
  "2159",
  "9574",
  "809",
  "3046",
  "6368",
  "6477",
  "8600",
  "1343",
  "3138",
  "6682",
  "1093",
  "4706",
  "4964",
  "7769",
  "3254",
  "6235",
  "6283",
  "9498",
  "4134",
  "848",
  "2161",
  "3835",
  "5238",
  "3156",
  "9832",
  "2302",
  "7626",
  "3143",
  "9724",
  "9372",
  "4704",
  "9453",
  "2797",
  "6169",
  "1627",
  "2517",
  "7095",
  "4508",
  "6772",
  "9305",
  "5052",
  "6527",
  "5580",
  "9361",
  "4683",
  "2608",
  "9337",
  "194",
  "4895",
  "7494",
  "3469",
  "5211",
  "9983",
  "2816",
  "7599",
  "3934",
  "6284",
  "3515",
  "6295",
  "4090",
  "5055",
  "6175",
  "8602",
  "9026",
  "266",
  "2548",
  "5220",
  "9245",
  "1473",
  "8173",
  "8636",
  "1021",
  "544",
  "1573",
  "4841",
  "7531",
  "3344",
  "2356",
  "9413",
  "2296",
  "2803",
  "7754",
  "3831",
  "5007",
  "9719",
  "6934",
  "2809",
  "4906",
  "7679",
  "6732",
  "8625",
  "829",
  "1186",
  "5733",
  "5978",
  "1048",
  "925",
  "3416",
  "5158",
  "5425",
  "5749",
  "1882",
  "7976",
  "7849",
  "9812",
  "164",
  "4288",
  "7340",
  "6612",
  "2843",
  "8070",
  "5060",
  "4956",
  "8435",
  "7382",
  "498",
  "5649",
  "9783",
  "4043",
  "2323",
  "688",
  "7570",
  "6456",
  "9066",
  "5317",
  "9478",
  "1897",
  "3363",
  "877",
  "540",
  "8121",
  "3094",
  "3357",
  "5757",
  "3676",
  "3308",
  "6200",
  "4988",
  "8325",
  "9184",
  "9919",
  "7215",
  "6147",
  "1783",
  "4205",
  "3520",
  "5442",
  "6362",
  "831",
  "539",
  "5917",
  "6382",
  "1685",
  "3089",
  "3630",
  "5083",
  "6852",
  "4484",
  "5503",
  "8849",
  "8967",
  "8350",
  "2943",
  "4792",
  "391",
  "1195",
  "5216",
  "5377",
  "7125",
  "4634",
  "2072",
  "3396",
  "5645",
  "5810",
  "6168",
  "6473",
  "9225",
  "796",
  "1083",
  "7090",
  "2499",
  "8776",
  "2922",
  "8825",
  "4724",
  "6425",
  "4853",
  "5266",
  "6827",
  "7502",
  "5298",
  "9234",
  "6216",
  "9137",
  "2221",
  "5191",
  "6992",
  "6027",
  "9352",
  "8059",
  "3820",
  "5795",
  "757",
  "1678",
  "1065",
  "2850",
  "5619",
  "9445",
  "7123",
  "7153",
  "6414",
  "4714",
  "7683",
  "9579",
  "4515",
  "87",
  "642",
  "8149",
  "7691",
  "6843",
  "2917",
  "7675",
  "5995",
  "6249",
  "5778",
  "8996",
  "9307",
  "2293",
  "5419",
  "3411",
  "9741",
  "9871",
  "97",
  "5750",
  "8555",
  "984",
  "5188",
  "4110",
  "4767",
  "183",
  "8838",
  "9214",
  "2116",
  "4787",
  "6198",
  "9052",
  "1005",
  "8177",
  "7122",
  "2731",
  "2889",
  "2895",
  "3098",
  "7568",
  "8278",
  "330",
  "5724",
  "3194",
  "3659",
  "5192",
  "9524",
  "4257",
  "2307",
  "6771",
  "7030",
  "3149",
  "584",
  "5882",
  "2774",
  "5525",
  "2415",
  "4023",
  "4566",
  "5499",
  "6131",
  "6250",
  "6374",
  "7844",
  "8307",
  "1070",
  "2352",
  "4358",
  "2538",
  "3729",
  "4673",
  "8824",
  "1948",
  "3826",
  "8417",
  "8921",
  "2106",
  "3415",
  "5221",
  "5497",
  "1593",
  "3196",
  "6937",
  "6252",
  "382",
  "2212",
  "5121",
  "6599",
  "8712",
  "108",
  "9647",
  "577",
  "5539",
  "2454",
  "6653",
  "8382",
  "1923",
  "4827",
  "4963",
  "7883",
  "5781",
  "3192",
  "6095",
  "8665",
  "6502",
  "5091",
  "6914",
  "2941",
  "8312",
  "9444",
  "1325",
  "4878",
  "9836",
  "7680",
  "7529",
  "2488",
  "3638",
  "4582",
  "7120",
  "3759",
  "751",
  "7645",
  "487",
  "2109",
  "3802",
  "4946",
  "3077",
  "5450",
  "1726",
  "2476",
  "1376",
  "5438",
  "1697",
  "2678",
  "517",
  "931",
  "1147",
  "3204",
  "4112",
  "7612",
  "8950",
  "9004",
  "4971",
  "8939",
  "9900",
  "999",
  "6116",
  "3621",
  "5958",
  "8893",
  "4786",
  "8674",
  "2147",
  "6088",
  "446",
  "7836",
  "376",
  "2226",
  "1221",
  "7178",
  "4151",
  "8657",
  "8744",
  "6881",
  "4126",
  "7033",
  "3710",
  "3103",
  "1729",
  "941",
  "1419",
  "3586",
  "918",
  "2366",
  "3776",
  "6394",
  "6396",
  "8995",
  "4672",
  "3310",
  "3117",
  "3137",
  "6369",
  "8556",
  "5803",
  "7249",
  "7409",
  "1692",
  "6525",
  "6973",
  "8713",
  "638",
  "8899",
  "4340",
  "3702",
  "1836",
  "9171",
  "3787",
  "5637",
  "8614",
  "3400",
  "8911",
  "6930",
  "8486",
  "5413",
  "9259",
  "1970",
  "4338",
  "9572",
  "5540",
  "6770",
  "7250",
  "3253",
  "7384",
  "8430",
  "6313",
  "6841",
  "2856",
  "332",
  "1849",
  "3414",
  "3726",
  "5703",
  "5922",
  "6809",
  "7216",
  "4510",
  "9862",
  "1422",
  "940",
  "4711",
  "9675",
  "9334",
  "4947",
  "6482",
  "4901",
  "2937",
  "6056",
  "6774",
  "7974",
  "4271",
  "6576",
  "7509",
  "134",
  "3599",
  "8941",
  "2508",
  "4054",
  "6186",
  "7103",
  "5148",
  "2348",
  "7604",
  "6044",
  "3591",
  "3666",
  "7273",
  "4701",
  "920",
  "4018",
  "8867",
  "4035",
  "7037",
  "6725",
  "5202",
  "7927",
  "8664",
  "6072",
  "2628",
  "2988",
  "7083",
  "856",
  "7842",
  "6572",
  "2071",
  "1094",
  "8731",
  "8999",
  "334",
  "7028",
  "2162",
  "2868",
  "3300",
  "9822",
  "1668",
  "6728",
  "1571",
  "8288",
  "1869",
  "9211",
  "9743",
  "5608",
  "8851",
  "8099",
  "9182",
  "3905",
  "8979",
  "7394",
  "9896",
  "3270",
  "9901",
  "7080",
  "1157",
  "1574",
  "5321",
  "5867",
  "7070",
  "5474",
  "580",
  "3349",
  "4010",
  "4267",
  "4774",
  "4817",
  "7218",
  "9379",
  "3203",
  "4716",
  "5475",
  "5615",
  "2391",
  "8924",
  "7910",
  "8741",
  "6806",
  "8853",
  "4254",
  "9865",
  "4742",
  "7756",
  "606",
  "1164",
  "8419",
  "790",
  "2345",
  "3923",
  "3941",
  "4406",
  "8219",
  "5284",
  "9302",
  "1956",
  "2242",
  "1883",
  "4927",
  "6278",
  "727",
  "8476",
  "3550",
  "500",
  "2269",
  "6141",
  "5344",
  "6775",
  "4453",
  "8441",
  "1793",
  "358",
  "8005",
  "1436",
  "7391",
  "262",
  "6847",
  "3384",
  "7060",
  "4761",
  "3281",
  "5467",
  "564",
  "4811",
  "5815",
  "4285",
  "913",
  "2857",
  "4059",
  "7121",
  "4081",
  "6481",
  "2431",
  "4659",
  "5100",
  "1822",
  "3583",
  "9915",
  "9109",
  "286",
  "6990",
  "8945",
  "9158",
  "234",
  "3054",
  "2032",
  "5440",
  "9618",
  "4179",
  "9348",
  "5865",
  "7315",
  "7644",
  "9416",
  "3605",
  "3764",
  "3177",
  "3565",
  "8807",
  "9408",
  "762",
  "4170",
  "9643",
  "1129",
  "5171",
  "5514",
  "5939",
  "7026",
  "9312",
  "5643",
  "1385",
  "5656",
  "2930",
  "7957",
  "1588",
  "2789",
  "3485",
  "3340",
  "5544",
  "6193",
  "215",
  "1080",
  "2936",
  "3808",
  "8729",
  "1592",
  "7790",
  "8036",
  "9486",
  "8621",
  "679",
  "898",
  "3245",
  "4766",
  "2101",
  "9155",
  "1056",
  "1611",
  "4269",
  "4753",
  "4816",
  "7150",
  "9691",
  "687",
  "5789",
  "7853",
  "8042",
  "4260",
  "6315",
  "1332",
  "2077",
  "9477",
  "9568",
  "2189",
  "8118",
  "9278",
  "2487",
  "7648",
  "2450",
  "3161",
  "7506",
  "9297",
  "1959",
  "3239",
  "2675",
  "7631",
  "9738",
  "792",
  "4961",
  "7435",
  "3622",
  "9760",
  "8067",
  "3793",
  "9628",
  "7821",
  "8989",
  "6497",
  "3448",
  "6257",
  "8361",
  "9856",
  "1036",
  "1831",
  "2542",
  "9752",
  "2314",
  "3477",
  "5302",
  "9575",
  "963",
  "3992",
  "8923",
  "8641",
  "197",
  "8244",
  "6162",
  "65",
  "1910",
  "3556",
  "74",
  "7727",
  "1842",
  "6694",
  "7688",
  "8812",
  "715",
  "7059",
  "684",
  "1607",
  "1973",
  "9449",
  "1820",
  "3398",
  "4754",
  "5335",
  "5205",
  "7666",
  "783",
  "1052",
  "8291",
  "2768",
  "4529",
  "8935",
  "3163",
  "9018",
  "841",
  "6493",
  "8872",
  "8024",
  "9549",
  "5181",
  "9391",
  "6334",
  "2383",
  "7228",
  "4192",
  "8393",
  "1159",
  "6143",
  "7573",
  "4757",
  "9202",
  "6314",
  "3451",
  "854",
  "9454",
  "4734",
  "8680",
  "4976",
  "8232",
  "4418",
  "1843",
  "6889",
  "7959",
  "3966",
  "8292",
  "276",
  "4335",
  "5601",
  "9480",
  "3715",
  "8054",
  "1317",
  "4939",
  "7952",
  "4850",
  "6349",
  "1952",
  "1937",
  "7607",
  "8624",
  "9327",
  "7330",
  "3446",
  "4732",
  "4744",
  "9701",
  "2674",
  "9561",
  "302",
  "2864",
  "8494",
  "8607",
  "9627",
  "9773",
  "2594",
  "474",
  "1990",
  "3323",
  "4373",
  "7563",
  "9873",
  "389",
  "9661",
  "9500",
  "9457",
  "1058",
  "1466",
  "2230",
  "4191",
  "5527",
  "4263",
  "428",
  "3306",
  "9060",
  "901",
  "1880",
  "1980",
  "5397",
  "4372",
  "9192",
  "9633",
  "6093",
  "6194",
  "1026",
  "6367",
  "4433",
  "1341",
  "5760",
  "3582",
  "364",
  "1803",
  "8635",
  "1029",
  "2711",
  "96",
  "1055",
  "8633",
  "17",
  "1215",
  "3701",
  "9848",
  "9960",
  "5053",
  "6288",
  "622",
  "1261",
  "6091",
  "842",
  "3058",
  "6568",
  "5365",
  "2171",
  "5855",
  "5888",
  "6487",
  "9556",
  "1698",
  "5579",
  "8954",
  "8977",
  "949",
  "5894",
  "2740",
  "526",
  "7248",
  "4432",
  "1027",
  "3704",
  "5748",
  "3298",
  "3692",
  "4130",
  "4442",
  "5555",
  "125",
  "1647",
  "8286",
  "993",
  "3503",
  "1187",
  "6358",
  "1212",
  "363",
  "8971",
  "252",
  "2878",
  "6015",
  "2629",
  "1168",
  "5880",
  "7559",
  "9554",
  "9882",
  "7690",
  "4564",
  "8297",
  "6552",
  "7092",
  "7419",
  "7628",
  "1006",
  "6933",
  "8994",
  "1965",
  "2489",
  "3843",
  "5964",
  "8619",
  "3641",
  "6675",
  "7288",
  "8723",
  "1674",
  "3933",
  "9240",
  "235",
  "400",
  "1619",
  "1402",
  "9421",
  "8406",
  "5350",
  "5239",
  "1263",
  "8768",
  "3730",
  "1193",
  "2579",
  "137",
  "6453",
  "6422",
  "459",
  "3024",
  "5831",
  "524",
  "1610",
  "6619",
  "5870",
  "6639",
  "9744",
  "1688",
  "119",
  "7039",
  "7878",
  "7561",
  "2649",
  "3359",
  "205",
  "4069",
  "2569",
  "7909",
  "1722",
  "8886",
  "4451",
  "1138",
  "3434",
  "3860",
  "8321",
  "4459",
  "3445",
  "9506",
  "410",
  "3856",
  "7246",
  "6874",
  "7667",
  "8021",
  "5277",
  "4395",
  "1098",
  "1620",
  "245",
  "7787",
  "340",
  "7166",
  "449",
  "7886",
  "3043",
  "3678",
  "5985",
  "8224",
  "8136",
  "9296",
  "5859",
  "7894",
  "8262",
  "2766",
  "5806",
  "9376",
  "8228",
  "8842",
  "2554",
  "4279",
  "499",
  "9918",
  "2418",
  "6815",
  "8642",
  "3769",
  "3017",
  "3088",
  "5314",
  "9807",
  "6688",
  "9212",
  "1742",
  "6364",
  "563",
  "9676",
  "2045",
  "4419",
  "2861",
  "3931",
  "1650",
  "5576",
  "1911",
  "1690",
  "3237",
  "4393",
  "8496",
  "5896",
  "6057",
  "2141",
  "6083",
  "8050",
  "9310",
  "6413",
  "2340",
  "4118",
  "6393",
  "851",
  "2099",
  "8798",
  "8383",
  "106",
  "7903",
  "1745",
  "3925",
  "804",
  "2139",
  "5501",
  "5735",
  "8796",
  "672",
  "1416",
  "4307",
  "4914",
  "9218",
  "9311",
  "2271",
  "2974",
  "5309",
  "6649",
  "8324",
  "9205",
  "3191",
  "7609",
  "6427",
  "7114",
  "8027",
  "4803",
  "2924",
  "3354",
  "189",
  "3563",
  "4491",
  "9843",
  "141",
  "2852",
  "8612",
  "6838",
  "4074",
  "7259",
  "645",
  "2362",
  "3878",
  "6224",
  "2094",
  "4499",
  "7161",
  "7733",
  "955",
  "975",
  "9289",
  "1512",
  "3175",
  "5416",
  "7497",
  "6154",
  "3100",
  "3383",
  "5658",
  "7263",
  "2739",
  "6471",
  "7336",
  "445",
  "1613",
  "3749",
  "5351",
  "278",
  "4033",
  "3643",
  "4804",
  "6957",
  "9364",
  "7207",
  "845",
  "7503",
  "8599",
  "5602",
  "2134",
  "7151",
  "2382",
  "5549",
  "6655",
  "7304",
  "1815",
  "3406",
  "9248",
  "32",
  "1469",
  "652",
  "5791",
  "7058",
  "4165",
  "8874",
  "2301",
  "8447",
  "2867",
  "4232",
  "4954",
  "5736",
  "8569",
  "151",
  "122",
  "2146",
  "2918",
  "6842",
  "1605",
  "6219",
  "7239",
  "9273",
  "1819",
  "6748",
  "1568",
  "3394",
  "2590",
  "4597",
  "5980",
  "1808",
  "9320",
  "9684",
  "869",
  "9118",
  "8831",
  "3385",
  "9270",
  "713",
  "5644",
  "6658",
  "8711",
  "6954",
  "8340",
  "9601",
  "2057",
  "582",
  "4223",
  "5563",
  "9062",
  "1228",
  "4856",
  "9145",
  "1449",
  "3035",
  "5207",
  "2784",
  "7062",
  "9653",
  "5605",
  "3734",
  "4960",
  "7867",
  "8371",
  "718",
  "4542",
  "5666",
  "958",
  "4434",
  "1766",
  "6998",
  "8179",
  "8765",
  "2603",
  "3880",
  "8147",
  "436",
  "3432",
  "451",
  "5176",
  "9031",
  "7154",
  "5417",
  "3547",
  "6371",
  "8012",
  "8669",
  "7274",
  "9501",
  "6182",
  "1233",
  "4921",
  "537",
  "1433",
  "6924",
  "9115",
  "9436",
  "313",
  "545",
  "2028",
  "546",
  "4687",
  "342",
  "8975",
  "4880",
  "6979",
  "7841",
  "7314",
  "45",
  "3855",
  "9404",
  "536",
  "9977",
  "8777",
  "599",
  "3112",
  "3491",
  "2263",
  "9215",
  "2713",
  "3375",
  "2182",
  "7342",
  "1889",
  "9552",
  "7116",
  "3198",
  "5099",
  "5483",
  "600",
  "861",
  "2709",
  "8733",
  "520",
  "3902",
  "8235",
  "8513",
  "9606",
  "7619",
  "7254",
  "8894",
  "9345",
  "634",
  "5293",
  "8007",
  "8748",
  "6133",
  "3072",
  "4446",
  "4974",
  "973",
  "7971",
  "2374",
  "1146",
  "5014",
  "2085",
  "296",
  "1223",
  "5537",
  "8237",
  "6557",
  "5744",
  "5026",
  "253",
  "3545",
  "4104",
  "7269",
  "1114",
  "1218",
  "1800",
  "5663",
  "4376",
  "6009",
  "7536",
  "7851",
  "1247",
  "4424",
  "2762",
  "5509",
  "8438",
  "5708",
  "6759",
  "9587",
  "9088",
  "9640",
  "3777",
  "325",
  "1982",
  "4150",
  "844",
  "4186",
  "6030",
  "2842",
  "207",
  "4983",
  "6613",
  "8759",
  "5564",
  "7094",
  "2027",
  "6834",
  "1491",
  "1536",
  "1725",
  "6000",
  "6430",
  "1395",
  "1095",
  "4846",
  "5500",
  "9831",
  "2664",
  "4413",
  "593",
  "5966",
  "6807",
  "3984",
  "1035",
  "1297",
  "76",
  "1478",
  "5415",
  "5606",
  "511",
  "1126",
  "5532",
  "6123",
  "3430",
  "6677",
  "7469",
  "9382",
  "736",
  "1904",
  "9586",
  "1368",
  "4632",
  "9780",
  "4036",
  "4166",
  "4949",
  "1499",
  "3450",
  "604",
  "4113",
  "5516",
  "6709",
  "8455",
  "4400",
  "7381",
  "4301",
  "5259",
  "15",
  "6444",
  "7472",
  "2058",
  "7866",
  "8653",
  "3130",
  "4948",
  "7022",
  "3081",
  "1000",
  "2098",
  "3863",
  "5212",
  "6714",
  "6821",
  "1993",
  "5376",
  "5128",
  "2812",
  "2601",
  "6142",
  "3901",
  "7759",
  "6466",
  "7795",
  "477",
  "7308",
  "3146",
  "5694",
  "5144",
  "6551",
  "2451",
  "7877",
  "9324",
  "4809",
  "2679",
  "4060",
  "2938",
  "4975",
  "6343",
  "4388",
  "6153",
  "617",
  "3065",
  "7047",
  "9431",
  "9165",
  "594",
  "1210",
  "5779",
  "7434",
  "9615",
  "4322",
  "7816",
  "9277",
  "9231",
  "7002",
  "1550",
  "2995",
  "2692",
  "5429",
  "8503",
  "2764",
  "3087",
  "9748",
  "1387",
  "6872",
  "170",
  "5588",
  "3500",
  "4133",
  "5543",
  "7616",
  "989",
  "1092",
  "3207",
  "4723",
  "6571",
  "8502",
  "180",
  "1042",
  "6663",
  "8467",
  "503",
  "3865",
  "7633",
  "9358",
  "4062",
  "9621",
  "2527",
  "8369",
  "9791",
  "513",
  "710",
  "5150",
  "5947",
  "6319",
  "1022",
  "2505",
  "7107",
  "2725",
  "5119",
  "5710",
  "1774",
  "3721",
  "4953",
  "1089",
  "4067",
  "6380",
  "7346",
  "8933",
  "7565",
  "7777",
  "8827",
  "2624",
  "747",
  "3170",
  "9803",
  "3680",
  "3904",
  "5495",
  "967",
  "1179",
  "2927",
  "9242",
  "2334",
  "3696",
  "7541",
  "2687",
  "4526",
  "9142",
  "6111",
  "7082",
  "9001",
  "6333",
  "3075",
  "7686",
  "9047",
  "3755",
  "5630",
  "6684",
  "2513",
  "8387",
  "3290",
  "4219",
  "8180",
  "4146",
  "7827",
  "8795",
  "1567",
  "2470",
  "3350",
  "3158",
  "4503",
  "1354",
  "7803",
  "8346",
  "5577",
  "791",
  "1432",
  "3482",
  "420",
  "2636",
  "5372",
  "3758",
  "6485",
  "7956",
  "2923",
  "5182",
  "3956",
  "6060",
  "6437",
  "9522",
  "996",
  "5689",
  "7316",
  "9625",
  "4651",
  "9224",
  "9451",
  "4097",
  "4089",
  "7061",
  "7303",
  "1591",
  "6084",
  "6171",
  "9101",
  "5674",
  "3637",
  "9120",
  "2353",
  "2531",
  "187",
  "2446",
  "3007",
  "5522",
  "9681",
  "9860",
  "2478",
  "4670",
  "6495",
  "7203",
  "2453",
  "7418",
  "8341",
  "2507",
  "9136",
  "1378",
  "6667",
  "9044",
  "3283",
  "4293",
  "5496",
  "6903",
  "5875",
  "6077",
  "2516",
  "4935",
  "7141",
  "8927",
  "9130",
  "1329",
  "5553",
  "1171",
  "4555",
  "6387",
  "7049",
  "9925",
  "5720",
  "7693",
  "236",
  "921",
  "8873",
  "9725",
  "3656",
  "5697",
  "6735",
  "4819",
  "1428",
  "789",
  "1118",
  "5149",
  "8281",
  "8930",
  "946",
  "3600",
  "9802",
  "3289",
  "9397",
  "9839",
  "5747",
  "6484",
  "9114",
  "6092",
  "7914",
  "3076",
  "568",
  "3703",
  "6938",
  "2886",
  "3839",
  "3936",
  "4633",
  "5076",
  "6652",
  "8264",
  "2947",
  "5573",
  "6720",
  "938",
  "3639",
  "4447",
  "2503",
  "5174",
  "2696",
  "6354",
  "7652",
  "2421",
  "5927",
  "6755",
  "7860",
  "1867",
  "3887",
  "4641",
  "7881",
  "4563",
  "4262",
  "5687",
  "483",
  "6416",
  "3063",
  "6474",
  "7260",
  "447",
  "972",
  "3942",
  "9650",
  "204",
  "4248",
  "816",
  "3851",
  "9024",
  "2433",
  "6556",
  "7034",
  "6103",
  "719",
  "9496",
  "738",
  "2360",
  "7412",
  "9090",
  "9258",
  "5399",
  "7535",
  "7794",
  "3570",
  "7732",
  "2241",
  "4178",
  "1746",
  "2879",
  "4997",
  "5401",
  "4604",
  "9909",
  "4662",
  "9095",
  "1569",
  "630",
  "7955",
  "8096",
  "9537",
  "3522",
  "6743",
  "7403",
  "8462",
  "6418",
  "899",
  "6673",
  "6823",
  "9622",
  "7508",
  "1616",
  "5536",
  "6515",
  "9247",
  "1384",
  "3153",
  "2953",
  "5578",
  "7614",
  "2330",
  "3873",
  "5777",
  "9343",
  "1603",
  "702",
  "9609",
  "1163",
  "9998",
  "1934",
  "8738",
  "953",
  "283",
  "3709",
  "4360",
  "7298",
  "5800",
  "8512",
  "7602",
  "8343",
  "8368",
  "8155",
  "47",
  "163",
  "7871",
  "8485",
  "9939",
  "2535",
  "3321",
  "2337",
  "5067",
  "7370",
  "2714",
  "9584",
  "8405",
  "608",
  "7205",
  "7967",
  "8990",
  "3335",
  "9369",
  "3427",
  "4231",
  "280",
  "5017",
  "6626",
  "6628",
  "8627",
  "9488",
  "2319",
  "1236",
  "2585",
  "9163",
  "9808",
  "4040",
  "6426",
  "5819",
  "5945",
  "3118",
  "5477",
  "2691",
  "3727",
  "6586",
  "31",
  "5808",
  "1301",
  "3183",
  "7329",
  "1760",
  "2395",
  "8463",
  "1749",
  "1768",
  "1608",
  "2006",
  "1099",
  "4367",
  "7575",
  "9955",
  "3746",
  "3858",
  "3982",
  "6337",
  "5835",
  "9515",
  "4387",
  "5230",
  "9342",
  "8622",
  "9539",
  "7780",
  "2986",
  "3975",
  "5468",
  "1606",
  "64",
  "1067",
  "2781",
  "3224",
  "3325",
  "9154",
  "2597",
  "4011",
  "7840",
  "346",
  "1254",
  "2154",
  "1300",
  "3140",
  "3944",
  "1643",
  "2715",
  "3505",
  "195",
  "2022",
  "3447",
  "1711",
  "1230",
  "1373",
  "7063",
  "3441",
  "8566",
  "3057",
  "722",
  "8870",
  "4213",
  "7164",
  "5883",
  "5997",
  "7053",
  "4215",
  "5854",
  "5263",
  "3366",
  "6630",
  "3897",
  "8131",
  "8418",
  "933",
  "3379",
  "307",
  "123",
  "5752",
  "4505",
  "3443",
  "6526",
  "8758",
  "9947",
  "7319",
  "7969",
  "761",
  "2847",
  "5833",
  "9071",
  "8925",
  "6913",
  "378",
  "2663",
  "6560",
  "6766",
  "8318",
  "778",
  "6517",
  "6375",
  "711",
  "1945",
  "5157",
  "6124",
  "5029",
  "7291",
  "7990",
  "8964",
  "6594",
  "3248",
  "19",
  "3292",
  "4996",
  "9462",
  "639",
  "155",
  "4929",
  "5124",
  "9203",
  "212",
  "4894",
  "3174",
  "3530",
  "3847",
  "1888",
  "3033",
  "1844",
  "7629",
  "6309",
  "7041",
  "7373",
  "7792",
  "6264",
  "1401",
  "6081",
  "7654",
  "648",
  "3980",
  "6021",
  "2970",
  "9869",
  "8144",
  "6492",
  "489",
  "3800",
  "4431",
  "8422",
  "574",
  "959",
  "6991",
  "7066",
  "9566",
  "3305",
  "3595",
  "5333",
  "9196",
  "2428",
  "2950",
  "3006",
  "3347",
  "3988",
  "7518",
  "1367",
  "2234",
  "3862",
  "7160",
  "730",
  "5823",
  "8285",
  "699",
  "897",
  "3740",
  "4276",
  "4664",
  "5104",
  "5934",
  "8360",
  "3833",
  "7870",
  "5487",
  "739",
  "1113",
  "3660",
  "2048",
  "3543",
  "4829",
  "7182",
  "7664",
  "5706",
  "6028",
  "4047",
  "4982",
  "8247",
  "8479",
  "2224",
  "4635",
  "6043",
  "8108",
  "1324",
  "4913",
  "6958",
  "7799",
  "8249",
  "1033",
  "8020",
  "8747",
  "1112",
  "9349",
  "7306",
  "418",
  "1019",
  "4116",
  "5151",
  "7897",
  "8186",
  "9796",
  "9922",
  "9583",
  "5545",
  "3355",
  "725",
  "2893",
  "588",
  "1594",
  "6776",
  "9243",
  "2003",
  "5731",
  "8721",
  "1712",
  "2059",
  "3827",
  "5138",
  "5874",
  "6379",
  "6900",
  "3435",
  "179",
  "6896",
  "7089",
  "872",
  "3461",
  "7128",
  "440",
  "4736",
  "4183",
  "6063",
  "4941",
  "2056",
  "4061",
  "5654",
  "7458",
  "8366",
  "8792",
  "3048",
  "5267",
  "5814",
  "6404",
  "5037",
  "653",
  "2560",
  "9837",
  "8098",
  "5159",
  "1082",
  "5937",
  "541",
  "3342",
  "9779",
  "4188",
  "8045",
  "4851",
  "4608",
  "1245",
  "5594",
  "5291",
  "7624",
  "8475",
  "3222",
  "6670",
  "1136",
  "3381",
  "3844",
  "5816",
  "5301",
  "5072",
  "7730",
  "2131",
  "3932",
  "1127",
  "1151",
  "1796",
  "9979",
  "8407",
  "9128",
  "3539",
  "1950",
  "5285",
  "8199",
  "8509",
  "8861",
  "4875",
  "8210",
  "9491",
  "7011",
  "3882",
  "3960",
  "2712",
  "3042",
  "8370",
  "9318",
  "138",
  "415",
  "2899",
  "5198",
  "5426",
  "7309",
  "5845",
  "1598",
  "8041",
  "4026",
  "5010",
  "1120",
  "7832",
  "2385",
  "5504",
  "5686",
  "5912",
  "3819",
  "4079",
  "3707",
  "5068",
  "7223",
  "8188",
  "3874",
  "7905",
  "3111",
  "4252",
  "8276",
  "7965",
  "4585",
  "1949",
  "3377",
  "9562",
  "9639",
  "8671",
  "4198",
  "4839",
  "9309",
  "2665",
  "911",
  "4145",
  "5428",
  "5807",
  "7707",
  "9028",
  "929",
  "2708",
  "4489",
  "6747",
  "3895",
  "7320",
  "6050",
  "406",
  "6459",
  "7585",
  "314",
  "656",
  "3606",
  "8750",
  "57",
  "775",
  "5288",
  "666",
  "6082",
  "1443",
  "6520",
  "5408",
  "4299",
  "6877",
  "1779",
  "4852",
  "5510",
  "295",
  "1037",
  "5278",
  "9654",
  "7527",
  "3425",
  "5742",
  "5869",
  "6245",
  "7339",
  "4674",
  "3280",
  "2083",
  "1676",
  "4544",
  "7677",
  "413",
  "3484",
  "6733",
  "731",
  "4769",
  "9368",
  "8174",
  "1564",
  "188",
  "8963",
  "4611",
  "1277",
  "2125",
  "5441",
  "5670",
  "9740",
  "5048",
  "3223",
  "5423",
  "6271",
  "5020",
  "2218",
  "3002",
  "6620",
  "4088",
  "1918",
  "1435",
  "2437",
  "5626",
  "8445",
  "9481",
  "1951",
  "7377",
  "7597",
  "9577",
  "2168",
  "4778",
  "6402",
  "1267",
  "1227",
  "8785",
  "1510",
  "4174",
  "6967",
  "2876",
  "881",
  "3538",
  "1197",
  "7081",
  "9476",
  "2993",
  "8699",
  "6170",
  "1877",
  "4351",
  "7696",
  "9470",
  "7040",
  "1498",
  "7292",
  "5946",
  "9208",
  "4474",
  "5611",
  "9300",
  "8345",
  "5431",
  "3030",
  "4932",
  "5784",
  "1933",
  "8266",
  "1802",
  "2331",
  "2025",
  "6985",
  "6798",
  "6144",
  "2053",
  "4623",
  "5998",
  "6468",
  "914",
  "4694",
  "640",
  "1873",
  "2046",
  "2285",
  "5881",
  "255",
  "5185",
  "216",
  "3633",
  "5657",
  "9810",
  "1431",
  "1735",
  "4173",
  "3789",
  "3883",
  "2329",
  "9207",
  "7455",
  "876",
  "6322",
  "1441",
  "3180",
  "8815",
  "9718",
  "9823",
  "8594",
  "4098",
  "8644",
  "1839",
  "8840",
  "618",
  "5449",
  "6501",
  "8338",
  "760",
  "7627",
  "4",
  "8104",
  "3370",
  "6916",
  "5458",
  "2583",
  "2434",
  "592",
  "6099",
  "6518",
  "6787",
  "6869",
  "8726",
  "2839",
  "1631",
  "7076",
  "7393",
  "4547",
  "1375",
  "5753",
  "7916",
  "8603",
  "5634",
  "3471",
  "3568",
  "5542",
  "7152",
  "368",
  "9874",
  "860",
  "5387",
  "6550",
  "7801",
  "1513",
  "3962",
  "943",
  "2019",
  "3876",
  "2402",
  "4928",
  "7581",
  "1442",
  "6964",
  "2013",
  "4006",
  "855",
  "9170",
  "2037",
  "2902",
  "9149",
  "4740",
  "5745",
  "2668",
  "5451",
  "4785",
  "2954",
  "7199",
  "2755",
  "7187",
  "2267",
  "4323",
  "2205",
  "3424",
  "4108",
  "6601",
  "8289",
  "1335",
  "7226",
  "9608",
  "2377",
  "2615",
  "6609",
  "2757",
  "534",
  "9992",
  "5107",
  "6543",
  "9037",
  "4539",
  "3786",
  "4848",
  "6762",
  "9321",
  "1572",
  "7413",
  "8788",
  "3658",
  "2779",
  "5871",
  "8094",
  "1930",
  "1",
  "2419",
  "3946",
  "6744",
  "8078",
  "9956",
  "4327",
  "4763",
  "6378",
  "184",
  "7994",
  "1663",
  "1886",
  "9336",
  "5956",
  "3973",
  "6577",
  "7296",
  "9058",
  "2211",
  "5476",
  "9091",
  "8490",
  "2689",
  "6731",
  "7450",
  "4849",
  "6",
  "2416",
  "2942",
  "5673",
  "9178",
  "1132",
  "4579",
  "9406",
  "2219",
  "2408",
  "7863",
  "8709",
  "806",
  "1981",
  "5491",
  "8575",
  "6049",
  "7100",
  "6445",
  "1386",
  "1135",
  "6228",
  "3005",
  "3593",
  "4044",
  "6051",
  "7942",
  "149",
  "708",
  "6018",
  "6363",
  "2095",
  "5249",
  "8986",
  "4619",
  "1041",
  "1677",
  "2904",
  "8335",
  "3537",
  "7012",
  "7368",
  "7830",
  "4314",
  "4922",
  "1176",
  "9667",
  "6761",
  "1967",
  "5751",
  "8141",
  "7001",
  "4247",
  "7196",
  "3206",
  "8004",
  "8942",
  "2166",
  "3554",
  "8732",
  "6906",
  "82",
  "703",
  "1013",
  "1166",
  "3399",
  "3667",
  "8439",
  "1925",
  "3490",
  "9351",
  "6784",
  "3559",
  "4546",
  "4925",
  "5303",
  "7767",
  "397",
  "5973",
  "8809",
  "2559",
  "320",
  "3433",
  "7432",
  "4422",
  "5698",
  "7775",
  "7374",
  "9542",
  "4429",
  "7507",
  "9460",
  "9665",
  "3852",
  "8358",
  "7985",
  "3338",
  "5517",
  "7972",
  "4584",
  "5000",
  "9964",
  "5521",
  "7715",
  "4389",
  "2371",
  "1728",
  "1511",
  "5226",
  "5235",
  "7473",
  "1821",
  "7613",
  "8501",
  "8855",
  "6629",
  "8061",
  "486",
  "7110",
  "9804",
  "3317",
  "924",
  "4613",
  "5490",
  "5709",
  "8681",
  "9679",
  "1944",
  "6650",
  "9731",
  "1104",
  "3378",
  "91",
  "2335",
  "185",
  "6119",
  "8661",
  "3937",
  "5006",
  "7781",
  "7944",
  "5405",
  "6076",
  "7185",
  "2702",
  "3533",
  "4543",
  "9127",
  "3822",
  "2030",
  "2223",
  "7544",
  "9770",
  "4726",
  "9425",
  "9281",
  "9924",
  "5164",
  "8558",
  "1486",
  "5828",
  "8328",
  "6214",
  "7417",
  "9251",
  "2690",
  "5598",
  "8290",
  "9782",
  "9853",
  "4353",
  "61",
  "3466",
  "5370",
  "8112",
  "9407",
  "1804",
  "2546",
  "2982",
  "4243",
  "3380",
  "7422",
  "1379",
  "9339",
  "9439",
  "3899",
  "4609",
  "4916",
  "5690",
  "3134",
  "5691",
  "9175",
  "3367",
  "337",
  "5971",
  "6179",
  "7106",
  "8157",
  "2126",
  "2520",
  "336",
  "2602",
  "2637",
  "9980",
  "1719",
  "1963",
  "2900",
  "6511",
  "9189",
  "7398",
  "2438",
  "6454",
  "659",
  "2703",
  "8018",
  "2780",
  "7526",
  "475",
  "2528",
  "8590",
  "5170",
  "7893",
  "4867",
  "291",
  "3105",
  "5330",
  "369",
  "4630",
  "8333",
  "9456",
  "1418",
  "2180",
  "5728",
  "6659",
  "9799",
  "4027",
  "7433",
  "7835",
  "7252",
  "7929",
  "8296",
  "1252",
  "3027",
  "4259",
  "2582",
  "8258",
  "7826",
  "5983",
  "1326",
  "9487",
  "8888",
  "5125",
  "290",
  "5740",
  "2384",
  "5743",
  "1974",
  "5323",
  "1371",
  "4031",
  "4369",
  "1296",
  "1381",
  "5953",
  "7962",
  "4721",
  "7584",
  "5214",
  "1214",
  "5801",
  "3333",
  "6318",
  "7737",
  "874",
  "2357",
  "2530",
  "5406",
  "9353",
  "1134",
  "3421",
  "4781",
  "5421",
  "7170",
  "9941",
  "2683",
  "8240",
  "5130",
  "335",
  "4096",
  "5826",
  "3221",
  "227",
  "7190",
  "7276",
  "7323",
  "9458",
  "412",
  "734",
  "9254",
  "9370",
  "2901",
  "808",
  "5394",
  "5759",
  "3618",
  "7065",
  "5612",
  "5818",
  "5737",
  "1601",
  "1704",
  "2380",
  "6232",
  "83",
  "2483",
  "3706",
  "6130",
  "6467",
  "7736",
  "9383",
  "2199",
  "6246",
  "4286",
  "9835",
  "6909",
  "824",
  "1334",
  "915",
  "2021",
  "5094",
  "6773",
  "4907",
  "3708",
  "9757",
  "2782",
  "393",
  "4795",
  "5252",
  "6148",
  "2090",
  "5379",
  "9402",
  "8673",
  "1423",
  "5565",
  "6151",
  "1412",
  "658",
  "3442",
  "8384",
  "2625",
  "2940",
  "6730",
  "3483",
  "3915",
  "8159",
  "1587",
  "2604",
  "7392",
  "9104",
  "7495",
  "9375",
  "2485",
  "4881",
  "7439",
  "7492",
  "6424",
  "4196",
  "8466",
  "399",
  "4675",
  "1683",
  "7685",
  "9250",
  "465",
  "3541",
  "66",
  "9697",
  "372",
  "7605",
  "8137",
  "8175",
  "8965",
  "174",
  "9378",
  "6931",
  "8185",
  "2086",
  "294",
  "3650",
  "7475",
  "2158",
  "6188",
  "7721",
  "3879",
  "1204",
  "8347",
  "7324",
  "3518",
  "6814",
  "9219",
  "6267",
  "3312",
  "2890",
  "3169",
  "9086",
  "6836",
  "7966",
  "7402",
  "979",
  "2284",
  "2312",
  "7487",
  "6529",
  "6272",
  "8008",
  "6971",
  "6215",
  "4107",
  "7689",
  "8516",
  "6308",
  "3555",
  "5646",
  "6405",
  "8859",
  "6523",
  "1826",
  "2595",
  "4671",
  "1612",
  "6641",
  "7642",
  "9174",
  "3097",
  "6510",
  "8983",
  "4680",
  "8069",
  "1917",
  "1943",
  "2164",
  "4580",
  "6902",
  "3981",
  "9420",
  "9424",
  "8215",
  "8444",
  "1283",
  "2496",
  "5526",
  "5538",
  "254",
  "8613",
  "1757",
  "4350",
  "5090",
  "5286",
  "3124",
  "4722",
  "344",
  "8779",
  "9412",
  "9985",
  "5331",
  "8028",
  "8529",
  "4001",
  "8214",
  "7036",
  "2519",
  "9792",
  "2920",
  "3511",
  "9677",
  "968",
  "3575",
  "4426",
  "8047",
  "469",
  "4502",
  "5190",
  "6321",
  "7408",
  "6987",
  "3917",
  "5358",
  "7651",
  "4530",
  "4012",
  "9972",
  "2786",
  "2939",
  "8386",
  "1348",
  "505",
  "131",
  "8920",
  "4207",
  "1864",
  "3437",
  "6848",
  "7278",
  "11",
  "4139",
  "4995",
  "5796",
  "6932",
  "5380",
  "5585",
  "2114",
  "6386",
  "7429",
  "6880",
  "3954",
  "9507",
  "1034",
  "1447",
  "3928",
  "4209",
  "8816",
  "5036",
  "6801",
  "8537",
  "152",
  "5925",
  "5001",
  "485",
  "8522",
  "9042",
  "3215",
  "9883",
  "1914",
  "2393",
  "8326",
  "4855",
  "7007",
  "1470",
  "9632",
  "8449",
  "1097",
  "5840",
  "5534",
  "8793",
  "7375",
  "7293",
  "1241",
  "4399",
  "6483",
  "7762",
  "7768",
  "3275",
  "6865",
  "7996",
  "2341",
  "4411",
  "8526",
  "9819",
  "5665",
  "3791",
  "7783",
  "4588",
  "9904",
  "453",
  "7788",
  "7986",
  "6769",
  "4428",
  "4103",
  "4625",
  "7184",
  "9813",
  "4560",
  "7567",
  "2429",
  "9097",
  "7335",
  "8351",
  "3927",
  "5520",
  "4160",
  "7621",
  "7874",
  "9943",
  "4346",
  "2959",
  "3713",
  "1858",
  "2014",
  "1468",
  "1576",
  "2659",
  "2772",
  "2640",
  "43",
  "6065",
  "5313",
  "6035",
  "6948",
  "8111",
  "8852",
  "6180",
  "8782",
  "7892",
  "6741",
  "6862",
  "3307",
  "8332",
  "7748",
  "8946",
  "1475",
  "233",
  "4575",
  "2573",
  "6138",
  "6196",
  "527",
  "5916",
  "7530",
  "7093",
  "7088",
  "8875",
  "5436",
  "2956",
  "9200",
  "6519",
  "5223",
  "8460",
  "5999",
  "6539",
  "7459",
  "9543",
  "7655",
  "5729",
  "2123",
  "4256",
  "8091",
  "228",
  "977",
  "9308",
  "272",
  "8389",
  "8412",
  "232",
  "1847",
  "7010",
  "945",
  "1754",
  "4665",
  "6406",
  "8014",
  "3000",
  "4403",
  "7004",
  "7608",
  "6476",
  "8016",
  "9508",
  "585",
  "2316",
  "5289",
  "9750",
  "7156",
  "4801",
  "8029",
  "8313",
  "3092",
  "5296",
  "3573",
  "6764",
  "9272",
  "776",
  "3327",
  "4445",
  "4700",
  "6668",
  "2137",
  "3646",
  "5886",
  "685",
  "3788",
  "8616",
  "2163",
  "6311",
  "6962",
  "5762",
  "1130",
  "6455",
  "9350",
  "6562",
  "7926",
  "9414",
  "1077",
  "2232",
  "6537",
  "9705",
  "2502",
  "9580",
  "743",
  "3756",
  "8970",
  "4483",
  "6022",
  "1997",
  "196",
  "4822",
  "1506",
  "3524",
  "7784",
  "742",
  "2054",
  "5730",
  "3128",
  "595",
  "1396",
  "9886",
  "5407",
  "8663",
  "2148",
  "1139",
  "3287",
  "3316",
  "3558",
  "4999",
  "1237",
  "7963",
  "3250",
  "3849",
  "3909",
  "837",
  "2427",
  "3011",
  "3187",
  "9111",
  "7359",
  "409",
  "478",
  "6569",
  "62",
  "1562",
  "3202",
  "7350",
  "1672",
  "3121",
  "8377",
  "8784",
  "2958",
  "787",
  "5732",
  "7887",
  "4421",
  "6019",
  "2626",
  "3155",
  "9143",
  "850",
  "3010",
  "7729",
  "2403",
  "2102",
  "834",
  "6310",
  "5508",
  "223",
  "2414",
  "1885",
  "3008",
  "6389",
  "6390",
  "5424",
  "1558",
  "3540",
  "5169",
  "5255",
  "612",
  "1913",
  "9867",
  "6461",
  "8907",
  "6350",
  "9440",
  "6585",
  "5034",
  "6566",
  "9423",
  "9512",
  "8915",
  "9829",
  "2324",
  "4858",
  "6106",
  "7540",
  "4592",
  "1941",
  "6919",
  "7157",
  "6150",
  "9386",
  "1731",
  "60",
  "4377",
  "5844",
  "800",
  "2155",
  "8817",
  "614",
  "788",
  "4056",
  "4374",
  "6963",
  "2439",
  "7400",
  "6212",
  "5567",
  "5648",
  "7786",
  "8245",
  "2968",
  "986",
  "7520",
  "9602",
  "5184",
  "6059",
  "3693",
  "4936",
  "5390",
  "4981",
  "9872",
  "5210",
  "8917",
  "5624",
  "7726",
  "9604",
  "2179",
  "7431",
  "6097",
  "8757",
  "9710",
  "991",
  "7543",
  "343",
  "5761",
  "3772",
  "810",
  "41",
  "1306",
  "4556",
  "4681",
  "9279",
  "9785",
  "6533",
  "7719",
  "5354",
  "1286",
  "1539",
  "2370",
  "5142",
  "377",
  "6121",
  "3989",
  "811",
  "7895",
  "5471",
  "6921",
  "4679",
  "4241",
  "6679",
  "5024",
  "6054",
  "8998",
  "9866",
  "9990",
  "4485",
  "5853",
  "1344",
  "5045",
  "8528",
  "9432",
  "6822",
  "7718",
  "461",
  "7131",
  "1404",
  "3513",
  "4788",
  "479",
  "1817",
  "7695",
  "8883",
  "9301",
  "3714",
  "5935",
  "2722",
  "8049",
  "6096",
  "9550",
  "2865",
  "3881",
  "3963",
  "7478",
  "2981",
  "7752",
  "8161",
  "6020",
  "3921",
  "4091",
  "1696",
  "3685",
  "128",
  "1995",
  "581",
  "1062",
  "3044",
  "6632",
  "7876",
  "1736",
  "9388",
  "8578",
  "8884",
  "6589",
  "9868",
  "9146",
  "9646",
  "9805",
  "9996",
  "3807",
  "4500",
  "85",
  "1632",
  "1962",
  "3294",
  "5292",
  "2461",
  "1438",
  "2036",
  "4661",
  "9121",
  "7378",
  "9532",
  "71",
  "1483",
  "12",
  "9596",
  "9238",
  "6384",
  "4456",
  "7676",
  "2237",
  "7277",
  "826",
  "4086",
  "5265",
  "9877",
  "1319",
  "1437",
  "3059",
  "6282",
  "4519",
  "8517",
  "1609",
  "4385",
  "6201",
  "6734",
  "7591",
  "5002",
  "190",
  "3376",
  "8736",
  "6808",
  "5338",
  "5911",
  "4135",
  "53",
  "9699",
  "3116",
  "3201",
  "5512",
  "9099",
  "1870",
  "6941",
  "7054",
  "8158",
  "8164",
  "9162",
  "1665",
  "9893",
  "2225",
  "7355",
  "8428",
  "8610",
  "6922",
  "3625",
  "8951",
  "54",
  "3148",
  "3738",
  "5129",
  "9167",
  "3479",
  "401",
  "392",
  "5559",
  "5664",
  "5780",
  "6945",
  "9931",
  "5775",
  "7789",
  "8949",
  "2452",
  "8885",
  "7702",
  "8178",
  "5328",
  "8797",
  "9703",
  "4647",
  "1316",
  "2881",
  "3803",
  "4290",
  "4891",
  "3828",
  "6588",
  "7084",
  "2566",
  "2966",
  "4577",
  "4291",
  "4455",
  "5161",
  "7442",
  "5651",
  "1555",
  "2555",
  "3420",
  "4874",
  "1295",
  "6345",
  "7885",
  "4831",
  "6411",
  "167",
  "8898",
  "3512",
  "2010",
  "2656",
  "7097",
  "3113",
  "3809",
  "5316",
  "9558",
  "9469",
  "7603",
  "2133",
  "3640",
  "7705",
  "9346",
  "8869",
  "2807",
  "9631",
  "2862",
  "7282",
  "9908",
  "8638",
  "9393",
  "1935",
  "6102",
  "431",
  "9246",
  "7549",
  "2529",
  "3778",
  "4414",
  "7056",
  "8761",
  "2257",
  "1694",
  "3648",
  "3527",
  "8525",
  "9333",
  "8549",
  "425",
  "3295",
  "5530",
  "1119",
  "2149",
  "4918",
  "5098",
  "5782",
  "6634",
  "274",
  "5848",
  "8334",
  "9419",
  "153",
  "481",
  "9811",
  "1480",
  "6024",
  "5311",
  "6472",
  "2115",
  "2235",
  "543",
  "2845",
  "3078",
  "8717",
  "9965",
  "9499",
  "1025",
  "1471",
  "1795",
  "5400",
  "5282",
  "2351",
  "417",
  "142",
  "4052",
  "9381",
  "6222",
  "7098",
  "3850",
  "7385",
  "3230",
  "1180",
  "4933",
  "6174",
  "8822",
  "9405",
  "8176",
  "1570",
  "9851",
  "6436",
  "7071",
  "3743",
  "9016",
  "6651",
  "2079",
  "3015",
  "5932",
  "10000",
  "956",
  "3211",
  "4120",
  "5254",
  "6540",
  "6955",
  "5237",
  "9801",
  "9159",
  "1009",
  "6831",
  "2644",
  "7569",
  "5834",
  "1497",
  "7710",
  "818",
  "3456",
  "5676",
  "9198",
  "5123",
  "6297",
  "2641",
  "8154",
  "9179",
  "9645",
  "6876",
  "692",
  "3120",
  "3814",
  "7590",
  "3216",
  "8187",
  "8425",
  "6703",
  "9798",
  "2207",
  "1377",
  "8696",
  "2260",
  "2455",
  "4783",
  "9152",
  "2262",
  "9824",
  "6692",
  "7766",
  "3031",
  "3670",
  "7921",
  "1299",
  "6089",
  "3262",
  "7322",
  "9468",
  "394",
  "5097",
  "2215",
  "2657",
  "6610",
  "8319",
  "9592",
  "646",
  "3313",
  "8221",
  "2677",
  "714",
  "5349",
  "4897",
  "2480",
  "4197",
  "694",
  "1614",
  "7247",
  "798",
  "6885",
  "1921",
  "345",
  "2336",
  "6513",
  "9859",
  "1312",
  "8914",
  "3387",
  "6942",
  "3614",
  "6242",
  "177",
  "2458",
  "7183",
  "3009",
  "3898",
  "4342",
  "8685",
  "9233",
  "4470",
  "5462",
  "7197",
  "5049",
  "7219",
  "9936",
  "1372",
  "3071",
  "4282",
  "4233",
  "6956",
  "4270",
  "4283",
  "7811",
  "7641",
  "9786",
  "2799",
  "6039",
  "8890",
  "3",
  "1124",
  "2448",
  "6927",
  "8434",
  "1625",
  "8675",
  "8866",
  "3765",
  "9560",
  "9683",
  "1546",
  "9048",
  "5193",
  "7947",
  "8409",
  "2005",
  "2735",
  "6911",
  "6026",
  "1106",
  "5671",
  "7130",
  "1848",
  "5203",
  "6662",
  "8786",
  "4163",
  "8560",
  "9479",
  "852",
  "1153",
  "3181",
  "8864",
  "8588",
  "9000",
  "6795",
  "1044",
  "5143",
  "1346",
  "352",
  "565",
  "1919",
  "7920",
  "4984",
  "6645",
  "2306",
  "2658",
  "3959",
  "6286",
  "231",
  "7989",
  "4119",
  "4931",
  "7208",
  "744",
  "3012",
  "6352",
  "9043",
  "3797",
  "8",
  "7623",
  "324",
  "3426",
  "3291",
  "9371",
  "6323",
  "7978",
  "1259",
  "9958",
  "4064",
  "318",
  "1071",
  "8065",
  "8881",
  "4025",
  "124",
  "3781",
  "9443",
  "5661",
  "9887",
  "1231",
  "6316",
  "2276",
  "3748",
  "9988",
  "8265",
  "5177",
  "5715",
  "3220",
  "6753",
  "8344",
  "8823",
  "3351",
  "5482",
  "3782",
  "7717",
  "1868",
  "3611",
  "8531",
  "8283",
  "4590",
  "2467",
  "9227",
  "7401",
  "8789",
  "3368",
  "5805",
  "5900",
  "6069",
  "6986",
  "562",
  "2977",
  "7625",
  "1194",
  "9129",
  "3320",
  "7462",
  "4729",
  "9902",
  "7174",
  "7577",
  "219",
  "7681",
  "8424",
  "8576",
  "8647",
  "6003",
  "6976",
  "4970",
  "159",
  "601",
  "1998",
  "2911",
  "8706",
  "46",
  "51",
  "6596",
  "1016",
  "1969",
  "1845",
  "6398",
  "2410",
  "6033",
  "6915",
  "7647",
  "1532",
  "1551",
  "5087",
  "4014",
  "2435",
  "5603",
  "165",
  "825",
  "8357",
  "6135",
  "6788",
  "7085",
  "8806",
  "1940",
  "3677",
  "4552",
  "4908",
  "3922",
  "4308",
  "1152",
  "8500",
  "9151",
  "5923",
  "4361",
  "8646",
  "8985",
  "859",
  "6366",
  "1732",
  "3041",
  "4823",
  "3890",
  "1531",
  "1691",
  "2616",
  "6290",
  "4511",
  "5064",
  "5116",
  "9073",
  "1792",
  "9288",
  "1639",
  "5215",
  "202",
  "8760",
  "7901",
  "1131",
  "3767",
  "2445",
  "3032",
  "4615",
  "4663",
  "5574",
  "7697",
  "3560",
  "786",
  "980",
  "9503",
  "8579",
  "2172",
  "7995",
  "4828",
  "4158",
  "6488",
  "1824",
  "5264",
  "8399",
  "8145",
  "8767",
  "6136",
  "5832",
  "4268",
  "7177",
  "1464",
  "6929",
  "226",
  "2785",
  "4992",
  "5287",
  "997",
  "8342",
  "2151",
  "8458",
  "3219",
  "8818",
  "657",
  "2821",
  "4745",
  "5339",
  "5949",
  "2618",
  "3147",
  "8295",
  "1174",
  "5213",
  "7175",
  "8586",
  "8961",
  "1165",
  "6304",
  "3798",
  "507",
  "9637",
  "1494",
  "7833",
  "371",
  "5640",
  "1646",
  "2181",
  "4750",
  "5166",
  "8848",
  "9177",
  "6281",
  "7804",
  "5392",
  "2575",
  "5933",
  "5817",
  "8639",
  "1823",
  "116",
  "2580",
  "6925",
  "381",
  "8618",
  "359",
  "6512",
  "3314",
  "7722",
  "629",
  "1787",
  "4222",
  "1892",
  "5396",
  "9139",
  "1585",
  "9525",
  "7137",
  "4048",
  "871",
  "886",
  "9034",
  "385",
  "3464",
  "5404",
  "2979",
  "3907",
  "5914",
  "9727",
  "379",
  "270",
  "6254",
  "7902",
  "3258",
  "8539",
  "9795",
  "4540",
  "7313",
  "8213",
  "1045",
  "8969",
  "9910",
  "1059",
  "1924",
  "3049",
  "3501",
  "5850",
  "6223",
  "7349",
  "2776",
  "5117",
  "1743",
  "2076",
  "3918",
  "8704",
  "2997",
  "862",
  "293",
  "1794",
  "1537",
  "5106",
  "8643",
  "1087",
  "7829",
  "663",
  "5712",
  "6676",
  "8787",
  "9262",
  "4807",
  "6952",
  "8404",
  "2645",
  "4573",
  "6898",
  "8123",
  "1191",
  "9472",
  "9210",
  "1906",
  "2765",
  "4730",
  "8035",
  "1915",
  "4039",
  "8715",
  "7700",
  "442",
  "2557",
  "5713",
  "7352",
  "9892",
  "1278",
  "4080",
  "3682",
  "3336",
  "3346",
  "3719",
  "5557",
  "1448",
  "4009",
  "6045",
  "338",
  "1834",
  "5688",
  "5079",
  "7235",
  "8410",
  "769",
  "5928",
  "6873",
  "7289",
  "964",
  "162",
  "7086",
  "6799",
  "9256",
  "1636",
  "6087",
  "8508",
  "8841",
  "7491",
  "2694",
  "3886",
  "4129",
  "732",
  "6160",
  "6328",
  "6712",
  "6752",
  "7850",
  "7891",
  "7525",
  "2355",
  "5529",
  "4464",
  "468",
  "9059",
  "88",
  "321",
  "7387",
  "384",
  "3805",
  "5681",
  "8103",
  "3467",
  "8783",
  "5140",
  "823",
  "5395",
  "1595",
  "4595",
  "8478",
  "1955",
  "5137",
  "263",
  "8856",
  "2217",
  "2964",
  "3829",
  "4229",
  "5115",
  "6583",
  "6742",
  "9994",
  "5268",
  "9984",
  "6897",
  "7809",
  "1234",
  "3697",
  "3619",
  "434",
  "2358",
  "5348",
  "8510",
  "4019",
  "2347",
  "4741",
  "169",
  "3567",
  "7742",
  "9905",
  "316",
  "1748",
  "5827",
  "5550",
  "6122",
  "7019",
  "6559",
  "7750",
  "9688",
  "641",
  "3967",
  "6605",
  "7537",
  "1340",
  "1938",
  "5074",
  "6071",
  "5623",
  "2472",
  "6779",
  "3542",
  "4181",
  "5561",
  "8160",
  "801",
  "2846",
  "3086",
  "4359",
  "6417",
  "5988",
  "4977",
  "1349",
  "2124",
  "6038",
  "6541",
  "4765",
  "9885",
  "749",
  "2994",
  "3190",
  "916",
  "3341",
  "3234",
  "4487",
  "7021",
  "289",
  "5837",
  "3244",
  "6203",
  "3753",
  "4415",
  "5887",
  "2097",
  "8955",
  "6939",
  "7220",
  "5156",
  "5785",
  "8900",
  "8279",
  "147",
  "2897",
  "6591",
  "8984",
  "9648",
  "6489",
  "1076",
  "2288",
  "705",
  "3700",
  "6105",
  "2840",
  "1281",
  "1450",
  "3360",
  "4199",
  "5659",
  "6220",
  "2220",
  "9751",
  "7482",
  "2736",
  "7872",
  "9269",
  "1891",
  "2838",
  "2063",
  "4168",
  "5356",
  "951",
  "2962",
  "9063",
  "2606",
  "8791",
  "3645",
  "8993",
  "9729",
  "8372",
  "1107",
  "2627",
  "4790",
  "7720",
  "4631",
  "4482",
  "5590",
  "1651",
  "1976",
  "5621",
  "383",
  "2646",
  "3407",
  "5628",
  "3016",
  "1224",
  "5189",
  "8615",
  "6627",
  "2325",
  "5155",
  "1287",
  "1682",
  "6307",
  "4567",
  "7479",
  "1144",
  "4799",
  "5582",
  "7331",
  "1031",
  "7889",
  "7596",
  "6637",
  "7991",
  "6055",
  "2375",
  "2792",
  "5295",
  "7542",
  "2695",
  "8468",
  "2561",
  "9389",
  "6792",
  "6098",
  "6434",
  "8433",
  "7488",
  "2231",
  "828",
  "3226",
  "3236",
  "4306",
  "4820",
  "3672",
  "4958",
  "7943",
  "4868",
  "7763",
  "322",
  "1753",
  "5620",
  "460",
  "6840",
  "7594",
  "9545",
  "2041",
  "807",
  "2322",
  "2796",
  "3519",
  "4324",
  "4707",
  "7806",
  "7931",
  "4549",
  "3598",
  "8854",
  "8913",
  "2830",
  "5877",
  "8821",
  "2775",
  "3557",
  "7445",
  "950",
  "3885",
  "2238",
  "8195",
  "8305",
  "4762",
  "8547",
  "2400",
  "3199",
  "4890",
  "4938",
  "5907",
  "2152",
  "4041",
  "8143",
  "5013",
  "6234",
  "9565",
  "8846",
  "9169",
  "156",
  "2611",
  "4969",
  "9292",
  "3304",
  "5506",
  "3345",
  "5614",
  "1320",
  "3309",
  "6161",
  "2143",
  "4636",
  "8206",
  "2737",
  "2925",
  "5411",
  "9483",
  "1516",
  "1840",
  "4655",
  "4756",
  "5242",
  "528",
  "2092",
  "7724",
  "8703",
  "5146",
  "2829",
  "8645",
  "9809",
  "3664",
  "6391",
  "3736",
  "9784",
  "2855",
  "3576",
  "5178",
  "7279",
  "3572",
  "531",
  "587",
  "590",
  "6702",
  "2409",
  "2523",
  "4589",
  "6440",
  "350",
  "2753",
  "3999",
  "28",
  "2903",
  "932",
  "3889",
  "9126",
  "4246",
  "484",
  "1727",
  "4117",
  "9712",
  "9864",
  "9656",
  "2494",
  "7362",
  "6330",
  "7953",
  "8446",
  "6926",
  "4813",
  "616",
  "1701",
  "3968",
  "5088",
  "2584",
  "1673",
  "5433",
  "5992",
  "9617",
  "1264",
  "5770",
  "8093",
  "3848",
  "1302",
  "6597",
  "8440",
  "256",
  "7912",
  "9347",
  "8922",
  "5337",
  "3022",
  "554",
  "2088",
  "6469",
  "40",
  "9814",
  "2413",
  "4362",
  "4245",
  "3478",
  "8660",
  "2073",
  "4772",
  "9657",
  "1123",
  "1813",
  "9715",
  "6241",
  "7548",
  "7843",
  "1253",
  "9897",
  "3679",
  "6690",
  "8457",
  "3489",
  "4569",
  "6339",
  "6859",
  "1621",
  "5641",
  "7386",
  "8527",
  "4915",
  "6530",
  "7987",
  "4200",
  "5179",
  "7504",
  "8085",
  "8448",
  "2378",
  "3247",
  "4862",
  "8745",
  "1763",
  "6573",
  "81",
  "4024",
  "6409",
  "6625",
  "6107",
  "2491",
  "2536",
  "3634",
  "6549",
  "6479",
  "3214",
  "8871",
  "2534",
  "3653",
  "7738",
  "9693",
  "6578",
  "4382",
  "7290",
  "5899",
  "9022",
  "4943",
  "538",
  "3737",
  "6176",
  "9952",
  "902",
  "1661",
  "8125",
  "8081",
  "9401",
  "6109",
  "6443",
  "5082",
  "7176",
  "7980",
  "8882",
  "7202",
  "9461",
  "5261",
  "8128",
  "8311",
  "14",
  "1339",
  "6441",
  "2256",
  "9082",
  "6184",
  "6750",
  "535",
  "3126",
  "4962",
  "5329",
  "1018",
  "6797",
  "8652",
  "424",
  "1205",
  "6129",
  "4524",
  "9467",
  "84",
  "8261",
  "832",
  "1219",
  "110",
  "3488",
  "2303",
  "8130",
  "8722",
  "670",
  "6724",
  "8695",
  "8097",
  "4261",
  "6336",
  "8211",
  "768",
  "9530",
  "636",
  "5260",
  "1446",
  "2486",
  "6403",
  "9630",
  "5186",
  "6262",
  "7749",
  "2396",
  "3528",
  "7906",
  "1553",
  "4531",
  "5420",
  "4883",
  "5342",
  "3771",
  "3888",
  "9340",
  "5595",
  "7547",
  "7716",
  "9588",
  "8304",
  "1314",
  "1398",
  "3523",
  "7272",
  "2951",
  "5852",
  "6298",
  "6303",
  "9020",
  "6820",
  "3176",
  "5470",
  "5739",
  "1899",
  "9830",
  "1493",
  "7708",
  "3331",
  "1561",
  "3353",
  "200",
  "8095",
  "4049",
  "7480",
  "8280",
  "1658",
  "8626",
  "5122",
  "8570",
  "9890",
  "1192",
  "2716",
  "6633",
  "9817",
  "3162",
  "7317",
  "1524",
  "2464",
  "1481",
  "2258",
  "1946",
  "3428",
  "7104",
  "1032",
  "8511",
  "361",
  "4696",
  "878",
  "2996",
  "3034",
  "4951",
  "6944",
  "1074",
  "5903",
  "1358",
  "6912",
  "5046",
  "6805",
  "7457",
  "4114",
  "4743",
  "3365",
  "5236",
  "8432",
  "2349",
  "5668",
  "9112",
  "6041",
  "7395",
  "7476",
  "7524",
  "472",
  "3592",
  "6221",
  "805",
  "4509",
  "6718",
  "3481",
  "7376",
  "9410",
  "9932",
  "8390",
  "9122",
  "9222",
  "697",
  "753",
  "4469",
  "9847",
  "843",
  "38",
  "8992",
  "1116",
  "1411",
  "4128",
  "9160",
  "529",
  "4305",
  "6157",
  "1213",
  "1689",
  "7735",
  "904",
  "508",
  "1871",
  "3652",
  "8799",
  "1235",
  "5378",
  "5763",
  "9341",
  "5979",
  "1664",
  "1866",
  "5793",
  "7924",
  "514",
  "7191",
  "9612",
  "4396",
  "5873",
  "5893",
  "7096",
  "9287",
  "4093",
  "9600",
  "615",
  "4606",
  "7937",
  "5276",
  "4842",
  "2047",
  "3608",
  "8106",
  "221",
  "275",
  "7997",
  "5032",
  "8420",
  "9497",
  "1780",
  "2305",
  "6439",
  "9014",
  "2769",
  "9173",
  "4438",
  "4667",
  "1459",
  "2060",
  "5727",
  "9030",
  "2236",
  "8780",
  "7295",
  "268",
  "1750",
  "5952",
  "6661",
  "6946",
  "9035",
  "9183",
  "4758",
  "5243",
  "8120",
  "8119",
  "2831",
  "5960",
  "1557",
  "1744",
  "6163",
  "3892",
  "130",
  "9937",
  "9536",
  "7979",
  "1799",
  "1936",
  "9571",
  "9966",
  "8132",
  "3225",
  "4541",
  "390",
  "7142",
  "7099",
  "6975",
  "3631",
  "5463",
  "5445",
  "5073",
  "883",
  "2632",
  "8046",
  "9188",
  "9644",
  "6845",
  "4235",
  "4818",
  "4228",
  "6447",
  "6949",
  "2874",
  "1357",
  "4169",
  "8666",
  "2087",
  "75",
  "2558",
  "1999",
  "5113",
  "8520",
  "6011",
  "7776",
  "4607",
  "9888",
  "133",
  "1861",
  "2481",
  "3993",
  "6738",
  "5196",
  "2372",
  "7225",
  "9377",
  "9768",
  "6499",
  "8637",
  "5080",
  "6636",
  "6604",
  "7522",
  "3624",
  "2029",
  "7701",
  "9135",
  "3799",
  "8763",
  "323",
  "830",
  "5583",
  "9852",
  "944",
  "1248",
  "6155",
  "8437",
  "8811",
  "2949",
  "6090",
  "3039",
  "3431",
  "5969",
  "6846",
  "8832",
  "6757",
  "3813",
  "7888",
  "3470",
  "3811",
  "7658",
  "8272",
  "8919",
  "552",
  "4475",
  "7172",
  "317",
  "1308",
  "5108",
  "5197",
  "9286",
  "2407",
  "3947",
  "3594",
  "3893",
  "5059",
  "6112",
  "7287",
  "2760",
  "5206",
  "610",
  "6166",
  "8934",
  "569",
  "8148",
  "9023",
  "9264",
  "16",
  "1734",
  "2729",
  "1049",
  "3454",
  "4532",
  "2342",
  "7523",
  "9398",
  "2121",
  "7286",
  "8349",
  "1291",
  "3857",
  "8150",
  "3914",
  "4077",
  "5248",
  "6710",
  "7421",
  "9448",
  "4669",
  "3953",
  "7345",
  "9672",
  "433",
  "2321",
  "3179",
  "8363",
  "6850",
  "7379",
  "7698",
  "8577",
  "2373",
  "5528",
  "6503",
  "192",
  "1818",
  "3241",
  "4063",
  "4008",
  "4940",
  "7533",
  "9228",
  "8375",
  "750",
  "7640",
  "5692",
  "339",
  "7620",
  "6563",
  "6618",
  "9582",
  "4866",
  "8379",
  "2178",
  "7981",
  "243",
  "7240",
  "4394",
  "7839",
  "7800",
  "251",
  "1960",
  "4450",
  "5283",
  "6704",
  "5616",
  "6385",
  "7521",
  "7551",
  "3824",
  "5987",
  "8339",
  "3388",
  "4462",
  "7865",
  "9485",
  "2001",
  "2621",
  "8365",
  "287",
  "5633",
  "6359",
  "6887",
  "9153",
  "1841",
  "6923",
  "5325",
  "1908",
  "5884",
  "5631",
  "9285",
  "4326",
  "2885",
  "3705",
  "7517",
  "5430",
  "8648",
  "1201",
  "1545",
  "1939",
  "2300",
  "5464",
  "1527",
  "2506",
  "5057",
  "304",
  "5466",
  "7571",
  "2404",
  "3590",
  "3816",
  "3268",
  "9396",
  "755",
  "3564",
  "2567",
  "5944",
  "6825",
  "9669",
  "1333",
  "9662",
  "1717",
  "5679",
  "4427",
  "5560",
  "6263",
  "6972",
  "4430",
  "5790",
  "9945",
  "1758",
  "6006",
  "9755",
  "9789",
  "1589",
  "3750",
  "5717",
  "6544",
  "2565",
  "309",
  "518",
  "6648",
  "8678",
  "1183",
  "706",
  "8623",
  "3977",
  "5485",
  "4562",
  "3722",
  "4812",
  "7740",
  "435",
  "3145",
  "8268",
  "602",
  "8978",
  "2244",
  "4937",
  "6868",
  "3815",
  "1154",
  "5951",
  "2273",
  "4217",
  "8401",
  "3970",
  "4443",
  "2570",
  "3136",
  "4835",
  "8936",
  "3084",
  "6464",
  "6210",
  "1670",
  "8667",
  "8456",
  "8672",
  "1318",
  "5587",
  "2249",
  "2313",
  "9591",
  "4204",
  "3935",
  "8204",
  "773",
  "5829",
  "6666",
  "3408",
  "114",
  "1109",
  "8450",
  "4535",
  "1207",
  "7945",
  "1931",
  "5609",
  "492",
  "5943",
  "9948",
  "5592",
  "9057",
  "1500",
  "7213",
  "8260",
  "2270",
  "5660",
  "1762",
  "9838",
  "8263",
  "3152",
  "6558",
  "2188",
  "2333",
  "3449",
  "7077",
  "6458",
  "7751",
  "7024",
  "1415",
  "8205",
  "2227",
  "3871",
  "983",
  "4149",
  "1495",
  "3810",
  "2462",
  "4892",
  "103",
  "8421",
  "9833",
  "239",
  "4417",
  "7779",
  "707",
  "1250",
  "9927",
  "4514",
  "5479",
  "7348",
  "8459",
  "1337",
  "2084",
  "2504",
  "5613",
  "9204",
  "8484",
  "5755",
  "8804",
  "700",
  "4644",
  "7009",
  "7119",
  "1100",
  "1530",
  "2512",
  "273",
  "626",
  "2050",
  "4622",
  "6727",
  "6758",
  "1860",
  "4893",
  "5364",
  "8275",
  "386",
  "3903",
  "6217",
  "8133",
  "6736",
  "814",
  "3458",
  "3628",
  "6999",
  "3681",
  "847",
  "1988",
  "6031",
  "4699",
  "2119",
  "4028",
  "6606",
  "8957",
  "567",
  "8952",
  "3796",
  "6040",
  "6172",
  "8538",
  "903",
  "995",
  "5056",
  "6408",
  "3866",
  "5885",
  "9199",
  "9409",
  "210",
  "2969",
  "1968",
  "9493",
  "5095",
  "5154",
  "3998",
  "5494",
  "5251",
  "9759",
  "1686",
  "5051",
  "559",
  "7837",
  "8002",
  "4688",
  "5334",
  "6851",
  "8774",
  "9696",
  "1669",
  "1874",
  "4239",
  "3495",
  "4773",
  "5773",
  "6681",
  "9551",
  "396",
  "2778",
  "5132",
  "6642",
  "9736",
  "553",
  "1654",
  "691",
  "8928",
  "1439",
  "3867",
  "5456",
  "7448",
  "5167",
  "374",
  "1825",
  "3830",
  "8310",
  "6211",
  "3131",
  "3361",
  "4100",
  "5684",
  "8679",
  "3950",
  "7938",
  "367",
  "573",
  "2884",
  "4979",
  "2751",
  "1047",
  "5575",
  "6173",
  "8127",
  "6042",
  "3762",
  "2707",
  "8981",
  "6213",
  "7334",
  "8068",
  "8689",
  "2432",
  "2672",
  "5892",
  "2229",
  "7744",
  "5586",
  "6707",
  "8988",
  "7407",
  "3186",
  "9548",
  "5769",
  "8762",
  "2140",
  "6465",
  "9957",
  "6507",
  "8039",
  "668",
  "7371",
  "9660",
  "9840",
  "4375",
  "6892",
  "3760",
  "4944",
  "5632",
  "7204",
  "5383",
  "4705",
  "7928",
  "8701",
  "6791",
  "8697",
  "402",
  "6415",
  "969",
  "2482",
  "8601",
  "9540",
  "250",
  "199",
  "1978",
  "3397",
  "1893",
  "3243",
  "1542",
  "9197",
  "2802",
  "158",
  "4987",
  "6496",
  "9535",
  "161",
  "2283",
  "2684",
  "4845",
  "2286",
  "2394",
  "6108",
  "9563",
  "6590",
  "9928",
  "2248",
  "2661",
  "5667",
  "9673",
  "1752",
  "8707",
  "8634",
  "3465",
  "4998",
  "2787",
  "6156",
  "6719",
  "4518",
  "4713",
  "3056",
  "3142",
  "3926",
  "6104",
  "9754",
  "1374",
  "9054",
  "6580",
  "2754",
  "4991",
  "8903",
  "978",
  "4719",
  "6579",
  "7562",
  "8497",
  "927",
  "1363",
  "2556",
  "4203",
  "6918",
  "3144",
  "6346",
  "7747",
  "8953",
  "4629",
  "5172",
  "388",
  "1765",
  "3282",
  "1275",
  "9911",
  "8414",
  "1518",
  "4176",
  "7982",
  "8025",
  "1090",
  "5994",
  "6891",
  "8551",
  "3548",
  "6640",
  "3773",
  "2026",
  "9614",
  "1581",
  "464",
  "9720",
  "2568",
  "5443",
  "7859",
  "9690",
  "3110",
  "4124",
  "7755",
  "146",
  "6592",
  "7687",
  "8980",
  "2190",
  "3476",
  "8495",
  "3123",
  "3930",
  "5974",
  "771",
  "9442",
  "952",
  "3577",
  "3733",
  "5403",
  "2701",
  "8115",
  "9050",
  "3836",
  "7528",
  "4697",
  "8426",
  "56",
  "1838",
  "4682",
  "6438",
  "7245",
  "9714",
  "9682",
  "5515",
  "9363",
  "9387",
  "5846",
  "6721",
  "1789",
  "8302",
  "4177",
  "2719",
  "5427",
  "548",
  "671",
  "4643",
  "8770",
  "2794",
  "1394",
  "2992",
  "1798",
  "6535",
  "1249",
  "3578",
  "6145",
  "4238",
  "2810",
  "2848",
  "8532",
  "7496",
  "5802",
  "9244",
  "5906",
  "6555",
  "6968",
  "8693",
  "6665",
  "1383",
  "2187",
  "201",
  "8959",
  "1485",
  "1284",
  "501",
  "570",
  "3585",
  "6904",
  "7611",
  "9700",
  "6053",
  "7741",
  "8771",
  "8938",
  "9709",
  "7443",
  "8926",
  "2880",
  "2926",
  "3374",
  "4593",
  "643",
  "8908",
  "2122",
  "4316",
  "7711",
  "6305",
  "6255",
  "2651",
  "2670",
  "2828",
  "5696",
  "7793",
  "4384",
  "2910",
  "5754",
  "9494",
  "8122",
  "1702",
  "8491",
  "1926",
  "1832",
  "8006",
  "5133",
  "7179",
  "7294",
  "3090",
  "3531",
  "4759",
  "1046",
  "1597",
  "3870",
  "2277",
  "3864",
  "5975",
  "6674",
  "1699",
  "2550",
  "2836",
  "4565",
  "301",
  "6197",
  "8267",
  "7397",
  "2354",
  "7672",
  "6672",
  "2268",
  "5306",
  "3106",
  "3371",
  "9849",
  "864",
  "4319",
  "7135",
  "9523",
  "127",
  "7917",
  "1575",
  "737",
  "2525",
  "566",
  "186",
  "117",
  "319",
  "4454",
  "1028",
  "2011",
  "2243",
  "5290",
  "5996",
  "6165",
  "8862",
  "2552",
  "6624",
  "4826",
  "5038",
  "7233",
  "1105",
  "8242",
  "328",
  "764",
  "3422",
  "5355",
  "355",
  "4296",
  "4225",
  "9578",
  "3269",
  "5030",
  "4466",
  "1771",
  "5707",
  "9898",
  "7168",
  "7321",
  "1522",
  "5299",
  "5677",
  "8682",
  "9053",
  "1599",
  "2339",
  "7270",
  "8718",
  "6654",
  "6070",
  "7416",
  "8082",
  "8257",
  "9033",
  "596",
  "7358",
  "8238",
  "571",
  "1578",
  "4676",
  "458",
  "6988",
  "1406",
  "6356",
  "6554",
  "5385",
  "4266",
  "7404",
  "717",
  "4496",
  "6989",
  "260",
  "551",
  "8077",
  "3597",
  "93",
  "912",
  "3167",
  "8889",
  "1772",
  "6723",
  "7890",
  "8316",
  "7087",
  "473",
  "7238",
  "3632",
  "4136",
  "5820",
  "7948",
  "8910",
  "2547",
  "9239",
  "4884",
  "6442",
  "6678",
  "7420",
  "5857",
  "9365",
  "7678",
  "4810",
  "2685",
  "3661",
  "8055",
  "5982",
  "8194",
  "1356",
  "4930",
  "9081",
  "9495",
  "6647",
  "450",
  "1365",
  "2169",
  "1484",
  "6993",
  "7807",
  "9303",
  "7173",
  "1909",
  "9997",
  "5041",
  "7670",
  "8001",
  "4123",
  "4614",
  "5600",
  "6010",
  "9193",
  "9265",
  "4840",
  "8225",
  "4570",
  "1482",
  "3745",
  "5382",
  "5931",
  "9367",
  "8896",
  "4557",
  "1063",
  "3601",
  "4490",
  "8591",
  "1520",
  "7167",
  "9680",
  "1859",
  "2551",
  "9041",
  "9096",
  "5240",
  "1038",
  "5493",
  "2770",
  "9399",
  "1206",
  "1424",
  "1809",
  "6353",
  "9011",
  "4561",
  "5792",
  "3372",
  "8469",
  "2132",
  "2752",
  "7363",
  "3122",
  "3574",
  "7136",
  "4085",
  "4889",
  "7390",
  "2425",
  "4242",
  "7307",
  "2000",
  "2332",
  "6538",
  "2397",
  "4016",
  "6125",
  "6399",
  "7444",
  "7610",
  "3823",
  "1857",
  "2732",
  "2793",
  "3356",
  "9776",
  "3133",
  "2117",
  "2442",
  "7262",
  "5308",
  "7778",
  "7815",
  "6351",
  "7231",
  "2280",
  "7534",
  "6239",
  "8688",
  "9087",
  "9735",
  "4224",
  "9764",
  "3285",
  "8234",
  "8348",
  "605",
  "2065",
  "8836",
  "9181",
  "5734",
  "4957",
  "1552",
  "2591",
  "3328",
  "4258",
  "9084",
  "1355",
  "6997",
  "7253",
  "9756",
  "3732",
  "6158",
  "8009",
  "3529",
  "5300",
  "5388",
  "1361",
  "6584",
  "7907",
  "794",
  "1211",
  "1929",
  "4986",
  "7825",
  "724",
  "1426",
  "9045",
  "3972",
  "2479",
  "3792",
  "5247",
  "4534",
  "1660",
  "6376",
  "298",
  "1770",
  "6853",
  "4278",
  "2844",
  "2916",
  "6657",
  "7361",
  "662",
  "341",
  "3728",
  "6177",
  "6696",
  "2359",
  "3096",
  "981",
  "5368",
  "7555",
  "3159",
  "8962",
  "104",
  "9079",
  "5008",
  "4863",
  "3412",
  "5862",
  "1321",
  "4013",
  "8687",
  "745",
  "6377",
  "3872",
  "9025",
  "9593",
  "2761",
  "50",
  "9777",
  "2447",
  "2756",
  "970",
  "3612",
  "1901",
  "1947",
  "7484",
  "8413",
  "1659",
  "8274",
  "9291",
  "9903",
  "5968",
  "1392",
  "3744",
  "4833",
  "7970",
  "8611",
  "3884",
  "79",
  "7513",
  "9229",
  "1304",
  "7423",
  "7935",
  "654",
  "1828",
  "3109",
  "259",
  "905",
  "5141",
  "8402",
  "509",
  "3642",
  "6013",
  "9834",
  "6204",
  "7818",
  "2643",
  "2814",
  "5367",
  "9435",
  "7960",
  "885",
  "73",
  "5120",
  "2272",
  "3264",
  "4185",
  "990",
  "2835",
  "4717",
  "7791",
  "2440",
  "1196",
  "2369",
  "5031",
  "7757",
  "3514",
  "2605",
  "1255",
  "2767",
  "8139",
  "403",
  "3985",
  "1101",
  "6802",
  "8595",
  "9624",
  "9619",
  "4277",
  "9490",
  "2748",
  "998",
  "1389",
  "2213",
  "1790",
  "4050",
  "5307",
  "5655",
  "3311",
  "4978",
  "9529",
  "6614",
  "7186",
  "8546",
  "8803",
  "2619",
  "1290",
  "8766",
  "1461",
  "5701",
  "1243",
  "1369",
  "315",
  "4076",
  "7758",
  "9634",
  "9475",
  "2250",
  "6781",
  "6494",
  "1747",
  "6920",
  "2795",
  "3804",
  "4637",
  "7044",
  "248",
  "1666",
  "2295",
  "5063",
  "8879",
  "1707",
  "9209",
  "1061",
  "6542",
  "1662",
  "8243",
  "4972",
  "8794",
  "7451",
  "285",
  "299",
  "7032",
  "6120",
  "6248",
  "8830",
  "8011",
  "4568",
  "2423",
  "6621",
  "1289",
  "5175",
  "502",
  "1084",
  "8845",
  "9564",
  "1541",
  "3794",
  "4718",
  "9567",
  "2401",
  "698",
  "3747",
  "5384",
  "4501",
  "4115",
  "774",
  "7217",
  "1987",
  "4329",
  "8367",
  "7284",
  "9176",
  "8236",
  "6001",
  "1188",
  "5723",
  "1788",
  "5054",
  "7858",
  "1507",
  "6149",
  "7660",
  "8231",
  "310",
  "269",
  "4748",
  "9156",
  "5361",
  "4366",
  "1618",
  "1786",
  "3695",
  "3473",
  "1637",
  "2813",
  "3686",
  "4265",
  "7574",
  "1444",
  "8294",
  "8431",
  "1140",
  "2120",
  "1465",
  "8250",
  "8256",
  "1086",
  "3502",
  "7911",
  "300",
  "4640",
  "5105",
  "5110",
  "8865",
  "799",
  "9446",
  "7251",
  "7074",
  "2417",
  "5672",
  "2069",
  "3654",
  "9064",
  "265",
  "4806",
  "3038",
  "5518",
  "8585",
  "2259",
  "9968",
  "9876",
  "785",
  "7369",
  "2563",
  "4274",
  "7941",
  "1737",
  "2018",
  "7242",
  "9157",
  "9344",
  "6706",
  "3212",
  "1709",
  "966",
  "2016",
  "7838",
  "4926",
  "5970",
  "7312",
  "2420",
  "1833",
  "8749",
  "746",
  "4441",
  "7467",
  "1596",
  "6506",
  "6739",
  "1467",
  "1791",
  "4005",
  "3166",
  "494",
  "6078",
  "6708",
  "1876",
  "8031",
  "18",
  "1739",
  "8398",
  "7671",
  "1181",
  "8769",
  "3913",
  "994",
  "4824",
  "6400",
  "2717",
  "8216",
  "2200",
  "5920",
  "3853",
  "560",
  "4357",
  "6646",
  "7593",
  "7820",
  "1364",
  "4990",
  "1604",
  "2318",
  "2449",
  "4834",
  "5758",
  "6786",
  "8362",
  "2068",
  "7668",
  "55",
  "846",
  "8710",
  "611",
  "2804",
  "6582",
  "6117",
  "5414",
  "8202",
  "4558",
  "2130",
  "4876",
  "756",
  "3675",
  "7460",
  "8230",
  "9186",
  "3911",
  "4596",
  "9689",
  "4789",
  "2980",
  "2075",
  "8436",
  "5872",
  "5913",
  "6875",
  "930",
  "2609",
  "6277",
  "9357",
  "8483",
  "5131",
  "9012",
  "5604",
  "7481",
  "1764",
  "5890",
  "3623",
  "5102",
  "9850",
  "107",
  "3413",
  "2291",
  "5322",
  "5889",
  "6879",
  "3983",
  "1209",
  "4125",
  "8973",
  "5669",
  "4626",
  "3453",
  "4793",
  "398",
  "1315",
  "4159",
  "261",
  "839",
  "1160",
  "4473",
  "7327",
  "3627",
  "7810",
  "9825",
  "3165",
  "9702",
  "4078",
  "9589",
  "3635",
  "5786",
  "6803",
  "8293",
  "6531",
  "2928",
  "4412",
  "2682",
  "5593",
  "5547",
  "5070",
  "6856",
  "6068",
  "9914",
  "6844",
  "6300",
  "2933",
  "5722",
  "1460",
  "9283",
  "9489",
  "4691",
  "5257",
  "519",
  "6475",
  "7682",
  "9594",
  "6984",
  "647",
  "2655",
  "8322",
  "9133",
  "3440",
  "5625",
  "5825",
  "8329",
  "9434",
  "456",
  "1232",
  "1430",
  "115",
  "438",
  "3834",
  "9963",
  "4002",
  "1853",
  "5258",
  "145",
  "2913",
  "5924",
  "8808",
  "7566",
  "1586",
  "9294",
  "1158",
  "6595",
  "2990",
  "370",
  "5353",
  "7823",
  "240",
  "6901",
  "1534",
  "4087",
  "6860",
  "583",
  "6907",
  "178",
  "1057",
  "1830",
  "1902",
  "8080",
  "907",
  "2350",
  "2671",
  "462",
  "2564",
  "27",
  "3812",
  "4397",
  "3763",
  "5084",
  "3929",
  "4003",
  "7138",
  "8336",
  "1684",
  "8640",
  "395",
  "1202",
  "3337",
  "4084",
  "3195",
  "6292",
  "4628",
  "1523",
  "985",
  "1850",
  "1580",
  "2514",
  "8086",
  "9726",
  "2635",
  "2493",
  "33",
  "306",
  "4686",
  "6700",
  "4689",
  "6432",
  "8658",
  "919",
  "5250",
  "812",
  "3067",
  "20",
  "9533",
  "3410",
  "690",
  "4618",
  "1863",
  "7005",
  "7665",
  "8800",
  "4904",
  "432",
  "72",
  "3297",
  "7424",
  "1720",
  "4017",
  "8060",
  "3286",
  "7237",
  "6470",
  "9216",
  "8778",
  "7115",
  "2630",
  "2660",
  "4218",
  "6943",
  "1274",
  "3780",
  "8184",
  "1244",
  "2208",
  "5145",
  "624",
  "3228",
  "5180",
  "1382",
  "7200",
  "1657",
  "8877",
  "9511",
  "5327",
  "6034",
  "8592",
  "1590",
  "6118",
  "8063",
  "8092",
  "4760",
  "5386",
  "7637",
  "4153",
  "6570",
  "7212",
  "2931",
  "2424",
  "988",
  "277",
  "9191",
  "3694",
  "2031",
  "3391",
  "2275",
  "3382",
  "244",
  "7038",
  "8192",
  "4343",
  "3139",
  "4210",
  "7932",
  "7255",
  "3101",
  "7072",
  "3948",
  "6294",
  "695",
  "1020",
  "6032",
  "6778",
  "5315",
  "6268",
  "954",
  "7244",
  "5359",
  "8828",
  "9076",
  "264",
  "4554",
  "7949",
  "5418",
  "176",
  "2758",
  "6686",
  "9438",
  "9695",
  "2240",
  "7728",
  "5195",
  "6233",
  "6320",
  "9465",
  "7649",
  "4520",
  "5204",
  "8937",
  "8649",
  "772",
  "1896",
  "8269",
  "3438",
  "1714",
  "7950",
  "9428",
  "8868",
  "1738",
  "6240",
  "797",
  "4821",
  "1352",
  "2279",
  "2510",
  "3004",
  "5047",
  "8891",
  "7211",
  "971",
  "782",
  "3673",
  "4007",
  "8400",
  "2193",
  "3246",
  "6961",
  "2287",
  "550",
  "4512",
  "720",
  "1579",
  "7042",
  "9658",
  "3757",
  "5118",
  "5954",
  "1380",
  "8730",
  "9164",
  "4600",
  "2971",
  "3330",
  "9168",
  "1496",
  "7477",
  "7194",
  "5948",
  "8473",
  "2245",
  "7280",
  "9668",
  "1117",
  "1023",
  "1054",
  "8254",
  "8102",
  "4289",
  "8677",
  "2734",
  "4653",
  "8066",
  "3616",
  "8471",
  "8878",
  "3916",
  "4365",
  "2741",
  "835",
  "7954",
  "4668",
  "7333",
  "109",
  "6253",
  "2184",
  "9520",
  "1258",
  "2946",
  "9913",
  "6412",
  "5208",
  "6697",
  "7193",
  "6360",
  "9855",
  "5369",
  "6878",
  "7576",
  "9641",
  "2518",
  "10",
  "3189",
  "5929",
  "3920",
  "7653",
  "2108",
  "4768",
  "9978",
  "1085",
  "1309",
  "1544",
  "7015",
  "7192",
  "7861",
  "8373",
  "8944",
  "9907",
  "5897",
  "3172",
  "6849",
  "1957",
  "5033",
  "9266",
  "4815",
  "5546",
  "5772",
  "2070",
  "4605",
  "1895",
  "5163",
  "7923",
  "4746",
  "3364",
  "9271",
  "8628",
  "5269",
  "8801",
  "9201",
  "3840",
  "2598",
  "2822",
  "5469",
  "4752",
  "8167",
  "3213",
  "8751",
  "6726",
  "1554",
  "4255",
  "8354",
  "6025",
  "721",
  "6832",
  "6616",
  "7862",
  "8032",
  "4920",
  "8597",
  "4021",
  "4784",
  "5955",
  "4300",
  "9912",
  "7615",
  "5919",
  "8589",
  "6451",
  "3939",
  "9899",
  "5126",
  "5127",
  "8309",
  "4654",
  "6064",
  "5312",
  "7410",
  "8753",
  "2206",
  "2465",
  "8982",
  "9894",
  "4649",
  "7958",
  "2135",
  "3689",
  "7364",
  "2771",
  "4678",
  "4264",
  "7169",
  "7389",
  "2111",
  "3551",
  "5787",
  "6048",
  "1890",
  "6756",
  "8298",
  "5591",
  "9046",
  "9314",
  "8480",
  "8079",
  "22",
  "9671",
  "5457",
  "4574",
  "6886",
  "8201",
  "1492",
  "4572",
  "8057",
  "1079",
  "4275",
  "6768",
  "6341",
  "6644",
  "6401",
  "6587",
  "6959",
  "728",
  "5304",
  "693",
  "3752",
  "4398",
  "838",
  "6548",
  "5569",
  "8488",
  "9455",
  "7657",
  "3157",
  "7133",
  "7449",
  "6134",
  "2007",
  "144",
  "2553",
  "650",
  "4476",
  "9527",
  "3029",
  "6002",
  "6782",
  "6671",
  "3735",
  "1313",
  "3526",
  "7846",
  "2688",
  "3229",
  "1155",
  "6058",
  "8507",
  "7659",
  "2004",
  "6509",
  "1170",
  "6435",
  "633",
  "2833",
  "7556",
  "6504",
  "8058",
  "900",
  "7725",
  "5571",
  "5275",
  "4481",
  "5478",
  "9707",
  "2008",
  "759",
  "8083",
  "6794",
  "1360",
  "2091",
  "6826",
  "9140",
  "766",
  "8629",
  "1226",
  "8084",
  "9746",
  "909",
  "726",
  "8152",
  "3480",
  "5812",
  "5219",
  "4903",
  "2733",
  "1875",
  "2614",
  "3552",
  "3343",
  "3457",
  "1533",
  "4468",
  "1781",
  "8453",
  "8834",
  "6238",
  "3276",
  "6854",
  "2706",
  "4309",
  "9841",
  "1617",
  "4452",
  "8353",
  "2540",
  "5711",
  "9299",
  "467",
  "3052",
  "6574",
  "1708",
  "2854",
  "9323",
  "9946",
  "1983",
  "3107",
  "3875",
  "3912",
  "8563",
  "8719",
  "423",
  "9521",
  "9879",
  "4201",
  "7453",
  "5484",
  "6302",
  "8754",
  "9049",
  "6528",
  "2104",
  "2160",
  "6023",
  "2144",
  "3452",
  "3868",
  "4083",
  "4523",
  "6745",
  "113",
  "7470",
  "4449",
  "7073",
  "7338",
  "2017",
  "1417",
  "6132",
  "4311",
  "4872",
  "3200",
  "3683",
  "9274",
  "2543",
  "6575",
  "4610",
  "6008",
  "222",
  "8019",
  "9102",
  "5058",
  "8073",
  "4945",
  "6312",
  "1456",
  "3971",
  "962",
  "3019",
  "1538",
  "7091",
  "257",
  "4193",
  "8734",
  "4994",
  "7210",
  "3969",
  "5972",
  "2320",
  "8074",
  "9553",
  "4167",
  "5507",
  "2398",
  "7511",
  "7760",
  "8273",
  "4175",
  "9686",
  "2201",
  "1851",
  "9039",
  "23",
  "8947",
  "2858",
  "143",
  "4162",
  "7144",
  "1328",
  "3227",
  "4770",
  "5860",
  "7968",
  "4698",
  "1894",
  "3284",
  "7",
  "8631",
  "3938",
  "6746",
  "9828",
  "8391",
  "6598",
  "8048",
  "733",
  "1353",
  "813",
  "5135",
  "4234",
  "649",
  "6243",
  "7622",
  "895",
  "6146",
  "375",
  "7426",
  "4802",
  "1103",
  "9576",
  "9226",
  "2827",
  "3649",
  "6643",
  "7367",
  "7761",
  "7129",
  "2024",
  "9441",
  "2509",
  "6086",
  "8153",
  "9730",
  "1773",
  "48",
  "4472",
  "7332",
  "105",
  "5021",
  "5716",
  "193",
  "7265",
  "9103",
  "9629",
  "5976",
  "9761",
  "5009",
  "6199",
  "198",
  "3074",
  "1501",
  "9790",
  "5043",
  "5511",
  "1463",
  "4214",
  "6260",
  "3801",
  "9935",
  "5962",
  "36",
  "6192",
  "7587",
  "7669",
  "7692",
  "8337",
  "9504",
  "9526",
  "327",
  "3838",
  "4020",
  "2107",
  "7501",
  "7834",
  "9820",
  "6777",
  "1535",
  "1584",
  "5081",
  "8303",
  "6553",
  "1756",
  "3877",
  "4155",
  "7550",
  "2907",
  "4046",
  "4356",
  "1837",
  "9889",
  "5274",
  "4885",
  "7229",
  "2484",
  "9505",
  "5391",
  "4318",
  "7922",
  "5160",
  "1994",
  "2064",
  "6066",
  "7201",
  "9395",
  "6348",
  "3257",
  "6835",
  "9636",
  "4703",
  "4527",
  "4253",
  "8381",
  "819",
  "3475",
  "1390",
  "1400",
  "3209",
  "4409",
  "4646",
  "5984",
  "8820",
  "5910",
  "9221",
  "6664",
  "8100",
  "3536",
  "5448",
  "360",
  "4066",
  "1451",
  "1060",
  "1932",
  "9417",
  "1405",
  "6855",
  "8472",
  "8222",
  "9268",
  "9722",
  "2186",
  "5465",
  "8552",
  "4525",
  "6498",
  "7383",
  "5797",
  "9531",
  "7440",
  "9687",
  "9187",
  "2747",
  "2965",
  "1414",
  "6699",
  "7020",
  "9938",
  "3265",
  "1705",
  "1002",
  "7268",
  "9161",
  "217",
  "3080",
  "4782",
  "8394",
  "9950",
  "2498",
  "297",
  "6863",
  "8742",
  "9116",
  "4805",
  "3783",
  "7908",
  "8573",
  "80",
  "7933",
  "5341",
  "936",
  "3761",
  "9036",
  "6206",
  "1628",
  "2617",
  "922",
  "4420",
  "4869",
  "8598",
  "8773",
  "2185",
  "7662",
  "2326",
  "1723",
  "8858",
  "9942",
  "8110",
  "101",
  "3401",
  "5168",
  "6722",
  "4380",
  "4598",
  "3775",
  "8356",
  "5909",
  "1514",
  "6884",
  "9585",
  "8052",
  "9217",
  "9774",
  "6480",
  "1972",
  "7025",
  "609",
  "9723",
  "1330",
  "3358",
  "7354",
  "2474",
  "6623",
  "8397",
  "7454",
  "8023",
  "2376",
  "4304",
  "7734",
  "7310",
  "532",
  "3651",
  "4507",
  "1068",
  "4161",
  "6127",
  "3817",
  "8109",
  "9332",
  "4808",
  "1548",
  "7461",
  "9581",
  "3217",
  "8857",
  "4837",
  "4363",
  "9917",
  "6793",
  "3386",
  "891",
  "896",
  "4642",
  "2912",
  "8740",
  "4751",
  "9293",
  "3003",
  "9916",
  "3125",
  "2082",
  "7046",
  "150",
  "8378",
  "7797",
  "4477",
  "2791",
  "5012",
  "7425",
  "3493",
  "2634",
  "4065",
  "9267",
  "7694",
  "8725",
  "6824",
  "2680",
  "1509",
  "976",
  "2825",
  "1630",
  "1879",
  "5967",
  "680",
  "4488",
  "5173",
  "426",
  "3499",
  "8562",
  "4037",
  "7000",
  "7145",
  "7864",
  "1265",
  "4317",
  "879",
  "3896",
  "5794",
  "6326",
  "9962",
  "1050",
  "2533",
  "2973",
  "4660",
  "3405",
  "558",
  "2888",
  "2562",
  "5680",
  "7812",
  "3602",
  "5961",
  "1503",
  "3486",
  "490",
  "6894",
  "9374",
  "3684",
  "4583",
  "9794",
  "8284",
  "7869",
  "853",
  "2368",
  "7471",
  "676",
  "7493",
  "533",
  "7299",
  "2078",
  "8692",
  "9134",
  "7601",
  "8451",
  "5078",
  "7222",
  "4715",
  "2724",
  "2697",
  "8805",
  "1270",
  "4870",
  "3553",
  "476",
  "6908",
  "7940",
  "5096",
  "3774",
  "591",
  "2311",
  "6760",
  "8241",
  "2475",
  "8650",
  "7486",
  "4073",
  "4506",
  "4959",
  "182",
  "1517",
  "6101",
  "689",
  "2049",
  "7305",
  "157",
  "8330",
  "4131",
  "578",
  "4102",
  "5596",
  "7045",
  "437",
  "660",
  "3742",
  "4559",
  "8076",
  "7057",
  "8287",
  "1964",
  "3712",
  "3037",
  "6152",
  "674",
  "7936",
  "7764",
  "3108",
  "452",
  "8756",
  "2869",
  "5336",
  "100",
  "5700",
  "63",
  "5746",
  "8306",
  "1462",
  "9846",
  "8790",
  "9072",
  "2299",
  "1730",
  "5682",
  "9541",
  "7882",
  "9664",
  "118",
  "4586",
  "5481",
  "8051",
  "6029",
  "1887",
  "4321",
  "1143",
  "5147",
  "9061",
  "9313",
  "7490",
  "173",
  "7546",
  "3373",
  "4492",
  "3266",
  "3436",
  "8003",
  "2541",
  "3613",
  "8670",
  "4101",
  "6383",
  "2592",
  "7632",
  "2945",
  "5114",
  "6660",
  "6947",
  "521",
  "7802",
  "9685",
  "9603",
  "7414",
  "822",
  "5635",
  "627",
  "2633",
  "9547",
  "8191",
  "9973",
  "5357",
  "7875",
  "5",
  "1806",
  "4208",
  "6140",
  "7341",
  "8929",
  "5352",
  "7079",
  "4966",
  "9356",
  "8542",
  "7396",
  "7545",
  "8596",
  "405",
  "942",
  "7930",
  "132",
  "7189",
  "9185",
  "7048",
  "9557",
  "5915",
  "2096",
  "3668",
  "7285",
  "3141",
  "3025",
  "5362",
  "6189",
  "7140",
  "7983",
  "2728",
  "6079",
  "488",
  "373",
  "1681",
  "9290",
  "3790",
  "5297",
  "2955",
  "4339",
  "2759",
  "2042",
  "4236",
  "504",
  "4287",
  "5345",
  "6014",
  "8038",
  "26",
  "6276",
  "2381",
  "3717",
  "1920",
  "6951",
  "3210",
  "8301",
  "5683",
  "7813",
  "6431",
  "6452",
  "3636",
  "4924",
  "1540",
  "9659",
  "9474",
  "2346",
  "6073",
  "6225",
  "5824",
  "6100",
  "2399",
  "7446",
  "2824",
  "2203",
  "1015",
  "9959",
  "5253",
  "1784",
  "8905",
  "1351",
  "6373",
  "13",
  "4645",
  "8033",
  "6866",
  "6819",
  "4836",
  "8968",
  "120",
  "576",
  "3607",
  "9623",
  "8781",
  "7297",
  "8320",
  "6190",
  "7598",
  "7016",
  "3854",
  "9078",
  "8165",
  "4152",
  "5447",
  "5918",
  "6259",
  "4864",
  "5851",
  "5434",
  "7124",
  "7464",
  "2002",
  "5858",
  "9502",
  "4032",
  "8388",
  "77",
  "2290",
  "5086",
  "5864",
  "7704",
  "3232",
  "6207",
  "3393",
  "3455",
  "6269",
  "7578",
  "4467",
  "4486",
  "1075",
  "1149",
  "2686",
  "5015",
  "1508",
  "3267",
  "7256",
  "1667",
  "1642",
  "4847",
  "7165",
  "1687",
  "7105",
  "9253",
  "1200",
  "8536",
  "380",
  "9538",
  "4712",
  "5050",
  "686",
  "4494",
  "3318",
  "8519",
  "7746",
  "1811",
  "6713",
  "2150",
  "3691",
  "1671",
  "3974",
  "4814",
  "8895",
  "4545",
  "70",
  "9107",
  "6680",
  "7415",
  "4057",
  "2574",
  "3060",
  "4648",
  "6429",
  "9232",
  "1217",
  "2292",
  "6347",
  "2388",
  "4240",
  "4070",
  "1336",
  "3132",
  "9125",
  "1474",
  "5381",
  "5783",
  "6960",
  "7661",
  "7961",
  "9728",
  "7630",
  "2704",
  "4121",
  "8582",
  "6691",
  "5229",
  "1445",
  "3326",
  "3718",
  "8090",
  "9008",
  "667",
  "9620",
  "9",
  "934",
  "3160",
  "6115",
  "5926",
  "8308",
  "4310",
  "6882",
  "208",
  "4612",
  "3894",
  "1190",
  "6917",
  "9747",
  "3806",
  "9113",
  "3725",
  "8072",
  "2666",
  "9092",
  "2304",
  "5533",
  "7918",
  "2197",
  "7195",
  "704",
  "8912",
  "8395",
  "8470",
  "9921",
  "2255",
  "9325",
  "2175",
  "2991",
  "4727",
  "5868",
  "6293",
  "6478",
  "4693",
  "8314",
  "9674",
  "7589",
  "3751",
  "3252",
  "6419",
  "9923",
  "5310",
  "586",
  "6007",
  "7770",
  "9652",
  "644",
  "9085",
  "8162",
  "7739",
  "1797",
  "1640",
  "1529",
  "2254",
  "3188",
  "2581",
  "763",
  "3127",
  "5799",
  "572",
  "1440",
  "8505",
  "9993",
  "1162",
  "140",
  "9971",
  "7441",
  "2718",
  "3272",
  "9895",
  "9252",
  "7539",
  "9180",
  "7468",
  "6062",
  "6685",
  "9427",
  "2289",
  "6317",
  "1282",
  "5218",
  "8037",
  "8819",
  "224",
  "5554",
  "3609",
  "9074",
  "9415",
  "4381",
  "4985",
  "6765",
  "9766",
  "5627",
  "8739",
  "2309",
  "9934",
  "525",
  "4861",
  "8564",
  "1175",
  "8190",
  "1488",
  "6812",
  "94",
  "6256",
  "1434",
  "4302",
  "836",
  "8565",
  "4045",
  "1189",
  "292",
  "1185",
  "9844",
  "4896",
  "5480",
  "5069",
  "7558",
  "3674",
  "7773",
  "203",
  "8010",
  "8209",
  "9987",
  "4879",
  "2944",
  "2128",
  "1266",
  "3329",
  "2783",
  "1543",
  "9282",
  "3617",
  "4022",
  "5990",
  "5788",
  "8189",
  "795",
  "4333",
  "5459",
  "7337",
  "4536",
  "2667",
  "7399",
  "2906",
  "683",
  "4094",
  "6607",
  "6813",
  "2960",
  "9787",
  "8146",
  "9255",
  "9275",
  "2278",
  "4800",
  "6561",
  "9067",
  "8040",
  "6617",
  "781",
  "2214",
  "4221",
  "7516",
  "1091",
  "7939",
  "1652",
  "4292",
  "8535",
  "5346",
  "6012",
  "1634",
  "3369",
  "9331",
  "8515",
  "1110",
  "4639",
  "1812",
  "4624",
  "9484",
  "3173",
  "5452",
  "7023",
  "8958",
  "9806",
  "1703",
  "530",
  "9380",
  "1528",
  "5876",
  "5523",
  "4796",
  "3964",
  "2638",
  "7656",
  "1577",
  "5662",
  "5461",
  "7447",
  "7353",
  "8182",
  "3997",
  "3240",
  "2043",
  "2466",
  "8581",
  "8030",
  "1370",
  "5109",
  "8352",
  "5599",
  "3085",
  "9949",
  "9605",
  "8705",
  "8880",
  "522",
  "1457",
  "709",
  "2883",
  "598",
  "9706",
  "1649",
  "9989",
  "9733",
  "7552",
  "6695",
  "4844",
  "7188",
  "466",
  "7109",
  "3699",
  "5343",
  "9765",
  "333",
  "4092",
  "5217",
  "2909",
  "8524",
  "4973",
  "3754",
  "4776",
  "6291",
  "7868",
  "8863",
  "3951",
  "2710",
  "1030",
  "1342",
  "597",
  "5402",
  "6306",
  "4621",
  "2183",
  "2929",
  "4912",
  "875",
  "2490",
  "5444",
  "7227",
  "6274",
  "9002",
  "4873",
  "4733",
  "506",
  "2631",
  "8659",
  "2750",
  "4710",
  "9610",
  "8568",
  "9150",
  "1137",
  "5294",
  "9411",
  "1706",
  "6564",
  "9452",
  "4692",
  "2805",
  "7437",
  "1656",
  "191",
  "8220",
  "8151",
  "2975",
  "3724",
  "1856",
  "444",
  "2142",
  "2815",
  "7900",
  "3663",
  "8752",
  "2589",
  "4334",
  "8584",
  "6287",
  "5005",
  "8193",
  "3395",
  "5704",
  "4444",
  "284",
  "9249",
  "6524",
  "1927",
  "7132",
  "8632",
  "7344",
  "6974",
  "5112",
  "677",
  "3322",
  "2932",
  "7163",
  "1198",
  "7283",
  "8197",
  "8124",
  "439",
  "5085",
  "5134",
  "9635",
  "9433",
  "1693",
  "4737",
  "1298",
  "803",
  "237",
  "4465",
  "7579",
  "6870",
  "4038",
];

const powers = [
  "51",
  "43",
  "35",
  "46",
  "48",
  "27",
  "30",
  "35",
  "32",
  "26",
  "28",
  "36",
  "33",
  "26",
  "34",
  "31",
  "38",
  "33",
  "34",
  "32",
  "27",
  "25",
  "18",
  "33",
  "30",
  "32",
  "31",
  "37",
  "32",
  "27",
  "23",
  "31",
  "31",
  "29",
  "32",
  "29",
  "31",
  "31",
  "20",
  "29",
  "28",
  "28",
  "29",
  "28",
  "30",
  "27",
  "24",
  "30",
  "29",
  "28",
  "23",
  "28",
  "26",
  "31",
  "32",
  "25",
  "28",
  "27",
  "32",
  "32",
  "21",
  "31",
  "33",
  "32",
  "26",
  "26",
  "33",
  "20",
  "31",
  "31",
  "24",
  "26",
  "31",
  "30",
  "29",
  "29",
  "31",
  "26",
  "33",
  "30",
  "27",
  "25",
  "30",
  "29",
  "28",
  "30",
  "24",
  "29",
  "26",
  "32",
  "25",
  "31",
  "23",
  "28",
  "35",
  "28",
  "32",
  "29",
  "31",
  "24",
  "26",
  "23",
  "22",
  "27",
  "22",
  "28",
  "29",
  "31",
  "25",
  "26",
  "30",
  "25",
  "34",
  "29",
  "25",
  "29",
  "27",
  "24",
  "23",
  "30",
  "27",
  "26",
  "27",
  "30",
  "30",
  "29",
  "28",
  "22",
  "28",
  "24",
  "30",
  "24",
  "22",
  "30",
  "30",
  "28",
  "34",
  "26",
  "26",
  "29",
  "25",
  "27",
  "30",
  "25",
  "28",
  "22",
  "24",
  "26",
  "21",
  "28",
  "22",
  "26",
  "29",
  "29",
  "26",
  "24",
  "32",
  "28",
  "23",
  "31",
  "25",
  "28",
  "28",
  "30",
  "30",
  "28",
  "30",
  "30",
  "22",
  "22",
  "25",
  "34",
  "31",
  "37",
  "33",
  "34",
  "30",
  "26",
  "27",
  "31",
  "32",
  "31",
  "28",
  "33",
  "32",
  "29",
  "26",
  "27",
  "31",
  "36",
  "29",
  "32",
  "30",
  "35",
  "30",
  "32",
  "31",
  "29",
  "28",
  "32",
  "33",
  "29",
  "34",
  "33",
  "31",
  "35",
  "35",
  "34",
  "30",
  "27",
  "29",
  "30",
  "27",
  "27",
  "31",
  "31",
  "25",
  "30",
  "30",
  "32",
  "32",
  "31",
  "31",
  "31",
  "30",
  "25",
  "30",
  "32",
  "33",
  "31",
  "34",
  "29",
  "34",
  "33",
  "31",
  "30",
  "34",
  "27",
  "31",
  "31",
  "32",
  "29",
  "24",
  "29",
  "32",
  "28",
  "31",
  "32",
  "24",
  "29",
  "33",
  "31",
  "26",
  "28",
  "32",
  "30",
  "33",
  "27",
  "32",
  "26",
  "28",
  "31",
  "30",
  "29",
  "30",
  "28",
  "29",
  "30",
  "27",
  "32",
  "30",
  "29",
  "35",
  "29",
  "33",
  "26",
  "27",
  "30",
  "24",
  "33",
  "32",
  "30",
  "29",
  "32",
  "29",
  "35",
  "36",
  "31",
  "29",
  "29",
  "30",
  "30",
  "31",
  "30",
  "31",
  "28",
  "31",
  "35",
  "32",
  "27",
  "33",
  "25",
  "28",
  "32",
  "26",
  "27",
  "31",
  "27",
  "26",
  "28",
  "31",
  "31",
  "30",
  "28",
  "29",
  "29",
  "31",
  "28",
  "27",
  "28",
  "32",
  "31",
  "23",
  "24",
  "28",
  "24",
  "32",
  "29",
  "27",
  "32",
  "31",
  "29",
  "31",
  "29",
  "29",
  "27",
  "30",
  "31",
  "32",
  "32",
  "24",
  "29",
  "34",
  "25",
  "24",
  "30",
  "31",
  "31",
  "34",
  "32",
  "32",
  "28",
  "32",
  "29",
  "28",
  "29",
  "29",
  "27",
  "31",
  "29",
  "31",
  "30",
  "31",
  "29",
  "30",
  "33",
  "27",
  "34",
  "30",
  "23",
  "31",
  "32",
  "30",
  "26",
  "36",
  "31",
  "25",
  "26",
  "25",
  "30",
  "33",
  "29",
  "28",
  "30",
  "31",
  "27",
  "30",
  "28",
  "28",
  "33",
  "27",
  "30",
  "29",
  "29",
  "28",
  "32",
  "34",
  "30",
  "29",
  "31",
  "28",
  "34",
  "29",
  "27",
  "27",
  "30",
  "28",
  "31",
  "28",
  "30",
  "28",
  "30",
  "29",
  "30",
  "30",
  "30",
  "28",
  "29",
  "28",
  "27",
  "31",
  "34",
  "24",
  "32",
  "32",
  "33",
  "29",
  "29",
  "30",
  "30",
  "28",
  "33",
  "32",
  "25",
  "26",
  "34",
  "32",
  "31",
  "33",
  "25",
  "30",
  "31",
  "25",
  "31",
  "28",
  "31",
  "29",
  "32",
  "31",
  "32",
  "26",
  "28",
  "27",
  "25",
  "26",
  "28",
  "23",
  "26",
  "29",
  "30",
  "31",
  "34",
  "34",
  "22",
  "28",
  "30",
  "27",
  "32",
  "26",
  "26",
  "31",
  "30",
  "24",
  "25",
  "24",
  "27",
  "31",
  "28",
  "28",
  "33",
  "32",
  "24",
  "28",
  "24",
  "31",
  "26",
  "25",
  "25",
  "26",
  "29",
  "29",
  "32",
  "31",
  "24",
  "27",
  "23",
  "32",
  "22",
  "28",
  "25",
  "26",
  "32",
  "24",
  "27",
  "30",
  "27",
  "31",
  "28",
  "25",
  "29",
  "29",
  "28",
  "26",
  "31",
  "22",
  "31",
  "28",
  "29",
  "30",
  "28",
  "27",
  "32",
  "25",
  "30",
  "32",
  "25",
  "29",
  "26",
  "32",
  "29",
  "26",
  "25",
  "30",
  "28",
  "29",
  "30",
  "28",
  "25",
  "29",
  "27",
  "25",
  "34",
  "27",
  "27",
  "21",
  "29",
  "27",
  "29",
  "29",
  "29",
  "26",
  "29",
  "28",
  "24",
  "28",
  "32",
  "27",
  "29",
  "30",
  "23",
  "31",
  "29",
  "24",
  "28",
  "29",
  "27",
  "33",
  "24",
  "23",
  "28",
  "30",
  "30",
  "28",
  "27",
  "32",
  "30",
  "27",
  "25",
  "26",
  "27",
  "29",
  "29",
  "27",
  "30",
  "33",
  "30",
  "27",
  "32",
  "30",
  "29",
  "27",
  "24",
  "30",
  "28",
  "30",
  "32",
  "30",
  "25",
  "26",
  "26",
  "30",
  "31",
  "28",
  "30",
  "24",
  "29",
  "28",
  "35",
  "32",
  "27",
  "31",
  "26",
  "25",
  "27",
  "29",
  "24",
  "30",
  "29",
  "31",
  "31",
  "28",
  "24",
  "20",
  "26",
  "24",
  "29",
  "26",
  "29",
  "26",
  "27",
  "27",
  "22",
  "25",
  "29",
  "30",
  "28",
  "28",
  "27",
  "26",
  "33",
  "28",
  "27",
  "29",
  "29",
  "28",
  "31",
  "33",
  "28",
  "29",
  "30",
  "29",
  "30",
  "27",
  "30",
  "29",
  "27",
  "29",
  "31",
  "28",
  "30",
  "28",
  "22",
  "26",
  "30",
  "31",
  "27",
  "28",
  "26",
  "30",
  "25",
  "30",
  "36",
  "26",
  "30",
  "28",
  "31",
  "35",
  "27",
  "31",
  "26",
  "25",
  "27",
  "33",
  "27",
  "24",
  "32",
  "26",
  "31",
  "31",
  "28",
  "28",
  "27",
  "29",
  "28",
  "31",
  "30",
  "32",
  "29",
  "31",
  "33",
  "27",
  "28",
  "26",
  "26",
  "29",
  "27",
  "28",
  "29",
  "24",
  "25",
  "28",
  "25",
  "27",
  "29",
  "18",
  "29",
  "29",
  "29",
  "31",
  "27",
  "31",
  "25",
  "27",
  "30",
  "26",
  "23",
  "28",
  "29",
  "23",
  "33",
  "27",
  "27",
  "34",
  "31",
  "30",
  "24",
  "30",
  "31",
  "31",
  "23",
  "27",
  "30",
  "28",
  "27",
  "31",
  "28",
  "26",
  "26",
  "28",
  "29",
  "27",
  "30",
  "27",
  "30",
  "20",
  "30",
  "34",
  "31",
  "27",
  "31",
  "26",
  "28",
  "27",
  "22",
  "24",
  "32",
  "29",
  "27",
  "24",
  "30",
  "31",
  "31",
  "30",
  "33",
  "28",
  "26",
  "28",
  "25",
  "29",
  "26",
  "29",
  "27",
  "26",
  "27",
  "31",
  "29",
  "28",
  "29",
  "24",
  "30",
  "30",
  "33",
  "32",
  "28",
  "22",
  "34",
  "28",
  "29",
  "27",
  "24",
  "24",
  "30",
  "29",
  "27",
  "30",
  "30",
  "32",
  "27",
  "29",
  "29",
  "23",
  "28",
  "24",
  "23",
  "30",
  "30",
  "29",
  "27",
  "26",
  "30",
  "26",
  "27",
  "30",
  "29",
  "27",
  "30",
  "28",
  "32",
  "27",
  "24",
  "28",
  "29",
  "29",
  "33",
  "22",
  "19",
  "31",
  "33",
  "28",
  "27",
  "29",
  "25",
  "26",
  "29",
  "29",
  "29",
  "28",
  "23",
  "32",
  "31",
  "32",
  "33",
  "31",
  "29",
  "29",
  "32",
  "29",
  "29",
  "31",
  "25",
  "24",
  "28",
  "29",
  "30",
  "28",
  "30",
  "24",
  "31",
  "29",
  "30",
  "31",
  "23",
  "27",
  "27",
  "29",
  "31",
  "30",
  "27",
  "25",
  "31",
  "29",
  "27",
  "28",
  "28",
  "33",
  "24",
  "28",
  "29",
  "30",
  "25",
  "30",
  "29",
  "28",
  "26",
  "30",
  "31",
  "33",
  "30",
  "30",
  "27",
  "28",
  "29",
  "30",
  "29",
  "32",
  "26",
  "27",
  "29",
  "25",
  "36",
  "29",
  "21",
  "27",
  "23",
  "28",
  "28",
  "31",
  "27",
  "28",
  "30",
  "28",
  "32",
  "30",
  "29",
  "30",
  "27",
  "26",
  "27",
  "30",
  "27",
  "29",
  "29",
  "24",
  "32",
  "29",
  "23",
  "24",
  "31",
  "26",
  "28",
  "26",
  "29",
  "36",
  "29",
  "18",
  "29",
  "33",
  "27",
  "29",
  "25",
  "30",
  "23",
  "27",
  "23",
  "31",
  "32",
  "25",
  "20",
  "34",
  "21",
  "28",
  "27",
  "25",
  "28",
  "30",
  "34",
  "33",
  "32",
  "30",
  "25",
  "30",
  "31",
  "31",
  "30",
  "30",
  "32",
  "27",
  "32",
  "28",
  "27",
  "29",
  "22",
  "25",
  "24",
  "29",
  "24",
  "23",
  "34",
  "27",
  "27",
  "24",
  "34",
  "28",
  "27",
  "26",
  "31",
  "29",
  "24",
  "31",
  "29",
  "29",
  "27",
  "25",
  "31",
  "24",
  "33",
  "28",
  "28",
  "33",
  "34",
  "31",
  "27",
  "26",
  "24",
  "30",
  "23",
  "30",
  "29",
  "29",
  "25",
  "31",
  "27",
  "29",
  "30",
  "24",
  "25",
  "25",
  "30",
  "33",
  "30",
  "30",
  "27",
  "25",
  "31",
  "30",
  "25",
  "23",
  "29",
  "30",
  "25",
  "27",
  "27",
  "23",
  "32",
  "22",
  "29",
  "27",
  "30",
  "23",
  "25",
  "28",
  "27",
  "23",
  "27",
  "26",
  "33",
  "32",
  "26",
  "27",
  "26",
  "25",
  "29",
  "32",
  "26",
  "34",
  "29",
  "23",
  "26",
  "28",
  "27",
  "32",
  "24",
  "27",
  "26",
  "27",
  "29",
  "26",
  "27",
  "28",
  "28",
  "29",
  "23",
  "24",
  "25",
  "27",
  "26",
  "31",
  "25",
  "28",
  "31",
  "30",
  "27",
  "30",
  "26",
  "29",
  "30",
  "32",
  "27",
  "25",
  "28",
  "27",
  "30",
  "27",
  "28",
  "28",
  "32",
  "31",
  "29",
  "29",
  "30",
  "24",
  "27",
  "24",
  "29",
  "32",
  "32",
  "27",
  "28",
  "27",
  "28",
  "30",
  "33",
  "29",
  "25",
  "21",
  "29",
  "30",
  "25",
  "28",
  "31",
  "31",
  "26",
  "22",
  "28",
  "26",
  "31",
  "30",
  "30",
  "27",
  "29",
  "29",
  "26",
  "28",
  "24",
  "30",
  "25",
  "29",
  "30",
  "27",
  "27",
  "23",
  "28",
  "30",
  "27",
  "26",
  "26",
  "35",
  "28",
  "29",
  "30",
  "27",
  "28",
  "33",
  "27",
  "33",
  "26",
  "33",
  "27",
  "22",
  "27",
  "32",
  "31",
  "30",
  "29",
  "28",
  "27",
  "30",
  "29",
  "35",
  "26",
  "27",
  "28",
  "26",
  "22",
  "28",
  "27",
  "29",
  "34",
  "31",
  "32",
  "33",
  "30",
  "26",
  "28",
  "29",
  "32",
  "22",
  "30",
  "20",
  "24",
  "26",
  "30",
  "29",
  "30",
  "26",
  "31",
  "30",
  "26",
  "27",
  "27",
  "22",
  "31",
  "35",
  "29",
  "23",
  "23",
  "25",
  "23",
  "30",
  "29",
  "33",
  "29",
  "29",
  "29",
  "27",
  "29",
  "31",
  "30",
  "20",
  "30",
  "28",
  "29",
  "28",
  "27",
  "20",
  "30",
  "27",
  "31",
  "30",
  "30",
  "28",
  "27",
  "25",
  "31",
  "27",
  "29",
  "29",
  "26",
  "25",
  "26",
  "26",
  "25",
  "32",
  "28",
  "28",
  "29",
  "22",
  "27",
  "26",
  "26",
  "27",
  "29",
  "23",
  "26",
  "32",
  "32",
  "33",
  "22",
  "26",
  "25",
  "19",
  "26",
  "28",
  "31",
  "28",
  "30",
  "32",
  "28",
  "29",
  "25",
  "31",
  "29",
  "29",
  "26",
  "28",
  "30",
  "29",
  "28",
  "23",
  "27",
  "26",
  "26",
  "34",
  "26",
  "31",
  "29",
  "28",
  "28",
  "28",
  "26",
  "25",
  "28",
  "28",
  "26",
  "28",
  "29",
  "27",
  "27",
  "27",
  "29",
  "29",
  "32",
  "23",
  "25",
  "31",
  "25",
  "24",
  "29",
  "30",
  "30",
  "27",
  "29",
  "32",
  "29",
  "26",
  "33",
  "30",
  "27",
  "25",
  "28",
  "31",
  "28",
  "29",
  "29",
  "31",
  "27",
  "26",
  "26",
  "27",
  "34",
  "32",
  "25",
  "30",
  "27",
  "30",
  "26",
  "27",
  "25",
  "26",
  "34",
  "25",
  "25",
  "29",
  "30",
  "23",
  "30",
  "24",
  "30",
  "27",
  "22",
  "31",
  "31",
  "27",
  "27",
  "31",
  "35",
  "22",
  "25",
  "32",
  "27",
  "30",
  "30",
  "33",
  "29",
  "33",
  "25",
  "26",
  "29",
  "32",
  "32",
  "26",
  "27",
  "29",
  "26",
  "29",
  "28",
  "31",
  "26",
  "25",
  "31",
  "30",
  "28",
  "30",
  "22",
  "31",
  "25",
  "32",
  "28",
  "33",
  "26",
  "28",
  "28",
  "32",
  "30",
  "32",
  "31",
  "20",
  "31",
  "31",
  "23",
  "30",
  "28",
  "31",
  "30",
  "26",
  "29",
  "27",
  "29",
  "31",
  "28",
  "30",
  "30",
  "26",
  "28",
  "30",
  "26",
  "28",
  "33",
  "27",
  "32",
  "28",
  "29",
  "26",
  "26",
  "31",
  "30",
  "29",
  "30",
  "30",
  "28",
  "29",
  "31",
  "28",
  "26",
  "25",
  "27",
  "33",
  "25",
  "30",
  "32",
  "31",
  "23",
  "29",
  "25",
  "29",
  "26",
  "26",
  "26",
  "25",
  "30",
  "30",
  "27",
  "31",
  "30",
  "31",
  "32",
  "26",
  "28",
  "29",
  "26",
  "29",
  "28",
  "27",
  "28",
  "31",
  "27",
  "25",
  "26",
  "24",
  "29",
  "30",
  "29",
  "34",
  "33",
  "27",
  "28",
  "27",
  "31",
  "27",
  "26",
  "33",
  "28",
  "32",
  "25",
  "23",
  "24",
  "31",
  "29",
  "26",
  "26",
  "27",
  "29",
  "27",
  "27",
  "29",
  "29",
  "27",
  "27",
  "24",
  "28",
  "24",
  "26",
  "30",
  "28",
  "24",
  "30",
  "31",
  "29",
  "29",
  "29",
  "29",
  "30",
  "27",
  "30",
  "28",
  "28",
  "30",
  "28",
  "30",
  "28",
  "27",
  "30",
  "29",
  "29",
  "30",
  "29",
  "34",
  "23",
  "27",
  "25",
  "27",
  "27",
  "30",
  "26",
  "31",
  "28",
  "30",
  "22",
  "27",
  "30",
  "30",
  "27",
  "29",
  "21",
  "31",
  "23",
  "29",
  "25",
  "30",
  "31",
  "28",
  "30",
  "30",
  "26",
  "26",
  "21",
  "31",
  "24",
  "25",
  "27",
  "28",
  "28",
  "27",
  "22",
  "27",
  "25",
  "30",
  "28",
  "28",
  "26",
  "30",
  "32",
  "32",
  "24",
  "33",
  "31",
  "28",
  "27",
  "26",
  "29",
  "24",
  "26",
  "31",
  "29",
  "27",
  "32",
  "30",
  "29",
  "31",
  "31",
  "28",
  "30",
  "28",
  "25",
  "31",
  "29",
  "26",
  "29",
  "25",
  "25",
  "25",
  "23",
  "26",
  "26",
  "28",
  "19",
  "23",
  "29",
  "26",
  "30",
  "25",
  "19",
  "27",
  "31",
  "23",
  "34",
  "27",
  "27",
  "24",
  "26",
  "28",
  "30",
  "30",
  "24",
  "28",
  "26",
  "31",
  "29",
  "27",
  "26",
  "29",
  "21",
  "24",
  "30",
  "26",
  "31",
  "31",
  "29",
  "29",
  "31",
  "32",
  "29",
  "31",
  "26",
  "24",
  "28",
  "26",
  "29",
  "25",
  "26",
  "30",
  "26",
  "28",
  "26",
  "27",
  "28",
  "21",
  "29",
  "23",
  "30",
  "21",
  "34",
  "19",
  "27",
  "28",
  "30",
  "28",
  "29",
  "22",
  "23",
  "31",
  "27",
  "31",
  "25",
  "23",
  "25",
  "28",
  "22",
  "26",
  "27",
  "27",
  "29",
  "30",
  "27",
  "26",
  "24",
  "24",
  "30",
  "27",
  "27",
  "27",
  "31",
  "29",
  "26",
  "25",
  "28",
  "30",
  "26",
  "25",
  "28",
  "30",
  "27",
  "19",
  "33",
  "28",
  "25",
  "26",
  "26",
  "31",
  "26",
  "27",
  "25",
  "27",
  "33",
  "24",
  "28",
  "27",
  "28",
  "27",
  "26",
  "30",
  "28",
  "22",
  "30",
  "23",
  "25",
  "30",
  "25",
  "25",
  "29",
  "23",
  "28",
  "23",
  "28",
  "32",
  "28",
  "25",
  "30",
  "31",
  "26",
  "32",
  "30",
  "20",
  "26",
  "29",
  "25",
  "27",
  "30",
  "28",
  "22",
  "27",
  "24",
  "32",
  "28",
  "26",
  "25",
  "26",
  "31",
  "33",
  "27",
  "28",
  "32",
  "23",
  "26",
  "28",
  "32",
  "25",
  "26",
  "30",
  "31",
  "26",
  "28",
  "26",
  "27",
  "32",
  "28",
  "28",
  "31",
  "22",
  "28",
  "22",
  "20",
  "24",
  "31",
  "26",
  "24",
  "28",
  "29",
  "30",
  "30",
  "28",
  "25",
  "29",
  "31",
  "28",
  "23",
  "28",
  "30",
  "26",
  "31",
  "30",
  "33",
  "33",
  "27",
  "21",
  "27",
  "25",
  "31",
  "26",
  "31",
  "28",
  "30",
  "21",
  "29",
  "30",
  "27",
  "28",
  "27",
  "31",
  "30",
  "31",
  "28",
  "27",
  "29",
  "25",
  "28",
  "28",
  "25",
  "30",
  "27",
  "28",
  "27",
  "30",
  "29",
  "26",
  "32",
  "30",
  "24",
  "31",
  "28",
  "33",
  "25",
  "26",
  "26",
  "28",
  "27",
  "28",
  "28",
  "31",
  "27",
  "26",
  "27",
  "27",
  "27",
  "28",
  "24",
  "25",
  "28",
  "26",
  "32",
  "29",
  "23",
  "22",
  "31",
  "25",
  "30",
  "23",
  "26",
  "29",
  "28",
  "28",
  "28",
  "30",
  "25",
  "32",
  "29",
  "30",
  "29",
  "28",
  "28",
  "24",
  "25",
  "28",
  "28",
  "25",
  "31",
  "28",
  "27",
  "27",
  "28",
  "31",
  "30",
  "24",
  "30",
  "25",
  "25",
  "29",
  "32",
  "28",
  "27",
  "25",
  "29",
  "28",
  "27",
  "24",
  "31",
  "27",
  "29",
  "27",
  "29",
  "28",
  "32",
  "29",
  "31",
  "20",
  "28",
  "33",
  "31",
  "22",
  "28",
  "24",
  "30",
  "28",
  "29",
  "27",
  "30",
  "22",
  "28",
  "28",
  "29",
  "30",
  "20",
  "21",
  "26",
  "29",
  "27",
  "28",
  "27",
  "20",
  "27",
  "28",
  "27",
  "28",
  "28",
  "28",
  "26",
  "28",
  "28",
  "26",
  "22",
  "29",
  "26",
  "26",
  "30",
  "29",
  "30",
  "26",
  "25",
  "31",
  "28",
  "28",
  "28",
  "29",
  "28",
  "21",
  "28",
  "32",
  "28",
  "29",
  "26",
  "31",
  "25",
  "31",
  "28",
  "26",
  "28",
  "28",
  "20",
  "30",
  "22",
  "29",
  "25",
  "31",
  "27",
  "27",
  "28",
  "26",
  "26",
  "30",
  "26",
  "33",
  "28",
  "32",
  "31",
  "27",
  "36",
  "30",
  "27",
  "22",
  "33",
  "27",
  "28",
  "26",
  "25",
  "28",
  "23",
  "25",
  "31",
  "31",
  "28",
  "26",
  "26",
  "28",
  "27",
  "28",
  "26",
  "27",
  "35",
  "27",
  "30",
  "31",
  "28",
  "22",
  "25",
  "30",
  "31",
  "29",
  "25",
  "22",
  "29",
  "30",
  "23",
  "25",
  "28",
  "29",
  "30",
  "31",
  "30",
  "23",
  "27",
  "29",
  "32",
  "31",
  "28",
  "30",
  "31",
  "27",
  "34",
  "24",
  "29",
  "27",
  "30",
  "28",
  "28",
  "29",
  "29",
  "32",
  "31",
  "29",
  "28",
  "29",
  "29",
  "25",
  "31",
  "27",
  "29",
  "29",
  "28",
  "29",
  "24",
  "26",
  "20",
  "31",
  "26",
  "24",
  "29",
  "27",
  "28",
  "31",
  "20",
  "26",
  "28",
  "30",
  "29",
  "27",
  "31",
  "28",
  "29",
  "29",
  "31",
  "33",
  "28",
  "31",
  "21",
  "26",
  "21",
  "27",
  "30",
  "27",
  "29",
  "27",
  "21",
  "26",
  "27",
  "31",
  "26",
  "23",
  "27",
  "27",
  "32",
  "27",
  "33",
  "24",
  "28",
  "29",
  "30",
  "27",
  "28",
  "32",
  "30",
  "26",
  "30",
  "26",
  "30",
  "27",
  "30",
  "30",
  "27",
  "28",
  "30",
  "26",
  "29",
  "28",
  "27",
  "34",
  "24",
  "33",
  "32",
  "24",
  "20",
  "28",
  "22",
  "25",
  "27",
  "26",
  "27",
  "30",
  "29",
  "32",
  "28",
  "32",
  "25",
  "31",
  "26",
  "26",
  "30",
  "27",
  "23",
  "30",
  "24",
  "31",
  "23",
  "26",
  "27",
  "30",
  "27",
  "32",
  "23",
  "26",
  "24",
  "25",
  "29",
  "27",
  "27",
  "30",
  "23",
  "24",
  "29",
  "28",
  "24",
  "22",
  "26",
  "31",
  "23",
  "27",
  "30",
  "28",
  "30",
  "26",
  "28",
  "21",
  "36",
  "31",
  "27",
  "33",
  "27",
  "30",
  "20",
  "24",
  "23",
  "31",
  "32",
  "24",
  "25",
  "25",
  "28",
  "25",
  "28",
  "32",
  "29",
  "27",
  "22",
  "24",
  "24",
  "27",
  "30",
  "30",
  "29",
  "23",
  "26",
  "22",
  "29",
  "26",
  "22",
  "30",
  "27",
  "28",
  "25",
  "27",
  "27",
  "26",
  "25",
  "29",
  "27",
  "30",
  "27",
  "27",
  "28",
  "31",
  "30",
  "31",
  "31",
  "29",
  "29",
  "28",
  "24",
  "21",
  "26",
  "28",
  "26",
  "28",
  "24",
  "25",
  "27",
  "24",
  "28",
  "23",
  "24",
  "21",
  "31",
  "28",
  "23",
  "29",
  "25",
  "26",
  "27",
  "21",
  "25",
  "28",
  "28",
  "27",
  "29",
  "24",
  "28",
  "29",
  "30",
  "30",
  "29",
  "29",
  "33",
  "29",
  "29",
  "29",
  "31",
  "28",
  "23",
  "24",
  "30",
  "26",
  "22",
  "29",
  "29",
  "29",
  "28",
  "25",
  "23",
  "28",
  "26",
  "23",
  "24",
  "26",
  "28",
  "25",
  "31",
  "29",
  "28",
  "32",
  "26",
  "26",
  "33",
  "25",
  "23",
  "29",
  "27",
  "29",
  "25",
  "28",
  "24",
  "27",
  "30",
  "24",
  "23",
  "22",
  "27",
  "29",
  "22",
  "26",
  "26",
  "27",
  "28",
  "29",
  "28",
  "30",
  "30",
  "29",
  "31",
  "31",
  "28",
  "28",
  "27",
  "32",
  "25",
  "22",
  "27",
  "24",
  "26",
  "31",
  "26",
  "28",
  "29",
  "27",
  "27",
  "28",
  "27",
  "28",
  "30",
  "25",
  "27",
  "29",
  "22",
  "23",
  "29",
  "32",
  "28",
  "32",
  "29",
  "22",
  "25",
  "29",
  "27",
  "28",
  "32",
  "24",
  "28",
  "27",
  "24",
  "25",
  "29",
  "30",
  "30",
  "26",
  "26",
  "31",
  "26",
  "22",
  "31",
  "27",
  "28",
  "31",
  "21",
  "29",
  "32",
  "29",
  "30",
  "24",
  "32",
  "24",
  "30",
  "32",
  "34",
  "24",
  "30",
  "24",
  "31",
  "31",
  "27",
  "26",
  "27",
  "21",
  "31",
  "32",
  "31",
  "32",
  "27",
  "31",
  "31",
  "30",
  "23",
  "31",
  "28",
  "26",
  "27",
  "30",
  "21",
  "34",
  "27",
  "28",
  "27",
  "28",
  "28",
  "27",
  "28",
  "29",
  "26",
  "27",
  "28",
  "30",
  "29",
  "29",
  "28",
  "26",
  "28",
  "30",
  "28",
  "25",
  "28",
  "26",
  "32",
  "30",
  "28",
  "29",
  "28",
  "23",
  "26",
  "26",
  "30",
  "27",
  "20",
  "28",
  "27",
  "28",
  "24",
  "26",
  "28",
  "29",
  "27",
  "28",
  "28",
  "27",
  "25",
  "26",
  "29",
  "30",
  "28",
  "28",
  "28",
  "21",
  "25",
  "29",
  "26",
  "32",
  "25",
  "24",
  "27",
  "27",
  "27",
  "29",
  "27",
  "27",
  "28",
  "25",
  "27",
  "27",
  "34",
  "32",
  "31",
  "28",
  "32",
  "23",
  "27",
  "28",
  "26",
  "30",
  "31",
  "23",
  "27",
  "33",
  "28",
  "24",
  "23",
  "24",
  "24",
  "24",
  "26",
  "29",
  "22",
  "30",
  "23",
  "25",
  "29",
  "27",
  "26",
  "25",
  "29",
  "27",
  "30",
  "26",
  "26",
  "29",
  "29",
  "28",
  "23",
  "27",
  "31",
  "24",
  "26",
  "27",
  "27",
  "32",
  "29",
  "24",
  "27",
  "35",
  "30",
  "26",
  "32",
  "32",
  "30",
  "31",
  "27",
  "32",
  "24",
  "27",
  "28",
  "27",
  "25",
  "29",
  "31",
  "27",
  "30",
  "23",
  "27",
  "25",
  "32",
  "32",
  "30",
  "23",
  "28",
  "27",
  "21",
  "29",
  "27",
  "29",
  "27",
  "23",
  "28",
  "30",
  "28",
  "22",
  "32",
  "28",
  "30",
  "27",
  "30",
  "28",
  "30",
  "24",
  "23",
  "28",
  "30",
  "30",
  "24",
  "29",
  "25",
  "27",
  "30",
  "28",
  "27",
  "27",
  "28",
  "30",
  "26",
  "24",
  "28",
  "25",
  "24",
  "23",
  "30",
  "22",
  "28",
  "27",
  "27",
  "34",
  "29",
  "24",
  "30",
  "27",
  "27",
  "26",
  "25",
  "32",
  "29",
  "29",
  "30",
  "23",
  "27",
  "27",
  "29",
  "32",
  "29",
  "26",
  "28",
  "28",
  "27",
  "27",
  "27",
  "21",
  "29",
  "22",
  "29",
  "28",
  "24",
  "27",
  "25",
  "27",
  "25",
  "20",
  "32",
  "30",
  "30",
  "29",
  "23",
  "27",
  "27",
  "30",
  "26",
  "32",
  "32",
  "29",
  "30",
  "30",
  "29",
  "24",
  "31",
  "32",
  "28",
  "27",
  "32",
  "24",
  "28",
  "26",
  "29",
  "24",
  "28",
  "34",
  "27",
  "30",
  "22",
  "28",
  "21",
  "32",
  "28",
  "30",
  "23",
  "29",
  "19",
  "31",
  "30",
  "28",
  "25",
  "28",
  "26",
  "26",
  "32",
  "26",
  "28",
  "27",
  "29",
  "28",
  "26",
  "30",
  "27",
  "27",
  "29",
  "30",
  "25",
  "23",
  "25",
  "30",
  "29",
  "30",
  "29",
  "25",
  "24",
  "22",
  "33",
  "33",
  "28",
  "30",
  "29",
  "27",
  "32",
  "28",
  "23",
  "28",
  "28",
  "26",
  "27",
  "29",
  "27",
  "27",
  "31",
  "28",
  "28",
  "28",
  "30",
  "27",
  "29",
  "28",
  "27",
  "30",
  "31",
  "25",
  "26",
  "22",
  "31",
  "28",
  "27",
  "32",
  "30",
  "29",
  "23",
  "31",
  "28",
  "30",
  "25",
  "23",
  "31",
  "24",
  "21",
  "27",
  "22",
  "29",
  "23",
  "32",
  "24",
  "32",
  "25",
  "26",
  "32",
  "27",
  "25",
  "28",
  "24",
  "29",
  "26",
  "22",
  "22",
  "27",
  "32",
  "29",
  "24",
  "24",
  "27",
  "26",
  "32",
  "24",
  "32",
  "25",
  "32",
  "31",
  "25",
  "28",
  "25",
  "27",
  "28",
  "29",
  "27",
  "26",
  "29",
  "28",
  "27",
  "31",
  "26",
  "24",
  "26",
  "26",
  "28",
  "28",
  "21",
  "24",
  "23",
  "26",
  "27",
  "30",
  "24",
  "25",
  "25",
  "28",
  "30",
  "27",
  "30",
  "27",
  "32",
  "22",
  "29",
  "25",
  "28",
  "28",
  "29",
  "27",
  "26",
  "25",
  "27",
  "29",
  "30",
  "32",
  "30",
  "26",
  "25",
  "22",
  "22",
  "28",
  "28",
  "29",
  "29",
  "31",
  "30",
  "31",
  "26",
  "29",
  "26",
  "28",
  "25",
  "28",
  "26",
  "29",
  "29",
  "26",
  "27",
  "24",
  "27",
  "26",
  "30",
  "31",
  "23",
  "26",
  "24",
  "29",
  "29",
  "26",
  "26",
  "30",
  "29",
  "24",
  "25",
  "21",
  "29",
  "30",
  "26",
  "28",
  "22",
  "24",
  "22",
  "30",
  "27",
  "28",
  "28",
  "29",
  "32",
  "28",
  "28",
  "25",
  "29",
  "24",
  "28",
  "28",
  "28",
  "27",
  "31",
  "26",
  "31",
  "25",
  "25",
  "26",
  "23",
  "25",
  "25",
  "29",
  "28",
  "31",
  "26",
  "28",
  "29",
  "29",
  "25",
  "27",
  "27",
  "31",
  "24",
  "28",
  "25",
  "26",
  "26",
  "23",
  "27",
  "29",
  "26",
  "28",
  "27",
  "25",
  "29",
  "25",
  "25",
  "29",
  "31",
  "27",
  "29",
  "23",
  "26",
  "26",
  "27",
  "25",
  "29",
  "24",
  "27",
  "26",
  "26",
  "30",
  "25",
  "25",
  "28",
  "28",
  "30",
  "31",
  "29",
  "30",
  "26",
  "30",
  "25",
  "20",
  "29",
  "23",
  "28",
  "24",
  "26",
  "26",
  "26",
  "22",
  "24",
  "26",
  "29",
  "31",
  "28",
  "31",
  "24",
  "29",
  "29",
  "27",
  "27",
  "27",
  "23",
  "27",
  "22",
  "26",
  "28",
  "31",
  "29",
  "29",
  "23",
  "30",
  "31",
  "30",
  "25",
  "31",
  "28",
  "28",
  "27",
  "23",
  "23",
  "25",
  "28",
  "31",
  "28",
  "24",
  "25",
  "29",
  "29",
  "27",
  "28",
  "31",
  "25",
  "30",
  "27",
  "24",
  "27",
  "26",
  "27",
  "23",
  "31",
  "30",
  "36",
  "21",
  "24",
  "27",
  "29",
  "26",
  "30",
  "25",
  "26",
  "31",
  "30",
  "22",
  "27",
  "28",
  "26",
  "23",
  "27",
  "28",
  "21",
  "28",
  "30",
  "28",
  "30",
  "29",
  "33",
  "31",
  "21",
  "26",
  "25",
  "24",
  "30",
  "30",
  "35",
  "25",
  "25",
  "31",
  "28",
  "28",
  "25",
  "27",
  "22",
  "33",
  "31",
  "22",
  "27",
  "26",
  "31",
  "25",
  "25",
  "24",
  "20",
  "27",
  "22",
  "23",
  "26",
  "31",
  "29",
  "28",
  "28",
  "24",
  "27",
  "29",
  "29",
  "29",
  "28",
  "28",
  "32",
  "30",
  "27",
  "31",
  "23",
  "29",
  "30",
  "29",
  "27",
  "30",
  "26",
  "29",
  "27",
  "21",
  "24",
  "27",
  "24",
  "27",
  "30",
  "30",
  "27",
  "29",
  "28",
  "26",
  "29",
  "28",
  "27",
  "31",
  "27",
  "26",
  "27",
  "27",
  "28",
  "27",
  "25",
  "26",
  "27",
  "28",
  "24",
  "27",
  "27",
  "27",
  "27",
  "29",
  "21",
  "31",
  "27",
  "30",
  "27",
  "28",
  "26",
  "25",
  "34",
  "33",
  "28",
  "29",
  "24",
  "26",
  "27",
  "26",
  "27",
  "26",
  "25",
  "30",
  "30",
  "25",
  "32",
  "32",
  "24",
  "27",
  "22",
  "26",
  "26",
  "27",
  "27",
  "29",
  "27",
  "28",
  "29",
  "27",
  "29",
  "33",
  "21",
  "26",
  "26",
  "28",
  "27",
  "20",
  "27",
  "24",
  "28",
  "28",
  "30",
  "23",
  "28",
  "25",
  "19",
  "26",
  "34",
  "23",
  "28",
  "24",
  "28",
  "26",
  "26",
  "28",
  "24",
  "28",
  "26",
  "26",
  "27",
  "28",
  "29",
  "28",
  "27",
  "30",
  "31",
  "27",
  "29",
  "21",
  "28",
  "27",
  "30",
  "27",
  "27",
  "28",
  "29",
  "25",
  "28",
  "28",
  "30",
  "29",
  "29",
  "29",
  "28",
  "30",
  "34",
  "29",
  "27",
  "29",
  "31",
  "31",
  "27",
  "25",
  "27",
  "26",
  "28",
  "31",
  "23",
  "28",
  "27",
  "27",
  "24",
  "23",
  "28",
  "26",
  "29",
  "23",
  "28",
  "28",
  "24",
  "23",
  "30",
  "22",
  "27",
  "29",
  "26",
  "27",
  "23",
  "24",
  "29",
  "27",
  "31",
  "31",
  "24",
  "29",
  "24",
  "29",
  "27",
  "24",
  "29",
  "26",
  "26",
  "23",
  "27",
  "26",
  "25",
  "26",
  "26",
  "26",
  "27",
  "27",
  "26",
  "28",
  "28",
  "30",
  "26",
  "26",
  "30",
  "27",
  "26",
  "26",
  "28",
  "27",
  "27",
  "31",
  "27",
  "28",
  "26",
  "31",
  "28",
  "31",
  "24",
  "29",
  "26",
  "26",
  "21",
  "21",
  "22",
  "26",
  "27",
  "24",
  "25",
  "28",
  "28",
  "25",
  "28",
  "30",
  "25",
  "27",
  "21",
  "22",
  "26",
  "22",
  "27",
  "32",
  "29",
  "26",
  "21",
  "24",
  "24",
  "23",
  "23",
  "30",
  "28",
  "28",
  "23",
  "23",
  "26",
  "29",
  "29",
  "25",
  "24",
  "28",
  "26",
  "27",
  "27",
  "21",
  "24",
  "28",
  "32",
  "27",
  "27",
  "29",
  "25",
  "30",
  "30",
  "24",
  "28",
  "24",
  "25",
  "28",
  "28",
  "27",
  "25",
  "24",
  "24",
  "27",
  "26",
  "30",
  "29",
  "27",
  "29",
  "30",
  "29",
  "28",
  "33",
  "27",
  "22",
  "29",
  "26",
  "23",
  "28",
  "29",
  "31",
  "29",
  "28",
  "26",
  "23",
  "25",
  "26",
  "30",
  "29",
  "22",
  "26",
  "28",
  "31",
  "28",
  "27",
  "26",
  "26",
  "22",
  "25",
  "26",
  "27",
  "23",
  "29",
  "29",
  "28",
  "29",
  "30",
  "21",
  "27",
  "29",
  "24",
  "28",
  "23",
  "25",
  "29",
  "27",
  "29",
  "30",
  "25",
  "30",
  "27",
  "25",
  "31",
  "29",
  "25",
  "21",
  "24",
  "28",
  "28",
  "24",
  "28",
  "27",
  "28",
  "27",
  "27",
  "27",
  "22",
  "27",
  "26",
  "29",
  "28",
  "26",
  "27",
  "29",
  "28",
  "24",
  "31",
  "26",
  "27",
  "30",
  "29",
  "25",
  "28",
  "27",
  "26",
  "28",
  "28",
  "29",
  "23",
  "29",
  "29",
  "32",
  "30",
  "27",
  "27",
  "24",
  "25",
  "29",
  "30",
  "26",
  "31",
  "26",
  "26",
  "26",
  "27",
  "24",
  "28",
  "30",
  "28",
  "32",
  "24",
  "30",
  "29",
  "26",
  "26",
  "24",
  "25",
  "25",
  "25",
  "24",
  "24",
  "19",
  "26",
  "29",
  "25",
  "29",
  "26",
  "31",
  "29",
  "22",
  "26",
  "23",
  "22",
  "23",
  "27",
  "29",
  "29",
  "22",
  "21",
  "28",
  "30",
  "27",
  "27",
  "21",
  "26",
  "28",
  "29",
  "28",
  "26",
  "25",
  "28",
  "25",
  "25",
  "21",
  "29",
  "23",
  "25",
  "29",
  "28",
  "22",
  "27",
  "23",
  "27",
  "27",
  "27",
  "26",
  "29",
  "29",
  "20",
  "27",
  "28",
  "25",
  "25",
  "28",
  "22",
  "27",
  "26",
  "26",
  "27",
  "26",
  "26",
  "26",
  "28",
  "30",
  "33",
  "23",
  "28",
  "30",
  "27",
  "27",
  "24",
  "24",
  "24",
  "22",
  "24",
  "30",
  "28",
  "27",
  "28",
  "28",
  "25",
  "27",
  "28",
  "27",
  "22",
  "22",
  "30",
  "27",
  "27",
  "28",
  "26",
  "27",
  "23",
  "25",
  "30",
  "31",
  "29",
  "30",
  "26",
  "27",
  "27",
  "27",
  "21",
  "28",
  "25",
  "27",
  "32",
  "28",
  "28",
  "23",
  "22",
  "26",
  "31",
  "27",
  "30",
  "29",
  "23",
  "29",
  "27",
  "25",
  "28",
  "28",
  "25",
  "29",
  "28",
  "27",
  "25",
  "31",
  "25",
  "25",
  "24",
  "27",
  "27",
  "23",
  "30",
  "27",
  "25",
  "26",
  "22",
  "28",
  "25",
  "29",
  "27",
  "26",
  "25",
  "29",
  "30",
  "29",
  "28",
  "29",
  "23",
  "23",
  "26",
  "30",
  "30",
  "25",
  "23",
  "26",
  "29",
  "29",
  "28",
  "28",
  "26",
  "26",
  "25",
  "24",
  "27",
  "18",
  "28",
  "24",
  "27",
  "21",
  "32",
  "30",
  "25",
  "30",
  "28",
  "28",
  "29",
  "24",
  "28",
  "23",
  "32",
  "24",
  "27",
  "20",
  "26",
  "27",
  "23",
  "25",
  "25",
  "28",
  "22",
  "30",
  "23",
  "27",
  "25",
  "20",
  "29",
  "22",
  "22",
  "31",
  "30",
  "27",
  "23",
  "27",
  "26",
  "27",
  "25",
  "26",
  "25",
  "27",
  "31",
  "25",
  "26",
  "27",
  "31",
  "26",
  "33",
  "26",
  "26",
  "28",
  "25",
  "26",
  "29",
  "28",
  "30",
  "26",
  "28",
  "31",
  "21",
  "26",
  "26",
  "24",
  "27",
  "23",
  "26",
  "28",
  "28",
  "25",
  "27",
  "27",
  "21",
  "26",
  "24",
  "29",
  "28",
  "23",
  "28",
  "30",
  "30",
  "29",
  "28",
  "22",
  "29",
  "32",
  "28",
  "29",
  "29",
  "25",
  "28",
  "25",
  "28",
  "24",
  "26",
  "27",
  "23",
  "29",
  "27",
  "31",
  "28",
  "22",
  "30",
  "29",
  "24",
  "25",
  "26",
  "24",
  "32",
  "27",
  "28",
  "27",
  "27",
  "29",
  "29",
  "26",
  "22",
  "28",
  "25",
  "24",
  "31",
  "25",
  "23",
  "29",
  "28",
  "22",
  "24",
  "25",
  "18",
  "27",
  "26",
  "26",
  "28",
  "24",
  "30",
  "29",
  "32",
  "26",
  "30",
  "27",
  "27",
  "23",
  "29",
  "31",
  "23",
  "27",
  "31",
  "27",
  "22",
  "30",
  "28",
  "24",
  "30",
  "34",
  "28",
  "28",
  "23",
  "29",
  "27",
  "28",
  "24",
  "28",
  "26",
  "27",
  "24",
  "27",
  "28",
  "27",
  "30",
  "25",
  "32",
  "27",
  "29",
  "23",
  "28",
  "29",
  "30",
  "23",
  "32",
  "26",
  "26",
  "26",
  "25",
  "24",
  "23",
  "19",
  "26",
  "20",
  "24",
  "28",
  "31",
  "28",
  "29",
  "26",
  "24",
  "28",
  "26",
  "29",
  "19",
  "28",
  "27",
  "29",
  "24",
  "29",
  "32",
  "25",
  "19",
  "29",
  "17",
  "29",
  "22",
  "26",
  "27",
  "27",
  "27",
  "26",
  "27",
  "26",
  "25",
  "25",
  "28",
  "23",
  "32",
  "25",
  "26",
  "25",
  "27",
  "27",
  "24",
  "20",
  "27",
  "32",
  "23",
  "22",
  "25",
  "30",
  "29",
  "22",
  "26",
  "27",
  "27",
  "27",
  "29",
  "34",
  "26",
  "28",
  "30",
  "32",
  "28",
  "26",
  "26",
  "23",
  "27",
  "22",
  "27",
  "26",
  "26",
  "27",
  "25",
  "23",
  "31",
  "20",
  "25",
  "26",
  "24",
  "29",
  "22",
  "24",
  "28",
  "26",
  "28",
  "26",
  "27",
  "27",
  "23",
  "26",
  "25",
  "27",
  "28",
  "25",
  "26",
  "25",
  "30",
  "22",
  "32",
  "28",
  "30",
  "27",
  "22",
  "25",
  "28",
  "26",
  "27",
  "27",
  "26",
  "30",
  "28",
  "24",
  "27",
  "27",
  "27",
  "26",
  "30",
  "28",
  "29",
  "32",
  "25",
  "26",
  "28",
  "30",
  "24",
  "28",
  "28",
  "27",
  "24",
  "27",
  "31",
  "30",
  "20",
  "25",
  "21",
  "28",
  "25",
  "23",
  "27",
  "21",
  "26",
  "23",
  "26",
  "30",
  "29",
  "26",
  "27",
  "25",
  "26",
  "28",
  "24",
  "23",
  "29",
  "28",
  "27",
  "26",
  "29",
  "25",
  "29",
  "28",
  "29",
  "28",
  "26",
  "25",
  "31",
  "28",
  "28",
  "30",
  "28",
  "31",
  "30",
  "22",
  "29",
  "26",
  "27",
  "27",
  "24",
  "25",
  "28",
  "28",
  "28",
  "27",
  "28",
  "27",
  "28",
  "29",
  "30",
  "26",
  "27",
  "27",
  "29",
  "28",
  "30",
  "24",
  "26",
  "24",
  "31",
  "27",
  "30",
  "22",
  "26",
  "30",
  "27",
  "22",
  "24",
  "27",
  "26",
  "26",
  "22",
  "23",
  "24",
  "23",
  "29",
  "27",
  "31",
  "31",
  "28",
  "25",
  "27",
  "27",
  "28",
  "27",
  "26",
  "28",
  "23",
  "27",
  "22",
  "30",
  "29",
  "30",
  "22",
  "30",
  "29",
  "27",
  "31",
  "29",
  "23",
  "27",
  "27",
  "30",
  "22",
  "23",
  "31",
  "26",
  "26",
  "28",
  "32",
  "25",
  "28",
  "25",
  "28",
  "27",
  "25",
  "30",
  "28",
  "29",
  "25",
  "29",
  "27",
  "25",
  "29",
  "29",
  "25",
  "28",
  "29",
  "26",
  "27",
  "27",
  "30",
  "30",
  "20",
  "29",
  "28",
  "26",
  "28",
  "27",
  "27",
  "24",
  "22",
  "30",
  "31",
  "26",
  "24",
  "29",
  "22",
  "31",
  "28",
  "28",
  "27",
  "28",
  "24",
  "29",
  "26",
  "22",
  "26",
  "25",
  "27",
  "31",
  "30",
  "22",
  "24",
  "24",
  "25",
  "27",
  "31",
  "29",
  "24",
  "23",
  "32",
  "29",
  "29",
  "28",
  "24",
  "27",
  "26",
  "25",
  "25",
  "28",
  "28",
  "29",
  "30",
  "22",
  "31",
  "28",
  "27",
  "31",
  "32",
  "28",
  "28",
  "27",
  "23",
  "28",
  "26",
  "25",
  "26",
  "24",
  "26",
  "20",
  "26",
  "26",
  "29",
  "28",
  "29",
  "29",
  "27",
  "28",
  "29",
  "32",
  "25",
  "29",
  "25",
  "26",
  "26",
  "26",
  "28",
  "29",
  "25",
  "25",
  "29",
  "29",
  "24",
  "28",
  "26",
  "28",
  "29",
  "28",
  "21",
  "28",
  "29",
  "29",
  "28",
  "26",
  "32",
  "23",
  "25",
  "27",
  "25",
  "29",
  "28",
  "22",
  "28",
  "20",
  "21",
  "27",
  "26",
  "30",
  "28",
  "29",
  "26",
  "30",
  "23",
  "26",
  "27",
  "28",
  "21",
  "26",
  "26",
  "29",
  "25",
  "27",
  "28",
  "25",
  "27",
  "26",
  "27",
  "25",
  "28",
  "31",
  "30",
  "26",
  "27",
  "34",
  "32",
  "27",
  "21",
  "30",
  "23",
  "25",
  "31",
  "26",
  "26",
  "26",
  "25",
  "24",
  "27",
  "30",
  "27",
  "25",
  "25",
  "24",
  "27",
  "20",
  "20",
  "28",
  "28",
  "20",
  "28",
  "29",
  "24",
  "26",
  "28",
  "25",
  "28",
  "29",
  "23",
  "28",
  "27",
  "31",
  "26",
  "27",
  "26",
  "31",
  "20",
  "27",
  "26",
  "31",
  "27",
  "25",
  "29",
  "19",
  "30",
  "30",
  "27",
  "28",
  "30",
  "29",
  "27",
  "30",
  "20",
  "27",
  "27",
  "28",
  "23",
  "25",
  "21",
  "24",
  "25",
  "29",
  "28",
  "26",
  "27",
  "25",
  "25",
  "24",
  "20",
  "29",
  "24",
  "28",
  "28",
  "20",
  "30",
  "21",
  "27",
  "25",
  "30",
  "31",
  "24",
  "26",
  "25",
  "26",
  "27",
  "22",
  "29",
  "23",
  "26",
  "23",
  "24",
  "29",
  "29",
  "23",
  "30",
  "27",
  "23",
  "25",
  "27",
  "29",
  "29",
  "27",
  "28",
  "26",
  "26",
  "29",
  "27",
  "25",
  "23",
  "29",
  "26",
  "26",
  "26",
  "22",
  "28",
  "29",
  "27",
  "26",
  "24",
  "25",
  "29",
  "26",
  "28",
  "25",
  "29",
  "20",
  "27",
  "30",
  "29",
  "30",
  "28",
  "25",
  "26",
  "27",
  "29",
  "25",
  "25",
  "29",
  "30",
  "29",
  "26",
  "25",
  "24",
  "22",
  "22",
  "30",
  "32",
  "23",
  "26",
  "30",
  "26",
  "25",
  "27",
  "28",
  "25",
  "27",
  "27",
  "31",
  "23",
  "27",
  "25",
  "23",
  "30",
  "22",
  "24",
  "29",
  "31",
  "22",
  "28",
  "26",
  "24",
  "26",
  "28",
  "27",
  "21",
  "28",
  "25",
  "30",
  "24",
  "28",
  "24",
  "27",
  "19",
  "28",
  "27",
  "28",
  "26",
  "28",
  "27",
  "24",
  "28",
  "27",
  "31",
  "26",
  "28",
  "23",
  "29",
  "29",
  "29",
  "27",
  "25",
  "28",
  "29",
  "28",
  "25",
  "30",
  "30",
  "20",
  "27",
  "24",
  "27",
  "24",
  "30",
  "24",
  "26",
  "28",
  "25",
  "29",
  "27",
  "31",
  "28",
  "22",
  "27",
  "28",
  "26",
  "26",
  "28",
  "28",
  "32",
  "32",
  "28",
  "28",
  "25",
  "20",
  "28",
  "25",
  "28",
  "27",
  "23",
  "31",
  "25",
  "28",
  "25",
  "26",
  "25",
  "27",
  "30",
  "25",
  "27",
  "29",
  "25",
  "24",
  "24",
  "30",
  "27",
  "25",
  "26",
  "29",
  "23",
  "29",
  "24",
  "27",
  "21",
  "24",
  "25",
  "22",
  "30",
  "24",
  "29",
  "28",
  "23",
  "29",
  "26",
  "30",
  "25",
  "24",
  "28",
  "18",
  "28",
  "28",
  "23",
  "27",
  "33",
  "27",
  "28",
  "25",
  "25",
  "27",
  "22",
  "29",
  "28",
  "23",
  "33",
  "24",
  "27",
  "28",
  "26",
  "25",
  "29",
  "27",
  "28",
  "27",
  "25",
  "27",
  "26",
  "24",
  "29",
  "31",
  "29",
  "33",
  "27",
  "23",
  "25",
  "29",
  "32",
  "24",
  "24",
  "28",
  "29",
  "19",
  "28",
  "27",
  "26",
  "25",
  "26",
  "27",
  "27",
  "23",
  "23",
  "23",
  "29",
  "23",
  "22",
  "29",
  "27",
  "25",
  "23",
  "31",
  "29",
  "28",
  "29",
  "22",
  "22",
  "28",
  "30",
  "25",
  "28",
  "27",
  "28",
  "21",
  "30",
  "26",
  "26",
  "24",
  "25",
  "27",
  "30",
  "28",
  "23",
  "27",
  "28",
  "26",
  "25",
  "31",
  "27",
  "25",
  "23",
  "23",
  "29",
  "31",
  "29",
  "29",
  "28",
  "21",
  "28",
  "25",
  "26",
  "28",
  "27",
  "25",
  "22",
  "27",
  "31",
  "30",
  "23",
  "25",
  "32",
  "22",
  "31",
  "28",
  "26",
  "31",
  "29",
  "27",
  "28",
  "30",
  "24",
  "32",
  "26",
  "27",
  "28",
  "25",
  "21",
  "30",
  "27",
  "25",
  "23",
  "22",
  "23",
  "26",
  "28",
  "26",
  "24",
  "28",
  "26",
  "26",
  "29",
  "27",
  "23",
  "30",
  "28",
  "23",
  "26",
  "27",
  "26",
  "24",
  "33",
  "26",
  "27",
  "27",
  "31",
  "29",
  "29",
  "25",
  "25",
  "29",
  "21",
  "30",
  "29",
  "24",
  "27",
  "23",
  "28",
  "31",
  "29",
  "23",
  "24",
  "27",
  "24",
  "28",
  "27",
  "26",
  "28",
  "26",
  "25",
  "26",
  "26",
  "25",
  "24",
  "32",
  "26",
  "27",
  "24",
  "28",
  "25",
  "31",
  "26",
  "27",
  "24",
  "28",
  "32",
  "27",
  "28",
  "28",
  "27",
  "22",
  "27",
  "20",
  "27",
  "27",
  "22",
  "26",
  "29",
  "24",
  "29",
  "28",
  "26",
  "26",
  "27",
  "26",
  "28",
  "23",
  "29",
  "27",
  "29",
  "22",
  "29",
  "25",
  "29",
  "31",
  "28",
  "26",
  "25",
  "19",
  "29",
  "22",
  "26",
  "30",
  "25",
  "30",
  "27",
  "26",
  "28",
  "32",
  "29",
  "27",
  "31",
  "18",
  "27",
  "24",
  "30",
  "28",
  "26",
  "29",
  "30",
  "27",
  "25",
  "23",
  "28",
  "26",
  "17",
  "19",
  "27",
  "22",
  "30",
  "27",
  "29",
  "25",
  "26",
  "25",
  "31",
  "27",
  "30",
  "23",
  "29",
  "25",
  "25",
  "26",
  "29",
  "31",
  "29",
  "26",
  "19",
  "24",
  "27",
  "26",
  "27",
  "21",
  "25",
  "30",
  "26",
  "30",
  "25",
  "25",
  "26",
  "25",
  "28",
  "28",
  "29",
  "25",
  "27",
  "26",
  "25",
  "24",
  "27",
  "29",
  "25",
  "28",
  "27",
  "21",
  "28",
  "30",
  "29",
  "20",
  "26",
  "18",
  "29",
  "27",
  "27",
  "26",
  "27",
  "24",
  "30",
  "22",
  "31",
  "28",
  "22",
  "26",
  "33",
  "30",
  "29",
  "24",
  "29",
  "30",
  "25",
  "22",
  "26",
  "25",
  "30",
  "25",
  "29",
  "27",
  "27",
  "27",
  "25",
  "20",
  "30",
  "20",
  "23",
  "30",
  "28",
  "27",
  "31",
  "29",
  "30",
  "29",
  "23",
  "29",
  "31",
  "30",
  "30",
  "28",
  "24",
  "26",
  "29",
  "30",
  "23",
  "24",
  "23",
  "29",
  "30",
  "32",
  "29",
  "26",
  "28",
  "23",
  "28",
  "23",
  "26",
  "24",
  "26",
  "27",
  "27",
  "28",
  "28",
  "26",
  "28",
  "27",
  "30",
  "31",
  "24",
  "23",
  "34",
  "29",
  "25",
  "28",
  "28",
  "29",
  "28",
  "26",
  "24",
  "27",
  "30",
  "29",
  "26",
  "32",
  "27",
  "27",
  "31",
  "30",
  "27",
  "29",
  "28",
  "25",
  "24",
  "28",
  "26",
  "25",
  "27",
  "26",
  "25",
  "28",
  "29",
  "30",
  "29",
  "26",
  "28",
  "28",
  "30",
  "29",
  "22",
  "24",
  "27",
  "28",
  "27",
  "29",
  "27",
  "26",
  "31",
  "29",
  "21",
  "30",
  "28",
  "29",
  "26",
  "26",
  "23",
  "27",
  "24",
  "28",
  "28",
  "26",
  "28",
  "27",
  "27",
  "29",
  "21",
  "26",
  "25",
  "32",
  "23",
  "25",
  "26",
  "30",
  "31",
  "29",
  "29",
  "27",
  "28",
  "27",
  "26",
  "24",
  "27",
  "25",
  "23",
  "29",
  "28",
  "27",
  "24",
  "30",
  "26",
  "24",
  "24",
  "30",
  "25",
  "28",
  "27",
  "23",
  "28",
  "23",
  "32",
  "25",
  "31",
  "25",
  "28",
  "30",
  "28",
  "22",
  "30",
  "30",
  "24",
  "21",
  "25",
  "23",
  "25",
  "26",
  "21",
  "24",
  "28",
  "22",
  "28",
  "20",
  "22",
  "30",
  "27",
  "20",
  "21",
  "24",
  "27",
  "28",
  "25",
  "24",
  "23",
  "25",
  "28",
  "30",
  "25",
  "27",
  "17",
  "24",
  "28",
  "29",
  "31",
  "28",
  "29",
  "29",
  "26",
  "29",
  "27",
  "28",
  "30",
  "26",
  "24",
  "25",
  "30",
  "27",
  "29",
  "25",
  "31",
  "28",
  "25",
  "22",
  "22",
  "24",
  "29",
  "29",
  "26",
  "28",
  "26",
  "30",
  "27",
  "30",
  "27",
  "25",
  "28",
  "25",
  "25",
  "24",
  "24",
  "28",
  "28",
  "27",
  "26",
  "27",
  "25",
  "27",
  "23",
  "25",
  "22",
  "29",
  "31",
  "29",
  "24",
  "24",
  "28",
  "27",
  "23",
  "27",
  "31",
  "29",
  "25",
  "23",
  "27",
  "24",
  "29",
  "27",
  "24",
  "27",
  "26",
  "24",
  "28",
  "25",
  "27",
  "21",
  "25",
  "25",
  "24",
  "24",
  "29",
  "27",
  "27",
  "31",
  "26",
  "29",
  "27",
  "25",
  "26",
  "23",
  "28",
  "28",
  "31",
  "27",
  "34",
  "25",
  "31",
  "27",
  "27",
  "23",
  "26",
  "27",
  "28",
  "25",
  "27",
  "27",
  "28",
  "25",
  "31",
  "22",
  "23",
  "26",
  "32",
  "30",
  "26",
  "22",
  "25",
  "30",
  "27",
  "26",
  "28",
  "28",
  "26",
  "26",
  "31",
  "25",
  "32",
  "27",
  "26",
  "26",
  "25",
  "27",
  "25",
  "23",
  "27",
  "30",
  "27",
  "28",
  "24",
  "30",
  "28",
  "23",
  "28",
  "26",
  "25",
  "31",
  "24",
  "25",
  "27",
  "27",
  "25",
  "29",
  "26",
  "31",
  "24",
  "24",
  "24",
  "25",
  "24",
  "29",
  "26",
  "28",
  "25",
  "26",
  "29",
  "27",
  "29",
  "25",
  "25",
  "29",
  "25",
  "27",
  "24",
  "22",
  "26",
  "27",
  "20",
  "22",
  "28",
  "33",
  "25",
  "28",
  "21",
  "25",
  "25",
  "25",
  "23",
  "22",
  "27",
  "19",
  "28",
  "23",
  "30",
  "24",
  "23",
  "26",
  "25",
  "24",
  "28",
  "29",
  "26",
  "27",
  "22",
  "27",
  "22",
  "24",
  "23",
  "25",
  "27",
  "27",
  "23",
  "28",
  "28",
  "26",
  "30",
  "25",
  "24",
  "25",
  "26",
  "24",
  "32",
  "23",
  "27",
  "28",
  "26",
  "27",
  "29",
  "24",
  "27",
  "27",
  "28",
  "29",
  "26",
  "27",
  "27",
  "28",
  "29",
  "21",
  "29",
  "28",
  "28",
  "30",
  "27",
  "28",
  "26",
  "29",
  "29",
  "22",
  "22",
  "28",
  "28",
  "30",
  "25",
  "29",
  "29",
  "22",
  "26",
  "29",
  "27",
  "22",
  "26",
  "27",
  "29",
  "30",
  "28",
  "27",
  "26",
  "29",
  "30",
  "26",
  "24",
  "27",
  "27",
  "20",
  "21",
  "26",
  "25",
  "27",
  "30",
  "27",
  "25",
  "25",
  "25",
  "24",
  "25",
  "28",
  "27",
  "30",
  "23",
  "24",
  "25",
  "30",
  "31",
  "30",
  "25",
  "26",
  "26",
  "30",
  "30",
  "26",
  "30",
  "30",
  "18",
  "23",
  "26",
  "23",
  "29",
  "26",
  "29",
  "24",
  "26",
  "26",
  "26",
  "26",
  "29",
  "30",
  "27",
  "30",
  "28",
  "27",
  "30",
  "29",
  "18",
  "22",
  "27",
  "29",
  "23",
  "20",
  "25",
  "32",
  "28",
  "23",
  "23",
  "28",
  "27",
  "27",
  "25",
  "25",
  "27",
  "29",
  "30",
  "23",
  "29",
  "28",
  "25",
  "28",
  "29",
  "25",
  "25",
  "25",
  "27",
  "28",
  "28",
  "25",
  "30",
  "21",
  "27",
  "27",
  "26",
  "28",
  "32",
  "18",
  "22",
  "28",
  "27",
  "22",
  "25",
  "23",
  "26",
  "25",
  "28",
  "22",
  "22",
  "26",
  "26",
  "29",
  "29",
  "31",
  "29",
  "26",
  "27",
  "25",
  "21",
  "27",
  "28",
  "27",
  "29",
  "27",
  "22",
  "24",
  "23",
  "25",
  "27",
  "27",
  "26",
  "28",
  "20",
  "25",
  "23",
  "29",
  "28",
  "25",
  "25",
  "29",
  "28",
  "30",
  "27",
  "28",
  "26",
  "28",
  "24",
  "29",
  "25",
  "26",
  "24",
  "24",
  "27",
  "20",
  "30",
  "26",
  "19",
  "28",
  "26",
  "24",
  "18",
  "17",
  "29",
  "23",
  "27",
  "26",
  "28",
  "24",
  "25",
  "29",
  "29",
  "28",
  "23",
  "24",
  "28",
  "25",
  "27",
  "26",
  "27",
  "23",
  "29",
  "22",
  "30",
  "26",
  "23",
  "30",
  "29",
  "25",
  "28",
  "29",
  "27",
  "27",
  "26",
  "26",
  "30",
  "23",
  "25",
  "25",
  "23",
  "25",
  "27",
  "26",
  "20",
  "30",
  "31",
  "27",
  "30",
  "24",
  "28",
  "23",
  "21",
  "21",
  "25",
  "28",
  "27",
  "23",
  "29",
  "29",
  "31",
  "24",
  "28",
  "30",
  "21",
  "26",
  "29",
  "27",
  "26",
  "25",
  "27",
  "27",
  "20",
  "30",
  "21",
  "26",
  "29",
  "27",
  "26",
  "27",
  "30",
  "25",
  "23",
  "25",
  "28",
  "25",
  "22",
  "22",
  "27",
  "26",
  "27",
  "22",
  "26",
  "26",
  "22",
  "27",
  "26",
  "24",
  "25",
  "20",
  "27",
  "25",
  "27",
  "30",
  "27",
  "21",
  "30",
  "23",
  "28",
  "27",
  "26",
  "25",
  "28",
  "21",
  "23",
  "30",
  "20",
  "25",
  "28",
  "28",
  "28",
  "26",
  "29",
  "21",
  "25",
  "28",
  "24",
  "24",
  "23",
  "24",
  "25",
  "25",
  "23",
  "23",
  "27",
  "27",
  "27",
  "29",
  "21",
  "28",
  "28",
  "27",
  "23",
  "30",
  "19",
  "29",
  "26",
  "28",
  "28",
  "28",
  "26",
  "23",
  "24",
  "28",
  "23",
  "26",
  "24",
  "25",
  "28",
  "31",
  "29",
  "27",
  "28",
  "21",
  "22",
  "26",
  "29",
  "21",
  "24",
  "29",
  "24",
  "23",
  "19",
  "25",
  "27",
  "26",
  "23",
  "29",
  "19",
  "25",
  "28",
  "25",
  "29",
  "26",
  "25",
  "28",
  "27",
  "28",
  "20",
  "28",
  "30",
  "28",
  "29",
  "26",
  "24",
  "25",
  "27",
  "25",
  "26",
  "25",
  "28",
  "22",
  "22",
  "28",
  "27",
  "28",
  "25",
  "29",
  "28",
  "29",
  "24",
  "22",
  "25",
  "29",
  "23",
  "21",
  "23",
  "26",
  "29",
  "25",
  "26",
  "30",
  "28",
  "26",
  "26",
  "29",
  "29",
  "25",
  "28",
  "27",
  "24",
  "30",
  "21",
  "27",
  "31",
  "26",
  "27",
  "22",
  "30",
  "26",
  "22",
  "26",
  "25",
  "27",
  "22",
  "24",
  "27",
  "29",
  "31",
  "28",
  "25",
  "25",
  "24",
  "27",
  "29",
  "24",
  "29",
  "30",
  "27",
  "20",
  "28",
  "28",
  "26",
  "28",
  "29",
  "27",
  "28",
  "26",
  "24",
  "28",
  "28",
  "26",
  "26",
  "20",
  "26",
  "26",
  "30",
  "27",
  "30",
  "21",
  "27",
  "26",
  "18",
  "22",
  "22",
  "27",
  "29",
  "23",
  "27",
  "23",
  "20",
  "24",
  "29",
  "26",
  "32",
  "27",
  "22",
  "28",
  "30",
  "32",
  "18",
  "31",
  "25",
  "28",
  "19",
  "24",
  "27",
  "29",
  "25",
  "27",
  "24",
  "23",
  "26",
  "27",
  "24",
  "29",
  "30",
  "25",
  "29",
  "27",
  "30",
  "27",
  "30",
  "23",
  "31",
  "26",
  "27",
  "26",
  "28",
  "24",
  "28",
  "26",
  "23",
  "26",
  "22",
  "24",
  "24",
  "28",
  "28",
  "28",
  "30",
  "28",
  "25",
  "29",
  "28",
  "25",
  "26",
  "28",
  "28",
  "28",
  "22",
  "21",
  "27",
  "27",
  "28",
  "28",
  "20",
  "23",
  "28",
  "25",
  "26",
  "24",
  "30",
  "26",
  "28",
  "26",
  "26",
  "28",
  "21",
  "26",
  "28",
  "30",
  "20",
  "22",
  "31",
  "23",
  "30",
  "23",
  "29",
  "22",
  "25",
  "27",
  "29",
  "25",
  "29",
  "25",
  "22",
  "29",
  "24",
  "26",
  "24",
  "28",
  "28",
  "22",
  "27",
  "25",
  "25",
  "27",
  "29",
  "22",
  "25",
  "25",
  "26",
  "29",
  "27",
  "27",
  "25",
  "26",
  "26",
  "26",
  "29",
  "26",
  "27",
  "26",
  "29",
  "26",
  "27",
  "26",
  "26",
  "29",
  "29",
  "22",
  "29",
  "23",
  "27",
  "29",
  "27",
  "27",
  "25",
  "25",
  "27",
  "26",
  "28",
  "24",
  "29",
  "31",
  "27",
  "26",
  "27",
  "29",
  "29",
  "26",
  "25",
  "30",
  "28",
  "26",
  "27",
  "26",
  "27",
  "25",
  "27",
  "25",
  "25",
  "25",
  "32",
  "28",
  "24",
  "26",
  "28",
  "26",
  "21",
  "20",
  "27",
  "22",
  "26",
  "25",
  "27",
  "25",
  "24",
  "25",
  "23",
  "28",
  "23",
  "26",
  "22",
  "23",
  "25",
  "24",
  "26",
  "31",
  "24",
  "23",
  "22",
  "31",
  "25",
  "28",
  "25",
  "24",
  "22",
  "29",
  "23",
  "27",
  "27",
  "22",
  "23",
  "24",
  "25",
  "25",
  "27",
  "20",
  "29",
  "23",
  "23",
  "27",
  "29",
  "22",
  "31",
  "22",
  "28",
  "32",
  "28",
  "27",
  "25",
  "27",
  "25",
  "23",
  "26",
  "29",
  "23",
  "27",
  "28",
  "21",
  "29",
  "22",
  "26",
  "29",
  "25",
  "29",
  "24",
  "29",
  "26",
  "23",
  "25",
  "26",
  "28",
  "28",
  "22",
  "27",
  "22",
  "25",
  "27",
  "26",
  "27",
  "25",
  "26",
  "27",
  "27",
  "29",
  "24",
  "26",
  "25",
  "22",
  "31",
  "26",
  "27",
  "28",
  "25",
  "30",
  "23",
  "26",
  "23",
  "27",
  "27",
  "22",
  "27",
  "26",
  "29",
  "27",
  "27",
  "28",
  "24",
  "27",
  "28",
  "26",
  "21",
  "29",
  "27",
  "23",
  "26",
  "27",
  "29",
  "21",
  "27",
  "25",
  "22",
  "25",
  "20",
  "29",
  "29",
  "26",
  "29",
  "26",
  "28",
  "22",
  "24",
  "25",
  "28",
  "24",
  "27",
  "22",
  "28",
  "26",
  "25",
  "26",
  "28",
  "30",
  "24",
  "29",
  "28",
  "25",
  "25",
  "25",
  "27",
  "29",
  "21",
  "29",
  "27",
  "27",
  "29",
  "30",
  "31",
  "28",
  "25",
  "27",
  "26",
  "28",
  "24",
  "29",
  "27",
  "29",
  "21",
  "28",
  "24",
  "29",
  "21",
  "28",
  "25",
  "27",
  "26",
  "29",
  "22",
  "26",
  "26",
  "27",
  "23",
  "26",
  "28",
  "23",
  "24",
  "25",
  "30",
  "27",
  "27",
  "26",
  "28",
  "28",
  "27",
  "27",
  "24",
  "26",
  "30",
  "27",
  "26",
  "26",
  "30",
  "24",
  "32",
  "31",
  "26",
  "29",
  "22",
  "20",
  "25",
  "30",
  "27",
  "27",
  "26",
  "26",
  "28",
  "26",
  "25",
  "29",
  "25",
  "29",
  "20",
  "28",
  "28",
  "27",
  "26",
  "23",
  "30",
  "27",
  "24",
  "28",
  "26",
  "26",
  "22",
  "26",
  "29",
  "29",
  "27",
  "27",
  "24",
  "28",
  "27",
  "26",
  "26",
  "21",
  "26",
  "28",
  "28",
  "22",
  "27",
  "26",
  "27",
  "29",
  "28",
  "28",
  "26",
  "25",
  "25",
  "28",
  "27",
  "25",
  "22",
  "28",
  "28",
  "26",
  "20",
  "27",
  "26",
  "25",
  "30",
  "22",
  "22",
  "26",
  "25",
  "25",
  "23",
  "29",
  "26",
  "27",
  "25",
  "28",
  "23",
  "30",
  "25",
  "25",
  "28",
  "30",
  "25",
  "30",
  "29",
  "20",
  "21",
  "28",
  "25",
  "23",
  "24",
  "24",
  "28",
  "29",
  "21",
  "23",
  "26",
  "20",
  "22",
  "28",
  "24",
  "25",
  "27",
  "32",
  "28",
  "24",
  "25",
  "23",
  "31",
  "28",
  "24",
  "24",
  "28",
  "27",
  "27",
  "32",
  "26",
  "23",
  "28",
  "29",
  "29",
  "22",
  "24",
  "27",
  "31",
  "26",
  "26",
  "25",
  "24",
  "25",
  "28",
  "28",
  "27",
  "26",
  "25",
  "26",
  "27",
  "29",
  "26",
  "30",
  "27",
  "31",
  "26",
  "26",
  "26",
  "25",
  "25",
  "25",
  "24",
  "24",
  "22",
  "29",
  "26",
  "23",
  "29",
  "29",
  "26",
  "27",
  "26",
  "26",
  "24",
  "27",
  "26",
  "26",
  "27",
  "28",
  "23",
  "25",
  "21",
  "27",
  "24",
  "26",
  "20",
  "28",
  "20",
  "26",
  "27",
  "24",
  "26",
  "29",
  "26",
  "20",
  "27",
  "28",
  "24",
  "23",
  "26",
  "26",
  "20",
  "23",
  "29",
  "22",
  "27",
  "23",
  "21",
  "26",
  "24",
  "25",
  "27",
  "27",
  "22",
  "23",
  "27",
  "29",
  "23",
  "25",
  "26",
  "28",
  "28",
  "26",
  "27",
  "29",
  "29",
  "24",
  "28",
  "19",
  "27",
  "21",
  "26",
  "27",
  "19",
  "22",
  "26",
  "24",
  "27",
  "30",
  "20",
  "27",
  "27",
  "29",
  "26",
  "23",
  "26",
  "28",
  "26",
  "24",
  "27",
  "26",
  "24",
  "28",
  "24",
  "21",
  "25",
  "24",
  "28",
  "25",
  "22",
  "28",
  "24",
  "27",
  "30",
  "26",
  "23",
  "26",
  "22",
  "20",
  "28",
  "22",
  "28",
  "29",
  "26",
  "25",
  "23",
  "29",
  "31",
  "25",
  "26",
  "28",
  "26",
  "27",
  "27",
  "28",
  "28",
  "17",
  "22",
  "28",
  "29",
  "22",
  "20",
  "20",
  "28",
  "21",
  "25",
  "23",
  "25",
  "28",
  "31",
  "27",
  "29",
  "28",
  "28",
  "24",
  "32",
  "27",
  "27",
  "28",
  "26",
  "26",
  "26",
  "28",
  "30",
  "25",
  "19",
  "26",
  "26",
  "28",
  "27",
  "24",
  "28",
  "28",
  "21",
  "25",
  "25",
  "25",
  "31",
  "31",
  "24",
  "27",
  "25",
  "22",
  "26",
  "27",
  "29",
  "27",
  "27",
  "30",
  "23",
  "26",
  "31",
  "27",
  "25",
  "28",
  "27",
  "25",
  "25",
  "25",
  "26",
  "25",
  "26",
  "25",
  "20",
  "26",
  "26",
  "24",
  "25",
  "30",
  "26",
  "31",
  "25",
  "22",
  "30",
  "25",
  "25",
  "27",
  "24",
  "25",
  "24",
  "31",
  "26",
  "30",
  "28",
  "27",
  "26",
  "25",
  "26",
  "25",
  "21",
  "27",
  "20",
  "25",
  "23",
  "28",
  "29",
  "24",
  "29",
  "27",
  "26",
  "27",
  "25",
  "28",
  "23",
  "20",
  "22",
  "24",
  "23",
  "27",
  "24",
  "19",
  "28",
  "25",
  "29",
  "30",
  "29",
  "27",
  "25",
  "24",
  "26",
  "25",
  "26",
  "26",
  "27",
  "25",
  "21",
  "26",
  "25",
  "30",
  "24",
  "28",
  "26",
  "23",
  "31",
  "25",
  "27",
  "20",
  "26",
  "28",
  "24",
  "30",
  "29",
  "24",
  "30",
  "25",
  "23",
  "22",
  "26",
  "27",
  "24",
  "21",
  "27",
  "27",
  "25",
  "29",
  "25",
  "29",
  "25",
  "24",
  "26",
  "26",
  "23",
  "28",
  "28",
  "19",
  "28",
  "27",
  "29",
  "25",
  "25",
  "28",
  "27",
  "27",
  "25",
  "24",
  "27",
  "26",
  "27",
  "31",
  "20",
  "26",
  "29",
  "27",
  "21",
  "27",
  "24",
  "23",
  "24",
  "27",
  "34",
  "24",
  "25",
  "26",
  "24",
  "27",
  "29",
  "30",
  "28",
  "29",
  "23",
  "27",
  "30",
  "24",
  "26",
  "28",
  "26",
  "26",
  "17",
  "25",
  "25",
  "25",
  "25",
  "24",
  "23",
  "26",
  "28",
  "23",
  "28",
  "25",
  "27",
  "24",
  "21",
  "27",
  "27",
  "29",
  "24",
  "28",
  "21",
  "28",
  "24",
  "21",
  "26",
  "25",
  "26",
  "25",
  "26",
  "29",
  "24",
  "28",
  "25",
  "27",
  "22",
  "27",
  "27",
  "24",
  "27",
  "21",
  "24",
  "26",
  "28",
  "32",
  "22",
  "26",
  "28",
  "21",
  "27",
  "27",
  "19",
  "29",
  "27",
  "24",
  "28",
  "28",
  "28",
  "27",
  "22",
  "26",
  "26",
  "25",
  "26",
  "21",
  "26",
  "27",
  "28",
  "29",
  "25",
  "24",
  "23",
  "23",
  "26",
  "24",
  "27",
  "25",
  "30",
  "29",
  "27",
  "21",
  "23",
  "28",
  "30",
  "29",
  "29",
  "27",
  "30",
  "24",
  "18",
  "24",
  "24",
  "27",
  "28",
  "29",
  "24",
  "26",
  "26",
  "31",
  "30",
  "27",
  "28",
  "28",
  "26",
  "24",
  "26",
  "24",
  "30",
  "26",
  "26",
  "26",
  "26",
  "25",
  "25",
  "20",
  "29",
  "27",
  "23",
  "21",
  "24",
  "28",
  "29",
  "27",
  "27",
  "27",
  "27",
  "23",
  "25",
  "26",
  "23",
  "22",
  "28",
  "22",
  "26",
  "29",
  "26",
  "25",
  "25",
  "24",
  "30",
  "21",
  "29",
  "27",
  "24",
  "23",
  "29",
  "23",
  "29",
  "24",
  "23",
  "28",
  "22",
  "23",
  "28",
  "27",
  "22",
  "20",
  "26",
  "28",
  "26",
  "22",
  "15",
  "25",
  "27",
  "26",
  "26",
  "25",
  "26",
  "28",
  "23",
  "25",
  "25",
  "23",
  "28",
  "24",
  "26",
  "25",
  "22",
  "24",
  "23",
  "29",
  "27",
  "28",
  "24",
  "26",
  "26",
  "21",
  "28",
  "23",
  "29",
  "28",
  "28",
  "25",
  "28",
  "29",
  "26",
  "25",
  "27",
  "27",
  "27",
  "29",
  "17",
  "25",
  "27",
  "26",
  "27",
  "29",
  "29",
  "29",
  "27",
  "25",
  "25",
  "31",
  "25",
  "22",
  "28",
  "21",
  "23",
  "26",
  "26",
  "22",
  "27",
  "27",
  "27",
  "21",
  "28",
  "20",
  "24",
  "26",
  "25",
  "27",
  "18",
  "26",
  "24",
  "27",
  "28",
  "28",
  "28",
  "26",
  "27",
  "28",
  "24",
  "28",
  "24",
  "25",
  "25",
  "26",
  "24",
  "21",
  "28",
  "25",
  "28",
  "21",
  "26",
  "27",
  "25",
  "25",
  "26",
  "22",
  "26",
  "21",
  "28",
  "23",
  "27",
  "26",
  "27",
  "22",
  "26",
  "26",
  "24",
  "30",
  "22",
  "25",
  "27",
  "32",
  "27",
  "24",
  "24",
  "25",
  "26",
  "30",
  "27",
  "26",
  "28",
  "26",
  "27",
  "28",
  "18",
  "26",
  "27",
  "28",
  "27",
  "25",
  "27",
  "29",
  "18",
  "25",
  "24",
  "28",
  "30",
  "29",
  "27",
  "21",
  "27",
  "24",
  "22",
  "26",
  "27",
  "29",
  "19",
  "25",
  "26",
  "26",
  "27",
  "23",
  "31",
  "24",
  "21",
  "26",
  "29",
  "32",
  "27",
  "22",
  "28",
  "22",
  "28",
  "28",
  "27",
  "28",
  "26",
  "26",
  "24",
  "28",
  "25",
  "26",
  "27",
  "24",
  "20",
  "27",
  "22",
  "25",
  "28",
  "26",
  "26",
  "24",
  "27",
  "28",
  "29",
  "30",
  "29",
  "26",
  "26",
  "18",
  "20",
  "27",
  "22",
  "23",
  "31",
  "28",
  "24",
  "27",
  "20",
  "29",
  "29",
  "23",
  "23",
  "25",
  "25",
  "24",
  "23",
  "23",
  "26",
  "28",
  "24",
  "28",
  "27",
  "27",
  "21",
  "26",
  "28",
  "29",
  "28",
  "27",
  "21",
  "22",
  "24",
  "27",
  "23",
  "20",
  "21",
  "27",
  "26",
  "29",
  "28",
  "28",
  "28",
  "20",
  "27",
  "30",
  "28",
  "25",
  "31",
  "29",
  "25",
  "27",
  "21",
  "31",
  "24",
  "26",
  "27",
  "27",
  "23",
  "27",
  "22",
  "26",
  "22",
  "29",
  "28",
  "23",
  "27",
  "27",
  "28",
  "23",
  "26",
  "30",
  "29",
  "27",
  "21",
  "24",
  "27",
  "26",
  "25",
  "25",
  "28",
  "27",
  "28",
  "24",
  "27",
  "27",
  "27",
  "21",
  "26",
  "25",
  "30",
  "25",
  "28",
  "24",
  "26",
  "28",
  "26",
  "21",
  "20",
  "30",
  "26",
  "28",
  "28",
  "24",
  "27",
  "27",
  "28",
  "23",
  "25",
  "21",
  "26",
  "29",
  "28",
  "26",
  "26",
  "30",
  "24",
  "30",
  "31",
  "26",
  "25",
  "25",
  "23",
  "27",
  "27",
  "27",
  "32",
  "20",
  "28",
  "26",
  "25",
  "25",
  "28",
  "23",
  "20",
  "27",
  "24",
  "27",
  "23",
  "27",
  "16",
  "29",
  "19",
  "30",
  "27",
  "27",
  "19",
  "21",
  "25",
  "28",
  "26",
  "24",
  "27",
  "28",
  "20",
  "30",
  "22",
  "20",
  "24",
  "24",
  "27",
  "24",
  "24",
  "25",
  "26",
  "24",
  "28",
  "24",
  "23",
  "24",
  "29",
  "25",
  "24",
  "20",
  "27",
  "29",
  "28",
  "27",
  "30",
  "25",
  "29",
  "25",
  "26",
  "27",
  "28",
  "22",
  "22",
  "27",
  "28",
  "29",
  "27",
  "29",
  "22",
  "28",
  "21",
  "25",
  "25",
  "23",
  "26",
  "24",
  "24",
  "27",
  "29",
  "24",
  "24",
  "28",
  "24",
  "26",
  "25",
  "31",
  "25",
  "30",
  "23",
  "25",
  "27",
  "25",
  "27",
  "22",
  "24",
  "28",
  "22",
  "25",
  "24",
  "21",
  "20",
  "25",
  "28",
  "31",
  "26",
  "26",
  "20",
  "23",
  "26",
  "23",
  "29",
  "20",
  "24",
  "27",
  "19",
  "26",
  "27",
  "29",
  "25",
  "17",
  "29",
  "25",
  "29",
  "29",
  "28",
  "24",
  "29",
  "28",
  "26",
  "20",
  "28",
  "26",
  "22",
  "29",
  "27",
  "23",
  "24",
  "25",
  "26",
  "24",
  "27",
  "31",
  "25",
  "24",
  "26",
  "25",
  "21",
  "25",
  "25",
  "27",
  "26",
  "25",
  "27",
  "26",
  "29",
  "30",
  "27",
  "24",
  "24",
  "27",
  "28",
  "25",
  "27",
  "31",
  "31",
  "26",
  "27",
  "25",
  "23",
  "22",
  "24",
  "27",
  "26",
  "25",
  "26",
  "25",
  "26",
  "28",
  "27",
  "26",
  "28",
  "27",
  "24",
  "23",
  "24",
  "25",
  "20",
  "26",
  "24",
  "27",
  "20",
  "24",
  "26",
  "21",
  "21",
  "24",
  "29",
  "27",
  "29",
  "26",
  "28",
  "25",
  "27",
  "21",
  "27",
  "23",
  "27",
  "21",
  "29",
  "28",
  "23",
  "27",
  "25",
  "30",
  "25",
  "26",
  "25",
  "29",
  "26",
  "29",
  "23",
  "24",
  "28",
  "19",
  "24",
  "27",
  "27",
  "26",
  "21",
  "28",
  "30",
  "23",
  "27",
  "26",
  "22",
  "25",
  "29",
  "32",
  "23",
  "26",
  "25",
  "27",
  "20",
  "25",
  "29",
  "27",
  "24",
  "26",
  "26",
  "29",
  "28",
  "32",
  "26",
  "28",
  "27",
  "24",
  "26",
  "28",
  "23",
  "26",
  "20",
  "30",
  "22",
  "25",
  "26",
  "24",
  "25",
  "24",
  "26",
  "28",
  "27",
  "19",
  "29",
  "24",
  "25",
  "29",
  "25",
  "21",
  "24",
  "28",
  "26",
  "25",
  "27",
  "22",
  "20",
  "28",
  "26",
  "24",
  "23",
  "27",
  "25",
  "26",
  "26",
  "26",
  "28",
  "24",
  "24",
  "24",
  "26",
  "22",
  "29",
  "29",
  "23",
  "27",
  "27",
  "23",
  "26",
  "22",
  "26",
  "28",
  "26",
  "25",
  "27",
  "18",
  "27",
  "24",
  "27",
  "27",
  "26",
  "26",
  "27",
  "21",
  "27",
  "29",
  "27",
  "27",
  "26",
  "27",
  "19",
  "28",
  "28",
  "21",
  "25",
  "29",
  "21",
  "26",
  "28",
  "17",
  "21",
  "27",
  "21",
  "26",
  "24",
  "26",
  "27",
  "29",
  "28",
  "26",
  "26",
  "27",
  "27",
  "22",
  "27",
  "27",
  "28",
  "26",
  "25",
  "23",
  "18",
  "26",
  "25",
  "26",
  "24",
  "28",
  "24",
  "22",
  "26",
  "27",
  "31",
  "24",
  "24",
  "27",
  "21",
  "25",
  "27",
  "26",
  "26",
  "20",
  "26",
  "27",
  "28",
  "28",
  "26",
  "29",
  "23",
  "29",
  "27",
  "28",
  "26",
  "26",
  "22",
  "26",
  "26",
  "23",
  "29",
  "29",
  "22",
  "27",
  "25",
  "33",
  "25",
  "28",
  "29",
  "25",
  "24",
  "25",
  "26",
  "27",
  "28",
  "28",
  "23",
  "25",
  "28",
  "25",
  "28",
  "26",
  "27",
  "29",
  "26",
  "29",
  "28",
  "29",
  "21",
  "25",
  "25",
  "26",
  "28",
  "24",
  "22",
  "28",
  "24",
  "26",
  "26",
  "24",
  "24",
  "27",
  "26",
  "23",
  "24",
  "26",
  "20",
  "20",
  "25",
  "22",
  "22",
  "27",
  "23",
  "21",
  "26",
  "24",
  "27",
  "23",
  "27",
  "28",
  "26",
  "26",
  "24",
  "28",
  "30",
  "25",
  "26",
  "25",
  "28",
  "26",
  "30",
  "26",
  "25",
  "24",
  "22",
  "26",
  "26",
  "28",
  "28",
  "27",
  "24",
  "27",
  "27",
  "29",
  "28",
  "26",
  "26",
  "25",
  "26",
  "28",
  "27",
  "20",
  "28",
  "26",
  "27",
  "23",
  "26",
  "26",
  "22",
  "27",
  "27",
  "26",
  "19",
  "28",
  "18",
  "24",
  "29",
  "27",
  "25",
  "25",
  "24",
  "25",
  "22",
  "22",
  "26",
  "23",
  "28",
  "26",
  "23",
  "21",
  "28",
  "28",
  "24",
  "29",
  "28",
  "25",
  "23",
  "29",
  "26",
  "27",
  "24",
  "22",
  "24",
  "28",
  "29",
  "25",
  "23",
  "29",
  "20",
  "19",
  "25",
  "24",
  "29",
  "25",
  "25",
  "25",
  "27",
  "26",
  "26",
  "24",
  "28",
  "26",
  "24",
  "26",
  "27",
  "26",
  "27",
  "26",
  "29",
  "25",
  "25",
  "30",
  "26",
  "22",
  "23",
  "23",
  "21",
  "25",
  "29",
  "26",
  "18",
  "25",
  "24",
  "25",
  "28",
  "29",
  "23",
  "27",
  "26",
  "26",
  "29",
  "27",
  "25",
  "26",
  "25",
  "26",
  "26",
  "24",
  "23",
  "27",
  "19",
  "28",
  "30",
  "24",
  "27",
  "27",
  "29",
  "30",
  "21",
  "21",
  "25",
  "21",
  "25",
  "24",
  "25",
  "24",
  "30",
  "25",
  "26",
  "27",
  "29",
  "27",
  "26",
  "23",
  "27",
  "27",
  "26",
  "27",
  "24",
  "25",
  "25",
  "25",
  "22",
  "29",
  "25",
  "21",
  "28",
  "29",
  "25",
  "27",
  "22",
  "26",
  "23",
  "26",
  "28",
  "27",
  "21",
  "26",
  "23",
  "21",
  "28",
  "24",
  "29",
  "28",
  "27",
  "27",
  "25",
  "24",
  "30",
  "24",
  "21",
  "27",
  "21",
  "24",
  "21",
  "27",
  "23",
  "23",
  "25",
  "25",
  "22",
  "23",
  "29",
  "26",
  "26",
  "22",
  "26",
  "29",
  "30",
  "24",
  "24",
  "20",
  "21",
  "24",
  "27",
  "26",
  "23",
  "27",
  "30",
  "25",
  "25",
  "26",
  "26",
  "22",
  "25",
  "20",
  "26",
  "24",
  "28",
  "26",
  "26",
  "21",
  "26",
  "25",
  "27",
  "19",
  "25",
  "22",
  "27",
  "26",
  "23",
  "23",
  "27",
  "29",
  "26",
  "30",
  "30",
  "24",
  "25",
  "25",
  "22",
  "27",
  "26",
  "29",
  "25",
  "26",
  "30",
  "25",
  "26",
  "20",
  "26",
  "27",
  "27",
  "23",
  "26",
  "23",
  "25",
  "23",
  "26",
  "28",
  "24",
  "26",
  "27",
  "26",
  "30",
  "23",
  "28",
  "22",
  "29",
  "27",
  "25",
  "27",
  "25",
  "29",
  "25",
  "28",
  "26",
  "26",
  "23",
  "23",
  "25",
  "25",
  "25",
  "25",
  "28",
  "26",
  "25",
  "17",
  "21",
  "21",
  "24",
  "24",
  "24",
  "25",
  "25",
  "26",
  "25",
  "24",
  "25",
  "26",
  "26",
  "22",
  "26",
  "27",
  "24",
  "26",
  "25",
  "24",
  "25",
  "28",
  "25",
  "25",
  "27",
  "21",
  "27",
  "23",
  "26",
  "20",
  "27",
  "24",
  "29",
  "27",
  "25",
  "25",
  "25",
  "24",
  "27",
  "26",
  "27",
  "23",
  "26",
  "26",
  "28",
  "26",
  "26",
  "21",
  "28",
  "26",
  "25",
  "25",
  "23",
  "25",
  "22",
  "23",
  "28",
  "28",
  "27",
  "24",
  "21",
  "24",
  "28",
  "24",
  "24",
  "23",
  "26",
  "29",
  "25",
  "26",
  "19",
  "30",
  "26",
  "27",
  "25",
  "24",
  "25",
  "28",
  "26",
  "26",
  "24",
  "26",
  "24",
  "24",
  "26",
  "23",
  "25",
  "23",
  "22",
  "28",
  "26",
  "28",
  "22",
  "28",
  "29",
  "28",
  "20",
  "29",
  "27",
  "29",
  "22",
  "27",
  "31",
  "30",
  "27",
  "25",
  "27",
  "27",
  "22",
  "22",
  "28",
  "27",
  "25",
  "27",
  "21",
  "24",
  "24",
  "26",
  "22",
  "26",
  "25",
  "29",
  "26",
  "22",
  "26",
  "27",
  "21",
  "25",
  "27",
  "25",
  "26",
  "25",
  "27",
  "26",
  "27",
  "26",
  "25",
  "28",
  "25",
  "28",
  "24",
  "28",
  "24",
  "24",
  "26",
  "20",
  "25",
  "26",
  "30",
  "27",
  "29",
  "27",
  "26",
  "24",
  "25",
  "26",
  "21",
  "27",
  "24",
  "30",
  "28",
  "23",
  "24",
  "26",
  "23",
  "24",
  "28",
  "20",
  "20",
  "28",
  "24",
  "25",
  "21",
  "25",
  "27",
  "19",
  "25",
  "25",
  "25",
  "26",
  "29",
  "21",
  "26",
  "23",
  "26",
  "23",
  "29",
  "25",
  "28",
  "21",
  "28",
  "27",
  "23",
  "26",
  "24",
  "25",
  "28",
  "22",
  "22",
  "24",
  "28",
  "25",
  "26",
  "22",
  "23",
  "30",
  "25",
  "27",
  "25",
  "24",
  "24",
  "25",
  "25",
  "27",
  "28",
  "25",
  "29",
  "26",
  "27",
  "21",
  "27",
  "25",
  "24",
  "27",
  "30",
  "25",
  "29",
  "25",
  "26",
  "27",
  "25",
  "23",
  "20",
  "22",
  "27",
  "26",
  "18",
  "27",
  "22",
  "25",
  "25",
  "24",
  "21",
  "27",
  "27",
  "29",
  "26",
  "28",
  "24",
  "23",
  "25",
  "23",
  "27",
  "25",
  "28",
  "23",
  "28",
  "24",
  "17",
  "27",
  "30",
  "25",
  "24",
  "26",
  "27",
  "30",
  "28",
  "27",
  "23",
  "23",
  "23",
  "25",
  "27",
  "27",
  "28",
  "24",
  "27",
  "25",
  "27",
  "24",
  "24",
  "26",
  "26",
  "26",
  "24",
  "22",
  "25",
  "23",
  "20",
  "25",
  "26",
  "20",
  "25",
  "25",
  "24",
  "30",
  "25",
  "27",
  "29",
  "26",
  "26",
  "26",
  "24",
  "26",
  "25",
  "25",
  "26",
  "18",
  "25",
  "27",
  "21",
  "29",
  "24",
  "28",
  "26",
  "23",
  "26",
  "27",
  "23",
  "25",
  "28",
  "21",
  "26",
  "24",
  "29",
  "28",
  "28",
  "29",
  "25",
  "30",
  "23",
  "26",
  "28",
  "26",
  "24",
  "18",
  "26",
  "25",
  "23",
  "25",
  "26",
  "28",
  "26",
  "26",
  "22",
  "28",
  "27",
  "24",
  "21",
  "25",
  "29",
  "29",
  "24",
  "25",
  "23",
  "24",
  "22",
  "23",
  "27",
  "23",
  "26",
  "24",
  "23",
  "21",
  "26",
  "24",
  "29",
  "25",
  "27",
  "27",
  "28",
  "25",
  "30",
  "22",
  "25",
  "27",
  "22",
  "23",
  "22",
  "27",
  "25",
  "26",
  "25",
  "27",
  "22",
  "26",
  "26",
  "28",
  "26",
  "27",
  "20",
  "26",
  "27",
  "26",
  "23",
  "23",
  "26",
  "25",
  "25",
  "22",
  "26",
  "20",
  "23",
  "25",
  "26",
  "26",
  "29",
  "20",
  "24",
  "25",
  "23",
  "29",
  "26",
  "28",
  "19",
  "25",
  "22",
  "25",
  "26",
  "26",
  "22",
  "24",
  "24",
  "24",
  "27",
  "23",
  "22",
  "25",
  "27",
  "25",
  "21",
  "24",
  "26",
  "25",
  "22",
  "28",
  "24",
  "27",
  "27",
  "26",
  "20",
  "28",
  "22",
  "29",
  "24",
  "26",
  "22",
  "25",
  "25",
  "26",
  "19",
  "26",
  "26",
  "24",
  "26",
  "21",
  "28",
  "23",
  "25",
  "26",
  "23",
  "25",
  "23",
  "30",
  "24",
  "21",
  "27",
  "23",
  "21",
  "26",
  "22",
  "19",
  "23",
  "27",
  "22",
  "26",
  "22",
  "24",
  "23",
  "27",
  "20",
  "25",
  "27",
  "23",
  "27",
  "29",
  "21",
  "28",
  "24",
  "27",
  "28",
  "26",
  "22",
  "27",
  "21",
  "21",
  "22",
  "20",
  "24",
  "22",
  "25",
  "26",
  "26",
  "20",
  "27",
  "20",
  "25",
  "27",
  "25",
  "26",
  "27",
  "27",
  "25",
  "27",
  "20",
  "28",
  "26",
  "24",
  "22",
  "25",
  "21",
  "29",
  "23",
  "28",
  "22",
  "23",
  "23",
  "25",
  "26",
  "28",
  "28",
  "27",
  "25",
  "21",
  "24",
  "22",
  "22",
  "27",
  "27",
  "27",
  "26",
  "19",
  "28",
  "24",
  "20",
  "19",
  "28",
  "25",
  "25",
  "28",
  "25",
  "26",
  "26",
  "20",
  "25",
  "22",
  "27",
  "27",
  "27",
  "19",
  "26",
  "29",
  "25",
  "27",
  "28",
  "21",
  "27",
  "23",
  "27",
  "24",
  "26",
  "22",
  "25",
  "25",
  "23",
  "24",
  "24",
  "27",
  "25",
  "24",
  "21",
  "24",
  "26",
  "23",
  "26",
  "25",
  "25",
  "24",
  "24",
  "28",
  "26",
  "24",
  "28",
  "26",
  "23",
  "26",
  "25",
  "24",
  "21",
  "23",
  "25",
  "23",
  "23",
  "21",
  "22",
  "25",
  "24",
  "23",
  "23",
  "27",
  "28",
  "27",
  "24",
  "27",
  "23",
  "26",
  "25",
  "22",
  "18",
  "25",
  "20",
  "22",
  "26",
  "26",
  "24",
  "27",
  "24",
  "26",
  "26",
  "25",
  "21",
  "25",
  "22",
  "22",
  "26",
  "26",
  "23",
  "27",
  "26",
  "25",
  "25",
  "26",
  "29",
  "25",
  "23",
  "29",
  "28",
  "25",
  "21",
  "26",
  "27",
  "26",
  "25",
  "26",
  "23",
  "25",
  "26",
  "26",
  "25",
  "27",
  "26",
  "20",
  "25",
  "27",
  "25",
  "24",
  "30",
  "22",
  "25",
  "22",
  "25",
  "25",
  "23",
  "26",
  "26",
  "27",
  "21",
  "24",
  "28",
  "21",
  "28",
  "27",
  "23",
  "27",
  "26",
  "24",
  "22",
  "27",
  "24",
  "22",
  "26",
  "26",
  "26",
  "25",
  "25",
  "19",
  "29",
  "25",
  "27",
  "24",
  "21",
  "19",
  "27",
  "27",
  "27",
  "21",
  "25",
  "24",
  "27",
  "25",
  "23",
  "24",
  "27",
  "25",
  "26",
  "27",
  "26",
  "29",
  "26",
  "19",
  "27",
  "23",
  "24",
  "27",
  "25",
  "22",
  "25",
  "29",
  "25",
  "25",
  "25",
  "27",
  "26",
  "27",
  "22",
  "23",
  "19",
  "23",
  "26",
  "24",
  "26",
  "25",
  "23",
  "26",
  "27",
  "24",
  "25",
  "22",
  "25",
  "21",
  "23",
  "25",
  "27",
  "25",
  "23",
  "19",
  "23",
  "29",
  "29",
  "22",
  "25",
  "20",
  "22",
  "24",
  "25",
  "27",
  "29",
  "22",
  "21",
  "28",
  "24",
  "19",
  "26",
  "26",
  "23",
  "25",
  "29",
  "25",
  "27",
  "19",
  "23",
  "26",
  "24",
  "24",
  "28",
  "28",
  "25",
  "29",
  "24",
  "29",
  "22",
  "22",
  "23",
  "24",
  "26",
  "28",
  "24",
  "23",
  "23",
  "26",
  "22",
  "26",
  "20",
  "27",
  "25",
  "27",
  "24",
  "28",
  "25",
  "25",
  "25",
  "22",
  "25",
  "29",
  "26",
  "26",
  "28",
  "26",
  "25",
  "23",
  "20",
  "28",
  "21",
  "22",
  "24",
  "26",
  "20",
  "26",
  "28",
  "24",
  "26",
  "23",
  "27",
  "28",
  "27",
  "25",
  "21",
  "28",
  "26",
  "26",
  "27",
  "26",
  "29",
  "28",
  "21",
  "28",
  "21",
  "20",
  "27",
  "29",
  "19",
  "26",
  "26",
  "25",
  "26",
  "26",
  "27",
  "23",
  "27",
  "28",
  "25",
  "25",
  "24",
  "26",
  "25",
  "22",
  "21",
  "28",
  "22",
  "25",
  "29",
  "24",
  "28",
  "24",
  "27",
  "26",
  "25",
  "29",
  "30",
  "19",
  "28",
  "27",
  "28",
  "23",
  "24",
  "24",
  "22",
  "25",
  "23",
  "29",
  "21",
  "25",
  "24",
  "27",
  "22",
  "28",
  "26",
  "26",
  "26",
  "28",
  "25",
  "28",
  "26",
  "25",
  "24",
  "28",
  "29",
  "27",
  "24",
  "25",
  "27",
  "22",
  "27",
  "28",
  "26",
  "18",
  "24",
  "23",
  "26",
  "25",
  "25",
  "25",
  "26",
  "27",
  "27",
  "23",
  "22",
  "27",
  "24",
  "23",
  "28",
  "27",
  "23",
  "25",
  "28",
  "21",
  "27",
  "26",
  "24",
  "25",
  "24",
  "22",
  "23",
  "19",
  "28",
  "26",
  "25",
  "20",
  "24",
  "29",
  "26",
  "27",
  "21",
  "25",
  "29",
  "26",
  "27",
  "24",
  "26",
  "26",
  "17",
  "28",
  "28",
  "28",
  "26",
  "23",
  "28",
  "24",
  "26",
  "22",
  "25",
  "25",
  "29",
  "23",
  "25",
  "25",
  "29",
  "23",
  "23",
  "21",
  "22",
  "28",
  "25",
  "28",
  "20",
  "25",
  "27",
  "27",
  "27",
  "23",
  "24",
  "26",
  "26",
  "26",
  "26",
  "27",
  "25",
  "25",
  "25",
  "26",
  "21",
  "26",
  "26",
  "27",
  "26",
  "18",
  "22",
  "19",
  "28",
  "25",
  "20",
  "26",
  "22",
  "27",
  "32",
  "26",
  "23",
  "27",
  "23",
  "21",
  "26",
  "24",
  "27",
  "21",
  "25",
  "22",
  "25",
  "25",
  "25",
  "26",
  "25",
  "27",
  "28",
  "26",
  "23",
  "29",
  "22",
  "25",
  "21",
  "24",
  "20",
  "27",
  "25",
  "28",
  "25",
  "27",
  "21",
  "26",
  "23",
  "24",
  "28",
  "26",
  "17",
  "25",
  "24",
  "21",
  "25",
  "28",
  "21",
  "23",
  "29",
  "23",
  "23",
  "23",
  "20",
  "23",
  "27",
  "28",
  "29",
  "23",
  "22",
  "18",
  "25",
  "26",
  "26",
  "20",
  "24",
  "28",
  "20",
  "21",
  "21",
  "24",
  "26",
  "28",
  "28",
  "24",
  "25",
  "23",
  "19",
  "19",
  "25",
  "24",
  "25",
  "28",
  "24",
  "29",
  "22",
  "23",
  "21",
  "25",
  "28",
  "28",
  "25",
  "24",
  "27",
  "25",
  "27",
  "27",
  "23",
  "22",
  "25",
  "18",
  "23",
  "23",
  "27",
  "28",
  "26",
  "20",
  "28",
  "22",
  "26",
  "28",
  "21",
  "23",
  "26",
  "26",
  "26",
  "18",
  "20",
  "28",
  "27",
  "28",
  "27",
  "25",
  "19",
  "27",
  "26",
  "25",
  "22",
  "27",
  "27",
  "31",
  "23",
  "24",
  "19",
  "21",
  "24",
  "27",
  "26",
  "23",
  "25",
  "24",
  "28",
  "25",
  "24",
  "24",
  "24",
  "26",
  "19",
  "24",
  "22",
  "25",
  "23",
  "23",
  "24",
  "26",
  "27",
  "22",
  "26",
  "29",
  "27",
  "24",
  "26",
  "26",
  "27",
  "21",
  "26",
  "16",
  "25",
  "22",
  "22",
  "26",
  "23",
  "23",
  "27",
  "26",
  "24",
  "24",
  "25",
  "27",
  "29",
  "27",
  "29",
  "24",
  "23",
  "28",
  "24",
  "27",
  "25",
  "25",
  "27",
  "28",
  "27",
  "26",
  "20",
  "26",
  "25",
  "21",
  "21",
  "27",
  "28",
  "27",
  "26",
  "21",
  "24",
  "20",
  "28",
  "26",
  "27",
  "23",
  "22",
  "22",
  "23",
  "25",
  "22",
  "28",
  "22",
  "24",
  "24",
  "26",
  "23",
  "26",
  "24",
  "24",
  "20",
  "25",
  "28",
  "27",
  "30",
  "24",
  "22",
  "26",
  "25",
  "27",
  "23",
  "22",
  "25",
  "26",
  "24",
  "24",
  "24",
  "23",
  "24",
  "23",
  "23",
  "26",
  "22",
  "24",
  "25",
  "18",
  "26",
  "21",
  "26",
  "28",
  "27",
  "24",
  "23",
  "24",
  "27",
  "19",
  "24",
  "24",
  "24",
  "21",
  "28",
  "27",
  "27",
  "28",
  "28",
  "28",
  "27",
  "26",
  "27",
  "26",
  "23",
  "28",
  "27",
  "20",
  "23",
  "26",
  "29",
  "22",
  "23",
  "20",
  "28",
  "23",
  "24",
  "25",
  "28",
  "24",
  "21",
  "27",
  "27",
  "24",
  "27",
  "26",
  "21",
  "26",
  "26",
  "27",
  "27",
  "24",
  "27",
  "24",
  "24",
  "27",
  "21",
  "21",
  "27",
  "24",
  "26",
  "25",
  "23",
  "20",
  "24",
  "25",
  "27",
  "17",
  "19",
  "28",
  "26",
  "27",
  "24",
  "27",
  "26",
  "27",
  "22",
  "23",
  "25",
  "24",
  "26",
  "23",
  "29",
  "25",
  "27",
  "19",
  "24",
  "23",
  "23",
  "27",
  "26",
  "22",
  "25",
  "27",
  "26",
  "28",
  "24",
  "22",
  "27",
  "22",
  "27",
  "25",
  "23",
  "27",
  "24",
  "26",
  "25",
  "25",
  "23",
  "26",
  "25",
  "24",
  "20",
  "27",
  "25",
  "22",
  "21",
  "25",
  "24",
  "23",
  "25",
  "20",
  "24",
  "24",
  "25",
  "24",
  "22",
  "24",
  "25",
  "25",
  "24",
  "24",
  "25",
  "25",
  "23",
  "20",
  "17",
  "28",
  "28",
  "26",
  "21",
  "25",
  "22",
  "28",
  "25",
  "25",
  "26",
  "27",
  "23",
  "24",
  "22",
  "25",
  "28",
  "29",
  "24",
  "24",
  "27",
  "25",
  "27",
  "21",
  "20",
  "22",
  "25",
  "25",
  "22",
  "28",
  "25",
  "25",
  "20",
  "21",
  "24",
  "25",
  "27",
  "28",
  "27",
  "27",
  "24",
  "23",
  "25",
  "28",
  "20",
  "22",
  "23",
  "23",
  "29",
  "26",
  "25",
  "26",
  "25",
  "19",
  "27",
  "26",
  "24",
  "26",
  "26",
  "28",
  "19",
  "24",
  "26",
  "26",
  "20",
  "27",
  "24",
  "27",
  "26",
  "22",
  "27",
  "26",
  "22",
  "25",
  "25",
  "23",
  "26",
  "23",
  "22",
  "26",
  "26",
  "21",
  "27",
  "17",
  "27",
  "26",
  "25",
  "24",
  "24",
  "23",
  "25",
  "25",
  "27",
  "27",
  "19",
  "21",
  "26",
  "24",
  "27",
  "23",
  "25",
  "23",
  "25",
  "21",
  "25",
  "23",
  "26",
  "28",
  "23",
  "26",
  "21",
  "25",
  "25",
  "22",
  "23",
  "20",
  "23",
  "26",
  "25",
  "24",
  "25",
  "22",
  "26",
  "24",
  "25",
  "23",
  "21",
  "18",
  "21",
  "24",
  "23",
  "28",
  "25",
  "24",
  "19",
  "24",
  "24",
  "25",
  "26",
  "25",
  "20",
  "27",
  "28",
  "19",
  "22",
  "21",
  "28",
  "26",
  "27",
  "23",
  "22",
  "27",
  "26",
  "26",
  "26",
  "26",
  "19",
  "22",
  "25",
  "23",
  "30",
  "26",
  "25",
  "27",
  "24",
  "25",
  "26",
  "23",
  "23",
  "27",
  "28",
  "19",
  "24",
  "22",
  "24",
  "24",
  "23",
  "26",
  "22",
  "19",
  "27",
  "23",
  "26",
  "23",
  "24",
  "25",
  "22",
  "15",
  "25",
  "23",
  "25",
  "25",
  "25",
  "25",
  "25",
  "25",
  "26",
  "18",
  "20",
  "23",
  "25",
  "22",
  "21",
  "27",
  "26",
  "20",
  "25",
  "26",
  "22",
  "26",
  "25",
  "25",
  "27",
  "21",
  "22",
  "25",
  "22",
  "24",
  "25",
  "26",
  "25",
  "23",
  "29",
  "20",
  "24",
  "24",
  "27",
  "25",
  "27",
  "24",
  "23",
  "28",
  "26",
  "24",
  "25",
  "25",
  "23",
  "24",
  "18",
  "22",
  "26",
  "20",
  "27",
  "23",
  "19",
  "25",
  "24",
  "20",
  "27",
  "22",
  "21",
  "28",
  "24",
  "25",
  "20",
  "26",
  "23",
  "21",
  "25",
  "26",
  "26",
  "25",
  "20",
  "27",
  "24",
  "25",
  "18",
  "26",
  "22",
  "25",
  "23",
  "22",
  "22",
  "27",
  "21",
  "24",
  "27",
  "25",
  "19",
  "24",
  "25",
  "25",
  "20",
  "22",
  "25",
  "26",
  "19",
  "24",
  "19",
  "25",
  "29",
  "26",
  "26",
  "26",
  "28",
  "24",
  "26",
  "25",
  "26",
  "26",
  "28",
  "21",
  "27",
  "23",
  "29",
  "26",
  "23",
  "25",
  "20",
  "20",
  "27",
  "23",
  "26",
  "27",
  "23",
  "24",
  "23",
  "19",
  "26",
  "27",
  "25",
  "21",
  "21",
  "28",
  "28",
  "26",
  "27",
  "24",
  "25",
  "26",
  "25",
  "25",
  "24",
  "25",
  "20",
  "24",
  "18",
  "23",
  "25",
  "21",
  "25",
  "25",
  "25",
  "25",
  "28",
  "24",
  "22",
  "25",
  "26",
  "23",
  "26",
  "26",
  "22",
  "25",
  "25",
  "23",
  "20",
  "26",
  "25",
  "24",
  "21",
  "22",
  "27",
  "24",
  "25",
  "26",
  "25",
  "26",
  "23",
  "23",
  "25",
  "26",
  "22",
  "26",
  "28",
  "26",
  "24",
  "23",
  "24",
  "21",
  "24",
  "23",
  "24",
  "27",
  "21",
  "26",
  "24",
  "24",
  "25",
  "25",
  "28",
  "26",
  "27",
  "21",
  "24",
  "26",
  "24",
  "24",
  "25",
  "26",
  "24",
  "22",
  "21",
  "24",
  "27",
  "24",
  "27",
  "22",
  "27",
  "24",
  "22",
  "26",
  "22",
  "25",
  "22",
  "23",
  "21",
  "25",
  "24",
  "21",
  "21",
  "23",
  "25",
  "24",
  "23",
  "25",
  "24",
  "25",
  "22",
  "24",
  "25",
  "18",
  "25",
  "24",
  "23",
  "24",
  "27",
  "18",
  "25",
  "24",
  "25",
  "25",
  "20",
  "24",
  "25",
  "24",
  "26",
  "27",
  "23",
  "23",
  "24",
  "25",
  "25",
  "22",
  "24",
  "19",
  "19",
  "25",
  "26",
  "25",
  "24",
  "21",
  "22",
  "24",
  "24",
  "18",
  "25",
  "25",
  "24",
  "18",
  "22",
  "23",
  "25",
  "25",
  "21",
  "25",
  "25",
  "20",
  "23",
  "21",
  "22",
  "25",
  "26",
  "18",
  "22",
  "25",
  "18",
  "27",
  "28",
  "26",
  "26",
  "20",
  "24",
  "23",
  "23",
  "21",
  "29",
  "25",
  "24",
  "26",
  "17",
  "25",
  "27",
  "24",
  "21",
  "22",
  "24",
  "24",
  "27",
  "17",
  "24",
  "25",
  "24",
  "28",
  "24",
  "24",
  "19",
  "17",
  "22",
  "23",
  "26",
  "26",
  "27",
  "25",
  "19",
  "18",
  "18",
  "23",
  "20",
  "24",
  "22",
  "25",
  "23",
  "26",
  "25",
  "26",
  "25",
  "21",
  "23",
  "21",
  "25",
  "25",
  "24",
  "25",
  "25",
  "20",
  "25",
  "24",
  "25",
  "23",
  "25",
  "24",
  "21",
  "27",
  "22",
  "23",
  "27",
  "16",
  "18",
  "24",
  "23",
  "21",
  "23",
  "25",
  "23",
  "23",
  "25",
  "24",
  "27",
  "21",
  "24",
  "25",
  "26",
  "26",
  "24",
  "23",
  "21",
  "25",
  "18",
  "19",
  "24",
  "24",
  "22",
  "21",
  "22",
  "23",
  "23",
  "24",
  "22",
  "20",
  "25",
  "26",
  "24",
  "25",
  "26",
  "27",
  "23",
  "24",
  "25",
  "26",
  "25",
  "21",
  "26",
  "21",
  "25",
  "25",
  "23",
  "24",
  "24",
  "23",
  "18",
  "24",
  "18",
  "20",
  "25",
  "24",
  "21",
  "23",
  "26",
  "20",
  "22",
  "24",
  "25",
  "23",
  "22",
  "24",
  "23",
  "20",
  "27",
  "17",
  "25",
  "24",
  "26",
  "26",
  "25",
  "26",
  "23",
  "22",
  "24",
  "24",
  "24",
  "22",
  "26",
  "28",
  "17",
  "26",
  "25",
  "24",
  "21",
  "22",
  "25",
  "24",
  "24",
  "23",
  "25",
  "23",
  "24",
  "21",
  "23",
  "24",
  "22",
  "25",
  "24",
  "23",
  "22",
  "24",
  "23",
  "20",
  "20",
  "18",
  "24",
  "22",
  "20",
  "28",
  "18",
  "25",
  "23",
  "23",
  "23",
  "24",
  "25",
  "24",
  "19",
  "22",
  "20",
  "25",
  "22",
  "25",
  "23",
  "17",
  "27",
  "21",
  "22",
  "24",
  "21",
  "24",
  "24",
  "26",
  "20",
  "24",
  "17",
  "25",
  "19",
  "22",
  "21",
  "25",
  "25",
  "22",
  "24",
  "25",
  "25",
  "23",
  "25",
  "19",
  "24",
  "19",
  "25",
  "26",
  "23",
  "23",
  "18",
  "24",
  "24",
  "19",
  "21",
  "22",
  "21",
  "19",
  "20",
  "23",
  "20",
];

function GetRank(booNumber) {
  return ranks.indexOf(booNumber) + 1;
}

export default GetRank;

export const GetPower = (booNumber) => {
  return powers[ranks.indexOf(booNumber)];
};
