import React, { Component } from "react";

class BattleArena extends Component {
  state = {
    fighter: this.props.fighter,
  };

  renderArena() {
    const { fighter } = this.state;
    return <></>;
  }

  render() {
    return <>{this.renderArena()}</>;
  }
}

export default BattleArena;
