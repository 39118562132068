import React, { Component } from "react";
import { BooAttributes, BooPower } from "../Battle/BooPower";
import AttBar from "../Battle/Components/attBar";
import AttBarGroup from "../Battle/Components/attBarGroup";

class NFTCardRank extends Component {
  state = {
    type: this.props.type,
    id: this.props.id,
    rank: this.props.rank,
    power: this.props.power,
  };

  renderCardActions() {
    const { type, id, rank, power } = this.state;

    return (
      <div className="card-actions">
        <div className="card-action boo-power" title="Combat Power">
          <div className="icon"></div>
          <div className="value">{power}</div>
        </div>
        <div
          className="card-action boo-rank"
          title="Rank of your Boo - Based on it's traits"
        >
          <div className="icon"></div>
          <div className="value">{rank}</div>
        </div>
        <hr className="card-actions-divider" />
        <AttBarGroup fighter={BooAttributes(id)} />
      </div>
    );
  }

  renderCardAttributes() {
    return (
      <>
        <div className="xpbar base">
          <div
            className="xpbar current"
            style={{ width: "50%" }}
            title="300 XP"
          ></div>
          <div
            className="xpbar pendent"
            style={{ width: "60%" }}
            title="300 XP to collect"
          ></div>
        </div>

        <div className="attributes">
          <div className="main_attributes">
            <div className="att" title="Health Points">
              <strong>❤️ HP:</strong> 120
            </div>
            <div className="att" title="Power">
              <strong>👊 POWER:</strong> 40
            </div>
          </div>
          <div className="minor_attributes">
            <div className="att" title="Stamina">
              <strong>🔋 Sta:</strong> 10
            </div>
            <div className="att" title="Intellect">
              <strong>🧠 Int:</strong> 10
            </div>
            <div className="att" title="Strenght">
              <strong>💪 Str:</strong> 10
            </div>
            <div className="att" title="Defense">
              <strong>🛡 Def:</strong> 10
            </div>
          </div>
        </div>

        <div className="gearItems">
          <div className="column left">
            <div className="gearItem HEAD"></div>
            <div className="gearItem NECK"></div>
            <div className="gearItem CHEST"></div>
            <div className="gearItem WAIST"></div>
            <div className="gearItem unavailable LEGS"></div>
            <div className="gearItem unavailable FEET"></div>
          </div>
          <div className="column right">
            <div className="gearItem HANDS"></div>
            <div className="gearItem TRINKET_LEFT"></div>
            <div className="gearItem TRINKET_RIGHT"></div>
            <div className="gearItem RING_LEFT"></div>
            <div className="gearItem RING_RIGHT"></div>
            <div className="gearItem unavailable PET"></div>
          </div>
        </div>
      </>
    );
  }

  renderCard() {
    const { type, id, rank, power } = this.state;

    return (
      <>
        <li
          key={id}
          className={"boo-card rank"}
          rank={rank}
          power={power}
          boo-id={id}
        >
          <div className="boo-number">{"Boo #" + id}</div>

          <div className="image-wrapper">
            <a
              href={
                "https://opensea.io/assets/matic/0xed9b49afac032401f15527c42c6c54931aa6571a/" +
                id
              }
              alt={"Check it Boo Things #" + id + " at OpenSea"}
              title={"Check it Boo Things #" + id + " at OpenSea"}
              target="_blank"
            >
              <div className="tool opensea"></div>
            </a>

            <img
              src={
                "https://desenhoeilustracao.com.br/wp-content/boo-things/" +
                id +
                ".png"
              }
            />
          </div>

          {this.renderCardActions()}

          <footer className="toolbar"></footer>
        </li>
      </>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default NFTCardRank;
